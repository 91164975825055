import React, {useState} from 'react';
import {Button, Form, Spinner} from 'react-bootstrap';
import { useDispatch, useSelector } from "react-redux";
import { updateUserInfo } from "../../services/action-creators";

const defaultInvoiceSettings = {
    companyName: '',
    vatNumber: '',
    addressLine1: '',
    addressLine2: '',
    city: '',
    stateProvince: '',
    zipPostalCode: '',
    country: ''
};

const AccountInvoiceSettings = () => {
    const dispatch = useDispatch();
    const { currentUser, isUpdateUserLoading } = useSelector((state) => state.authentication);

    if (Object.hasOwn(currentUser, 'invoiceSettings')) {
        defaultInvoiceSettings['companyName'] = currentUser.invoiceSettings.companyName;
        defaultInvoiceSettings['vatNumber'] = currentUser.invoiceSettings.vatNumber;
        defaultInvoiceSettings['addressLine1'] = currentUser.invoiceSettings.addressLine1;
        defaultInvoiceSettings['addressLine2'] = currentUser.invoiceSettings.addressLine2;
        defaultInvoiceSettings['city'] = currentUser.invoiceSettings.city;
        defaultInvoiceSettings['stateProvince'] = currentUser.invoiceSettings.stateProvince;
        defaultInvoiceSettings['zipPostalCode'] = currentUser.invoiceSettings.zipPostalCode;
        defaultInvoiceSettings['country'] = currentUser.invoiceSettings.country;
    }

    const [companyName, setCompanyName] = useState(defaultInvoiceSettings.companyName);
    const [vatNumber, setVatNumber] = useState(defaultInvoiceSettings.vatNumber);
    const [addressLine1, setAddressLine1] = useState(defaultInvoiceSettings.addressLine1);
    const [addressLine2, setAddressLine2] = useState(defaultInvoiceSettings.addressLine2);
    const [city, setCity] = useState(defaultInvoiceSettings.city);
    const [stateProvince, setStateProvince] = useState(defaultInvoiceSettings.stateProvince);
    const [zipPostalCode, setZipPostalCode] = useState(defaultInvoiceSettings.zipPostalCode);
    const [country, setCountry] = useState(defaultInvoiceSettings.country);

    // const [disabled, setDisabled] = useState(true);

    // useEffect(() => {
    //     if (companyName !== defaultInvoiceSettings.companyName
    //         || vatNumber !== defaultInvoiceSettings.vatNumber
    //         || addressLine1 !== defaultInvoiceSettings.addressLine1
    //         || addressLine2 !== defaultInvoiceSettings.addressLine2
    //         || city !== defaultInvoiceSettings.city
    //         || stateProvince !== defaultInvoiceSettings.stateProvince
    //         || zipPostalCode !== defaultInvoiceSettings.zipPostalCode
    //         || country !== defaultInvoiceSettings.country
    //     ) {
    //         setDisabled(false);
    //     } else {
    //         setDisabled(true);
    //     }
    // }, [companyName, vatNumber, addressLine1, addressLine2, city, stateProvince, zipPostalCode, country]);

    const handleCompanyNameChange = (event) => {
        setCompanyName(event.target.value);
    };
    const handleVatNumberChange = (event) => {
        setVatNumber(event.target.value);
    };
    const handleAddressLine1Change = (event) => {
        setAddressLine1(event.target.value);
    };
    const handleAddressLine2Change = (event) => {
        setAddressLine2(event.target.value);
    };
    const handleCityChange = (event) => {
        setCity(event.target.value);
    };
    const handleStateProvinceChange = (event) => {
        setStateProvince(event.target.value);
    };
    const handleZipPostalCodeChange = (event) => {
        setZipPostalCode(event.target.value);
    };
    const handleCountryChange = (event) => {
        setCountry(event.target.value);
    };

    const handleSaveClick = (event) => {
        event.preventDefault();
        dispatch(updateUserInfo({
            currentEmail: currentUser.email,
            id: currentUser._id,
            invoiceSettings:{
                companyName,
                vatNumber,
                addressLine1,
                addressLine2,
                city,
                stateProvince,
                zipPostalCode,
                country
            }
        }));
    };

    return (
        <div className="account_content_section">
            <h1>Invoice Settings</h1>
            <div className="account-invoice-header-comment">Provide 'Bill To' info for Proofy invoices (all fields optional)</div>
            <div className="line"/>
            <div className="form account-form-common">
                <Form>
                    <div className="account-form-row">
                        <Form.Group className="form-group" controlId="firstName">
                            <Form.Label>Company Name</Form.Label>
                            <Form.Control value={companyName} onChange={handleCompanyNameChange} type="text" placeholder="My Company" />
                        </Form.Group>
                        <Form.Group className="form-group" controlId="vatNumber">
                            <Form.Label>VAT, Tax ID/EIN</Form.Label>
                            <Form.Control form-group value={vatNumber} onChange={handleVatNumberChange} type="text" placeholder="GBXXXXXXXXX" />
                        </Form.Group>
                        <Form.Group className="form-group" controlId="addressLine1">
                            <Form.Label>Address Line 1</Form.Label>
                            <Form.Control form-group value={addressLine1} onChange={handleAddressLine1Change} type="text" placeholder="3690 Orange PI" />
                        </Form.Group>
                        <Form.Group className="form-group" controlId="addressLine2">
                            <Form.Label>Address Line 2</Form.Label>
                            <Form.Control form-group value={addressLine2} onChange={handleAddressLine2Change} type="text" placeholder="Suite 310" />
                        </Form.Group>
                    </div>
                    <div className="account-form-row">
                        <Form.Group className="form-group" controlId="city">
                            <Form.Label>City</Form.Label>
                            <Form.Control form-group value={city} onChange={handleCityChange} type="text" placeholder="Cleveland" />
                        </Form.Group>
                        <Form.Group className="form-group" controlId="stateProvince">
                            <Form.Label>State/Province</Form.Label>
                            <Form.Control form-group value={stateProvince} onChange={handleStateProvinceChange} type="text" placeholder="Ohio" />
                        </Form.Group>
                        <Form.Group className="form-group" controlId="zipPostalCode">
                            <Form.Label>Zipcode/Postal Code</Form.Label>
                            <Form.Control form-group value={zipPostalCode} onChange={handleZipPostalCodeChange} type="text" placeholder="44122" />
                        </Form.Group>
                        <Form.Group className="form-group" controlId="country">
                            <Form.Label>Country</Form.Label>
                            <Form.Control form-group value={country} onChange={handleCountryChange} type="text" placeholder="United States" />
                        </Form.Group>
                    </div>
                    <div className="account-from-controls">
                        <Button className="mobile-mt-16 mobile-btn-width" onClick={handleSaveClick} variant="success" type="submit" disabled={isUpdateUserLoading}>
                            {!isUpdateUserLoading && <img className="btn-icon" src="/images/account/save-btn-icon.svg" alt=""/>}
                            {isUpdateUserLoading && (
                                <Spinner
                                    className="mr14"
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                />
                            )}
                            Save
                        </Button>
                    </div>
                </Form>
            </div>
        </div>
    )
};

export default AccountInvoiceSettings;
