import React, {useState} from 'react';
import {Button, Modal} from "react-bootstrap";

const PaymentResultModal = ({success}) => {

    const [showModal, setShowModal] = useState(true);

    const successHeader = ' Your payment was completed successfully';
    const successMessage = 'You have completed your payment. Thank you for choosing us.'

    const failureHeader = 'Your payment was not completed successfully';
    const failureMessage = 'To buy credits, select a package or a custom amount and try again.';

    const handleClose = () => {
        setShowModal(false);
        if (success) {
            window.location.href = '/bulk';
        } else {
            window.location.href = '/payment';
        }
    }

    return (
        <Modal centered
               show={showModal}
               onHide={handleClose}
               className="modal-payment-result">
            <button onClick={handleClose} type="button" className="btn-close btn-close-absolute" aria-label="Close"/>
            <div className="small-modal-content">
                {success
                    ? <img className="mb-4" src="images/success.svg" alt="Success icon"/>
                    : <img className="mb-4" src="images/failure.svg" alt="Failure icon"/>
                }
                <div className="flex-row align-center">
                <h3>{success ? successHeader : failureHeader}</h3>
                </div>
                <div className="modal-text">
                    {success ? successMessage : failureMessage}
                </div>
                <div className="modal-payment-footer">
                    <Button className="adm-btn" variant="outline-secondary" onClick={handleClose}>Continue</Button>
                </div>
            </div>
        </Modal>
    );
}

export default PaymentResultModal;
