import {
    CHOOSE_YOUR_PROFILE,
    CHOOSE_EMAIL_PREFERENCES,
    CHOOSE_SECURITY_SETTINGS,
    CHOOSE_CHANGE_PASSWORD,
    CHOOSE_TEAM_SETTINGS,
    CHOOSE_BILLING_SETTINGS,
    CHOOSE_INVOICE_SETTINGS,
    CHOOSE_PAYMENT_METHODS,
    SHOW_PAYMENT_DELETE_MODAL,
    HIDE_PAYMENT_DELETE_MODAL,
    PAYMENT_METHOD_DELETE_REQUEST,
    PAYMENT_METHOD_DELETE_SUCCESS,
    PAYMENT_METHOD_DELETE_FAILURE,
    GET_PAYMENT_METHODS_REQUEST,
    GET_PAYMENT_METHODS_SUCCESS,
    GET_PAYMENT_METHODS_FAILURE,
    GET_SUBSCRIPTIONS_LIST_REQUEST,
    GET_SUBSCRIPTIONS_LIST_SUCCESS,
    GET_SUBSCRIPTIONS_LIST_FAILURE,
    GET_SUBSCRIPTION_ACTIVE_FAILURE,
    GET_SUBSCRIPTION_ACTIVE_SUCCESS,
    GET_SUBSCRIPTION_ACTIVE_REQUEST,
    SUBSCRIPTION_UPDATE_REQUEST,
    SUBSCRIPTION_UPDATE_SUCCESS,
    SUBSCRIPTION_UPDATE_FAILURE,
    SUBSCRIPTION_CANCEL_REQUEST,
    SUBSCRIPTION_CANCEL_SUCCESS,
    SUBSCRIPTION_CANCEL_FAILURE,
    SUBSCRIPTION_CONTINUE_REQUEST,
    SUBSCRIPTION_CONTINUE_SUCCESS,
    SUBSCRIPTION_CONTINUE_FAILURE,
    SET_DEFAULT_PAYMENT_METHOD_REQUEST,
    SET_DEFAULT_PAYMENT_METHOD_SUCCESS, SET_DEFAULT_PAYMENT_METHOD_FAILURE
} from "./action-types";
import API from "../../utils/httpAPI";
import {NotificationManager} from "react-notifications";

export const chooseYourProfile = (history) => {
    const newPath = '/account/yourProfile';
    if (history.location.pathname !== newPath) history.push(newPath);
    return {
        type: CHOOSE_YOUR_PROFILE,
        payload: 'yourProfile'
    };
};

export const chooseEmailPreferences = (history) => {
    const newPath = '/account/emailPreferences';
    if (history.location.pathname !== newPath) history.push(newPath);
    return {
        type: CHOOSE_EMAIL_PREFERENCES,
        payload: 'emailPreferences'
    };
};

export const chooseSecuritySettings = (history) => {
    const newPath = '/account/securitySettings';
    if (history.location.pathname !== newPath) history.push(newPath);
    return {
        type: CHOOSE_SECURITY_SETTINGS,
        payload: 'securitySettings'
    };
};

export const chooseChangePassword = (history) => {
    const newPath = '/account/changePassword';
    if (history.location.pathname !== newPath) history.push(newPath);
    return {
        type: CHOOSE_CHANGE_PASSWORD,
        payload: 'changePassword'
    };
};

export const chooseTeamSettings = (history) => {
    const newPath = '/account/teamSettings';
    if (history.location.pathname !== newPath) history.push(newPath);
    return {
        type: CHOOSE_TEAM_SETTINGS,
        payload: 'teamSettings'
    };
};

export const chooseBillingSettings = (history) => {
    const newPath = '/account/billingSettings';
    if (history.location.pathname !== newPath) history.push(newPath);
    return {
        type: CHOOSE_BILLING_SETTINGS,
        payload: 'billingSettings'
    };
};

export const chooseInvoiceSettings = (history) => {
    const newPath = '/account/invoiceSettings';
    if (history.location.pathname !== newPath) history.push(newPath);
    return {
        type: CHOOSE_INVOICE_SETTINGS,
        payload: 'invoiceSettings'
    };
};

export const choosePaymentMethods = (history) => {
    const newPath = '/account/paymentMethods';
    if (history.location.pathname !== newPath) history.push(newPath);
    return {
        type: CHOOSE_PAYMENT_METHODS,
        payload: 'paymentMethods'
    };
};

export const getPaymentMethodsRequest = () => {
    return {
        type: GET_PAYMENT_METHODS_REQUEST
    };
}

export const getPaymentMethodsSuccess = (data) => {
    return {
        type: GET_PAYMENT_METHODS_SUCCESS,
        payload: data.list,
    };
}

export const getPaymentMethodsFailure = (error) => {
    return {
        type: GET_PAYMENT_METHODS_FAILURE,
        payload: error,
    };
}

const paymentMethodDeleteRequest = () => {
    return {
        type: PAYMENT_METHOD_DELETE_REQUEST
    };
};

const paymentMethodDeleteSuccess = (id) => {
    return {
        type: PAYMENT_METHOD_DELETE_SUCCESS,
        payload: {
            id
        }
    };
};

const paymentMethodDeleteFailure = (error) => {
    return {
        type: PAYMENT_METHOD_DELETE_FAILURE,
        payload: error
    };
};


const setDefaultPaymentMethodRequest = () => {
    return {
        type: SET_DEFAULT_PAYMENT_METHOD_REQUEST
    };
};

const setDefaultPaymentMethodSuccess = (id) => {
    return {
        type: SET_DEFAULT_PAYMENT_METHOD_SUCCESS,
        payload: {
            id
        }
    };
};

const setDefaultPaymentMethodFailure = (error) => {
    return {
        type: SET_DEFAULT_PAYMENT_METHOD_FAILURE,
        payload: error
    };
};


export const getSubscriptionsListRequest = () => {
    return {
        type: GET_SUBSCRIPTIONS_LIST_REQUEST
    };
}

export const getSubscriptionsListSuccess = (data) => {
    return {
        type: GET_SUBSCRIPTIONS_LIST_SUCCESS,
        payload: data.list,
    };
}

export const getSubscriptionsListFailure = (error) => {
    return {
        type: GET_SUBSCRIPTIONS_LIST_FAILURE,
        payload: error,
    };
}

export const getSubscriptionActiveRequest = () => {
    return {
        type: GET_SUBSCRIPTION_ACTIVE_REQUEST
    };
}

export const getSubscriptionActiveSuccess = (data) => {
    return {
        type: GET_SUBSCRIPTION_ACTIVE_SUCCESS,
        payload: data,
    };
}

export const getSubscriptionActiveFailure = (error) => {
    return {
        type: GET_SUBSCRIPTION_ACTIVE_FAILURE,
        payload: error,
    };
}

export const subscriptionUpdateRequest = () => {
    return {
        type: SUBSCRIPTION_UPDATE_REQUEST
    };
}

export const subscriptionUpdateSuccess = (data) => {
    return {
        type: SUBSCRIPTION_UPDATE_SUCCESS,
        payload: data,
    };
}

export const subscriptionUpdateFailure = (error) => {
    return {
        type: SUBSCRIPTION_UPDATE_FAILURE,
        payload: error,
    };
}

export const subscriptionCancelRequest = () => {
    return {
        type: SUBSCRIPTION_CANCEL_REQUEST
    };
}

export const subscriptionCancelSuccess = () => {
    return {
        type: SUBSCRIPTION_CANCEL_SUCCESS,
    };
}

export const subscriptionCancelFailure = (error) => {
    return {
        type: SUBSCRIPTION_CANCEL_FAILURE,
        payload: error,
    };
}

export const subscriptionContinueRequest = () => {
    return {
        type: SUBSCRIPTION_CONTINUE_REQUEST
    };
}

export const subscriptionContinueSuccess = (data) => {
    return {
        type: SUBSCRIPTION_CONTINUE_SUCCESS,
        payload: data.list,
    };
}

export const subscriptionContinueFailure = (error) => {
    return {
        type: SUBSCRIPTION_CONTINUE_FAILURE,
        payload: error,
    };
}


export const showPaymentDeleteModal = (id, paymentName) => {
    return {
        type: SHOW_PAYMENT_DELETE_MODAL,
        payload: {id, paymentName},
    };
};

export const hidePaymentDeleteModal = () => {
    return {
        type: HIDE_PAYMENT_DELETE_MODAL
    };
};




export const getSubscriptionsList = () => {
    return function (dispatch) {
        dispatch(getSubscriptionsListRequest());
        API({method: "get", url: "/subscriptions/list"})
            .then((response) => {
                dispatch(getSubscriptionsListSuccess(response.data));
            })
            .catch((error) => {
                dispatch(getSubscriptionsListFailure(error));
            });
    }
}

export const getSubscriptionActive = () => {
    return function (dispatch) {
        dispatch(getSubscriptionActiveRequest());
        API({method: "get", url: "/subscriptions/active"})
            .then((response) => {
                dispatch(getSubscriptionActiveSuccess(response.data));
            })
            .catch((error) => {
                dispatch(getSubscriptionActiveFailure(error));
            });
    }
}

export const subscriptionUpdate = () => {
    return function (dispatch) {
        dispatch(subscriptionUpdateRequest());
        API({method: "post", url: "/subscriptions/update"})
            .then((response) => {
                dispatch(subscriptionUpdateSuccess(response.data));
            })
            .catch((error) => {
                dispatch(subscriptionUpdateFailure(error));
            });
    }
}

export const subscriptionCancel = (id) => {
    return function (dispatch) {
        dispatch(subscriptionCancelRequest());
        API({
            method: "post",
            url: "/subscriptions/cancel-at-period-end",
            data: { id }
        })
            .then((response) => {
                NotificationManager.success('Subscription successfully canceled', 'Complete', 3000);
                dispatch(subscriptionCancelSuccess(response.data));
                dispatch(getSubscriptionActive());
            })
            .catch((error) => {
                NotificationManager.error('Error', 'Something went wrong', 3000);
                dispatch(subscriptionCancelFailure(error));
            });
    }
}

export const subscriptionContinue = (id) => {
    return function (dispatch) {
        dispatch(subscriptionContinueRequest());
        API({
            method: "post",
            url: "/subscriptions/continue-at-period-end",
            data: { id }
        })
            .then((response) => {
                dispatch(subscriptionContinueSuccess(response.data));
                dispatch(getSubscriptionActive());
            })
            .catch((error) => {
                dispatch(subscriptionContinueFailure(error));
            });
    }
}


export const getPaymentMethods = () => {
    return function (dispatch) {
        dispatch(getPaymentMethodsRequest());
        API({method: "get", url: "/payment-methods/list"})
            .then((response) => {
                dispatch(getPaymentMethodsSuccess(response.data));
            })
            .catch((error) => {
                dispatch(getPaymentMethodsFailure(error));
            });
    }
}

export const startPaymentDelete = (id) => {
    return function (dispatch) {
        dispatch(getPaymentMethodsRequest());
        dispatch(hidePaymentDeleteModal());
        dispatch(paymentMethodDeleteRequest());
        API({
            method: "post",
            url: "/payment-methods/delete",
            data: { id }
        })
            .then((response) => {
                dispatch(paymentMethodDeleteSuccess())
                dispatch(getPaymentMethods());
            })
            .catch((error) => {
                dispatch(paymentMethodDeleteFailure(error));
            });
    }
};

export const setDefaultPaymentMethod = (id) => {
    return function (dispatch) {
        dispatch(setDefaultPaymentMethodRequest());
        dispatch(getPaymentMethodsRequest());
        API({
            method: "post",
            url: "/payment-methods/set-default",
            data: { id }
        })
            .then((response) => {
                dispatch(setDefaultPaymentMethodSuccess(id));
                dispatch(getPaymentMethods());
            })
            .catch((error) => {
                dispatch(setDefaultPaymentMethodFailure(error));
            });
    }
};
