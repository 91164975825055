import {Button, Form} from "react-bootstrap";
import {TablePagination} from "@material-ui/core";
import React, {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {showStripeProductAddModal, showStripeProductDeleteModal} from "../../../services/admin/action-creators";

const ProductsList = () => {
    const {
        products,
        productsLoading,
    } = useSelector((state) => state.admin);
    const dispatch = useDispatch();

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [searchSub, setSearchSub] = useState("");


    const handlePageChange = (_, newPage) => {
        setPage(newPage);
    };

    const countProducts = () => {
        return filterProducts().length;
    };

    const filterProducts = () => {
        const res = Object.keys(products)
            .filter(
                key => searchSub === "" ||
                    (products[key].name.includes(searchSub)) ||
                    (key.includes(searchSub))
            );
        return res;
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleSearchChange = (event) => {
        setSearchSub(event.target.value);
    };

    const handleAddProductClick = () => {
        dispatch(showStripeProductAddModal());
    }

    const handleDeleteProductClick = (selectedProductId) => {
        dispatch(showStripeProductDeleteModal(selectedProductId));
    }

    console.log('products', products);
    return (
        <>
            {productsLoading && <div>loading...</div>}
            <div className="clients_content_section">
                <div className="clients_menu">
                    <Form.Control className="table_clients_search" type="search" onChange={handleSearchChange}
                                  placeholder="enter name"/>
                    <Button variant="success" onClick={handleAddProductClick}>Add Product</Button>
                </div>
                <div className="line"></div>


                {products && products.length > 0 &&
                        <TablePagination
                        component="div"
                        count={countProducts()}
                        page={Number(page)}
                        onPageChange={handlePageChange}
                        rowsPerPage={rowsPerPage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                }
                {products && products.length > 0 &&
                    <div className="content-section-body">
                        <div className="table_clients">
                            <div className="t_row t_header">
                                <div className="tc_center tc_description">ID</div>
                                <div className="tc_center tc_description">Tag</div>
                                <div className="tc_center tc_description">Name</div>
                                <div className="tc_center tc_description">Product ID</div>
                                <div className="tc_center tc_description">Settings</div>
                            </div>
                            {filterProducts()
                                .filter(
                                    (el, i) =>
                                        i < rowsPerPage * (Number(page) + 1) &&
                                        i > (Number(page) * rowsPerPage - 1)
                                )
                                .map((key, index) => (
                                    <div className="t_row" key={key}>
                                        <div className="tc_center tc_description">{products[key].id}</div>
                                        <div className="tc_center tc_description">{products[key].tag}</div>
                                        <div className="tc_center tc_description">{products[key].name}</div>
                                        <div className="tc_center tc_description">{products[key].productId}</div>
                                        <div className="tc_center tc_description">
                                            <div className="account-btn-delete"
                                                 onClick={() => handleDeleteProductClick(products[key].id)}
                                            >
                                                <img src="/images/clean/delete-new.svg" alt=""/>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                        </div>
                    </div>
                }
            </div>
        </>
    )
}

export default ProductsList;
