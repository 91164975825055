import React from "react";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {hideModal} from "../services/action-creators";
import FileUpload from "./FileUpload";


const PageModal = () => {
    const dispatch = useDispatch();
    const { activeTab, showModal } = useSelector((state) => state.navigation);
    const handleClose = () => dispatch(hideModal());

    switch(activeTab) {
        case 'bulk':
            return (
                <Modal show={showModal} centered onHide={handleClose} className="modal-upload-file">
                    <div className="modal-header-box">
                        <span className="clean-modal-header">Upload From Computer</span>
                    </div>
                    <button onClick={handleClose} type="button" className="btn-close btn-close-absolute"
                            aria-label="Close"/>
                    <div className="modal-two-parts-content">
                        <div className="modal-left">
                            <ol className="clean-modal-list">
                                <li>Upload your file in CSV or TXT up to 65 MB (<a href="#" className="convert-learn">See
                                    how to convert Excel to CSV</a>).
                                </li>
                                <li>For CSV: Emails in one column (other data columns allowed).
                                    For TXT: Emails separated by commas or on separate lines.
                                </li>
                            </ol>
                            <FileUpload/>
                        </div>
                        <div className="modal-right">
                            <h3 className="integration-card__title">Integration</h3>
                            <div className="modal-right__wrap">
                                <div className="integration-card">
                                    <ul className="integration-card__list">
                                        <li><img src="images/page-modal/mailchimp.png" alt="Mailchimp"/> Mailchimp</li>
                                        <li><img src="images/page-modal/zoho-crm.svg" alt="Zoho CRM"/> Zoho CRM</li>
                                        <li><img src="images/page-modal/salesforce.png" alt="Salesforce"/> Salesforce
                                        </li>
                                        <li><img src="images/page-modal/hubspot.png" alt="Campaign Monitor"/> Campaign
                                            Monitor
                                        </li>
                                        <li><img src="images/page-modal/google-sheets.svg"
                                                 alt="Google Sheets"/> Google Sheets
                                        </li>
                                        <li><img src="images/page-modal/aweber.png" alt="Aweber"/> AWeber</li>
                                        <li><img src="images/page-modal/agile.png" alt="Agile"/> Agile CRM</li>
                                        <li><img src="images/page-modal/pipedrive.png" alt="Pipedrive"/> Pipedrive</li>
                                        <li><img src="images/page-modal/active-campaign.svg"
                                                 alt="Active Campaign"/> Active
                                            Campaign
                                        </li>
                                    </ul>
                                    <div className="coming-soon-title">Coming soon</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal>
            );
        case 'apps':
            return (
                <Modal show={showModal} onHide={handleClose}>
                    <button onClick={handleClose} type="button" class="btn-close btn-close-absolute"
                            aria-label="Close"></button>
                    <div className="modal-left"></div>
                    <div className="modal-right">
                        <h1>Upload From Computer</h1>
                        {/*<ul>*/}
                        {/*<li>Upload you list(s) in CSV format (<span>Learn how to convert Excel to CSV</span>).</li>*/}
                        {/*<li>Emails must be in 1 column (You may include unlimited columns of other data).</li>*/}
                        {/*<li>When finished uploading, you may analyze or clean your list.</li>*/}
                        {/*</ul>*/}
                        {/*<FileUpload/>*/}
                    </div>
                </Modal>
            );
        case 'appUsage':
            return (
                <Modal show={showModal} onHide={handleClose}>
                    <button onClick={handleClose} type="button" class="btn-close btn-close-absolute"
                            aria-label="Close"></button>
                    <div className="modal-left"></div>
                    <div className="modal-right">
                        <h1>Settings</h1>
                        {/*<ul>*/}
                        {/*<li>Upload you list(s) in CSV format (<span>Learn how to convert Excel to CSV</span>).</li>*/}
                        {/*<li>Emails must be in 1 column (You may include unlimited columns of other data).</li>*/}
                        {/*<li>When finished uploading, you may analyze or clean your list.</li>*/}
                        {/*</ul>*/}
                        {/*<FileUpload/>*/}
                    </div>
                </Modal>
            );
        default:
            return (
                <>
                </>
            )
    }

};

export default PageModal;
