import React from 'react'
import PageMenu from '../component/PageMenu'
import VerifyContent from './verify/VerifyContent'

const Verify = () => {

  return (
      <div className="page">
          <PageMenu activeTabName={"single"} />
          <VerifyContent/>
      </div>
  )
}

export default Verify