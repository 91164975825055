import React, {useEffect, useState} from 'react'
import {Accordion, Button, Spinner} from "react-bootstrap";
import {keyFeatures, commonQuestions} from "../../config/payment"
import Calculator from "./Calculator";
import SaveCardModal from "./SaveCardModal";
import API from "../../utils/httpAPI";

const PaymentContent = () => {
    const [isTrialLoading, setIsTrialLoading] = useState(false);
    const [isAvailableTrial, setIsAvailableTrial] = useState(false);

    const buyTrialClickHandle = async () => {
        setIsTrialLoading(true);
        try {
            const {data} = await API({method: "post", url: "/trial/checkout"});

            const {status, url} = data;

            if (status === 'ok' && url) {
                window.location.href = url;
            }
        } catch (err) {}
        setIsTrialLoading(false);
    }

    const getAvailableTrial = async () => {
        const res = await API({method: "get", url: "/trial/available"});
        setIsAvailableTrial(res.data.available);
    }

    useEffect(() => {
        getAvailableTrial();
    }, []);

    return(
        <div>
            {/*{showSavePaymentModal &&*/}
            {/*    <SaveCardModal*/}
            {/*        showModal={showSavePaymentModal}*/}
            {/*        setShowModal={setShowSavePaymentModal}*/}
            {/*        checkout={checkout} />*/}
            {/*}*/}
            <div className="page-payment">
                {isAvailableTrial && <div className="packages">
                    <div className="payment-trial">
                        <div className="payment-box">
                            <img src="/images/payment/medal-in-circle.svg" alt=""/>
                            <div className="payment-trial-label">
                                Trial
                            </div>
                            <div className="payment-trial-description">
                                <div className="price">
                                    $4
                                </div>
                                <div className="credits">
                                    1000 verifier credits
                                </div>
                            </div>
                            <div>
                                *For new users
                            </div>
                        </div>
                        <Button className="btn-trial"
                                variant="light"
                                onClick={buyTrialClickHandle}
                                disabled={isTrialLoading}>
                            Buy Trial
                            {isTrialLoading && (
                                <Spinner
                                    className="ms-3"
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                />
                            )}
                        </Button>
                    </div>
                </div>
                }
                <Calculator/>
            </div>
            <div className="payment_key_features">
                <div className="payment_key_features__wrap">
                    <h3>Our key features</h3>
                    <div className="key_feature_columns">
                        {Object.keys(keyFeatures).map(key => (
                            <div key={key} className="key_feature_column">
                                {Object.keys(keyFeatures[key]).map(featureKey => (
                                    <div key={featureKey} className="key_feature">
                                        <div className="key-feature-icon"/>
                                        <span>{keyFeatures[key][featureKey]}</span>
                                    </div>
                                ))}
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <div className="page-payment">
                <div className="payment_common_questions">
                    <h3>Common questions about email verifications</h3>
                    <div  className="questions_columns">
                        {Object.keys(commonQuestions).map(key => (
                            <Accordion className={`questions_column ${key === 'qc1' ? 'm-0' : ''}`} defaultActiveKey="0" key={'qa-' + key}>
                                {Object.keys(commonQuestions[key]).map((questionKey, index) => (
                                    <Accordion.Item key={'qa-' + index} eventKey={index}>
                                        <Accordion.Header>
                                            {commonQuestions[key][questionKey].question}
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            {commonQuestions[key][questionKey].answer}
                                        </Accordion.Body>
                                    </Accordion.Item>
                                ))}
                            </Accordion>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
};

export default PaymentContent;
