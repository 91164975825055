import React, {useState} from 'react';
import {Button, Form, Modal, Spinner} from "react-bootstrap";

const SaveCardModal = ({showModal, setShowModal, checkout}) => {

    const [savePaymentInformation, setSavePaymentInformation] = useState(false);
    const [loading, setLoading] = useState(false);

    const handleContinueClick = ()  => {
        setLoading(true);
        checkout(savePaymentInformation, () => {setLoading(false)});
    }

    const handleClose = () => {
        setShowModal(false);
    }

    return (
        <Modal centered
               show={showModal}
               onHide={handleClose}
               className="modal-payment-result"
               backdropClassName='foo-modal-backdrop'>
            <button onClick={handleClose} type="button" className="btn-close btn-close-absolute" aria-label="Close"/>
            <div className="small-modal-content">

                <div className="flex-row align-center mb-4">
                    <h4>Would you like to save the card information?</h4>
                </div>
                <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                    <Form>
                        <Form.Check
                            type="switch"
                            id="custom-switch"
                            checked={savePaymentInformation}
                            onChange={() => setSavePaymentInformation(!savePaymentInformation)}
                        />
                    </Form>
                    <div style={{ textAlign: 'left' }}>
                        Save this payment method for your future payments
                    </div>
                </div>
                <div className="modal-payment-footer">
                    <Button className="adm-btn" variant="success" onClick={handleContinueClick}>
                        Continue
                        {loading && (
                            <Spinner
                                className="ms-3"
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                            />
                        )}
                    </Button>
                </div>
            </div>
        </Modal>
    );
}

export default SaveCardModal;
