import React from "react";
import {Modal, Button} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
    showModal,
    hideAppDeleteModal,
    startAppDelete
} from "../../services/action-creators";


const AppDeleteModal = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { selectedId, selectedName, showDeleteModal } = useSelector((state) => state.appUsageDetails);
    const handleClose = () => {
        dispatch(hideAppDeleteModal());
        dispatch(showModal());
    };
    const handleDelete = () => {
        if (selectedId) {
            dispatch(startAppDelete(selectedId, history));
        }
    };
    return (
        <Modal show={showDeleteModal} onHide={ handleClose } className="modal-custom-confirm">
            <button onClick={handleClose} type="button" className="btn-close btn-close-absolute" aria-label="Close"/>
            <div className="small-modal-content">
                <div className="flex-row align-center">
                    <h3>Confirm App Deletion</h3>
                </div>
                <div className="dfm-text">
                    Deletion this app will immediately permanently disable the API Key.
                </div>
                <div className="adm-footer">
                    <Button className="adm-btn" variant="outline-secondary" onClick={handleClose}>Cancel</Button>
                    <Button className="adm-btn" variant="danger" onClick={handleDelete}>Delete</Button>
                </div>
            </div>
        </Modal>
    );

};

export default AppDeleteModal;
