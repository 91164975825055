import React, {useEffect, useState} from 'react';
import {Button, Form, Spinner} from 'react-bootstrap';
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import {chooseClean, chooseClient, loadUsers} from "../../services/action-creators"
import {TablePagination} from "@material-ui/core";
import {CSVLink} from "react-csv";
import AdminClient from "./AdminClient";
import {getCsvData, getCsvFileName, getDateTimeString} from "../../utils/helpers";
import {usePagingListSync} from "../../utils/hooks/usePagingListSync";


const AdminClients = () => {
    const { users, total, usersLoaded, usersLoading, failure } = useSelector((state) => state.users);

    const { chosenId } = useSelector((state) => state.admin);
    const dispatch = useDispatch();
    const history = useHistory();

    const { params, updateParams } = usePagingListSync({
        page: 1,
        pageSize: 10,
        search: "",
        status: ""
    });
    const [debouncedSearch, setDebouncedSearch] = useState(params.search);

    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedSearch(params.search);
        }, 500);

        return () => clearTimeout(handler);
    }, [params.search]);

    useEffect(() => {
        dispatch(loadUsers({ ...params, search: debouncedSearch }));
    }, [params.page, params.pageSize, params.status, debouncedSearch, dispatch]);

    const handleIdClick = (id) => () => {
        dispatch(chooseClient(id))
    };

    const handlePageChange = (event, newPage) => {
        updateParams({ page: newPage < 1 ? 1 : newPage + 1 });
    };

    const handleChangeRowsPerPage = (event) => {
        updateParams({
            page: 1,
            pageSize: parseInt(event.target.value, 10),
        });
    };

    const handleSearchChange = (event) => {
        updateParams({ search: event.target.value });
    };

    const handleSelectChange = (event) => {
        updateParams({ status: event.target.value });
    }

    if (failure && !usersLoaded) {
        setTimeout(() => dispatch(chooseClean(history)), 50);
        return (<div> failure : {JSON.stringify(failure)}</div>)
    }

    const filterUsers = () => {
        return Object.keys(users);
    };

    if (chosenId) {
        return <AdminClient/>
    }

    return(
        <div className = "clients_content_section">
            <div className="clients_menu">

                <div className="sl_block">
                    <Form.Control
                        className="table_clients_search"
                        placeholder="first name, last name or email"
                        type="search"
                        value={params.search}
                        onChange={handleSearchChange}
                    />

                    <Form.Select value={params.status}
                                 className="client-choose-type-control"
                                 onChange={handleSelectChange}>
                        <option value="">-</option>
                        <option value="Activated">Activated</option>
                        <option value="Pending">Pending</option>
                    </Form.Select>

                    {usersLoading &&
                        <Spinner
                            className="ms-3 me-3"
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                        />
                    }
                </div>

                <CSVLink className="link-export" data={ getCsvData(filterUsers(), users) } filename={ getCsvFileName('clients', params.search) }>
                    <Button variant="success" >Export to CSV</Button>
                </CSVLink>
            </div>
            <div className="line"></div>

            {!Object.keys(users).length && <div>No results found</div>}

            {Object.keys(users).length > 0 &&
                <TablePagination
                    component="div"
                    count={total}
                    page={Number(params.page) - 1}
                    onPageChange={handlePageChange}
                    rowsPerPage={Number(params.pageSize)}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            }

            {Object.keys(users).length > 0 &&
                <div className="content-section-body">
                    <div className="table_clients">

                        <div className="t_row t_header">
                            <div className="tc_email tc_center">Email</div>
                            <div className="tc_center tc_checks">Status</div>
                            <div className="tc_center tc_payments">Credits</div>
                            <div className="tc_center tc_date">Last Signin Date</div>
                            <div className="tc_center tc_date">Registration Date</div>
                            <div className="tc_center tc_block">Block Acc</div>
                            <div className="tc_center tc_ip">IP</div>
                        </div>


                        {filterUsers()
                            .map((key) => (
                                <div className="t_row fs-14" key={key}>
                                    <div className="tc_email">
                                        <div className="tc_click proofy-link ml-32 underline"
                                             onClick={handleIdClick(users[key]._id)}>
                                            {users[key].email}
                                        </div>
                                    </div>
                                    <div className="tc_checks">
                                        <div style={{
                                            backgroundColor: users[key].emailVerificationStatus === 'Activated'
                                                ? '#5DD38C'
                                                : '#FFBE21',
                                            width: "10px",
                                            height: "10px",
                                            borderRadius: "10px",
                                            margin: "auto"
                                        }}/>
                                    </div>
                                    <div className="tc_center tc_payments">{users[key].credits}</div>
                                    <div
                                        className="tc_center tc_date">{getDateTimeString(users[key].lastSignInDate)}</div>
                                    <div className="tc_center tc_date">{getDateTimeString(users[key].createdAt)}</div>
                                    <div className="tc_center tc_block">
                                        <img
                                            src={users[key].blockAccount ? "images/clients/circle-cancel.svg" : "images/clients/circle-ok.svg"}
                                            alt=""/>
                                    </div>
                                    <div className="tc_center tc_ip">
                                        {users[key].registrationIP || ''}
                                    </div>
                                </div>
                            ))}
                    </div>
                </div>
            }
        </div>
    )
};

export default AdminClients;