import React from 'react';
import { useSelector } from "react-redux";
import JsWidgetAppForm from './forms/JsWidgetAppForm';
import DefaultAppForm from './forms/DefaultAppForm';
import useQuery from "../../utils/hooks/useQuery";

const AppForm = () => {
    const { appType } = useSelector((state) => state.apps);

    const query = useQuery();
    const selectedAppType = appType || query.get('appType');

    switch (selectedAppType) {
        case '':
            return (<></>);
        case 'js':
            return (<JsWidgetAppForm/>);
        default:
            return (<></>);
    }
};

export default AppForm
