import API from "../../utils/httpAPI";
import { showAlertByError, updateUserCredits } from "../action-creators"

import {
    FILE_UPLOAD_START_REQUEST,
    FILE_UPLOAD_START_FAILURE,
    FILE_UPLOAD_REQUEST,
    FILE_UPLOAD_SUCCESS,
    FILE_UPLOAD_FAILURE,
    FILE_DELETE_REQUEST,
    FILE_DELETE_SUCCESS,
    FILE_DELETE_FAILURE,
    FILE_HELP_REQUEST,
    FILE_HELP_FAILURE,
    GET_FILE_REQUESTS_REQUEST,
    GET_FILE_REQUESTS_SUCCESS,
    GET_FILE_REQUESTS_FAILURE,
    FILE_CLEAN_FAILURE,
    FILE_CANCEL_CLEAN_FAILURE,
    FILE_ANALYZE_FAILURE,
    GET_FILES_REQUEST,
    GET_FILES_FAILURE,
    GET_FILES_SUCCESS,
    SHOW_FILE_DELETE_MODAL,
    HIDE_FILE_DELETE_MODAL,
    SHOW_PURCHASE_MODAL,
    HIDE_PURCHASE_MODAL,
    SHOW_DOWNLOAD_MODAL,
    HIDE_DOWNLOAD_MODAL,
    HIDE_MODAL,
    TOGGLE_DOWNLOAD_PRESET,
    EXPORT_CREATED,
    UPDATE_FILES,
    UPDATE_FILE_PROGRESS
} from "./action-types";
import {CREATE_CUSTOM_EXPORT} from "../apps/action-types";

const fileUploadStartRequest = (fileName) => {
    return {
        type: FILE_UPLOAD_START_REQUEST,
        payload: {
            fileName
        }
    };
};

const fileUploadStartFailure = (error) => {
    return {
        type: FILE_UPLOAD_START_FAILURE,
        payload: error,
    };
};

const fileUploadRequest = (signedUrl) => {
    return {
        type: FILE_UPLOAD_REQUEST,
        payload: {
            signedUrl,
        },
    };
};
const fileUploadSuccess = (file) => {
    return {
        type: FILE_UPLOAD_SUCCESS,
        payload: {
            file
        }
    };
};
const fileUploadFailure = (error) => {
    return {
        type: FILE_UPLOAD_FAILURE,
        payload: error
    };
};

const fileDeleteRequest = () => {
    return {
        type: FILE_DELETE_REQUEST
    };
};

const fileDeleteSuccess = (id) => {
    return {
        type: FILE_DELETE_SUCCESS,
        payload: {
            id
        }
    };
};

const fileDeleteFailure = (error) => {
    return {
        type: FILE_DELETE_FAILURE,
        payload: error
    };
};

const fileHelpRequest = () => {
    return {
        type: FILE_HELP_REQUEST
    };
};

const fileHelpFailure = (error) => {
    return {
        type: FILE_HELP_FAILURE,
        payload: error
    };
};

const fileCleanFailure = (error) => {
    return {
        type: FILE_CLEAN_FAILURE,
        payload: error,
    };
};

const fileCancelCleanFailure = (error) => {
    return {
        type: FILE_CANCEL_CLEAN_FAILURE,
        payload: error,
    };
};

const fileAnalyzeFailure = (error) => {
    return {
        type: FILE_ANALYZE_FAILURE,
        payload: error,
    };
};

const updateFileProgress = (progress) => {
    return {
        type: UPDATE_FILE_PROGRESS,
        payload: progress
    }
}

export const fileUploadStart = (displayFileName, fileName, fileText) => {
    return function (dispatch) {
        dispatch(fileUploadStartRequest(displayFileName));
        API({
            method: "post",
            url: "/file/signed-url",
            data: { fileName },
        })
            .then((response) => {
                const { signedUrl, bucket, key } = response.data;
                dispatch(fileUploadRequest(signedUrl));
                fileUpload({
                    displayFileName,
                    fileName,
                    signedUrl,
                    dispatch,
                    fileText,
                    bucket,
                    key
                });
            })
            .catch((error) => {
                console.error(error);
                console.error(error.message, error.response);
                dispatch(hideModal());
                dispatch(fileUploadStartFailure(error));
                if (error.response && error.response.data) {
                    dispatch(showAlertByError({ type: 'fileUpload', message: error.response.data.error }));
                }

            });
    };
};

export const fileUpload = async ({ displayFileName, fileName, signedUrl, dispatch, fileText, bucket, key }) => {
    uploadToS3(signedUrl, fileText, dispatch)
        .then(result => {
            if (result.status === 403) {
                dispatch(fileUploadFailure('error 403'));
                return;
            }

            API({
                method: "post",
                url: "/file",
                data: { displayFileName, fileName, bucket, key },
            })
                .then((response) => {
                    const { file } = response.data;
                    dispatch(fileUploadSuccess(file));
                    dispatch(hideModal());
                    dispatch(updateFileProgress(0));
                })
                .catch((error) => {
                    dispatch(fileUploadFailure(error));
                });
        })
        .catch(error => {
            dispatch(hideModal());
            dispatch(updateFileProgress(0));
            dispatch(fileUploadFailure(error));
            dispatch(showAlertByError({ type: 'fileUpload', message: error.message }));
        })

};


const uploadToS3 = (signedUrl, fileText, dispatch) => {
    return new Promise((resolve, reject) => {
        const blobData = new Blob([fileText], { type: 'text/csv' });

        const xhr = new XMLHttpRequest();

        xhr.upload.addEventListener('progress', (event) => {
            if (event.lengthComputable) {
                const progress = Math.round((event.loaded / event.total) * 100);
                dispatch(updateFileProgress(progress));
            }
        });

        xhr.addEventListener('load', () => {
            if (xhr.status === 200) {
                dispatch(updateFileProgress(100));
                resolve('Upload complete');
            } else {
                dispatch(updateFileProgress(-1));
                reject(new Error(`Upload failed: ${xhr.status} ${xhr.statusText}`));
            }
        });

        xhr.addEventListener('error', () => {
            reject(new Error('An error occurred while uploading the file.'));
        });

        xhr.addEventListener('abort', () => {
            reject(new Error('Upload aborted.'));
        });

        try {
            xhr.open('PUT', signedUrl);
            xhr.setRequestHeader('Content-Type', 'text/csv');
            // xhr.setRequestHeader('X-amz-server-side-encryption', 'AES256');
            xhr.send(blobData);
        } catch (error) {
            dispatch(updateFileProgress(-1));
            reject(new Error(`Error initializing request: ${error.message}`));
        }
    });
};

export const cleanFile = (id) => {
    return function (dispatch) {
        API({
            method: "post",
            url: `/file/${id}/clean`
        })
            .then((response) => {
                const { error, file, userCredits } = response.data;
                if (error) {
                    dispatch(showAlertByError({ type: 'fileClean', message: error }));
                    return;
                }

                dispatch(updateFiles([file]));
                if (userCredits) dispatch(updateUserCredits(userCredits));
            })
            .catch((error) => {
                if (error.response?.status === 402) {
                    // not enough credits
                    dispatch(showPurchaseModal(id));
                    return;
                }
                dispatch(fileCleanFailure(error));
            });
    }
};

export const cancelCleanFile = (id) => {
    return function (dispatch) {
        API({
            method: "post",
            url: `/file/${id}/cancel`,
        })
            .then((response) => {
                const { error, file, userCredits } = response.data;
                if (error) {
                    dispatch(showAlertByError({ type: 'fileClean', message: error }));
                    return;
                }
                dispatch(updateFiles([file]));
                if (userCredits) dispatch(updateUserCredits(userCredits));
            })
            .catch((error) => {
                dispatch(fileCancelCleanFailure(error));
            });
    }
};

export const startDownload = (presetSelected, selectedId) => {
    console.error('Start download logic', presetSelected, selectedId);

    //todo: some download logic

    return hideDownloadModal();
};

export const startFileDelete = (id) => {
    return function (dispatch) {
        dispatch(fileDeleteRequest());
        dispatch(hideDeleteModal());
        API({
            method: "delete",
            url: `/file/${id}`
        })
            .then((response) => {
                dispatch(fileDeleteSuccess(id));
            })
            .catch((error) => {
                dispatch(fileDeleteFailure(error));
            });
    }
};

export const startRequestHelp = (id) => {
    return function (dispatch) {
        dispatch(fileHelpRequest());
        API({
            method: "post",
            url: `/file/${id}/help`
        })
            .then((response) => {
                const { file } = response.data;
                dispatch(updateFiles([file]));
            })
            .catch((error) => {
                dispatch(fileHelpFailure(error));
            });
    }
};

export const showDeleteModal = (id, fileName) => {
    return {
        type: SHOW_FILE_DELETE_MODAL,
        payload: {id, fileName},
    };
};

export const hideDeleteModal = () => {
    return {
        type: HIDE_FILE_DELETE_MODAL
    };
};

export const showPurchaseModal = (id, fileName) => {
    return {
        type: SHOW_PURCHASE_MODAL,
        payload: {id, fileName},
    };
};

export const hidePurchaseModal = () => {
    return {
        type: HIDE_PURCHASE_MODAL
    };
};

const hideModal = () => {
    return {
        type: HIDE_MODAL
    };
};

export const showDownloadModal = (id, fileName) => {
    return {
        type: SHOW_DOWNLOAD_MODAL,
        payload: {id, fileName},
    };
};
export const hideDownloadModal = () => {
    return {
        type: HIDE_DOWNLOAD_MODAL
    };
};
export const toggleDownloadPreset = (presetName) => {
    return {
        type: TOGGLE_DOWNLOAD_PRESET,
        payload: presetName
    };
};

export const analyzeFile = (id) => {
    return function (dispatch) {
        API({
            method: "post",
            url: `/file/${id}/analyze`
        })
            .then((response) => {
                const { file } = response.data;
                dispatch(updateFiles([file]));
            })
            .catch((error) => {
                dispatch(fileAnalyzeFailure(error));
            });
    }
};

const getFilesRequest = (noLoading) => {
    return {
        type: GET_FILES_REQUEST,
        payload: { noLoading }
    };
};

const getFilesSuccess = (files, total, exports) => {
    return {
        type: GET_FILES_SUCCESS,
        payload: {
            files,
            total,
            exports
        },
    };
};

const updateFiles = (files) => {
    return {
        type: UPDATE_FILES,
        payload: { files }
    }
}

const getFilesFailure = (error) => {
    return {
        type: GET_FILES_FAILURE,
        payload: error,
    };
};

const exportSuccessfullyCreated = (exportFile) => {
    return {
        type: EXPORT_CREATED,
        payload: {
            exportFile
        },
    };
};

export const loadFiles = (search, type, page, pageSize, noLoading) => {
    return function (dispatch) {
        dispatch(getFilesRequest(noLoading));
        API({
            method: "get",
            url: "/file",
            params: {
                search,
                type,
                page,
                pageSize
            },
            data: {}
        })
            .then((response) => {
                const { files, total, exports, userCredits } = response.data;
                dispatch(getFilesSuccess(files, total, exports));
                dispatch(updateUserCredits(userCredits));
            })
            .catch(async (error) => {
                dispatch(getFilesFailure(error));
            });
    };
};

const getFileRequestsRequest = () => {
    return {
        type: GET_FILE_REQUESTS_REQUEST,
    };
};

const getFileRequestsSuccess = (fileRequests) => {
    return {
        type: GET_FILE_REQUESTS_SUCCESS,
        payload: {
            fileRequests,
        },
    };
};

const getFileRequestsFailure = (error) => {
    return {
        type: GET_FILE_REQUESTS_FAILURE,
        payload: error,
    };
};

const createCustomExport = () => {
    return {
        type: CREATE_CUSTOM_EXPORT,
    };
};

export const loadFileRequests = () => {
    return function (dispatch) {
        dispatch(getFileRequestsRequest());
        API({
            method: "post",
            url: "/admin/getFileRequests",
            data: {}
        })
            .then((response) => {
                const { fileRequests } = response.data;
                dispatch(getFileRequestsSuccess(fileRequests));
            })
            .catch(async (error) => {
                dispatch(getFileRequestsFailure(error));
            });
    };
};

export const customExport = ({bulkId, options, columns, excludeDuplicates}) => {
    return function (dispatch) {
        dispatch(createCustomExport());
        API({
            method: "post",
            url: "/export/custom",
            data: {
                bulkId, options, columns, excludeDuplicates
            }
        })
            .then((response) => {
                const { data } = response;
                dispatch(exportSuccessfullyCreated(data.download));
            })
            .catch(async (error) => {
                //dispatch(saveAppDataFailure(error));
            });
    };
}

export const presetExport = ({bulkId, preset}) => {
    return function (dispatch) {
        dispatch(createCustomExport());
        API({
            method: "post",
            url: "/export/preset",
            data: {
                bulkId, preset
            }
        })
            .then((response) => {
                const { data } = response;
                dispatch(exportSuccessfullyCreated(data.download));
            })
            .catch(async (error) => {
                //dispatch(saveAppDataFailure(error));
            });
    };
}
