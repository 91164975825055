import React, {useEffect, useState} from 'react'
import API from "../../utils/httpAPI";
import Subscriptions from "./Subscriptions";
import {useDispatch, useSelector} from "react-redux";
import {getSubscriptionActive} from "../../services/account/action-creators";
import PayAsYouGo from "./PayAsYouGo";

const Calculator = () => {
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(true);

    const [paymentType, setPaymentType] = useState("pay-as-you-go");
    const [subscriptions, setSubscriptions] = useState({
        prices: [],
        basePrice: 0
    });
    const [lastSelectedCredits, setLastSelectedCredits] = useState(1000);

    const {
        subscriptionActive,
        subscriptionActiveLoading,
    } = useSelector((state) => state.account);

    const [payAsYouGo, setPayAsYouGo] = useState({
        id: null,
        prices: [],
        basePrice: 0
    });

    useEffect(() => {
        fetchData();
    }, []);


    const fetchData = async () => {
        dispatch(getSubscriptionActive());

        const responseSubscriptions = await API({method: "get", url: "/prices/subscription-tiers"});
        const responsePayAsYouGo = await API({method: "get", url: "/prices/pay-as-you-go"});

        initSubscriptions(responseSubscriptions.data);
        initPayAsYouGo(responsePayAsYouGo.data);
        setIsLoading(false);
    }

    const initSubscriptions = (data) => {
        const {prices, basePrice} = data;

        setSubscriptions({
            prices: prices,
            basePrice: basePrice.price
        });
    }

    const initPayAsYouGo = (data) => {
        const {prices, basePrice, id} = data;

        setPayAsYouGo({
            id: id,
            prices: prices,
            basePrice: basePrice
        });
    }

    const togglePaymentType = () => {
        setPaymentType(paymentType === "pay-as-you-go" ? "monthly" : "pay-as-you-go");
    }

    return(
        <>
            {(isLoading || subscriptionActiveLoading) && <div className="loadingFiles payment-loader" />}
            {
                !isLoading && !subscriptionActiveLoading && paymentType === 'monthly' &&
                    <Subscriptions {...subscriptions}
                                   paygPrices={payAsYouGo.prices}
                                   subscriptionActive={subscriptionActive}
                                   lastSelectedCredits={lastSelectedCredits}
                                   setLastSelectedCredits={setLastSelectedCredits}>
                        <TypeSwitcher paymentType={paymentType} togglePaymentType={togglePaymentType} />
                    </Subscriptions>
            }
            {
                !isLoading && !subscriptionActiveLoading && paymentType === 'pay-as-you-go' &&
                    <PayAsYouGo {...payAsYouGo}
                                lastSelectedCredits={lastSelectedCredits}
                                setLastSelectedCredits={setLastSelectedCredits}
                    >
                        <TypeSwitcher paymentType={paymentType} togglePaymentType={togglePaymentType} />
                    </PayAsYouGo>
            }
        </>
    )
};

export default Calculator;

const TypeSwitcher = ({paymentType, togglePaymentType}) => {
    return (
        <div className="payment-summary-switch" onClick={togglePaymentType}>
            <div className={`summary-switch-option ${paymentType === "pay-as-you-go" ? "selected" : ""}`}>
                Pay-As-You-Go
            </div>
            <div className="payment-switcher">
                <div className={`switch-circle ${paymentType === "pay-as-you-go" ? "left" : "right"}`}/>
            </div>
            <div className={`summary-switch-option ${paymentType === "monthly" ? "selected" : ""}`}>
                Monthly
            </div>
        </div>
    )
}
