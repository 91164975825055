import React from 'react';
import { Route, Redirect, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { startSync } from '../services/action-creators';
import { PRIVATE_ROUTES } from '../constants';

export const UserRoute = ({ component, path, ...rest }) => {
    const dispatch = useDispatch();
    const location = useLocation();
    const locationPathName = location.pathname.split('/')[1];
    const { currentUser, syncStarted, syncError } = useSelector(
        (state) => state.authentication
    );

    if (!syncStarted && currentUser !== null) {
        dispatch(startSync());
    }

    if (currentUser && !syncError) {
        return <Route exact path={path} component={component} {...rest} />;
    }

    if (PRIVATE_ROUTES.includes(locationPathName)) {
        return <Redirect to="/" />;
    }

    return null;
};
