import React, {useEffect, useState} from 'react'
import { useDispatch, useSelector } from "react-redux";
import { Button } from 'react-bootstrap'
import AppUsageChart from "./AppUsageChart";
import { usageJSWidget as configs } from '../../../config/apps';
import { DateRangePicker } from "react-dates";
import moment from "moment";
import {
    getAppUsageDetails,
    showRefreshApiKeyModal
} from "../../../services/action-creators";
import AppUsageDetails from './AppUsageDetails';
import AppUsageJSData from './AppUsageJsData';
import {copyToClipboard} from "../../../utils/helpers";

const AppUsageJSContent = () => {
    const dispatch = useDispatch();

    const { apps, selectedAppId } = useSelector((state) => state.apps);
    const app = apps[selectedAppId];
    // const chartType = "results";
    const [showApiKey, setShowApiKey] = useState(false);

    const [hiddenBuckets, setHiddenBuckets] = useState([]);
    const { chart, details, apiKeyRefreshLoading, getAppUsageDetailsLoading } = useSelector((state) => state.appUsageDetails);


    const [dates, setDates] = useState({
        start: moment().subtract(7, 'days'),
        end: moment().endOf('day')
    });
    const [pickerDates, setPickerDates] = useState(dates);
    const [focusedInput, setFocusedInput] = useState(null);
    const isOutsideRange = date => date.isAfter(moment().endOf('day'));
    const [chartType, setChartType] = useState("requests");

    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);

    useEffect(() => {
        dispatch(getAppUsageDetails({
            appId: selectedAppId,
            from: dates.start.valueOf(),
            to: dates.end.valueOf(),
            page: page,
            pageSize: pageSize,
            hiddenBuckets: hiddenBuckets
        }));
    }, [selectedAppId, dates, page, pageSize, hiddenBuckets]);

    const summary = {};
    chart.forEach(item => {
        for (let key in item) {
            summary[key] = (summary[key] || 0) +  item[key];
        }
    });

    const handleBucketClick = bucket => {
        const newHiddenBuckets = hiddenBuckets.filter(item => item !== bucket);
        if (newHiddenBuckets.length === hiddenBuckets.length) {
            newHiddenBuckets.push(bucket);
        }
        setHiddenBuckets(newHiddenBuckets);
    };
    
    const handleDateChange = ({startDate, endDate}) => {
        setPickerDates({
            start: startDate,
            end: endDate
        });
    };

    const handlePickerClose = ({ startDate, endDate }) => {
        const start = startDate.startOf('day');
        const end = endDate.endOf('day');
        if (!start.isSame(dates.start) || !end.isSame(dates.end)) {
            setDates({ start, end });
        }
    };

    const toggleShowApiKey = () => {
        setShowApiKey(!showApiKey);
    }

    const handleRefreshApiKey = () => {
        dispatch(showRefreshApiKeyModal(selectedAppId, apps[selectedAppId].name));
    };

    const handlePageChange = (event, newPage) => {
        const p = newPage < 1 ? 1 : newPage + 1;
        setPage(p);
    };

    const handlePageSizeChange = (event) => {
        setPageSize(parseInt(event.target.value, 10));
        setPage(1);
    };

    const handleChartTypeChange = (event) => {
        setChartType(event.target.value);
        setHiddenBuckets([]);
    };

    const getJSWidgetCode = (apikey) => {
        return '<script type="text/javascript">\n'
            + '    _ProofySettings = {\n'
            + '        apiKey: \''
            + apikey
            + '\'\n'
            + '    };\n'
            + '</script>\n'
            + '<script type="text/javascript" src="https://cdn.proofy.io/widget/Proofy.js"></script>'
    }

    return (
        <>
        <div className={`custom-api-key ${showApiKey ? '' : 'close'}`}>
            <div className={`collapse-icon ${!showApiKey ? '' : 'close'}`} onClick={toggleShowApiKey}/>
            <div className={`custom-api-key-header ${showApiKey ? 'open' : ''}`} onClick={toggleShowApiKey}>
                <h3>JS widget code</h3>
            </div>
            <div className="custom-api-key-content">
                <div>
                    <p className="custom-api-key-description">Place this script just before the closing <span className="highlight-tag">{"</body>"}</span>  tag on your website.
                        Widget documentation available <a className='proofy-link' href='https://docs.proofy.io/widget/widget-configuration' target='_blank'>here</a>.</p>
                    <div className="au-js-link-section">
                        <Button variant="outline-success" className="copy-link-button"
                                onClick={() => copyToClipboard(getJSWidgetCode(app.apiKey))}>
                            <img className="btn-icon btn-icon-green" src="/images/appUsage/btn-link.svg" alt=""/>
                            <img className="btn-icon btn-icon-white" src="/images/appUsage/btn-link-white.svg" alt=""/>
                            Copy Code
                        </Button>
                        {apiKeyRefreshLoading && <div className="loadingFiles"/>}
                        {!apiKeyRefreshLoading && <textarea rows={6} className="au-js-code" readOnly value={getJSWidgetCode(app.apiKey)} />}
                    </div>
                    <p>Click here to <a href="#" className="reset-key" onClick={handleRefreshApiKey}>reset the API key</a>. This will deactivate the previous one.</p>
                </div>
            </div>
        </div>
        <div className="app-usage-stats">
            <div className="app-usage-stats-header">
                <div className="stats-title">
                    <h3>API Usage</h3>
                </div>
                <div className="stats-type">
                    <form>
                        <select className="au-select" size="sm" defaultValue={chartType}
                                onChange={handleChartTypeChange}>
                            <option value="requests">View Credits Usage</option>
                            <option value="results">View Results</option>
                        </select>
                    </form>
                </div>
                <div className="stats-date-range">
                    <div className="date-range-picker-wrapper">
                        <div className="calendar-icon"/>
                        <DateRangePicker
                            isOutsideRange={isOutsideRange}
                            startDate={pickerDates.start}
                            startDateId="start_date_id"
                            endDate={pickerDates.end}
                            endDateId="end_date_id"
                            onDatesChange={handleDateChange}
                            focusedInput={focusedInput}
                            onFocusChange={setFocusedInput}
                            onClose={handlePickerClose}
                            displayFormat={'MMMM D, YYYY'}
                        />
                    </div>
                </div>
            </div>

            <div className="app-usage-data">
                <AppUsageJSData data={summary}
                                type={chartType}
                                hiddenBuckets={hiddenBuckets}
                                onBucketClick={handleBucketClick}/>
                {!getAppUsageDetailsLoading && <AppUsageChart data={chart} config={configs[chartType]} hiddenBuckets={hiddenBuckets}/>}
                {getAppUsageDetailsLoading && <div className="loadingFiles mt-5 mb-3"/>}
            </div>
            <div className="app-result-history">
                <div className="app-usage-details-header">
                    <h3>Event Details</h3>
                </div>
                <div className="app-usage-details">
                    <AppUsageDetails
                        events={details.events}
                        count={details.count}
                        page={page - 1}
                        pageSize={pageSize}
                        onPageChange={handlePageChange}
                        onPageSizeChange={handlePageSizeChange}
                    />
                </div>
            </div>
        </div>
        </>
    )

};

export default AppUsageJSContent;
