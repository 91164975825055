import {
    CHOOSE_YOUR_PROFILE,
    CHOOSE_EMAIL_PREFERENCES,
    CHOOSE_SECURITY_SETTINGS,
    CHOOSE_CHANGE_PASSWORD,
    CHOOSE_TEAM_SETTINGS,
    CHOOSE_BILLING_SETTINGS,
    CHOOSE_INVOICE_SETTINGS,
    CHOOSE_PAYMENT_METHODS,
    SIGN_IN_SUCCESS,
    SIGN_UP_SUCCESS,
    SIGN_OUT_SUCCESS,
    SHOW_PAYMENT_DELETE_MODAL,
    HIDE_PAYMENT_DELETE_MODAL,
    GET_PAYMENT_METHODS_REQUEST,
    GET_PAYMENT_METHODS_SUCCESS,
    GET_PAYMENT_METHODS_FAILURE,
    GET_SUBSCRIPTIONS_LIST_REQUEST,
    GET_SUBSCRIPTIONS_LIST_SUCCESS,
    GET_SUBSCRIPTIONS_LIST_FAILURE,
    GET_SUBSCRIPTION_ACTIVE_REQUEST,
    GET_SUBSCRIPTION_ACTIVE_SUCCESS,
    GET_SUBSCRIPTION_ACTIVE_FAILURE,
    SUBSCRIPTION_UPDATE_REQUEST,
    SUBSCRIPTION_UPDATE_SUCCESS,
    SUBSCRIPTION_UPDATE_FAILURE,
    SUBSCRIPTION_CANCEL_REQUEST,
    SUBSCRIPTION_CANCEL_SUCCESS,
    SUBSCRIPTION_CANCEL_FAILURE,
    SUBSCRIPTION_CONTINUE_REQUEST,
    SUBSCRIPTION_CONTINUE_SUCCESS,
    SUBSCRIPTION_CONTINUE_FAILURE,
} from "./action-types";

const accountReducer = function (state, action) {
  switch (action.type) {
      case CHOOSE_YOUR_PROFILE:
      case CHOOSE_EMAIL_PREFERENCES:
      case CHOOSE_SECURITY_SETTINGS:
      case CHOOSE_CHANGE_PASSWORD:
      case CHOOSE_TEAM_SETTINGS:
      case CHOOSE_BILLING_SETTINGS:
      case CHOOSE_INVOICE_SETTINGS:
      case CHOOSE_PAYMENT_METHODS:
        return {
            ...state,
            activeTab: action.payload
        };
      case GET_PAYMENT_METHODS_REQUEST:
          return {
              ...state,
              paymentMethodsLoading: true,
          };
      case GET_PAYMENT_METHODS_SUCCESS:
          return {
              ...state,
              paymentMethodsLoading: false,
              paymentMethods: action.payload
          };
      case GET_PAYMENT_METHODS_FAILURE:
          return {
              ...state,
              filesLoading: false,
              failure: action.payload,
          };
      case SHOW_PAYMENT_DELETE_MODAL:
          return {
              ...state,
              showPaymentDeleteModal: true,
              selectedId: action.payload.id,
              selectedName: action.payload.paymentName,
          };
      case HIDE_PAYMENT_DELETE_MODAL:
          return {
              ...state,
              showPaymentDeleteModal: false
          };



      case GET_SUBSCRIPTIONS_LIST_REQUEST:
          return {
              ...state,
              subscriptionsLoading: true,
          };
      case GET_SUBSCRIPTIONS_LIST_SUCCESS:
          return {
              ...state,
              subscriptionsLoading: false,
              subscriptionsList: action.payload
          };
      case GET_SUBSCRIPTIONS_LIST_FAILURE:
          return {
              ...state,
              subscriptionsLoading: false,
              failure: action.payload,
          };



      case GET_SUBSCRIPTION_ACTIVE_REQUEST:
          return {
              ...state,
              subscriptionActiveLoading: true,
          };
      case GET_SUBSCRIPTION_ACTIVE_SUCCESS:
          return {
              ...state,
              subscriptionActiveLoading: false,
              subscriptionActive: action.payload
          };
      case GET_SUBSCRIPTION_ACTIVE_FAILURE:
          return {
              ...state,
              subscriptionActiveLoading: false,
              failure: action.payload,
          };



      case SUBSCRIPTION_UPDATE_REQUEST:
          return {
              ...state,
          };
      case SUBSCRIPTION_UPDATE_SUCCESS:
          return {
              ...state,
          };
      case SUBSCRIPTION_UPDATE_FAILURE:
          return {
              ...state,
              failure: action.payload,
          };


      case SUBSCRIPTION_CANCEL_REQUEST:
          return {
              ...state,
              subscriptionCancelLoading: true,
          };
      case SUBSCRIPTION_CANCEL_SUCCESS:
          return {
              ...state,
              subscriptionCancelLoading: false,
          };
      case SUBSCRIPTION_CANCEL_FAILURE:
          return {
              ...state,
              subscriptionCancelLoading: false,
              failure: action.payload,
          };


      case SUBSCRIPTION_CONTINUE_REQUEST:
          return {
              ...state,
              subscriptionContinueLoading: true,
          };
      case SUBSCRIPTION_CONTINUE_SUCCESS:
          return {
              ...state,
              subscriptionContinueLoading: false,
          };
      case SUBSCRIPTION_CONTINUE_FAILURE:
          return {
              ...state,
              subscriptionContinueLoading: false,
              failure: action.payload,
          };



      case SIGN_IN_SUCCESS:
      case SIGN_UP_SUCCESS:
      case SIGN_OUT_SUCCESS:
          return {
              ...state,
              activeTab: 'yourProfile',
              showPaymentDeleteModal: false
          };
    default:
      return { ...state };
  }
};

export default accountReducer;
