import React, {useCallback, useState} from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {Form, Button, Spinner, InputGroup, Alert} from 'react-bootstrap';
import {
    signUp,
    chooseSignIn,
} from '../../../services/action-creators';
import { isValidEmail, isValidPassword } from '../../../utils/helpers';
import {useGoogleReCaptcha} from "react-google-recaptcha-v3";
import {MAIN_PROOFY_URL} from "../../../config/proofy-links";


const SignUpForm = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { loading, signUpErrors } = useSelector((state) => state.authentication);

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [name, setName] = useState('');
    const [checkTermsOfUse, setCheckTermsOfUse] = useState(false);

    const [isEmailInvalid, setIsEmailInvalid] = useState(false);
    const [isPasswordInvalid, setIsPasswordInvalid] = useState(false);
    const [isNameInvalid, setIsNameInvalid] = useState(false);
    const [isCheckTermsOfUseInvalid, setIsCheckTermsOfUseInvalid] = useState(false);

    const [passwordError, setPasswordError] = useState('');

    const [eyeShown, setEyeShown] = useState(true);
    const [passwordInputType, setPasswordInputType] = useState('password');

    const { executeRecaptcha } = useGoogleReCaptcha();

    const handleReCaptchaVerify = useCallback(async () => {
        if (!executeRecaptcha) {
            console.log('Execute recaptcha not yet available');
            return;
        }

        return await executeRecaptcha('signUp');
    }, [executeRecaptcha]);

    const handleSignInClick = () => dispatch(chooseSignIn(history));

    const formCheckValidity = () => {
        if (!name) {
            setIsNameInvalid(true);
        }
        const emailInvalid = !isValidEmail(email);
        if (emailInvalid) {
            setIsEmailInvalid(true);
        }
        const pass = isValidPassword(password, email);
        if (!pass.valid) {
            setIsPasswordInvalid(true);
            setPasswordError(pass.error);
        }
        if (!checkTermsOfUse) {
            setIsCheckTermsOfUseInvalid(true);
        }

        return !(!name || emailInvalid || !pass.valid || !checkTermsOfUse);
    }

    const handleSignUpClick = (event) => {
        event.preventDefault();
        event.stopPropagation();

        const isValid = formCheckValidity();

        if (isValid) {
            handleReCaptchaVerify()
                .then((token) => {
                    dispatch(
                        signUp({ name, email, password, checkTermsOfUse, token })
                    );
                });
        }
    };

    const handleEyeClick = () => {
        if (passwordInputType === 'password') {
            setPasswordInputType('text');
            setEyeShown(false);
        } else {
            setPasswordInputType('password');
            setEyeShown(true);
        }
    };

    const termsOfServiceClickHandler = () => {
        window.location.href = 'https://proofy.io/terms-of-service/';
    }

    const handleProofyClick = () => {
        window.open(MAIN_PROOFY_URL, '_blank').focus();
    }

    const getCheckTermsLabel = () => {
        return (
            <Form.Text>
                I have read, understand and accept the <a className="payment-tax-link" onClick={termsOfServiceClickHandler}> Terms of Service </a>
            </Form.Text>
        )
    }

    return (
        <div className="signin-wrapper">
            <div className="signin-logo" onClick={handleProofyClick}>
                <img src="/images/new-logo.svg" alt="logo"/>
            </div>
            <div className="signin-card">
                <div className="signin-card-body">
                    <div className="signin-header">Sign Up For Proofy</div>

                    <Form className="signin-form"
                          onSubmit={handleSignUpClick} noValidate>
                        <Form.Group controlId="formBasicName">
                            <Form.Label>Full name</Form.Label>
                            <InputGroup>
                                <Form.Control
                                    placeholder="Enter first and last name"
                                    onChange={(e) => {
                                        isNameInvalid && setIsNameInvalid(false);
                                        setName(e.target.value);
                                    }}
                                    value={name}
                                    isInvalid={isNameInvalid}
                                    type="text"
                                    required
                                />

                                <Form.Control.Feedback type="invalid">
                                    First name and last name are required.
                                </Form.Control.Feedback>
                            </InputGroup>
                        </Form.Group>
                        <Form.Group controlId="formBasicEmail">
                            <Form.Label>Email address</Form.Label>
                            <InputGroup>
                                <Form.Control
                                    className="signin-email-control"
                                    value={email}
                                    placeholder="email@company.com"
                                    onChange={(e) => {
                                        isEmailInvalid && setIsEmailInvalid(false);
                                        setEmail(e.target.value);
                                    }}
                                    isInvalid={isEmailInvalid}
                                    type="email"
                                    required
                                />

                                <Form.Control.Feedback type="invalid">
                                    Please provide a valid email.
                                </Form.Control.Feedback>
                            </InputGroup>
                        </Form.Group>

                        <Form.Group>
                            <Form.Label>Password</Form.Label>
                            <div className="password-input">
                                <InputGroup className="password-input-group">
                                    <Form.Control
                                        type={passwordInputType}
                                        placeholder="Enter password"
                                        value={password}
                                        onChange={(e) => {
                                            isPasswordInvalid && setIsPasswordInvalid(false);
                                            setPassword(e.target.value);
                                        }}
                                        isInvalid={isPasswordInvalid}
                                        required
                                    />
                                    <img
                                        src={`images/${
                                            eyeShown ? 'eye-slash' : 'eye'
                                        }.svg`}
                                        alt=""
                                        onClick={handleEyeClick}
                                        className="eye"
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {passwordError}
                                    </Form.Control.Feedback>
                                </InputGroup>

                            </div>
                        </Form.Group>

                        <Form.Group className="signup-terms-of-use-check">
                            <Form.Check
                                checked={checkTermsOfUse}
                                onChange={() => {
                                    isCheckTermsOfUseInvalid && setIsCheckTermsOfUseInvalid(false);
                                    setCheckTermsOfUse(!checkTermsOfUse);
                                }}
                                isInvalid={isCheckTermsOfUseInvalid}
                                type="checkbox"
                                label={getCheckTermsLabel()}
                                feedback={'The terms must be accepted.'}
                                feedbackType="invalid"
                                required
                            />

                        </Form.Group>

                        {signUpErrors && (
                            <Alert className="mt-3 mb-0 py-2 px-3" variant="danger">
                                <ul className="mb-0">
                                    {Object.values(signUpErrors).map((error) => {
                                        return <li key={error}> {error} </li>;
                                    })}
                                </ul>
                            </Alert>
                        )}

                        <div className="signup-submit-form">
                            <Button
                                className="signin-button mt-3"
                                variant="success"
                                type="submit"
                                disabled={loading}
                            >
                                Create My Account
                                {loading && (
                                    <Spinner
                                        className="ms-3"
                                        as="span"
                                        animation="border"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                    />
                                )}
                            </Button>
                        </div>
                    </Form>

                    <div className="signin-no-account">
                        Already have a Proofy account? <a href="#" className="signin-comment-link"
                                                          onClick={handleSignInClick}>Sing In</a>
                    </div>
                </div>
            </div>
            <div className="sign-in-copy">Copyright ©2025 Proofy.io. All Rights Reserved</div>
        </div>
    );
};

export default SignUpForm;
