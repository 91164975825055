import React, { useState } from 'react';
import {Button, Form, Modal, InputGroup, Spinner} from "react-bootstrap";
import Gravatar from 'react-gravatar';
import { updateUserInfo, deleteAccountConfirmed } from "../../services/action-creators";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { isValidEmail } from '../../utils/helpers';
import useStateWithValidation from '../../utils/hooks/useStateWithValidation';

const AccountYourProfile = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { currentUser, isUpdateUserLoading } = useSelector((state) => state.authentication);

    const [firstName, setFirstName] = useState(currentUser.firstName ?? "");
    const [lastName, setLastName] = useState(currentUser.lastName ?? "");
    const [email, setEmail, { isInvalid: isEmailInvalid }] =
        useStateWithValidation({
            initialState: currentUser.email ?? "",
            isInvalidFn: (email) => !isValidEmail(email),
        });
    const [title, setTitle] = useState(currentUser.title ?? "");
    const [phoneNumber, setPhoneNumber] = useState(currentUser.phoneNumber ?? "");
    const [password, setPassword] = useState("");
    const [deleteConfirm, setDeleteConfirm] = useState("");
    const [showModal, setShowModal] = useState(false);

    const handleInputChange = (setFn) => (event) => setFn(event.target.value);

    const handleSaveClick = (event) => {
        event.preventDefault();

        if(email !== '' && !isValidEmail(email)) {
            // return dispatch(showError('Email is not correct'));
            return;
        }

        dispatch(updateUserInfo({currentEmail: currentUser.email, id: currentUser._id,  firstName, lastName, email, title, phoneNumber }));
    };

    const handleCancelClick = (event) => {
        event.preventDefault();
        setFirstName('');
        setLastName('');
        setEmail('');
        setTitle('');
        setPhoneNumber('');
    };

    /* */
    const handleDeleteClick = (event) => {
        event.preventDefault();
        setDeleteConfirm("");
        setShowModal(true);
        // dispatch(signUp({ name, email, password, checkTermsOfUse }, history));
    };

    const handleDeleteAccountClick = (event) => {
        event.preventDefault();
        if (deleteConfirm !== 'delete') {
            setDeleteConfirm("");
            return;
        }

        if (!password && password.length < 8) {
            setPassword("");
            return;
        }

        setShowModal(false);
        dispatch(deleteAccountConfirmed(password, history));
    };

    const handleCloseModal = () => {
        setShowModal(false);
        // dispatch(signUp({ name, email, password, checkTermsOfUse }, history));
    };

    const handleGravatarLink = () => {
        window.open('https://gravatar.com', '_blank').focus();
    };

    return (
        <div className="account_content_section">
            <h1>Your Profile</h1>
            <div className="line"/>
            <div className="account-form">
                <div className="account-image">
                    <Gravatar default='mp' email={currentUser.email} className="rounded-circle" size={80}/>
                    <div className="link" onClick={handleGravatarLink}>Edit Gravatar Image</div>
                </div>
                <Form className="account-data">
                    <div className="account-data-row">
                        <Form.Group className="account-form-group">
                            <Form.Label>First Name</Form.Label>
                            <Form.Control value={firstName} onChange={handleInputChange(setFirstName)} type="text" />
                        </Form.Group>
                        <Form.Group className="account-form-group">
                            <Form.Label>Last Name</Form.Label>
                            <Form.Control value={lastName} onChange={handleInputChange(setLastName)} type="text" />
                        </Form.Group>
                        <Form.Group className="account-form-group">
                            <Form.Label>Current Email</Form.Label>
                            <InputGroup hasValidation>
                                <Form.Control value={email} type="email" onChange={handleInputChange(setEmail)} isInvalid={isEmailInvalid} />
                                <Form.Control.Feedback type="invalid">
                                    Please provide a valid email.
                                </Form.Control.Feedback>
                            </InputGroup>
                        </Form.Group>
                    </div>
                    <div className="account-data-row">
                        <Form.Group className="account-form-group">
                            <Form.Label>Title (Optional)</Form.Label>
                            <Form.Control value={title} onChange={handleInputChange(setTitle)} type="text" />
                        </Form.Group>
                        <Form.Group className="account-form-group">
                            <Form.Label>Phone Number (Optional)</Form.Label>
                            <Form.Control value={phoneNumber} onChange={handleInputChange(setPhoneNumber)} type="text" />
                        </Form.Group>
                        <Form.Group className="account-form-group form-buttons">
                            <Button className="mobile-btn-width" onClick={handleSaveClick} variant="success" type="submit" disabled={isUpdateUserLoading}>
                                {!isUpdateUserLoading && <img className="btn-icon" src="/images/account/save-btn-icon.svg" alt=""/>}
                                {isUpdateUserLoading && (
                                    <Spinner
                                        className="mr14"
                                        as="span"
                                        animation="border"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                    />
                                )}
                                Save
                            </Button>
                        </Form.Group>
                    </div>
                    <div className="account-warning">
                        <div className="text">
                            <h3>Delete Account</h3>
                            <span>If you no longer wish to use Proofy, you can permanently delete your account.</span>
                        </div>
                        <Button variant="danger" onClick={handleDeleteClick} >
                            <img className="btn-icon" src="/images/account/delete-btn-icon.svg" alt=""/>
                            Delete Account
                        </Button>
                    </div>
                </Form>
            </div>

            <Modal centered show={showModal} onHide={ handleCloseModal }>

                <button onClick={handleCloseModal} type="button" className="btn-close btn-close-absolute" aria-label="Close"/>
                <div className="delete-modal">
                    <h2 className="mb-3">Delete Account</h2>
                    <Form.Label>Type your password to confirm</Form.Label>
                    <Form.Control className="account-delete-control" value={password}
                                  onChange={handleInputChange(setPassword)} type="password" placeholder=""/>
                    <Form.Label>Type <strong>"delete"</strong> to confirm</Form.Label>
                    <Form.Control className="account-delete-control" value={deleteConfirm}
                                  onChange={handleInputChange(setDeleteConfirm)} type="deleteConfirm" placeholder=""/>
                    <Button disabled={!password || deleteConfirm !== 'delete'} className="delete-button" onClick={handleDeleteAccountClick} variant="danger">
                        <img className="account-btn-icon" src="/images/account/delete-btn-icon.svg" alt=""/>
                        <div className="delete-button-text">Delete Account</div>
                    </Button>
                </div>
            </Modal>
        </div>
    )
};

export default AccountYourProfile;
