import { useMemo, useState } from 'react';
import { debounce } from 'lodash-es';
import { DEBOUNCE_INPUT_WAIT_TIME } from '../../constants';
import { useUpdateEffect } from 'react-use';

const useStateWithValidation = ({ initialState, isInvalidFn, isValidFn }) => {
    const [state, setState] = useState(initialState);
    const [isStateInvalid, setIsValueInvalid] = useState(false);
    const [isStateValid, setIsValueValid] = useState(false);

    const debouncedSetIsValueInvalid = useMemo(
        () => debounce(setIsValueInvalid, DEBOUNCE_INPUT_WAIT_TIME),
        [setIsValueInvalid]
    );
    const debouncedSetIsValueValid = useMemo(
        () => debounce(setIsValueValid, DEBOUNCE_INPUT_WAIT_TIME),
        [setIsValueValid]
    );

    useUpdateEffect(() => {
        if (!isInvalidFn) return;

        debouncedSetIsValueInvalid(isInvalidFn(state));
    }, [state]);

    useUpdateEffect(() => {
        if (!isValidFn) return;

        debouncedSetIsValueValid(isValidFn(state));
    }, [state]);

    return [
        state,
        setState,
        { isValid: isStateValid, isInvalid: isStateInvalid },
    ];
};

export default useStateWithValidation;
