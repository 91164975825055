import React from 'react'
import AppsContent from './apps/AppsContent'
import PageMenu from "./apps/PageMenu";

const Apps = () => {
    return (
        <div className="page">
            <PageMenu/>
            {/* <PageAlert/> */}
            <AppsContent/>
        </div>
    )
};

export default Apps
