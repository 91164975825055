import React from "react";

const PaymentType = {
    card: 'card',
    link: 'link',
    google: 'google_pay',
    apple: 'apple_pay'
}

const getPaymentTitle = (type) => {
    switch (type) {
        case PaymentType.card:
            return "Card";
        case PaymentType.link:
            return "Link";
        case PaymentType.google:
            return "Google Pay";
        case PaymentType.apple:
            return "Apple Pay";
        default:
            return "Card";
    }
}

const PaymentMethodRow = ({item, remove, setDefault}) => {

    const getExpiresDate = () => {
        if (item.hasOwnProperty('card')) {
            const expMonth = String(item.card?.exp_month).length > 1 ? item.card?.exp_month : `0${item.card?.exp_month}`;
            return `${expMonth}/${item.card?.exp_year}`;
        }
        return '-';
    }

    return (
        <tr>
            <td>
                <PaymentTypeLabel item={item} />
            </td>
            <td>
                <div className="table-cell_column">
                    <span className="table-cell_header">Details</span>
                    <div className={item.isDefault ? "" : "disabled-title"}>
                        {item?.billingDetails.name || "Not set"}
                    </div>
                </div>
            </td>
            <td>
                <div className="table-cell_column">
                    <span className="table-cell_header">Address</span>
                    <div className={item.isDefault ? "" : "disabled-title"}>
                        {item?.billingDetails?.address.country || "Not set"}
                    </div>
                </div>
            </td>
            <td>
                <div className="table-cell_column">
                    <span className="table-cell_header">Expires date</span>
                    <div className={item.isDefault ? "" : "disabled-title"}>
                        {getExpiresDate()}
                    </div>
                </div>
            </td>
            <td>
                <div className="account-btn-delete"
                     onClick={() => remove(item._id, item.type === PaymentType.card
                         ? `${getPaymentTitle(item.type)} **** ${item?.card?.last4}`
                         : getPaymentTitle(item.type))}
                >
                    <img src="/images/clean/delete-new.svg" alt=""/>
                    Delete
                </div>
            </td>
            <td>
                <div className="payment-methods-set-default">
                    {!item.isDefault
                        ? <img style={{cursor: 'pointer'}}
                               src="/images/account/bullet.svg"
                               alt=""
                               onClick={() => setDefault(item._id)}/>
                        : <img src="/images/account/bullet-green.svg" alt=""/>
                    }
                </div>
            </td>
        </tr>
    )
}

const PaymentTypeLabel = ({item}) => {

    return (
        <div className={`payment-methods-row ${item.isDefault ? "" : "disabled-title"}`}>
            <img className="mr12"
                 style={{opacity: item.isDefault ? 1 : 0.5}}
                 src={`/images/payment/${item.type}.svg`}
                 alt=""/>
            <div className="payment-title mr24">
                {getPaymentTitle(item.type)}
            </div>
            {item.type === PaymentType.card &&
                <div className="payment-title">
                **** {item?.card?.last4}
                </div>
            }
        </div>
    )
}

export default PaymentMethodRow;
