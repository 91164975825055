import React, {useEffect, useState} from "react";
import { Container, Button } from "react-bootstrap";
import { showModal, choosePayment, chooseApps, hideModal } from "../services/action-creators";
import { useDispatch, useSelector } from "react-redux";
import {useHistory, useLocation} from "react-router-dom";
import { apps as appsConfig } from "../config/apps";
import {MOBILE_VIEW_WIDTH} from "../config/mobile";
import {useMediaQuery} from "../utils/hooks/useMediaQuery";


const PageMenu = ({activeTabName = ''}) => {
    const { apps, selectedAppId } = useSelector((state) => state.apps);
    const app = apps[selectedAppId];
    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();

    const handleShowModal = () => dispatch(showModal());
    const handlePaymentClick = () => dispatch(choosePayment(history));
    // const handleCreateNewApp = () => dispatch(createNewApp());
    // const handleBackToApps = () => dispatch(backToApps());
    const handleApps = () => dispatch(chooseApps(history));
    const handleBack = () => dispatch(chooseApps(history));

    const [successBtnMouseEnter, setSuccessBtnMouseEnter] = useState(false);
    const isMobile = useMediaQuery(`(max-width: ${MOBILE_VIEW_WIDTH}px)`);

    useEffect(() => {
        dispatch(hideModal());
    },[location]);

    switch(activeTabName) {
        case 'adminpanel':
            return (
                <div className="page-menu">
                    <Container>
                        <h1>Admin Panel</h1>
                    </Container>
                </div>
            );
        case 'bulk':
            return (
                <div className="page-menu">
                    <Container>
                        <h1>Bulk</h1>
                    </Container>
                    <Container className="page-menu-buttons">
                        <Button className="btn-page btn-outline" variant="outline-success" onClick={handleShowModal}>+ Add File</Button>
                        <Button className="btn-page" variant="success" onClick={handlePaymentClick}>Buy Credits</Button>
                    </Container>
                </div>
            );
        case 'sync':
            return (
                <div className="page-menu">
                    <Container>
                        <h1>Sync</h1>
                    </Container>
                    <Container className="page-menu-buttons">
                        <Button className="btn-page btn-outline" variant="outline-success">+ Add File</Button>
                        <Button className="btn-page" variant="success" onClick={handlePaymentClick}>Buy Credits</Button>
                    </Container>
                </div>
            );
        case 'appUsage':
            return (
                <div className="page-menu">
                    <Container className="flex-row align-center">
                        <img style={{ height: '48px', marginRight: '12px' }} className="padding-right" src={appsConfig[app.type].img} alt=""/>
                        <h1>{app.name}</h1>
                    </Container>

                    <Container className="page-menu-buttons">
                        <Button className="btn-page btn-outline"
                                variant="outline-success"
                                onClick={handleApps}
                                onMouseEnter={() => {setSuccessBtnMouseEnter(true)}}
                                onMouseLeave={() => {setSuccessBtnMouseEnter(false)}}
                        >
                            {
                                !successBtnMouseEnter
                                    ? <img style={{ margin: isMobile? '-1px 0 0' : '-1px 10px 0 0' }} src="/images/apps/back.svg" alt=""/>
                                    : <img style={{ margin: isMobile? '-1px 0 0' : '-1px 10px 0 0' }} src="/images/apps/back-w.svg" alt=""/>
                            }
                            {isMobile ? '' : 'Back To Apps'}
                        </Button>
                        <Button className="btn-page" variant="success" onClick={handleShowModal}>
                            <img style={{ margin: isMobile? '-1px 0 0' : '-1px 10px 0 0' }} src="/images/apps/settings.svg" alt="" />
                            {isMobile ? '' : 'App Settings'}
                        </Button>
                    </Container>
                </div>
            );
        case 'usage':
            return (
                <div className="page-menu">
                    <Container>
                        <h1>Usage</h1>
                    </Container>
                </div>
            );
        case 'single':
            return (
                <div className="page-menu">
                    <Container>
                        <h1>Single</h1>
                    </Container>
                    <Container className="page-menu-buttons">
                        <Button className="btn-page" variant="success" onClick={handlePaymentClick}>Buy Credits</Button>
                    </Container>
                </div>
            );
        case 'account':
            return (
                <div className="page-menu">
                    <Container>
                        <h1>Account</h1>
                    </Container>
                    <Container className="page-menu-buttons">
                        <Button className="btn-page" variant="success" onClick={handlePaymentClick}>Buy Credits</Button>
                    </Container>
                </div>
            );
        case 'payment':
            return (
                <div className="page-menu">
                    <Container>
                        <h1>Price</h1>
                        <div className="page-menu__text">1 credit per email verification. Credits never expire</div>
                    </Container>
                </div>
            );
        case 'paymentHistory':
            return (
                <div className="page-menu">
                    <Container>
                        <h1>Price</h1>
                    </Container>
                    <Container className="page-menu-buttons">
                        <Button className="btn-page btn-outline" variant="outline-success" onClick={handleBack}>Back</Button>
                        <Button className="btn-page" variant="success" onClick={handleShowModal}>Upload Files?</Button>
                    </Container>
                </div>
            );
        default:
            return(
                <>
                {activeTabName} doesn't have menu yet
                </>
            )
    }

};

export default PageMenu;
