import React, { useState } from 'react';
import {Modal, Button, Form, Badge, Alert} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { hideModal, showAppDeleteModal, updateSelectedAppData, saveAppData } from "../../../services/action-creators";
import * as _ from "lodash-es";

const kvThrottle = {
    sec: "second",
    min: "minute",
    hour: "hour",
    week: "week",
    ip: "per IP",
    globally: "globally",
}

const AppJsSettingsModal = () => {
    const { apps, selectedAppId, selectedAppData } = useSelector((state) => state.apps);
    const { showModal } = useSelector((state) => state.navigation);
    const app = apps[selectedAppId];

    const [addDomain, setAddDomain] = useState('');
    const [addRequests, setAddRequests] = useState('');
    const [addTime, setAddTime] = useState('min');
    const [addCondition, setAddCondition] = useState('ip');
    const dispatch = useDispatch();
    const { data } = selectedAppData;

    const [isValidName, setIsValidName] = useState(true);
    const [addThrottleError, setAddThrottleError] = useState('');

    const handleAddDomainChange = (event) => {
        setAddDomain(event.target.value);
    };

    const handleAddDomain = () => {
        const domains = data?.domains ? data.domains : [];
        if (!domains.find(d => d === addDomain)) {
            domains.push(addDomain);
        }
        setAddDomain('');
        dispatch(updateSelectedAppData({domains}));
    };

    const handleDeleteDomain = (item) => {
        const domains = data.domains.filter(domain => domain !== item);
        dispatch(updateSelectedAppData({domains}));
    }

    const handleAddThrottle = () => {

        if (addRequests.length > 0) {
            const throttles = data?.throttles ? data.throttles : [];
            const newThrottleRule = {requests: addRequests, time: addTime, condition: addCondition};

            if (Number(addRequests) > 500) {
                setAddThrottleError('Maximum number of requests 500')
                setAddRequests('');
                return;
            }

            if (throttles.find(e => (e.time === newThrottleRule.time && e.condition === newThrottleRule.condition))) {
                setAddThrottleError(`You already have a rule for verifications per ${kvThrottle[newThrottleRule.time]}`);
                return;
            }

            throttles.push(newThrottleRule);
            setAddRequests('');
            dispatch(updateSelectedAppData({throttles}));
            setAddThrottleError('');
        }
    };

    const handleDeleteThrottle = (item) => {
        setAddThrottleError('');
        const throttles = data.throttles.filter(throttle =>  (!_.isEqual(throttle, item)))
        dispatch(updateSelectedAppData({throttles}));
    }

    const handleMaxAllowedRequestsChange = (event) => {
        let value = event.target.value;

        const numberRegex = `(^[0-9]+$|^$)`;
        if (value.match(numberRegex)) {

            if (value.substr(0,1) == "0")
            {
                value = value.substr(1);
            }
            setAddRequests(value);
        }
    };

    const handleMaxAllowedRequestsTimeChange = (event) => {
        setAddTime(event.target.value);
    };

    const handleClose = () => dispatch(hideModal());

    const handleCancel = () => {
        dispatch(updateSelectedAppData(app.data));
        dispatch(hideModal());
    };

    const handleDelete = () => {
        dispatch(hideModal());
        dispatch(showAppDeleteModal(selectedAppId, app.name));
    };

    const handleAppNameChange = (event) => {
        if (event.target.value.length > 0) {
            setIsValidName(true);
        }
        dispatch(updateSelectedAppData({appName: event.target.value}));
    };

    const handleSave = (event) => {
        event.preventDefault();
        event.stopPropagation();
        const form = event.currentTarget;

        if (form.checkValidity() === false) {
            setIsValidName(false);
            return;
        }

        dispatch(saveAppData({ id: selectedAppId, appData: selectedAppData.data }));
        dispatch(hideModal());
    };

    return (
        <Modal centered show={showModal} onHide={ handleClose } className="modal-app-settings-custom">
            <button onClick={handleClose} type="button" className="btn-close btn-close-absolute" aria-label="Close"/>
            <div className="app-js-usage-modal">
                <div className="app-usage-modal-header align-center">
                    <h1>App Settings</h1>
                </div>
                <Form className="app-js-settings-modal-form" onSubmit={handleSave} noValidate>
                    <Form.Group className="ca-form-row">
                        <Form.Label className="app-application-name">Application Name</Form.Label>
                        <Form.Control
                            defaultValue={data?.appName}
                            onChange={handleAppNameChange}
                            type="text"
                            placeholder=""
                            required
                            isInvalid={!isValidName}
                            formNoValidate
                        />
                        <Form.Control.Feedback type="invalid">
                            The name field is required.
                        </Form.Control.Feedback>
                        <p className="ca-row-comment ">Provide a name for easy identification of this app.</p>
                    </Form.Group>
                    <div className="ca-form-row content-padding">
                        <div className="ca-description-block">
                            <h4>Add Authorization Domain</h4>
                            <p className="ca-description-text">
                                Set domains for app access restrictions, or leave blank for open use.
                            </p>
                        </div>
                        <Form.Group className="input-group-with-button">
                            <div className="input-group-with-button-input">
                                <Form.Control value={addDomain} onChange={handleAddDomainChange} className="ca-auth-domain-input" type="text" placeholder="example.com" />
                            </div>
                            <div className="input-group-with-button-button">
                                <Button variant="success" className="btn-add" onClick={handleAddDomain}>Add</Button>
                            </div>
                        </Form.Group>
                        <div className="mt-2">
                            <Form.Text>
                                {data?.domains && data?.domains.length > 0 ? 'Widget is restricted to:' : 'Widget is not restricted to specific domains'}
                            </Form.Text>
                            {data?.domains && data?.domains.length > 0 &&
                                <div className="ca-throttle-usage-tags-container mt-1">
                                    {data.domains.map((item, index) => {
                                        return (
                                            <Badge key={index} className="ca-throttle-usage-tag">
                                                {item}
                                                <button onClick={() => handleDeleteDomain(item)}
                                                        type="button"
                                                        className="btn-close ca-throttle-usage-close"
                                                        aria-label="Close"/>
                                            </Badge>
                                        );
                                    })}
                                </div>
                            }
                        </div>
                    </div>
                    <div className="line"/>

                    <div className="ca-form-row content-padding">
                        <div className="ca-description-block">
                            <h4>Rate Limit</h4>
                            <p className="ca-description-text">
                                Set request limits to control widget usage. Defaults are unrestricted.
                            </p>
                        </div>
                        <Form.Group>
                            <div className="ca-throttle-usage-settings">
                                <div className="ca-allow-up-to">
                                    <Form.Label>Allow up to</Form.Label>
                                    <Form.Control value={addRequests} onChange={handleMaxAllowedRequestsChange} type="text" placeholder="10" />
                                </div>
                                <div className="ca-verifications-per">
                                    <Form.Label>Verifications per</Form.Label>
                                    <Form.Select value={addTime} className="ca-verifications-per-time" onChange={handleMaxAllowedRequestsTimeChange}>
                                        <option value="min">minute</option>
                                        <option value="hour">hour</option>
                                    </Form.Select>
                                    {/*<Form.Select value={addCondition} className="ca-verifications-per-type" onChange={handleMaxAllowedRequestsConditionChange}>*/}
                                    {/*    <option value="ip">per IP</option>*/}
                                    {/*</Form.Select>*/}
                                    <Button variant="success" className="btn-add" onClick={handleAddThrottle}>Add</Button>
                                </div>
                            </div>
                            {addThrottleError.length > 0 && <div className="error-text">
                                {addThrottleError}
                            </div>}
                            {data?.throttles && data?.throttles.length > 0
                                ? <div className="ca-throttle-usage-tags-container">
                                    {data.throttles.map((item, index) => {
                                        return (
                                            <Badge key={index} className="ca-throttle-usage-tag">
                                                {`${item.requests} - ${item.time} - ${item.condition}`}
                                                <button onClick={() => handleDeleteThrottle(item)}
                                                        type="button"
                                                        className="btn-close ca-throttle-usage-close"
                                                        aria-label="Close"/>
                                            </Badge>
                                        );
                                    })}
                                  </div>
                                : <Badge className="ca-throttle-usage-tag">Unlimited usage is allowed</Badge>
                            }
                        </Form.Group>
                    </div>

                    {!isValidName &&
                        <Alert key="danger" variant="danger" className="app-settings-alert">
                            Please correct the errors above before continuing
                        </Alert>
                    }
                    <div className="app-js-settings-controls">
                    <div className="aum-buttons">
                        <Button className ="aum-btn" variant="outline-danger" onClick={handleDelete}>Delete App</Button>
                        <Button className ="aum-btn" variant="outline-secondary" onClick={handleCancel}>Cancel</Button>
                    </div>

                    <Button className ="aum-confirm-btn" variant="success" type="submit">Save</Button>
                    </div>
                </Form>
            </div>
        </Modal>
    );

};

export default AppJsSettingsModal;
