import {
    SET_PAYMENT_HISTORY_DETAILS_START_DATE,
    SET_PAYMENT_HISTORY_DETAILS_END_DATE,
    GET_PAYMENT_HISTORY_DETAILS_REQUEST,
    GET_PAYMENT_HISTORY_DETAILS_SUCCESS,
    GET_PAYMENT_HISTORY_DETAILS_FAILURE,
    SET_PAYMENT_USAGE_DETAILS_START_DATE,
    SET_PAYMENT_USAGE_DETAILS_END_DATE,
    GET_PAYMENT_USAGE_DETAILS_REQUEST,
    GET_PAYMENT_USAGE_DETAILS_SUCCESS,
    GET_PAYMENT_USAGE_DETAILS_FAILURE,
    SIGN_IN_SUCCESS,
    SIGN_UP_SUCCESS,
    SIGN_OUT_SUCCESS,
} from "./action-types";

const paymentHistoryDetailsReducer = function (state, action) {
    switch (action.type) {
        case SET_PAYMENT_HISTORY_DETAILS_START_DATE:
            return {
                ...state,
                phdStartDate: action.payload.date,
                phdIsChangedDate: true
            };
        case SET_PAYMENT_HISTORY_DETAILS_END_DATE:
            return {
                ...state,
                phdEndDate: action.payload.date,
                phdIsChangedDate: true
            };
        case GET_PAYMENT_HISTORY_DETAILS_REQUEST:
            return {
                ...state,
                phdIsChangedDate: false
            };
        case GET_PAYMENT_HISTORY_DETAILS_FAILURE:
            return {
                ...state,
                phdIsChangedDate: true
            };
        case GET_PAYMENT_HISTORY_DETAILS_SUCCESS:
            const newHistoryDetails = {};
            action.payload.details.forEach(detail => {
                newHistoryDetails[detail._id] = detail;
            });
            return {
                ...state,
                phdDetails: newHistoryDetails
            };
        case SET_PAYMENT_USAGE_DETAILS_START_DATE:
            return {
                ...state,
                pudStartDate: action.payload.date,
                pudIsChangedDate: true
            };
        case SET_PAYMENT_USAGE_DETAILS_END_DATE:
            return {
                ...state,
                pudEndDate: action.payload.date,
                pudIsChangedDate: true
            };
        case GET_PAYMENT_USAGE_DETAILS_REQUEST:
            return {
                ...state,
                pudIsChangedDate: false
            };
        case GET_PAYMENT_USAGE_DETAILS_FAILURE:
            return {
                ...state,
                pudIsChangedDate: true
            };
        case GET_PAYMENT_USAGE_DETAILS_SUCCESS:
            return {
                ...state,
                pudDetails: action.payload.details
            };
        case SIGN_IN_SUCCESS:
        case SIGN_UP_SUCCESS:
        case SIGN_OUT_SUCCESS:
            return {
                ...state,
                phdStartDate: null,
                phdEndDate: null,
                phdIsChangedDate: false,
                phdDetails: {},
                pudStartDate: null,
                pudEndDate: null,
                pudIsChangedDate: false,
                pudDetails: []
            };
        default:
            return { ...state };
    }
};

export default paymentHistoryDetailsReducer;
