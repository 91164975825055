import React, {useCallback, useState} from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Button, InputGroup, Alert, Spinner } from 'react-bootstrap';

import {signIn, chooseResetPassword, chooseRegistration} from '../../services/action-creators';
import { isValidEmail } from '../../utils/helpers';
import useStateWithValidation from '../../utils/hooks/useStateWithValidation';
import {useGoogleReCaptcha} from "react-google-recaptcha-v3";
import {MAIN_PROOFY_URL} from "../../config/proofy-links";

const Signin = () => {
    const dispatch = useDispatch();
    const history = useHistory();

    const { loading, error } = useSelector((state) => state.authentication);

    const handleRegistration = () => dispatch(chooseRegistration(history));

    const [email, setEmail, { isInvalid: isEmailInvalid }] =
        useStateWithValidation({
            initialState: '',
            isInvalidFn: (email) => !isValidEmail(email),
        });
    const [password, setPassword, { isInvalid: isPasswordInvalid }] =
        useStateWithValidation({
            initialState: '',
            isInvalidFn: (password) => !password,
        });

    const [isRememberMe, setIsRememberMe] = useState(false);
    const [eyeShown, setEyeShown] = useState(true);
    const [passwordInputType, setPasswordInputType] = useState('password');
    const handleInputChange = (setFn) => (event) => setFn(event.target.value);

    const { executeRecaptcha } = useGoogleReCaptcha();

    const handleReCaptchaVerify = useCallback(async () => {
        if (!executeRecaptcha) {
            console.log('Execute recaptcha not yet available');
            return;
        }

        return await executeRecaptcha('signIn');
    }, [executeRecaptcha]);

    const handleSignInClick = (event) => {
        event.preventDefault();

        if (!isValidEmail(email)) return;
        if (!password) return;


        const data = { email, password: password };

        if (isRememberMe) {
            data.rememberMe = isRememberMe;
        }

        handleReCaptchaVerify().then((token) => {
            dispatch(signIn({ ...data, token }, history));
        })
    };

    const handleResetPassword = () => dispatch(chooseResetPassword(history));

    const handleEyeClick = () => {
        if (passwordInputType === 'password') {
            setPasswordInputType('text');
            setEyeShown(false);
        } else {
            setPasswordInputType('password');
            setEyeShown(true);
        }
    };

    const handleProofyClick = () => {
        window.open(MAIN_PROOFY_URL, '_blank').focus();
    }

    return (
        <div className="signin-wrapper">
            <div className="signin-logo" onClick={handleProofyClick}>
                <img src="/images/new-logo.svg" alt="logo"/>
            </div>

            <div className="signin-card">
                <div className="signin-card-body">
                    <div className="signin-header">Sign In To Proofy</div>
                    <Form className="signin-form" noValidate>
                        <Form.Group controlId="formBasicEmail">
                            <Form.Label>Email address</Form.Label>
                            <InputGroup hasValidation>
                                <Form.Control
                                    className="signin-email-control"
                                    type="email"
                                    placeholder="email@company.com"
                                    onChange={handleInputChange(setEmail)}
                                    isInvalid={isEmailInvalid}
                                />
                                <Form.Control.Feedback type="invalid">
                                    Please provide a valid email.
                                </Form.Control.Feedback>
                            </InputGroup>
                        </Form.Group>

                        <Form.Group controlId="formBasicPassword">
                            <Form.Label>Password</Form.Label>
                            <InputGroup hasValidation className="password-input-group">
                                <Form.Control
                                    className="signin-password-control"
                                    type={passwordInputType}
                                    placeholder="Enter password"
                                    onChange={handleInputChange(setPassword)}
                                    isInvalid={isPasswordInvalid}
                                />
                                <Form.Control.Feedback type="invalid">
                                    Please provide a password.
                                </Form.Control.Feedback>
                                <img src={`images/${eyeShown ? 'eye-slash' : 'eye'}.svg`} alt="" onClick={handleEyeClick} className="eye"/>
                            </InputGroup>
                        </Form.Group>

                        <Form.Group className="forgot-password-line checkbox-group">
                            <div className="signin-forgot-password-checkbox">
                                <Form.Check checked={isRememberMe}
                                            onChange={() => setIsRememberMe(!isRememberMe)}
                                            type="checkbox"
                                            label="Remember me"
                                />
                            </div>
                            <div className="signin-forgot-password-link">
                                <a className="signin-comment-link" onClick={handleResetPassword}>Forgot password?</a>
                            </div>
                        </Form.Group>

                            {error && (
                            <Alert className="mb-2 py-2 px-3" variant="danger">
                                {error}
                            </Alert>
                        )}
                        <div className="signup-submit-form">
                            <Button
                                className="signin-button"
                                onClick={handleSignInClick}
                                variant={"success"}
                                type="submit"
                                disabled={loading}
                            >
                                Log In
                                {loading && (
                                    <Spinner
                                        className="ms-3"
                                        as="span"
                                        animation="border"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                    />
                                )}
                            </Button>
                        </div>
                    </Form>
                    <div className="signin-no-account">
                        Don't have an account yet?
                        <a className="signin-comment-link" onClick={handleRegistration}>Get signed up</a>
                    </div>

                </div>
            </div>

            <div className="sign-in-copy">Copyright ©2025 Proofy.io. All Rights Reserved</div>
        </div>
    );
};

export default Signin;
