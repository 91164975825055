import moment from 'moment';

export const getDateString = (timestamp) => {
    if (!timestamp) return '';
    const dateString = new Intl.DateTimeFormat('en-US', {
        month: 'short',
        day: '2-digit',
        year: 'numeric',
    }).format(new Date(timestamp));

    return dateString;
};

export const getTimeString = (timestamp) => {
    if (!timestamp) return '';
    const timeString = new Intl.DateTimeFormat('en-US', {
        hour: '2-digit',
        minute: '2-digit',
    }).format(new Date(timestamp));

    return timeString;
};

export const getDateTimeString = (timestamp) => {
    if (!timestamp) return '';
    const dateString = new Intl.DateTimeFormat('en-US', {
        month: 'short',
        day: '2-digit',
        year: 'numeric',
    }).format(new Date(timestamp));
    const timeString = new Intl.DateTimeFormat('en-US', {
        hour: '2-digit',
        minute: '2-digit',
    }).format(new Date(timestamp));

    return `${dateString} ${timeString}`;
};

export const getSmallId = (id) => {
    const length = id.length;

    if (length < 8) return id;

    const result = id.substr(0, 3) + '..' + id.substr(length - 3, length);
    return result;
};

export const isValidEmail = (email) => {
    return email.match(validEmailRE);
};

const containsDigit = (password) => {
    const digitRegex = /\d/;
    return digitRegex.test(password);
};

export const containsSpecialCharacter = (password) => {
    const specialCharacters = /[!@#$%^&*(),.?":{}|<>]/;
    return specialCharacters.test(password);
};

export const isValidPassword = (password, email) => {
    if (!password) return {
        valid: false,
        error: 'The password field is required',
    };
    if (password.length < 8 || password.length > 50) return {
        valid: false,
        error: 'Password must be at least 8 characters long',
    };
    if (email && password.includes(email.split("@")[0])) return {
        valid: false,
        error: 'Password cannot contain your email',
    };
    // if (!containsSpecialCharacter(password)) return {
    //     valid: false,
    //     error: 'Password must contain at least one special character'
    // }
    if (!containsDigit(password)) return {
        valid: false,
        error: 'Password must contain at least one digit'
    }
    return {
        valid: true,
        error: ''
    };
};

export const validEmailString = '^[^s@]+@[^s@]+.[^s@]+$';

export const validEmailRE = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

export const getCsvFileName = (p1, p2) => {
    let result = p1;
    if (p2 !== '') result = result + '-' + p2;
    result = result + '-' + moment().format('YYYY-MM-DD---HH-mm');
    result = result + '.csv';
    return result;
};

export const getCsvData = (idArr, dataObj) => {
    return idArr.map((id) => {
        const result = { id };
        Object.keys(dataObj[id]).forEach((key) => {
            if (key === '_id') return;
            result[key] = dataObj[id][key];
        });
        return result;
    });
};

export function fileSize(bytes) {
    const units = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    let i = 0;
    while (bytes >= 1024) {
        bytes /= 1024;
        ++i;
    }
    return Math.round(bytes) + ' ' + units[i];
}

export function saveResponseAsFile(response) {
    const href = URL.createObjectURL(response.data);

    // create "a" HTML element with href to file & click
    const a = document.createElement('a');
    a.href = href;

    let filename = 'file';
    const contentDisposition = response.headers['content-disposition'];
    if (contentDisposition) {
        const m = contentDisposition.match(/filename="(.+)"/)
        if (m && m.length === 2) {
            filename = m[1]
        }
    }

    a.setAttribute('download', filename);
    document.body.appendChild(a);
    a.click();

    // clean up "a" element & remove ObjectURL
    document.body.removeChild(a);
    URL.revokeObjectURL(href);
}

export function copyToClipboard(text, callback) {
    navigator.clipboard.writeText(text).then(() => {
        callback && callback();
    });
}
