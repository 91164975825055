import React, {useEffect, useState} from "react";

const PresetsAccordion = ({alias, name, children, onSelect, isActive = false, isRecommended = false}) => {

    const [showOptions, setShowOptions] = useState(false);

    useEffect(() => {
        setShowOptions(isActive);
    }, [isActive]);

    const onShowMoreClick = () => {
        setShowOptions(!showOptions);
    }

    return (
        <div className={`settings-accordion ${isActive ? "selected-accordion" : ""}`}>
            <div className="settings-accordion-header">
                <div className="theme-checkbox">
                    <input id={`select-accordion-${alias}`} type="checkbox" value={alias} checked={isActive} onChange={(e) => {
                        onSelect(e.target.value);
                    }} />
                    <label htmlFor={`select-accordion-${alias}`}>{name} {isRecommended && <span className="small-text">(Recommended)</span>}</label>
                </div>
                <ShowOptions isActive={showOptions} onClick={onShowMoreClick}/>
            </div>
            <div className={`presets-description ${showOptions ? "open" : ""}`}>
                { children }
            </div>
        </div>
    );
};

const ShowOptions = ({isActive, onClick}) => {
    if (!isActive) {
        return (
            <div className={"settings-accordion-show-options"} onClick={onClick}>
                <span>Show more</span>
                <div className={"icon icon-arrow-down"}/>
            </div>
        );
    }

    return (
        <div className={"settings-accordion-show-options"} onClick={onClick}>
            <span>Show less</span>
            <div className={"icon icon-arrow-up"}/>
        </div>
    );
}

export default PresetsAccordion;
