import React from "react";
import {Container} from "react-bootstrap";

const PageMenu = () => {
    return (
        <div className="page-menu">
            <Container>
                <h1>Payment History</h1>
            </Container>
        </div>
    );
};

export default PageMenu;
