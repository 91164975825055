import {
    CHOOSE_CLIENT,
    CHOOSE_ADMIN_CLIENTS,
    CHOOSE_ADMIN_CLEAN,
    // CHOOSE_EMAIL_PREFERENCES,
    SHOW_CLIENT_BLOCK_MODAL,
    HIDE_CLIENT_BLOCK_MODAL,
    ADD_USER_CREDITS_REQUEST,
    ADD_USER_CREDITS_SUCCESS,
    ADD_USER_CREDITS_FAILURE,
    CHANGE_BLOCKED_STATUS_REQUEST,
    CHANGE_BLOCKED_STATUS_SUCCESS,
    CHANGE_BLOCKED_STATUS_FAILURE,
    CHOOSE_ADMIN_WIDGET,
    CHOOSE_ADMIN_STRIPE,
    GET_STRIPE_PRODUCTS_REQUEST,
    GET_STRIPE_PRODUCTS_SUCCESS,
    GET_STRIPE_PRODUCTS_FAILURE,
    GET_STRIPE_TAGS_REQUEST,
    GET_STRIPE_TAGS_SUCCESS,
    GET_STRIPE_TAGS_FAILURE,
    ADD_STRIPE_PRODUCT_REQUEST,
    ADD_STRIPE_PRODUCT_SUCCESS,
    ADD_STRIPE_PRODUCT_FAILURE,
    REMOVE_STRIPE_PRODUCT_REQUEST,
    REMOVE_STRIPE_PRODUCT_SUCCESS,
    REMOVE_STRIPE_PRODUCT_FAILURE,
    SHOW_STRIPE_PRODUCT_DELETE_MODAL,
    HIDE_STRIPE_PRODUCT_DELETE_MODAL,
    SHOW_STRIPE_PRODUCT_ADD_MODAL,
    HIDE_STRIPE_PRODUCT_ADD_MODAL,
} from "./action-types";
import API from "../../utils/httpAPI";

export const chooseClient = (id) => {
    return {
        type: CHOOSE_CLIENT,
        payload: { id }
    };
};

export const chooseAdminClients = () => {
    return {
        type: CHOOSE_ADMIN_CLIENTS,
        payload: 'clients'
    };
};

export const chooseAdminClean = () => {
    return {
        type: CHOOSE_ADMIN_CLEAN,
        payload: 'clean'
    };
};

export const chooseAdminWidget = () => {
    return {
        type: CHOOSE_ADMIN_WIDGET,
        payload: 'widget'
    }
}

export const chooseAdminStripe = () => {
    return {
        type: CHOOSE_ADMIN_STRIPE,
        payload: 'stripe'
    }
}

export const showClientBlockModal = () => {
    return {
        type: SHOW_CLIENT_BLOCK_MODAL
    };
};

export const hideClientBlockModal = () => {
    return {
        type: HIDE_CLIENT_BLOCK_MODAL
    };
};

const addUserCreditsRequest = () => {
    return {
        type: ADD_USER_CREDITS_REQUEST
    };
};

const addUserCreditsSuccess = () => {
    return {
        type: ADD_USER_CREDITS_SUCCESS,
    };
};
const addUserCreditsFailure = (error) => {
    return {
        type: ADD_USER_CREDITS_FAILURE,
        payload: error,
    };
};

const changeBlockedStatusRequest = () => {
    return {
        type: CHANGE_BLOCKED_STATUS_REQUEST
    };
};

const changeBlockedStatusSuccess = (userId, blockAccount) => {
    return {
        type: CHANGE_BLOCKED_STATUS_SUCCESS,
        payload: { userId, blockAccount }
    };
};
const changeBlockedStatusFailure = (error) => {
    return {
        type: CHANGE_BLOCKED_STATUS_FAILURE,
        payload: error,
    };
};

export const addUserCredits = ({userId, credits, reason}) => {
    return function (dispatch) {
        dispatch(addUserCreditsRequest());
        API({
            method: 'patch',
            url: `/admin/users/${userId}/credits`,
            data: {credits, reason},
        })
            .then((response) => {
                dispatch(addUserCreditsSuccess());
            })
            .catch((error) => {
                dispatch(addUserCreditsFailure(error));
            });
    };
};

export const changeBlockedStatus = (userId, blockStatus) => {
    return function (dispatch) {
        dispatch(changeBlockedStatusRequest());
        API({
            method: 'put',
            url: `/admin/users/${userId}/block`,
            data: {blockAccount: blockStatus},
        })
            .then((response) => {
                const { userId, blockAccount } = response.data;
                dispatch(changeBlockedStatusSuccess(userId, blockAccount));
                dispatch(hideClientBlockModal());
            })
            .catch((error) => {
                dispatch(changeBlockedStatusFailure(error));
            });
    };
};

const getStripeProductsRequest = () => {
    return {
        type: GET_STRIPE_PRODUCTS_REQUEST
    };
};

const getStripeProductsSuccess = (data) => {
    return {
        type: GET_STRIPE_PRODUCTS_SUCCESS,
        payload: data,
    };
};
const getStripeProductsFailure = (error) => {
    return {
        type: GET_STRIPE_PRODUCTS_FAILURE,
        payload: error,
    };
};

const getStripeTagsRequest = () => {
    return {
        type: GET_STRIPE_TAGS_REQUEST
    };
};

const getStripeTagsSuccess = (data) => {
    return {
        type: GET_STRIPE_TAGS_SUCCESS,
        payload: data,
    };
};
const getStripeTagsFailure = (error) => {
    return {
        type: GET_STRIPE_TAGS_FAILURE,
        payload: error,
    };
};

const addStripeProductRequest = () => {
    return {
        type: ADD_STRIPE_PRODUCT_REQUEST
    };
};

const addStripeProductSuccess = () => {
    return {
        type: ADD_STRIPE_PRODUCT_SUCCESS,
    };
};
const addStripeProductFailure = (error) => {
    return {
        type: ADD_STRIPE_PRODUCT_FAILURE,
        payload: error,
    };
};

const removeStripeProductRequest = () => {
    return {
        type: REMOVE_STRIPE_PRODUCT_REQUEST
    };
};

const removeStripeProductSuccess = () => {
    return {
        type: REMOVE_STRIPE_PRODUCT_SUCCESS,
    };
};

const removeStripeProductFailure = (error) => {
    return {
        type: REMOVE_STRIPE_PRODUCT_FAILURE,
        payload: error,
    };
};

export const showStripeProductAddModal = () => {
    return {
        type: SHOW_STRIPE_PRODUCT_ADD_MODAL
    };
};

export const hideStripeProductAddModal = () => {
    return {
        type: HIDE_STRIPE_PRODUCT_ADD_MODAL
    };
};

export const showStripeProductDeleteModal = (selectedProductId) => {
    return {
        type: SHOW_STRIPE_PRODUCT_DELETE_MODAL,
        payload: selectedProductId
    };
};

export const hideStripeProductDeleteModal = () => {
    return {
        type: HIDE_STRIPE_PRODUCT_DELETE_MODAL
    };
};


export const getStripeProducts = () => {
    return function (dispatch) {
        dispatch(getStripeProductsRequest());
        API({
            method: 'get',
            url: `/admin/products-list`
        })
            .then((response) => {
                dispatch(getStripeProductsSuccess(response.data.list));
            })
            .catch((error) => {
                dispatch(getStripeProductsFailure(error));
            });
    };
};

export const getStripeTags = () => {
    return function (dispatch) {
        dispatch(getStripeTagsRequest());
        API({
            method: 'get',
            url: `/admin/product-tags`
        })
            .then((response) => {
                dispatch(getStripeTagsSuccess(response.data.list));
            })
            .catch((error) => {
                dispatch(getStripeTagsFailure(error));
            });
    };
};

export const addStripeProduct = ({tag, name, productId}) => {
    return function (dispatch) {
        dispatch(addStripeProductRequest());
        API({
            method: 'post',
            url: `/admin/add-product`,
            data: {tag, name, productId},
        })
            .then((response) => {
                dispatch(addStripeProductSuccess());
                dispatch(getStripeProducts());
                dispatch(hideStripeProductAddModal());
            })
            .catch((error) => {
                dispatch(addStripeProductFailure(error));
            });
    };
};

export const removeStripeProduct = (id) => {
    return function (dispatch) {
        dispatch(removeStripeProductRequest());
        API({
            method: 'delete',
            url: `/admin/product/${id}`
        })
            .then((response) => {
                dispatch(removeStripeProductSuccess());
                dispatch(getStripeProducts());
                dispatch(hideStripeProductDeleteModal());
            })
            .catch((error) => {
                dispatch(removeStripeProductFailure(error));
            });
    };
};

