import React from "react";
import {Modal, Button} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
    hideRefreshApiKeyModal,
    startAppRefreshApiKey
} from "../../services/action-creators";


const AppRefreshApiKeyModal = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { selectedId, showRefreshModal } = useSelector((state) => state.appUsageDetails);
    const handleClose = () => {
        dispatch(hideRefreshApiKeyModal());
    };
    const handleRefresh = () => {
        if (selectedId) {
            dispatch(startAppRefreshApiKey(selectedId, history));
        }
    };
    return (
        <Modal centered show={showRefreshModal} onHide={ handleClose } className="modal-custom-confirm">
            <button onClick={handleClose} type="button" className="btn-close btn-close-absolute" aria-label="Close"/>
            <div className="small-modal-content">
                <div className="flex-row align-center">
                    <h3>Confirm App Key Refresh</h3>
                </div>
                <div className="dfm-text">
                    Refreshing the API Key will break any existing integrations that this app is connected to!
                </div>
                <div className="adm-footer">
                    <Button className="modal-button" variant="outline-secondary" onClick={handleClose}>Cancel</Button>
                    <Button className="modal-button" variant="success" onClick={handleRefresh}>Ok</Button>
                </div>
            </div>
        </Modal>
    );

};

export default AppRefreshApiKeyModal;
