import React from "react";
import {show as showIntercom} from "@intercom/messenger-js-sdk";


const NeedMoreCreditsHover = ({ visible, maxCredits }) => {
    return (
        <div className={`payment-need-more-credits ${visible ? 'show' : ''}`}>
            <div className="mb-3 label">Need more than {maxCredits} credits?</div>
            <button className={`btn btn-success`} onClick={() => showIntercom()}>
                Contact Us
            </button>
        </div>
    )
}

export default NeedMoreCreditsHover;
