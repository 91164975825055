import API, { updateHeaders } from '../../utils/httpAPI';

import {
    SYNC_REQUEST,
    SYNC_SUCCESS,
    SYNC_FAILURE,
    SIGN_IN_FAILURE,
    SIGN_IN_REQUEST,
    SIGN_IN_SUCCESS,
    SIGN_UP_FAILURE,
    SIGN_UP_REQUEST,
    SIGN_UP_SUCCESS,
    SIGN_OUT_FAILURE,
    SIGN_OUT_REQUEST,
    SIGN_OUT_SUCCESS,
    RESET_SUCCESS_SIGN_UP_STATUS,
    CONFIRM_EMAIL_REQUEST,
    CONFIRM_EMAIL_SUCCESS,
    CONFIRM_EMAIL_FAILURE,
    UPDATE_USER_INFO_REQUEST,
    UPDATE_USER_INFO_SUCCESS,
    UPDATE_USER_INFO_FAILURE,
    DELETE_USER_REQUEST,
    DELETE_USER_SUCCESS,
    DELETE_USER_FAILURE,
    RESET_PASSWORD_REQUEST,
    RESET_PASSWORD_SUCCESS,
    RESET_PASSWORD_FAILURE,
    CHANGE_PASSWORD_REQUEST,
    CHANGE_PASSWORD_SUCCESS,
    CHANGE_PASSWORD_FAILURE,
    AUTH_VALIDATION_ERROR,
    RESET_EMAIL_CONFIRMATION_ERROR,
    RESET_RESET_PASSWORD_SUCCESS_STATUS,
} from './action-types';
import { showAlertByError } from '../navigation/action-creators';
import {NotificationManager} from "react-notifications";

const signUpRequest = () => {
    return {
        type: SIGN_UP_REQUEST,
    };
};

const signUpSuccess = (user, token) => {
    return {
        type: SIGN_UP_SUCCESS,
        payload: {
            user,
            token,
        },
    };
};

const signUpFailure = (errors) => {
    return {
        type: SIGN_UP_FAILURE,
        payload: errors,
    };
};

export const resetSuccessSignUpStatus = () => ({
    type: RESET_SUCCESS_SIGN_UP_STATUS,
});

export const signUp = (user) => {
    return function (dispatch) {
        dispatch(signUpRequest());
        API({
            method: 'post',
            url: '/auth/signUp',
            data: user,
        })
            .then((response) => {
                const { user, token } = response.data;
                dispatch(signUpSuccess(user, token));
            })
            .catch((error) => {
                if (error.message === 'Network Error')
                    return dispatch(signUpFailure({error: error.message}));

                if (
                    error.response &&
                    error.response.data &&
                    error.response.data.errors
                ) {
                    return dispatch(signUpFailure(error.response.data.errors));
                }

                dispatch(signUpFailure({error: 'Can not register user with such data'}));
            });
    };
};

const confirmEmailRequest = () => {
    return {
        type: CONFIRM_EMAIL_REQUEST,
    };
};

const confirmEmailSuccess = () => {
    return {
        type: CONFIRM_EMAIL_SUCCESS,
    };
};

const confirmEmailFailure = (error) => {
    return {
        type: CONFIRM_EMAIL_FAILURE,
        payload: {
            error,
        },
    };
};

export const resetEmailConfirmationError = () => {
    return {
        type: RESET_EMAIL_CONFIRMATION_ERROR,
    };
};

export const confirmEmail = (confirmationToken) => {
    return function (dispatch) {
        dispatch(confirmEmailRequest());
        API({
            method: 'post',
            url: '/auth/confirmEmail',
            data: { confirmationToken },
        })
            .then((response) => {
                const { success, error } = response.data;

                if (success) {
                    dispatch(confirmEmailSuccess());
                }

                dispatch(confirmEmailFailure(error));
            })
            .catch((error) => {
                dispatch(confirmEmailFailure(error.message));
            });
    };
};

const syncRequest = () => {
    return {
        type: SYNC_REQUEST,
    };
};

const syncSuccess = ({ userCredits, alerts }) => {
    return {
        type: SYNC_SUCCESS,
        payload: {
            userCredits,
            alerts,
        },
    };
};

const syncFailure = (error) => {
    return {
        type: SYNC_FAILURE,
        payload: error,
    };
};

const signInRequest = () => {
    return {
        type: SIGN_IN_REQUEST,
    };
};

const signInSuccess = (user, token, hash) => {
    return {
        type: SIGN_IN_SUCCESS,
        payload: {
            user,
            token,
            hash
        },
    };
};

const signInFailure = (error) => {
    return {
        type: SIGN_IN_FAILURE,
        payload: error,
    };
};

const resetPasswordRequest = () => {
    return {
        type: RESET_PASSWORD_REQUEST,
    };
};

const resetPasswordSuccess = (user) => {
    return {
        type: RESET_PASSWORD_SUCCESS,
        payload: {
            user,
        },
    };
};

const resetPasswordFailure = (error) => {
    return {
        type: RESET_PASSWORD_FAILURE,
        payload: error,
    };
};

export const resetResetPasswordSuccessStatus = () => {
    return {
        type: RESET_RESET_PASSWORD_SUCCESS_STATUS,
    };
};

export const signIn = (payload, history) => {
    return function (dispatch) {
        dispatch(signInRequest());
        API({
            method: 'post',
            url: '/auth/signIn',
            data: payload,
        })
            .then((response) => {
                const { user, token, hash } = response.data;
                localStorage.setItem('USER-TOKEN', token);
                updateHeaders();
                dispatch(signInSuccess(user, token, hash));
                history.push('/bulk');
            })
            .catch((error) => {
                if (error.message === 'Network Error')
                    return dispatch(signInFailure(error.message));
                if (
                    error.response &&
                    error.response.data &&
                    error.response.data.Error === 'User is blocked'
                ) {
                    return dispatch(
                        signInFailure(
                            `User "${error.response.data.userEmail}" is blocked. Please ask support for help`
                        )
                    );
                }
                if (
                    error.response &&
                    error.response.data &&
                    error.response.data.error
                ) {
                    return dispatch(signInFailure(error.response.data.error));
                }
                dispatch(
                    signInFailure('Can not find user or password mismatch')
                );
            });
    };
};

export const resetPassword = (payload) => {
    return function (dispatch) {
        dispatch(resetPasswordRequest());
        API({
            method: 'post',
            url: '/auth/resetPassword',
            data: payload,
        })
            .then(() => {
                dispatch(resetPasswordSuccess());
            })
            .catch((error) => {
                if (error.message === 'Network Error')
                    return dispatch(resetPasswordFailure(error.message));

                dispatch(
                    resetPasswordFailure('Can not reset password. Try Later')
                );
            });
    };
};

export const startSync = () => {
    return function (dispatch) {
        if (localStorage.getItem('USER-TOKEN')) {
            //console.error('token', localStorage.getItem("USER-TOKEN"));
            dispatch(syncRequest());
            API({
                method: 'post',
                url: '/user/sync',
                data: {},
            })
                .then((response) => {
                    const { userCredits, alerts } = response.data;
                    if (userCredits !== undefined) {
                        dispatch(syncSuccess({ userCredits, alerts }));
                    }
                })
                .catch((err) => {
                    console.error('sync', err);
                    dispatch(syncFailure(err.message));
                });
        }

        setTimeout(() => dispatch(startSync({})), 60000);
    };
};

export const signOutRequest = function () {
    return {
        type: SIGN_OUT_REQUEST,
    };
};

export const signOutSuccess = function () {
    return {
        type: SIGN_OUT_SUCCESS,
    };
};

export const signOutFailure = function () {
    return {
        type: SIGN_OUT_FAILURE,
    };
};

export const signOut = function (history) {
    return function (dispatch) {
        dispatch(signOutRequest());
        localStorage.clear();
        window.location.pathname = '/signin';
        if (localStorage.getItem('USER_TOKEN')) {
            dispatch(signOutFailure());
        } else {
            dispatch(signOutSuccess());
        }
    };
};

export const updateUserInfoRequest = function () {
    return {
        type: UPDATE_USER_INFO_REQUEST,
    };
};

export const updateUserInfoSuccess = function (user) {
    return {
        type: UPDATE_USER_INFO_SUCCESS,
        payload: { user },
    };
};

export const updateUserInfoFailure = function () {
    return {
        type: UPDATE_USER_INFO_FAILURE,
    };
};

export const deleteUserRequest = function () {
    return {
        type: DELETE_USER_REQUEST,
    };
};

export const deleteUserSuccess = function () {
    return {
        type: DELETE_USER_SUCCESS,
    };
};

export const deleteUserFailure = function () {
    return {
        type: DELETE_USER_FAILURE,
    };
};

export const changePasswordRequest = function () {
    return {
        type: CHANGE_PASSWORD_REQUEST,
    };
};

export const changePasswordSuccess = function () {
    return {
        type: CHANGE_PASSWORD_SUCCESS,
    };
};

export const changePasswordFailure = function (error) {
    return {
        type: CHANGE_PASSWORD_FAILURE,
        payload: {
            error,
        },
    };
};

export const updateUserInfo = (payload) => {
    return function (dispatch) {
        dispatch(updateUserInfoRequest());
        API({
            method: 'post',
            url: '/user/updateInfo',
            data: payload,
        })
            .then((response) => {
                const { user } = response.data;
                dispatch(updateUserInfoSuccess(user));
                NotificationManager.success('Successfully saved', 'Complete', 3000);
            })
            .catch((error) => {
                dispatch(updateUserInfoFailure(error));
                NotificationManager.error('Something went wrong', 'Error', 3000);
            });
    };
};

export const deleteAccountConfirmed = (password, history) => {
    return function (dispatch) {
        dispatch(deleteUserRequest);
        API({
            method: 'post',
            url: '/user/delete',
            data: { password },
        })
            .then((response) => {
                dispatch(deleteUserSuccess());
                NotificationManager.success('Your account will be deleted soon!', 'Success', 4500);
                setTimeout(() => dispatch(signOut(history)), 5000);
            })
            .catch((err) => {
                dispatch(deleteUserFailure());
                NotificationManager.error(err.response.data.error, 'Error', 3000);
            });
    };
};

export const changePassword = (oldPassword, newPassword, history) => {
    return function (dispatch) {
        dispatch(changePasswordRequest());
        API({
            method: 'post',
            url: '/user/changePassword',
            data: { oldPassword, newPassword },
        })
            .then((response) => {
                dispatch(changePasswordSuccess());
                // dispatch(
                //     showAlertByError({
                //         type: 'changePassword',
                //         message: 'Password changed successfully!',
                //     })
                // );
                NotificationManager.success('Password changed successfully', 'Complete', 3000);
                dispatch(signOut(history));
            })
            .catch((error) => {
                console.error(error.body, error);
                dispatch(changePasswordFailure());
                // dispatch(
                //     showAlertByError({
                //         type: 'changePassword',
                //         message: 'Can not change password',
                //     })
                // );
                NotificationManager.error('Can not change password', 'Error', 3000);
            });
    };
};

export const changePasswordUsingResetToken = (resetPasswordToken, password) => {
    return function (dispatch) {
        dispatch(changePasswordRequest());
        API({
            method: 'post',
            url: '/auth/changePassword',
            data: { resetPasswordToken, password },
        })
            .then((response) => {
                dispatch(changePasswordSuccess());
            })
            .catch((error) => {
                dispatch(changePasswordFailure(error.response.data.error));
            });
    };
};

export const testAddCredits = () => {
    return function (dispatch) {
        dispatch(updateUserInfoRequest);
        API({
            method: 'post',
            url: '/testAddCredits',
            data: {},
        })
            .then((response) => {
                const { user } = response.data;
                dispatch(updateUserInfoSuccess(user));
            })
            .catch((error) => {
                dispatch(updateUserInfoFailure(error));
            });
    };
};

export const authValidationError = function (message) {
    return {
        type: AUTH_VALIDATION_ERROR,
        payload: message,
    };
};

export const showError = (message) => {
    return function (dispatch) {
        dispatch(authValidationError(message));
    };
};
