import React, {useState} from "react";
import {Modal, Button, Form, Alert} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
    hideModal,
    showAppDeleteModal,
    updateSelectedAppData,
    saveAppData
} from "../../../services/action-creators";


const AppWebhookSettingsModal = () => {
    const { apps, selectedAppId, selectedAppData } = useSelector((state) => state.apps);
    const app = apps[selectedAppId];
    const { data } = selectedAppData;

    const dispatch = useDispatch();
    const { showModal } = useSelector((state) => state.navigation);

    const [isValidName, setIsValidName] = useState(true);

    const handleClose = () => dispatch(hideModal());
    const handleCancel = () => {
        dispatch(updateSelectedAppData(app.data));
        dispatch(hideModal());
    };
    const handleDelete = () => {
        dispatch(hideModal());
        dispatch(showAppDeleteModal(selectedAppId, app.name));
    };

    const handleSendNotificationsToChange = (event) => {
        dispatch(updateSelectedAppData({sendNotificationsTo: event.target.value}));
    };

    const handleMinimumCreditTresholdChange = (event) => {
        dispatch(updateSelectedAppData({minimumCreditTreshold: event.target.value}));
    };

    const handleReceiveLowBalanceAlertsChange = (event) => {
        dispatch(updateSelectedAppData({receiveLowBalanceAlerts: event.target.value === 'on' ? '' : 'on'}));
    };

    const handleAppNameChange = (event) => {
        dispatch(updateSelectedAppData({appName: event.target.value}));
    };

    const handleSave = (event) => {
        event.preventDefault();
        event.stopPropagation();
        const form = event.currentTarget;

        if (form.checkValidity() === false) {
            setIsValidName(false);
            return;
        }

        dispatch(saveAppData({ id: selectedAppId, appData: selectedAppData.data }));
        dispatch(hideModal());
    };

    return (
        <Modal centered show={showModal} onHide={ handleClose } className="modal-app-settings-custom">
            <button onClick={handleClose} type="button" className="btn-close btn-close-absolute" aria-label="Close"/>
            <div className="app-usage-modal">
                <div className="app-usage-modal-header align-center">
                    <h1>App Settings</h1>
                </div>
                <Form className="app-settings-modal-form" onSubmit={handleSave} noValidate>
                    <Form.Group className="ca-form-row">
                        <Form.Label className="app-application-name">Application Name</Form.Label>
                        <Form.Control
                            value={data?.appName}
                            onChange={handleAppNameChange}
                            type="text"
                            placeholder=""
                            required
                            isInvalid={!isValidName}
                            formNoValidate
                        />
                        <Form.Control.Feedback type="invalid">
                            The name field is required.
                        </Form.Control.Feedback>
                        <p className="ca-row-comment ">Provide a name for easy identification of this app.</p>
                    </Form.Group>

                    {!isValidName &&
                        <Alert key="danger" variant="danger" className="app-settings-alert m-0">
                            Please correct the errors above before continuing
                        </Alert>
                    }

                    <div className="aum-buttons">
                        <Button className ="aum-btn" variant="outline-danger" onClick={handleDelete}>Delete App</Button>
                        <Button className ="aum-btn" variant="outline-secondary" onClick={handleCancel}>Cancel</Button>
                    </div>
                    <Button className ="aum-confirm-btn" variant="success" type="submit">Save</Button>
                </Form>
            </div>
        </Modal>
    );

};

export default AppWebhookSettingsModal;
