import axios from 'axios'

const setUserCreds = () => {
    axios.defaults.headers.common['Authorization'] = `Bearer: ${localStorage.getItem("USER-TOKEN")}`;
    //axios.defaults.headers.common['user'] = localStorage.getItem("USER-ID");
    //axios.defaults.headers.common['token'] = localStorage.getItem("USER-TOKEN");
};

setUserCreds();
axios.defaults.baseURL = process.env.REACT_APP_PUBLIC_API_HOST;
axios.defaults.responseType = "json";

axios.interceptors.response.use((response) => response, (error) => {
    if (error.response.status === 401) {
        localStorage.clear();
        window.location.href = '/';
    }

    return Promise.reject(error);
});

export default axios;

export const updateHeaders = () => {
    setUserCreds();
};
