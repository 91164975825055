import React from 'react'
import { useSelector } from "react-redux";



const PageContent = () => {
    const { activeTab } = useSelector((state) => state.navigation);
            return(
                <>
                {activeTab} doesn't have content yet
                </>
            )
};

export default PageContent