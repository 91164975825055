import API from "../../utils/httpAPI";

import {
    GET_USAGE_DETAILS_REQUEST,
    GET_USAGE_DETAILS_SUCCESS,
    GET_USAGE_DETAILS_FAILURE,
} from "./action-types";

export const getUsageDetailsRequest = () => {
    return {
        type: GET_USAGE_DETAILS_REQUEST,
        // payload: { date }
    };
};

export const getUsageDetailsSuccess = ({ chart, details }) => {
    return {
        type: GET_USAGE_DETAILS_SUCCESS,
        payload: { chart, details }
    };
};

export const getUsageDetailsFailure = () => {
    return {
        type: GET_USAGE_DETAILS_FAILURE,
        // payload: { date }
    };
};

export const getUsageDetails = ({ from, to, page, pageSize, appId, hiddenBuckets }) => {
    return function (dispatch) {
        dispatch(getUsageDetailsRequest());
        API({
            method: 'get',
            url: '/usage',
            params: { from, to, page, pageSize, appId, hiddenBuckets }
        })
            .then((response) => {
                const { chart, details } = response.data;
                dispatch(getUsageDetailsSuccess({ chart, details }));
            })
            .catch((error) => {
                dispatch(getUsageDetailsFailure(error));
            });
    };
};

export const getUsageDetailsById = ({ userId, from, to, page, pageSize, appId, hiddenBuckets }) => {
    return function (dispatch) {
        dispatch(getUsageDetailsRequest());
        API({
            method: 'get',
            url: `/admin/users/${userId}/usage`,
            params: { from, to, page, pageSize, appId, hiddenBuckets }
        })
            .then((response) => {
                const { chart, details } = response.data;
                dispatch(getUsageDetailsSuccess({ chart, details }));
            })
            .catch((error) => {
                dispatch(getUsageDetailsFailure(error));
            });
    };
};
