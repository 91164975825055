import React from "react";
import {Button, Container} from "react-bootstrap";
import {Link} from "react-router-dom";
import {MOBILE_VIEW_WIDTH} from "../../config/mobile";
import {useMediaQuery} from "../../utils/hooks/useMediaQuery";


const PageMenu = () => {

    const isMobile = useMediaQuery(`(max-width: ${MOBILE_VIEW_WIDTH}px)`);

    return (
        <div className="page-menu">
            <Container>
                <h1>Apps</h1>
            </Container>
            <Container className="page-menu-buttons">
                <Link to={"/createApp"} className="btn-page btn btn-success">{isMobile ? 'Create App' : 'Create New App'}</Link>
                <Button className="btn-page btn-outline" variant="outline-success" onClick={() => {
                    window.open('https://docs.proofy.io/getting-started');
                }}>{isMobile ? 'Docs' : 'API Documentation'}</Button>
            </Container>
        </div>
    );
};

export default PageMenu;
