export * from '../common-action-types';

export const CHOOSE_APPS = "CHOOSE_APPS";
export const CHOOSE_VERIFY = "CHOOSE_VERIFY";
export const CHOOSE_CLEAN = "CHOOSE_CLEAN";
export const CHOOSE_SYNC = "CHOOSE_SYNC";
export const CHOOSE_ACCOUNT = "CHOOSE_ACCOUNT";
export const CHOOSE_PAYMENT = "CHOOSE_PAYMENT";
export const CHOOSE_CHECKOUT_SUCCESS = "CHOOSE_CHECKOUT_SUCCESS";
export const CHOOSE_CHECKOUT_CANCEL = "CHOOSE_CHECKOUT_CANCEL";

export const SHOW_MODAL = "SHOW_MODAL";
export const HIDE_ALERT = "HIDE_ALERT";
