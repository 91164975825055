import React from "react";
import { Button } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import {MAIN_PROOFY_URL} from "../../../config/proofy-links";

const SignUpSuccess = () => {
    const history = useHistory();

    const redirectToHome = () => history.push("/");

    const handleProofyClick = () => {
        window.open(MAIN_PROOFY_URL, '_blank').focus();
    }

    return (
        <div className="signin-wrapper">
            <div className="signin-logo" onClick={handleProofyClick}>
                <img src="/images/new-logo.svg" alt="logo"/>
            </div>

            <div className="signup-success-content">
                <img className="signup-icon" src="images/success.svg" alt="Success icon"/>
                <h2 className="signup-success-title">Thank you!</h2>
                <p className="signin-text">
                    A confirmation email has been sent. To activate your account, click the link in the email. Check your inbox and spam folder.
                </p>
                <Button className="signup-success-button" variant="success" onClick={redirectToHome}>
                    Go to admin panel
                </Button>
            </div>

            <div className="sign-in-copy">Copyright ©2025 Proofy.io. All Rights Reserved</div>
        </div>
    );
};

export default SignUpSuccess;
