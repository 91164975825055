import API from "../../utils/httpAPI";

import {
    GET_USERS_REQUEST,
    GET_USERS_FAILURE,
    GET_USERS_SUCCESS,
    GET_USER_TRANSACTIONS_REQUEST,
    GET_USER_TRANSACTIONS_SUCCESS,
    GET_USER_TRANSACTIONS_FAILURE,
    CLEAR_USERS,
    UPDATE_USER_CREDITS
} from "./action-types";

const getUsersRequest = () => {
    return {
        type: GET_USERS_REQUEST,
    };
};

const getUsersSuccess = (data) => {
    return {
        type: GET_USERS_SUCCESS,
        payload: data,
    };
};
const getUsersFailure = (error) => {
    return {
        type: GET_USERS_FAILURE,
        payload: error,
    };
};

const getUserTransactionsRequest = () => {
    return {
        type: GET_USER_TRANSACTIONS_REQUEST,
    };
};

const getUserTransactionsSuccess = (userTransactions) => {
    return {
        type: GET_USER_TRANSACTIONS_SUCCESS,
        payload: {
            userTransactions,
        },
    };
};
const getUserTransactionsFailure = (error) => {
    return {
        type: GET_USER_TRANSACTIONS_FAILURE,
        payload: error,
    };
};

export const clearUsers = () => {
    return {
        type: CLEAR_USERS,
    };
};

export const loadUsers = (params) => {
    return function (dispatch) {
        dispatch(getUsersRequest());
        API({
            method: 'get',
            url: '/admin/users',
            params,
            data: {}
        })
            .then((response) => {
                const { data } = response;
                dispatch(getUsersSuccess(data));
            })
            .catch(async (error) => {
                dispatch(getUsersFailure(error));
            });
    };
};

export const loadUserTransactions = (userId) => {
    return function (dispatch) {
        dispatch(getUserTransactionsRequest());
        API({
            method: 'get',
            url: `/admin/users/${userId}/transactions`
        })
            .then((response) => {
                const { userTransactions } = response.data;
                dispatch(getUserTransactionsSuccess(userTransactions));
            })
            .catch(async (error) => {
                dispatch(getUserTransactionsFailure(error));
            });
    };
};

export const updateUserCredits = (userCredits) => {
    return {
        type: UPDATE_USER_CREDITS,
        payload: { userCredits }
    };
};