import {
    FILE_UPLOAD_START_REQUEST,
    FILE_UPLOAD_START_SUCCESS,
    FILE_UPLOAD_START_FAILURE,
    FILE_UPLOAD_SUCCESS,
    FILE_UPLOAD_FAILURE,
    FILE_DELETE_SUCCESS,
    GET_FILES_REQUEST,
    GET_FILES_FAILURE,
    GET_FILES_SUCCESS,
    GET_FILE_REQUESTS_REQUEST,
    GET_FILE_REQUESTS_SUCCESS,
    GET_FILE_REQUESTS_FAILURE,

    SIGN_IN_SUCCESS,
    SIGN_UP_SUCCESS,
    SIGN_OUT_SUCCESS,

    SHOW_DOWNLOAD_MODAL,
    HIDE_DOWNLOAD_MODAL,

    SHOW_PURCHASE_MODAL,
    HIDE_PURCHASE_MODAL,

    SHOW_FILE_DELETE_MODAL,
    HIDE_FILE_DELETE_MODAL,
    TOGGLE_DOWNLOAD_PRESET,
    EXPORT_CREATED,
    UPDATE_FILES,
    UPDATE_FILE_PROGRESS
} from "./action-types";

const fileReducer = function (state, action) {
    switch (action.type) {
        case FILE_UPLOAD_START_REQUEST:
            return {
                ...state,
                fileName: action.payload.fileName,
                isFileUploading: true,
            };
        case FILE_UPLOAD_START_SUCCESS:
            return {
                ...state,
                signedUrl: action.payload.signedUrl,
            };
        case FILE_UPLOAD_START_FAILURE:
            return {
                ...state,
                fileName: "",
                signedUrl: "",
                isFileUploading: false,
            };
        case GET_FILES_REQUEST:
            return {
                ...state,
                filesLoading: !action.payload.noLoading,
            };
        case GET_FILES_FAILURE:
            return {
                ...state,
                filesLoading: false,
                failure: action.payload,
            };
        case GET_FILE_REQUESTS_REQUEST:
            return {
                ...state,
                fileRequestsLoading: true,
            };
        case GET_FILE_REQUESTS_FAILURE:
            return {
                ...state,
                fileRequestsLoading: false,
                failure: action.payload,
            };
        case GET_FILES_SUCCESS:
            return {
                ...state,
                filesLoading: false,
                filesLoaded: true,
                files: action.payload.files,
                total: action.payload.total,
                exports: action.payload.exports
            };
        case UPDATE_FILES:
            const updatedFiles = {};
            action.payload.files.forEach(file => updatedFiles[file.id] = file);

            return {
                ...state,
                files: state.files.map(file => {
                    if (updatedFiles[file.id]) {
                        return updatedFiles[file.id];
                    }
                    return file;
                })
            };

        case UPDATE_FILE_PROGRESS:
            return {
                ...state,
                progress: action.payload,
            }
        case GET_FILE_REQUESTS_SUCCESS:
            const newFileRequests = {};
            action.payload.fileRequests.forEach(fileRequest => {
                newFileRequests[fileRequest.id] = fileRequest;
            });
            return {
                ...state,
                fileRequestsLoading: false,
                fileRequestsLoaded: true,
                fileRequests: newFileRequests
            };
        case FILE_UPLOAD_SUCCESS:
            return {
                ...state,
                files: [action.payload.file, ...state.files],
                total: state.total + 1,
                isFileUploading: false
            };
        case FILE_UPLOAD_FAILURE:
            return {
                ...state,
                isFileUploading: false
            };
        case FILE_DELETE_SUCCESS:
            return {
                ...state,
                files: state.files.filter(file => file.id !== action.payload.id),
                total: state.total - 1
            };
        case SHOW_DOWNLOAD_MODAL:
            return {
                ...state,
                selectedName: action.payload.fileName,
                selectedId: action.payload.id,
                showDownloadModal: true,
            };
        case HIDE_DOWNLOAD_MODAL:
            return {
                ...state,
                selectedName: null,
                selectedId: null,
                showDownloadModal: false,
            };
        case SHOW_FILE_DELETE_MODAL:
            return {
                ...state,
                selectedName: action.payload.fileName,
                selectedId: action.payload.id,
                showDeleteModal: true,
            };
        case HIDE_FILE_DELETE_MODAL:
            return {
                ...state,
                // selectedName: null,
                // selectedId: null,
                showDeleteModal: false,
            };
        case SHOW_PURCHASE_MODAL:
            return {
                ...state,
                selectedName: action.payload.fileName,
                selectedId: action.payload.id,
                showPurchaseModal: true,
            };
        case HIDE_PURCHASE_MODAL:
            return {
                ...state,
                selectedName: null,
                selectedId: null,
                showPurchaseModal: false,
            };
        case TOGGLE_DOWNLOAD_PRESET:
            // if (state.presetSelected === action.payload) {
            //     return {...state, presetSelected: null};
            // }
            return {
                ...state,
                presetSelected: action.payload
            };
        case SIGN_IN_SUCCESS:
        case SIGN_UP_SUCCESS:
        case SIGN_OUT_SUCCESS:
            return {
                ...state,
                fileName: "",
                signedUrl: "",
                failure: null,
                filesLoading: false,
                filesLoaded: false,
                files: [],
            };
        case EXPORT_CREATED:
            return {
                ...state,
                exports: [...state.exports, action.payload.exportFile]
            };
        default:
            return { ...state };
    }
};

export default fileReducer;
