import {
    SET_APP_USAGE_DETAILS_START_DATE,
    SET_APP_USAGE_DETAILS_END_DATE,
    GET_APP_USAGE_DETAILS_REQUEST,
    GET_APP_USAGE_DETAILS_SUCCESS,
    GET_APP_USAGE_DETAILS_FAILURE,
    SIGN_IN_SUCCESS,
    SIGN_UP_SUCCESS,
    SIGN_OUT_SUCCESS,
    SHOW_APP_DELETE_MODAL,
    HIDE_APP_DELETE_MODAL,
    SHOW_APP_REFRESH_API_KEY_MODAL,
    HIDE_APP_REFRESH_API_KEY_MODAL,
    SAVE_APP_DATA_SUCCESS,
    APP_REFRESH_API_KEY_REQUEST,
    APP_REFRESH_API_KEY_SUCCESS,
    APP_REFRESH_API_KEY_FAILURE,
} from "./action-types";

const appUsageDetailsReducer = function (state = {}, action) {
    switch (action.type) {
        case SET_APP_USAGE_DETAILS_START_DATE:
            return {
                ...state,
                auStartDate: action.payload.date,
                auIsChangedDate: true
            };
        case SET_APP_USAGE_DETAILS_END_DATE:
            return {
                ...state,
                auEndDate: action.payload.date,
                auIsChangedDate: true
            };
        case GET_APP_USAGE_DETAILS_REQUEST:
            return {
                ...state,
                auIsChangedDate: false,
                getAppUsageDetailsLoading: true,
            };
        case GET_APP_USAGE_DETAILS_FAILURE:
            return {
                ...state,
                auIsChangedDate: true,
                getAppUsageDetailsLoading: false,
            };
        case GET_APP_USAGE_DETAILS_SUCCESS:
            return {
                ...state,
                chart: action.payload.chart,
                details: action.payload.details,
                getAppUsageDetailsLoading: false,
            };
        case SHOW_APP_DELETE_MODAL:
            return {
                ...state,
                selectedName: action.payload.appName,
                selectedId: action.payload.id,
                showDeleteModal: true,
            };
        case HIDE_APP_DELETE_MODAL:
            return {
                ...state,
                selectedName: null,
                selectedId: null,
                showDeleteModal: false,
            };
        case SHOW_APP_REFRESH_API_KEY_MODAL:
            return {
                ...state,
                selectedId: action.payload.id,
                showRefreshModal: true,
            };
        case HIDE_APP_REFRESH_API_KEY_MODAL:
            return {
                ...state,
                selectedId: null,
                showRefreshModal: false,
            };
        case SIGN_IN_SUCCESS:
        case SIGN_UP_SUCCESS:
        case SIGN_OUT_SUCCESS:
            return {
                ...state,
                auStartDate: null,
                auEndDate: null,
                auIsChangedDate: false,
                auDetails: [],
                enabled: {
                    total: true,
                    single: true,
                    bulk: true,
                    other: true,
                    valid: true,
                    risky: true,
                    unknown: true,
                    invalid: true
                }
            };
        case SAVE_APP_DATA_SUCCESS:
            return {
                ...state,
                selectedName: action.payload.app.name
            };
        case APP_REFRESH_API_KEY_REQUEST:
            return {
                ...state,
                apiKeyRefreshLoading: true,
            }
        case APP_REFRESH_API_KEY_SUCCESS:
            return {
                ...state,
                apiKeyRefreshLoading: false,
            };
        case APP_REFRESH_API_KEY_FAILURE:
            return {
                ...state,
                apiKeyRefreshLoading: false
            };
        default:
            return state;
    }
};

export default appUsageDetailsReducer;
