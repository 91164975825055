import React from 'react'
import PageMenu from '../component/PageMenu'
import PageModal from '../component/PageModal'
import PageContent from '../component/PageContent'

const Sync = () => {
    return (
        <div className="page">
            <PageMenu activeTabName="sync" />
            {/*<PageAlert/>*/}
            <PageContent/>
            <PageModal/>
        </div>
    )
};

export default Sync
