import React, { useCallback, useState } from 'react';
import {Button, Form} from 'react-bootstrap';
import { useDispatch, useSelector } from "react-redux";

import { useHistory } from "react-router-dom";
import { chooseClean, addUserCredits, loadUserTransactions, loadAppsByUserId, getUsageDetailsById } from "../../services/action-creators"
import { TablePagination } from "@material-ui/core";
import { getDateTimeString, getSmallId } from "../../utils/helpers";
import {showClientBlockModal} from "../../services/admin/action-creators";
import { DateRangePicker } from "react-dates";
import moment from "moment";
import UsageContent from '../usage/UsageContent';

const AdminClient = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const [tFocusedInput, setTFocusedInput] = useState(null);
    const [tStartDate, setTStartDate] = useState(null);
    const [tEndDate, setTEndDate] = useState(null);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [credits, setCredits] = useState("");
    const [creditsType, setCreditsType] = useState("");
    const {
        users,
        userTransactions,
        userTransactionsLoaded,
        userTransactionsLoading,
        failure
    } = useSelector((state) => state.users);

    const { chosenId } = useSelector((state) => state.admin);

    const handlePageChange = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleCreditsChange = (event) => {
        setCredits(event.target.value);
    };

    const handleCreditsTypeChange = (event) => {
        setCreditsType(event.target.value);
    };

    const handleAddClick = () => {
        const creditsNumber = Number(credits);
        if (creditsNumber < 1) {
            console.error('Credits not set!');
            return;
        }


        if (creditsType === "") {
            console.error('Credits type not set!');
            return;
        }

        setCredits("");
        setCreditsType("");
        dispatch(addUserCredits({
            userId: chosenId, credits: creditsNumber, reason: creditsType
        }));
    };

    const handleBlockClick =  () => {
       dispatch(showClientBlockModal());
    };

    const fetchApps = useCallback(() => {
        dispatch(loadAppsByUserId(chosenId))
    }, [dispatch, chosenId]);

    const fetchUsageDetails = useCallback(opts => {
        dispatch(getUsageDetailsById({ userId: chosenId, ...opts }))
    }, [dispatch, chosenId]);

    if (failure && !userTransactionsLoaded) {
        setTimeout(() => dispatch(chooseClean(history)), 20000);
        return (<div> failure : {JSON.stringify(failure)}</div>)
    }

    if (!userTransactionsLoading && !userTransactionsLoaded) {
        dispatch(loadUserTransactions(chosenId));
        return (<div> loading ... </div>);
    }

    const filterTransactions = () => {
        const result = Object.keys(userTransactions)
            .map(key => userTransactions[key])
            .filter(transaction => {
                let flag = true;
                if (tStartDate && tStartDate.isAfter(transaction.createdAt)) {
                    flag = false;
                }
                if (tEndDate && tEndDate.isBefore(transaction.createdAt)) {
                    flag = false;
                }

                return flag;
            });
        return result;
    };

    const transactions = filterTransactions();

    const countTransactions = () => {
        return transactions.length;
    };

    const filterUsers = () => {
        const result = Object.keys(users)
            .filter(key => users[key]._id === chosenId);
        return result;
    };

    if(!Object.keys(users).length) {
        return (<></>);
    }

    const nowMoment = moment();
    const isOutsideRange = date => date.isAfter(nowMoment);

    const onTransactionClose = ({ startDate, endDate }) => {
        if (!startDate || !endDate) return;

        const startStamp = startDate.startOf('day').unix();
        const endStamp = endDate.endOf('day').unix();

        dispatch(loadUserTransactions(chosenId, startStamp, endStamp));
    };

    const handleTransactionDateChange = ({startDate, endDate}) => {
        if (startDate !== null && !startDate.isSame(tStartDate)) {
            setTStartDate(startDate);
        }
        if (endDate !== null && !endDate.isSame(tEndDate)) {
            setTEndDate(endDate);
        }
    };

    return(
        <div>
            <div className = "clients_content_section">
                <div className="content-section-body">
                    <div className="table_clients">
                        <div className="t_row t_header">
                            <div className="tc_center tc_id">User ID</div>
                            <div className="tc_center tc_email">Email</div>
                            <div className="tc_center tc_checks">Status</div>
                            <div className="tc_center tc_checks">Credits</div>
                            <div className="tc_center tc_date">Last Signin Date</div>
                            <div className="tc_center tc_date">Registration Date</div>
                            <div className="tc_center tc_block">Block Acc</div>
                            <div className="tc_center tc_ip">IP</div>
                        </div>


                        {filterUsers()
                            .map((key, index) => (
                                <div className="t_row" key={key}>
                                    <div className="tc_center tc_id">
                                        <div>{users[key]._id}</div>
                                    </div>
                                    <div className="tc_center tc_email">
                                        <div>{users[key].email}</div>
                                    </div>
                                    <div className="tc_checks">
                                        <div style={{
                                            backgroundColor: users[key].emailVerificationStatus === 'Activated'
                                                ? '#5DD38C'
                                                : '#FFBE21',
                                            width: "10px",
                                            height: "10px",
                                            borderRadius: "10px",
                                            margin: "auto"
                                        }}/>
                                    </div>
                                    <div className="tc_center tc_checks">
                                        <div>{users[key].credits}</div>
                                    </div>
                                    <div className="tc_center tc_date">
                                        <div>{getDateTimeString(users[key].lastSignInDate)}</div>
                                    </div>
                                    <div className="tc_center tc_date">
                                        <div>{getDateTimeString(users[key].createdAt)}</div>
                                    </div>
                                    <div className="tc_click tc_center tc_block" onClick={handleBlockClick}>
                                        <img src={`images/clients/circle-${users[key].blockAccount ? "cancel" : "ok"}.svg`}
                                             alt=""/>
                                    </div>
                                    <div className="tc_center tc_ip">{users[key].registrationIP}</div>
                                </div>
                            ))}
                    </div>
                </div>

            </div>
            <div className="clients_content_section">
                <div className="clients_menu">
                    <h3>Add Checks</h3>
                    <div className="client-add-credits-group">
                        <div>User ID: {getSmallId(chosenId)}</div>
                        <Form.Control value={credits} className="client-add-credits-control"
                                      onChange={handleCreditsChange} placeholder=""/>
                        <Form.Select value={creditsType} className="client-choose-type-control"
                                     onChange={handleCreditsTypeChange}>
                            <option value=""></option>
                            <option value="bonus">Bonus</option>
                            <option value="payment">Payment</option>
4                        </Form.Select>
                        <Button variant="success" className="client-add-credits-control" onClick={handleAddClick}>Add</Button>
                    </div>
                </div>
            </div>
            <UsageContent
                fetchApps={fetchApps}
                fetchUsageDetails={fetchUsageDetails}
            />
            <div className = "clients_content_section">
                <div className="clients_menu">
                    <h3>Payment Details</h3>
                    <DateRangePicker
                        // enableOutsideDays={true}
                        isOutsideRange={isOutsideRange}
                        startDate={tStartDate}
                        startDateId="start_date_id"
                        endDate={tEndDate}
                        endDateId="end_date_id"
                        onDatesChange={handleTransactionDateChange}
                        focusedInput={tFocusedInput}
                        onFocusChange={setTFocusedInput}
                        onClose = {onTransactionClose}
                    />
                </div>
                <div className="line"></div>

                <TablePagination
                    component="div"
                    count={countTransactions()}
                    page={Number(page)}
                    onPageChange={handlePageChange}
                    rowsPerPage={rowsPerPage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
                <div className="content-section-body">
                    <div className="table_clients">
                        <div className="t_row t_header">
                            <div className="tc_center tc_date">Date</div>
                            <div className="tc_center tc_description">Description</div>
                            <div className="tc_center tc_id">Transaction ID</div>
                            <div className="tc_center tc_total">Total Paid</div>
                        </div>
                        {
                            transactions
                                .filter(
                                    (el, i) =>
                                        i < rowsPerPage * (Number(page) + 1) &&
                                        i > (Number(page) * rowsPerPage - 1)
                                )
                                .map((transaction, index) => (
                                    <div className="t_row" key={transaction._id}>
                                        <div className="tc_center tc_date">{
                                            getDateTimeString(transaction.createdAt)
                                        }</div>

                                        <div className="tc_center tc_description">{
                                            `${transaction.initiator}
                                        : ${transaction.reason}
                                        : ${transaction.comment}`
                                        }</div>
                                        <div className="tc_center tc_id">{getSmallId(transaction._id)}</div>
                                        <div className="tc_center tc_total">{transaction.credits}</div>
                                    </div>
                                ))}
                    </div>
                </div>

            </div>
        </div>
    )
};

export default AdminClient;