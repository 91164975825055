import React, {useEffect, useState} from "react";
import SettingsAccordion from "./SettingsAccordion";
import {Button, Form, Spinner} from "react-bootstrap";
import API from "../../../utils/httpAPI";

const exportResults = [
    {alias: "valid", name: "Valid"},
    {
        alias: "invalid",
        name: "Invalid",
        options: [
            {alias: "bad_syntax", name: "Bad syntax", description: "", value: {syntax: false}},
            {alias: "no_mx", name: "No MX", description: "", value: {has_mx_records: false}},
            {alias: "not_deliverable", name: "SMTP not deliverable", description: "", value: {deliverable: false}},
            {alias: "disabled", name: "Disabled", description: "", value: {disabled: true}},
        ]
    },
    {
        alias: "risky",
        name: "Risky",
        options: [
            {alias: "disposable", name: "Disposable", description: "", value: {disposable: true}},
            {alias: "catch_all", name: "Catch-all", description: "", value: {catch_all: true}},
            {alias: "full_inbox", name: "Full inbox", description: "", value: {full_inbox: true}},
            {alias: "role_no_reply", name: "Role no-reply", description: "", value: {role_no_reply: true}},
            {alias: "possible_spam_trap", name: "Possible spam trap", description: "", value: {possible_spam_trap: true}},
        ]
    },
    {alias: "unknown", name: "Unknown"},
];

const columns = [
    {name: "Syntax", alias: "syntax"},
    {name: "Username", alias: "username"},
    {name: "Domain", alias: "domain"},
    {name: "Free", alias: "free"},
    {name: "Role account", alias: "role_account"},
    {name: "Role no-replay", alias: "role_no_reply"},
    {name: "Possible spam trap", alias: "possible_spam_trap"},
    {name: "Disposable", alias: "disposable"},
    {name: "MX has records", alias: "mx_has_records"},
    {name: "MX accepts mail", alias: "mx_accepts_mail"},
    {name: "Can connect", alias: "can_connect"},
    {name: "Full inbox", alias: "full_inbox"},
    {name: "Catch-all", alias: "catch_all"},
    {name: "Deliverable", alias: "deliverable"},
    {name: "Disabled", alias: "disabled"},
];

const Custom = ({file}) => {

    const [selectedGroups, setSelectedGroups] = useState([exportResults[0].alias]);
    const [selectedResults, setSelectedResult] = useState([]);
    const [selectedColumns, setSelectedColumns] = useState([]);
    const [excludeDuplicates, setExcludeDuplicates] = useState(false);
    const [downloadIsInProgress, setDownloadIsInProgress] = useState(false);
    let timerId = null;

    useEffect(() => {
        return () => {
            clearTimeout(timerId);
        };
    }, []);

    const onClickSelectAllResults = (e) => {
        const {checked} = e.target;

        const results = collectResultsOptions();
        const groups = exportResults.map(item => item.alias);

        setSelectedGroups(checked ? groups : []);
        setSelectedResult(checked ? results : []);
    }

    const onColumnSelect = (e) => {
        const {value} = e.target;

        setSelectedColumns(
            selectedColumns.includes(value)
            ? selectedColumns.filter(column => column !== value)
            : [...selectedColumns, value]
        );
    }

    const onClickSelectAllColumns = () => {
        setSelectedColumns(
            selectedColumns.length === columns.length
            ? []
            : columns.map(item => item.alias)
        );
    }

    const collectResultsOptions = () => {
        return exportResults.map(item => {
            if (item.options && item.options.length > 0) {
                return item.options.map(item => item.alias);
            }

            return [];
        }).flat()
    }

    const isActiveResult = (item) => {
        return selectedGroups.includes(item.alias);
    }

    const onResultGroupSelectClick = (e) => {
        const {value} = e.target;

        setSelectedGroups(
            selectedGroups.includes(value)
                ? selectedGroups.filter(alias => alias !== value)
                : [...selectedGroups, value]
        );

        const item = exportResults.find(item => item.alias === value);

        if (item.options) {
            const optionNames = item.options.map(option => option.alias);

            if (optionNames.some(alias => selectedResults.includes(alias))) {
                setSelectedResult(
                    selectedResults.filter(alias => !optionNames.includes(alias))
                );
            } else {
                setSelectedResult([...selectedResults, ...optionNames]);
            }
        }
    }

    const onResultOptionSelectClick = (e, groupAlias) => {
        const {value} = e.target;

        let optionsResult;

        if (selectedResults.includes(value)) {
            // option uncheck

            optionsResult = selectedResults.filter(name => name !== value);

            let hasOption = false;
            exportResults.forEach((group) => {
                if (group.alias === groupAlias) {
                    hasOption = group.options.some((option) => optionsResult.includes(option.alias));
                }
            });

            if (!hasOption) {
                setSelectedGroups(selectedGroups.filter(name => name !== groupAlias));
            }

        } else {
            // option check

            if (!selectedGroups.includes(groupAlias)) {
                setSelectedGroups([...selectedGroups, groupAlias]);
            }
            optionsResult = [...selectedResults, value];
        }

        setSelectedResult(optionsResult);
    }

    const handleCustomDownload = async () => {
        
        setDownloadIsInProgress(true);
        try {
            let exclude = {};
            exportResults.filter((group) => selectedGroups.includes(group.alias)).forEach((group) => {
                group.options && group.options.forEach((option) => {
                    if (!selectedResults.includes(option.alias)) {
                        exclude = {...exclude, ...option.value}
                    }
                });
            });
            const {data} = await API({
                method: "post",
                url: "/export/custom",
                data: {
                    bulkId: file.id,
                    options: {
                        groups: selectedGroups,
                        exclude: exclude
                    },
                    columns: selectedColumns,
                    excludeDuplicates: excludeDuplicates
                }
            });

            const {download, url} = data;

            if (download.status === "created" && url) {
                setDownloadIsInProgress(false);
                return window.open(url, "_blank");
            }

            if (download.status !== "created") {

                const check = async(id) => {
                    const {data} = await API({method: "post", url: "export/check-status", data: {id: id}});

                    const {download, url} = data

                    if (download.status !== "created") {
                        timerId = setTimeout(check, 2000);
                    }

                    if (download.status === "created" && url) {
                        setDownloadIsInProgress(false);
                        return window.open(url, "_blank");
                    }
                }

                timerId = setTimeout(() => { check(download.id) }, 2000);
            }

        } catch (error) {
            setDownloadIsInProgress(false);
            // TODO: Add error handler
        }
    }

    const downloadDisabled = selectedGroups.length === 0;

    return (
        <>
            <div className="custom-options-content">
                <div className="custom-export-section">

                    <div className="custom-options-box mb-3">
                        <h2 className="custom-options-title">Results to export</h2>
                        <div className="all-columns">
                            <div className="theme-checkbox select-all">
                                <input
                                    id={"selectAllResults"}
                                    type="checkbox"
                                    checked={exportResults.length === selectedGroups.length &&
                                        collectResultsOptions().length === selectedResults.length}
                                    onChange={onClickSelectAllResults}
                                />
                                <label htmlFor={"selectAllResults"}>All results</label>
                            </div>
                        </div>
                        {exportResults.map(result => {
                            return <SettingsAccordion
                                key={result.name}
                                alias={result.alias}
                                name={result.name}
                                options={result.options}
                                isActive={isActiveResult(result)}
                                selectedResults={selectedResults}
                                onResultOptionSelectClick={onResultOptionSelectClick}
                                onResultGroupSelectClick={onResultGroupSelectClick}
                            />
                        })}
                    </div>

                    <div className="custom-options-box mb-3">
                        <h2 className="custom-options-title">Columns to include <span className='cf-optional-text'>(optional)</span></h2>
                        <div className="custom-export-columns-list">
                            <div className="all-columns">
                                <div className="theme-checkbox select-all">
                                    <input
                                        id={"selectAllColumns"}
                                        type="checkbox"
                                        onChange={onClickSelectAllColumns}
                                        checked={selectedColumns.length === columns.length}
                                    />
                                    <label htmlFor={"selectAllColumns"}>All columns</label>
                                </div>
                            </div>
                            <div className="columns-list">
                                {columns.map(column => {
                                    return (
                                        <div key={column.alias} className="theme-checkbox">
                                            <input id={`select-${column.alias}`} type="checkbox" value={column.alias}
                                                   onChange={onColumnSelect}
                                                   checked={selectedColumns.includes(column.alias)}/>
                                            <label htmlFor={`select-${column.alias}`}>{column.name}</label>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </div>

                    <div className="custom-options-box">
                        <div style={{display: 'flex', justifyContent: 'center'}}>
                            <Form>
                                <Form.Switch
                                    className="custom-options-excludeDuplicates"
                                    type="switch"
                                    id="custom-switch"
                                    label="Exclude duplicates"
                                    checked={excludeDuplicates}
                                    onChange={() => {
                                        setExcludeDuplicates(!excludeDuplicates);
                                    }}
                                />
                            </Form>
                        </div>
                    </div>

                </div>
            </div>
            <div className="download-modal-footer">
                <Button variant="success" disabled={downloadDisabled || downloadIsInProgress}
                        onClick={handleCustomDownload}>
                    {downloadIsInProgress
                        ? <>
                            Please wait
                            <Spinner
                                className="ms-3 me-3"
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                            />
                        </>
                        : "Download"}
                </Button>
            </div>
        </>
    );
};

export default Custom;
