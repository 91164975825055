import React from 'react';
import { useSelector } from "react-redux";
import { TablePagination } from "@material-ui/core";
import { Link } from "react-router-dom";

import { getDateTimeString } from "../../utils/helpers";
import EventDetails from './EventDetails';

const UsageDetails = ({ events, count, page, pageSize, onPageChange, onPageSizeChange }) => {
    return (
        <div className="au-details">
            <div className="payment_history_details_table">
                <div className="content-section-body">
                    <div className="table_clients">
                        <div className="t_row t_header">
                            <div className="tc_center tc_date">Time</div>
                            <div className="tc_center tc_total">Credits</div>
                            <div className="tc_center tc_description">Usage Type</div>
                            <div className="tc_center tc_status">Event Details</div>
                            <div className="tc_center tc_invoice">Source</div>
                        </div>

                        {events.length ? (
                            events.map((e, i) => (
                                <div className="t_row" key={i}>
                                    <div className="tc_center tc_date">{getDateTimeString(e.time)}</div>
                                    <Credits event={e}/>
                                    <div className="tc_center tc_description">{e.appId ? 'API' : 'Website'}</div>
                                    <div className="tc_left tc_description">
                                      <EventDetails event={e}/>
                                    </div>
                                    <div className="tc_center tc_invoice">
                                        <Source event={e}/>
                                    </div>
                                </div>
                            ))
                        ) : (<div className="t_row">
                            <div className="tc_center tc_no_content">
                                No events found for this period                        </div>
                        </div>)}
                    </div>
                </div>
                {events.length ? (
                    <TablePagination
                        component="div"
                        count={count}
                        page={page}
                        onPageChange={onPageChange}
                        rowsPerPage={pageSize}
                        onRowsPerPageChange={onPageSizeChange}
                    />
                ) : (<></>)}
            </div>
        </div>
    );
};

const Credits = ({ event }) => {
    const classNames = ["tc_center", "tc_total"];

    if (event.creditsUsed > 0) {
        classNames.push("text-danger");
    } else {
        classNames.push("text-success");
    }

    return (
        <div className={classNames.join(' ')}>
            {-event.creditsUsed}
        </div>
    );
}

const Source = ({ event }) => {
    const { apps } = useSelector((state) => state.apps);

    const { appId } = event;
    if (!appId) { 
        return <p>You</p>;
    }

    const app = apps[appId];
    if (!app) {
        return <p>{appId}</p>;
    }

    return (
        <Link to={`appUsage/${appId}`}>
            {app.data.appName}
        </Link>
    );
};

export default UsageDetails;
