import React, {useEffect, useState} from "react";
import {Button} from "react-bootstrap";

const AdminWidget = () => {

    const [scriptLoaded, setScriptLoaded] = useState(false);

    useEffect(() => {
        const params = document.createElement('script');
        params.setAttribute('type','text/javascript');
        const inlineCode = document.createTextNode(`_ProofySettings = {
        apiKey: 'EdAbzv8YbultWsBuFu7ziCWgbwysmk50LQOYmOnkFsQw6fOnF1OEJPfkMeTuNXZL'
      };`);
        params.appendChild(inlineCode);
        const widget = document.createElement('script');
        widget.setAttribute('src','https://cdn.proofy.io/widget/Proofy.js');
        widget.setAttribute('type','text/javascript');
        widget.onload = () => {
            setScriptLoaded(true);
        }
        document.body.appendChild(params);
        document.body.appendChild(widget);

        return () => {
            document.body.removeChild(params);
            document.body.removeChild(widget);
        }
    }, []);

    return (
        <div style={{margin: 20}}>
            {scriptLoaded
                ? <div style={{ display: 'flex', alignItems: 'end', flexDirection: 'column', width: 400, margin: 15 }}>
                    <input className="widget-input" placeholder="example@gmail.com" data-proofy-check />
                    <Button style={{ width: 100, marginTop: 10 }} variant="success" className="btn-add" onClick={() => {}}>Submit</Button>
                </div>
                : <div>Script loading..</div>
            }
        </div>
    )
}

export default AdminWidget;
