export * from '../common-action-types';

export const CHOOSE_CLIENT = "CHOOSE_CLIENT";
export const CHOOSE_ADMIN_CLEAN = "CHOOSE_ADMIN_CLEAN";
export const CHOOSE_ADMIN_WIDGET = "CHOOSE_ADMIN_WIDGET";
export const CHOOSE_ADMIN_STRIPE = "CHOOSE_ADMIN_STRIPE";
export const CHOOSE_EMAIL_PREFERENCES = "CHOOSE_EMAIL_PREFERENCES";

export const SHOW_CLIENT_BLOCK_MODAL = "SHOW_CLIENT_BLOCK_MODAL";
export const HIDE_CLIENT_BLOCK_MODAL = "HIDE_CLIENT_BLOCK_MODAL";

export const ADD_USER_CREDITS_REQUEST = "ADD_USER_CREDITS_REQUEST";
export const ADD_USER_CREDITS_SUCCESS = "ADD_USER_CREDITS_SUCCESS";
export const ADD_USER_CREDITS_FAILURE = "ADD_USER_CREDITS_FAILURE";

export const CHANGE_BLOCKED_STATUS_REQUEST = "CHANGE_BLOCKED_STATUS_REQUEST";
export const CHANGE_BLOCKED_STATUS_FAILURE = "CHANGE_BLOCKED_STATUS_FAILURE";

export const GET_STRIPE_PRODUCTS_REQUEST = "GET_STRIPE_PRODUCTS_REQUEST";
export const GET_STRIPE_PRODUCTS_SUCCESS = "GET_STRIPE_PRODUCTS_SUCCESS";
export const GET_STRIPE_PRODUCTS_FAILURE = "GET_STRIPE_PRODUCTS_FAILURE";

export const GET_STRIPE_TAGS_REQUEST = "GET_STRIPE_TAGS_REQUEST";
export const GET_STRIPE_TAGS_SUCCESS = "GET_STRIPE_TAGS_SUCCESS";
export const GET_STRIPE_TAGS_FAILURE = "GET_STRIPE_TAGS_FAILURE";

export const ADD_STRIPE_PRODUCT_REQUEST = "ADD_STRIPE_PRODUCT_REQUEST";
export const ADD_STRIPE_PRODUCT_SUCCESS = "ADD_STRIPE_PRODUCT_SUCCESS";
export const ADD_STRIPE_PRODUCT_FAILURE = "ADD_STRIPE_PRODUCT_FAILURE";

export const REMOVE_STRIPE_PRODUCT_REQUEST = "REMOVE_STRIPE_PRODUCT_REQUEST";
export const REMOVE_STRIPE_PRODUCT_SUCCESS = "REMOVE_STRIPE_PRODUCT_SUCCESS";
export const REMOVE_STRIPE_PRODUCT_FAILURE = "REMOVE_STRIPE_PRODUCT_FAILURE";

export const SHOW_STRIPE_PRODUCT_ADD_MODAL = "SHOW_STRIPE_PRODUCT_ADD_MODAL";
export const HIDE_STRIPE_PRODUCT_ADD_MODAL = "HIDE_STRIPE_PRODUCT_ADD_MODAL";

export const SHOW_STRIPE_PRODUCT_DELETE_MODAL = "SHOW_STRIPE_PRODUCT_DELETE_MODAL";
export const HIDE_STRIPE_PRODUCT_DELETE_MODAL = "HIDE_STRIPE_PRODUCT_DELETE_MODAL";
