import React from "react";
import {Modal, Button} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { hideDeleteModal, startFileDelete } from "../../services/action-creators";


const CleanDeleteModal = () => {
    const dispatch = useDispatch();
    const { selectedId, selectedName, showDeleteModal } = useSelector((state) => state.file);
    const handleClose = () => dispatch(hideDeleteModal());
    const handleDelete = () => {
        if (selectedId) {
            return dispatch(startFileDelete(selectedId));
        }
    };

    return (
        <Modal centered
               show={showDeleteModal}
               onHide={handleClose}
               className='modal-clean-delete'>
            <button onClick={handleClose} type="button" className="btn-close btn-close-absolute" aria-label="Close"/>
            <div className="delete-file-modal">
                    <div className="dfm-header">
                        Delete File
                    </div>
                    <div className="dfm-warning">
                        This action cannot be undone
                    </div>
                    <div className="dfm-text">
                        Delete file from our servers. Please confirm the deletion of <strong>{selectedName}</strong> ({selectedId})
                    </div>
                <div className="dfm-footer">
                    <Button variant="outline-secondary" onClick = {handleClose}>Cancel</Button>
                    <Button variant="danger" onClick = {handleDelete}>Delete</Button>
                </div>
            </div>
        </Modal>
    );

};

export default CleanDeleteModal;
