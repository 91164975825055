import React from 'react'
import PageMenu from "./paymentHistory/PageMenu"

import PaymentHistoryContent from './paymentHistory/PaymentHistoryContent'

const PaymentHistory = () => {
    return (
        <div className="page">
            <PageMenu/>
            {/*<PageAlert/>*/}
            <PaymentHistoryContent/>
        </div>
    )
};

export default PaymentHistory
