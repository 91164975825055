import API from "../../utils/httpAPI";

import {
    SET_APP_USAGE_DETAILS_START_DATE,
    SET_APP_USAGE_DETAILS_END_DATE,
    GET_APP_USAGE_DETAILS_REQUEST,
    GET_APP_USAGE_DETAILS_SUCCESS,
    GET_APP_USAGE_DETAILS_FAILURE,
    SHOW_APP_DELETE_MODAL,
    HIDE_APP_DELETE_MODAL,
    SHOW_APP_REFRESH_API_KEY_MODAL,
    HIDE_APP_REFRESH_API_KEY_MODAL,
    APP_DELETE_REQUEST,
    APP_DELETE_SUCCESS,
    APP_DELETE_FAILURE,
    APP_REFRESH_API_KEY_REQUEST,
    APP_REFRESH_API_KEY_SUCCESS,
    APP_REFRESH_API_KEY_FAILURE
} from "./action-types";
import {loadApps} from "../apps/action-creators";

export const setAppUsageDetailsStartDate = (date) => {
    return {
        type: SET_APP_USAGE_DETAILS_START_DATE,
        payload: { date }
    };
};

export const setAppUsageDetailsEndDate = (date) => {
    return {
        type: SET_APP_USAGE_DETAILS_END_DATE,
        payload: { date }
    };
};

export const getAppUsageDetailsRequest = () => {
    return {
        type: GET_APP_USAGE_DETAILS_REQUEST,
        // payload: { date }
    };
};

export const getAppUsageDetailsSuccess = ({ chart, details }) => {
    return {
        type: GET_APP_USAGE_DETAILS_SUCCESS,
        payload: { chart, details }
    };
};

export const getAppUsageDetailsFailure = () => {
    return {
        type: GET_APP_USAGE_DETAILS_FAILURE,
        // payload: { date }
    };
};


export const getAppUsageDetails = ({ appId, from, to, page, pageSize, hiddenBuckets }) => {
        return function (dispatch) {
            dispatch(getAppUsageDetailsRequest());
            API({
                get: "post",
                url: `/app/${appId}/usage`,
                params: { from, to, page, pageSize, hiddenBuckets },
            })
                .then((response) => {
                    const { chart, details } = response.data;
                    dispatch(getAppUsageDetailsSuccess({ chart, details }));
                })
                .catch((error) => {
                    // dispatch(getAppUsageDetailsFailure(error));
                });
        };
    };


export const showAppDeleteModal = (id, appName) => {
    return {
        type: SHOW_APP_DELETE_MODAL,
        payload: {id, appName},
    };
};
export const hideAppDeleteModal = () => {
    return {
        type: HIDE_APP_DELETE_MODAL
    };
};


export const showRefreshApiKeyModal = (id) => {
    return {
        type: SHOW_APP_REFRESH_API_KEY_MODAL,
        payload: {id},
    };
};
export const hideRefreshApiKeyModal = () => {
    return {
        type: HIDE_APP_REFRESH_API_KEY_MODAL
    };
};


export const startAppDelete = (id, history) => {
    return function (dispatch) {
        dispatch(appDeleteRequest());
        dispatch(hideAppDeleteModal());
        API({
            method: "post",
            url: "/app/delete",
            data: { id }
        })
            .then((response) => {
                dispatch(appDeleteSuccess(id));
                history.push("/apps");
                dispatch(loadApps());
            })
            .catch((error) => {
                dispatch(appDeleteFailure(error));
            });
    }
};


export const startAppRefreshApiKey = (id, history) => {
    return function (dispatch) {
        dispatch(appRefreshApiKeyRequest());
        dispatch(hideRefreshApiKeyModal());
        API({
            method: "post",
            url: "/app/refreshApiKey",
            data: { id }
        })
            .then((response) => {
                const { app } = response.data;
                dispatch(appRefreshApiKeySuccess(app));
            })
            .catch((error) => {
                dispatch(appRefreshApiKeyFailure(error));
            });
    }
};


const appDeleteRequest = () => {
    return {
        type: APP_DELETE_REQUEST
    };
};
const appDeleteSuccess = (id) => {
    return {
        type: APP_DELETE_SUCCESS,
        payload: { id }
    };
};
const appDeleteFailure = (error) => {
    return {
        type: APP_DELETE_FAILURE,
        payload: error
    };
};


const appRefreshApiKeyRequest = () => {
    return {
        type: APP_REFRESH_API_KEY_REQUEST
    };
};
const appRefreshApiKeySuccess = (app) => {
    return {
        type: APP_REFRESH_API_KEY_SUCCESS,
        payload: { app }
    };
};
const appRefreshApiKeyFailure = (error) => {
    return {
        type: APP_REFRESH_API_KEY_FAILURE,
        payload: error
    };
};
