import React from "react";
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import moment from "moment";

const AppUsageChart = ({ data, config, hiddenBuckets }) => {
    if (!data || data.length < 2) return (<></>);

    const diffDays = moment.utc(data[1].time).diff(moment.utc(data[0].time), 'days');
    const diffMonths = moment.utc(data[1].time).diff(moment.utc(data[0].time), 'months');

    const tickFormatter = time => {
        let format = 'MMM YYYY';
        if (diffDays < 1) {
            format = 'h a';
        } else if (diffMonths < 1) {
            format = 'MMM D';
        }
        return moment.utc(time).format(format);
    };

    const labelFormatter = time => {
        let format = 'MMM, YYYY';
        if (diffDays < 1) {
            format = 'MMM D, YYYY h:mm:ss a'
        } else if (diffMonths < 30) {
            format = 'MMM D YYYY'
        }
        return moment.utc(time).format(format);
    };

    return (
        <div className="au-chart">
        <ResponsiveContainer width="100%" height="100%">
            <LineChart
                data={data}
                margin={{
                    top: 15,
                    right: 10,
                    left: 10,
                    bottom: 5,
                }}>
                <CartesianGrid vertical={false} />
                <XAxis dataKey="time" tickFormatter={tickFormatter} />
                <YAxis />
                <Tooltip labelFormatter={labelFormatter} />
                {Object.keys(config)
                    .map((key, index) => {
                        return (<Line
                            key={key}
                            name={config[key].header}
                            hide={hiddenBuckets.includes(key)}
                            dataKey={key}
                            stroke={config[key].color}
                            strokeWidth={3}
                            activeDot={{ r: 8 }}
                            type="monotone" />
                        )
                    })
                }
            </LineChart>
        </ResponsiveContainer>
        </div>
    );
};

export default AppUsageChart;
