import React, {useState} from 'react';
import {Button, Form } from 'react-bootstrap';

const AccountTeamSettings = () => {
    const [companyIndustry, setCompanyIndustry] = useState("");
    const [companyName, setCompanyName] = useState("");
    const handleCompanyNameChange = (event) => {
        setCompanyName(event.target.value);
    };
    const handleCompanyIndustryChange = (event) => {
        setCompanyIndustry(event.target.value);
    };
    const handleSaveClick = (event) => {
        event.preventDefault();
        // dispatch(signUp({ name, email, password, checkTermsOfUse }, history));
    };
    return (
        <>
        <div className="account_content_section">
            <h1>Team Settings</h1>
            <div className="line"></div>
            <div className="form">
                <Form>
                    <Form.Group className="mb-3" controlId="formBasicPassword">
                        <Form.Label>Company Name</Form.Label>
                        <Form.Control value={companyName} onChange={handleCompanyNameChange} type="companyName" placeholder="" />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formBasicPassword">
                        <Form.Label>Industry (Optional)</Form.Label>
                        <Form.Control value={companyIndustry} onChange={handleCompanyIndustryChange} type="companyIndustry" placeholder="" />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formBasicPassword">
                        <Form.Check type="checkbox" label="Receive Two-Factor Authentication for all team mwmbers" />
                    </Form.Group>
                    <Button onClick={handleSaveClick} variant="success" type="submit">
                        Save
                    </Button>
                </Form>
            </div>
        </div>
        <div className="account_content_section">
            <h1>Manage Team</h1>
            <Form>
                <Form.Group className="mb-3" controlId="formBasicPassword">
                    <Form.Label>Company Name</Form.Label>
                    <Form.Control value={companyName} onChange={handleCompanyNameChange} type="companyName" placeholder="All roles" />
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicPassword">
                    <Form.Control value={companyIndustry} onChange={handleCompanyIndustryChange} type="companyIndustry" placeholder="Search by email" />
                </Form.Group>
                <Button onClick={handleSaveClick} variant="success" type="submit">
                    Send An Invite
                </Button>
            </Form>
            <div className="line"></div>
            {/*<div className="form">*/}
                {/*<Form>*/}
                    {/*<Form.Group className="mb-3" controlId="formBasicPassword">*/}
                        {/*<Form.Label>Company Name</Form.Label>*/}
                        {/*<Form.Control value={companyName} onChange={handleCompanyNameChange} type="companyName" placeholder="" />*/}
                    {/*</Form.Group>*/}
                    {/*<Form.Group className="mb-3" controlId="formBasicPassword">*/}
                        {/*<Form.Label>Industry (Optional)</Form.Label>*/}
                        {/*<Form.Control value={companyIndustry} onChange={handleCompanyIndustryChange} type="companyIndustry" placeholder="" />*/}
                    {/*</Form.Group>*/}
                    {/*<Form.Group className="mb-3" controlId="formBasicPassword">*/}
                        {/*<Form.Check type="checkbox" label="Receive Two-Factor Authentication for all team mwmbers" />*/}
                    {/*</Form.Group>*/}
                    {/*<Button onClick={handleSaveClick} variant="success" type="submit">*/}
                        {/*Save*/}
                    {/*</Button>*/}
                {/*</Form>*/}
            {/*</div>*/}
        </div>
        </>
    )
};

export default AccountTeamSettings;