import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SignUpForm from './SignUp/SignUpForm';
import SignUpSuccess from './SignUp/SignUpSuccess';
import { resetSuccessSignUpStatus } from '../../services/action-creators';

const Signup = () => {
    const dispatch = useDispatch();
    const { successSignUp } = useSelector((state) => state.authentication);

    const handleSignUpLeave = () => dispatch(resetSuccessSignUpStatus());

    useEffect(
        () => () => {
            handleSignUpLeave();
        },
        []
    );

    return successSignUp
        ? <SignUpSuccess />
        : <SignUpForm />;
};

export default Signup;
