import {
    CHOOSE_APPS,
    CHOOSE_ADMIN_PANEL,
    CHOOSE_VERIFY,
    CHOOSE_CLEAN,
    CHOOSE_SYNC,
    CHOOSE_ACCOUNT,
    CHOOSE_PAYMENT,
    SHOW_MODAL,
    HIDE_MODAL,
    SIGN_IN_SUCCESS,
    SIGN_UP_SUCCESS,
    SIGN_OUT_SUCCESS,
    CHOOSE_APP_USAGE,
    CHOOSE_RESET_PASSWORD,
    SYNC_SUCCESS,
    HIDE_ALERT,
    SHOW_ALERT,
    APP_DELETE_SUCCESS
} from "./action-types";

const navigationReducer = function (state, action) {
  switch (action.type) {
      case CHOOSE_ADMIN_PANEL:
      case CHOOSE_PAYMENT:
      case CHOOSE_APPS:
      case CHOOSE_VERIFY:
      case CHOOSE_CLEAN:
      case CHOOSE_SYNC:
      case CHOOSE_ACCOUNT:
      case CHOOSE_RESET_PASSWORD:
        return {
            ...state,
            activeTab: action.payload
        };
      case APP_DELETE_SUCCESS:
          return {
              ...state,
              activeTab: 'apps'
          };
      case CHOOSE_APP_USAGE:
        return {
            ...state,
            activeTab: action.payload.activeTab
        };
      case SHOW_MODAL:
          return {
              ...state,
              showModal: true
          };
      case HIDE_MODAL:
          return {
              ...state,
              showModal: false
          };
      case SIGN_IN_SUCCESS:
      case SIGN_UP_SUCCESS:
      case SIGN_OUT_SUCCESS:
          return {
              ...state,
              activeTab: 'bulk',
              showModal: false
          };
      case SYNC_SUCCESS:
          action.payload.alerts.forEach(alert => state.pageAlerts.push(alert));
          return {
              ...state
          };
      case SHOW_ALERT:
          state.pageAlerts.push(action.payload.alert);
          return {
              ...state
          };
      case HIDE_ALERT:
          state.pageAlerts = state.pageAlerts.filter(({key}) => key !== action.payload);
          return {
              ...state
          };
    default:
      return { ...state };
  }
};

export default navigationReducer;
