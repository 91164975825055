import React from 'react';
import { Link } from "react-router-dom";

const EventDetails = ({ event }) => {
  switch (event.type) {
    case 'verification':
      return <VerificationDetails event={event} />;
    case 'job.create':
    case 'job.fail':
    case 'job.start':
    case 'job.cancel':
    case 'job.complete':
      return <JobDetails event={event} />;
  }
  return <></>;
};

const VerificationDetails = ({ event }) => {
  let result = '';
  if (event.valid === 1) {
    result = 'valid';
  } else if (event.risky === 1) {
    result = 'risky';
  } else if (event.invalid === 1) {
    result = 'invalid';
  } else if (event.unknown === 1) {
    result = 'unknown';
  }

  return (
    <div>
      <b>Single Verification</b> <i>{event.email}</i>
      {result && 
        <div className={`verify-result ${result}`}>
          {result}
        </div>
      }
    </div>
  );
};

const jobEventDetailsTypes = {
  'job.create_file': 'File Create',
  'job.fail_file': 'File Fail',
  'job.start_file': 'File Start',
  'job.cancel_file': 'File Cancel',
  'job.complete_file': 'File Complete',

  'job.create_batch': 'Batch Create',
  'job.fail_batch': 'Batch Fail',
  'job.start_batch': 'Batch Start',
  'job.cancel_batch': 'Batch Cancel',
  'job.complete_batch': 'Batch Complete'
};

const JobDetails = ({ event }) => {
  const jobType = event.jobType === 'batch' ? 'batch' : 'file';
  const title = jobEventDetailsTypes[`${event.type}_${jobType}`];
  return (
    <div>
      <b>{title}</b>
      <br/>
      <Link to={`/bulk?search=${event.jobId}`}>
        {event.jobId}
      </Link>
    </div>
  );
};

export default EventDetails;