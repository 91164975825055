import React, {useEffect, useRef, useState} from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { Navbar, Container, Nav, NavDropdown, Card, Button } from "react-bootstrap";
import Gravatar from 'react-gravatar';

import {
    chooseApps, chooseClean, chooseSync, chooseVerify, signOut,
    chooseAccount, choosePayment,
    chooseUsage, choosePaymentHistory,
    chooseAdmin,
} from "../services/action-creators";
import {HelpIcon, PaymentIcon, SettingsIcon, SignOutIcon, UsageIcon} from "./icons";
import {show as showIntercom} from "@intercom/messenger-js-sdk";
import {MOBILE_VIEW_WIDTH} from "../config/mobile";
import {useMediaQuery} from "../utils/hooks/useMediaQuery";

const NavBar = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { currentUser } = useSelector((state) => state.authentication);
    const { activeTab } = useSelector((state) => state.navigation);

    const handleSignOut = () => dispatch(signOut(history));
    const handleApps = () => dispatch(chooseApps(history));
    const handleAdmin = () => {
        if (history.location.pathname !== "/adminpanel") {
            dispatch(chooseAdmin(history));
        }
    }
    const handleClean = () => dispatch(chooseClean(history));
    const handleVerify = () => dispatch(chooseVerify(history));
    const handleBuyCredits = () => dispatch(choosePayment(history));
    const handleAccount = () => dispatch(chooseAccount(history));
    const handleUsage = () => dispatch(chooseUsage(history));
    const handlePaymentHistory = () => dispatch(choosePaymentHistory(history));
    const handleEditProfile = () => dispatch(chooseAccount(history));

    const handleHelpClick = () => {
        showIntercom();
    }

    const showNavItems = useMediaQuery(`(min-width: 1200px)`);
    const showCredits = useMediaQuery(`(min-width: ${MOBILE_VIEW_WIDTH}px)`);

    /* Because of main container structure we need to render nav bar always */
    if (currentUser == null) {
        return (<div/>);
    }

    return (
        <Navbar className="main_navbar">

            <Container id="nav-brand">
                <Nav.Link onClick={handleClean}>
                    <div className="nav-bar-logo">
                        <img src="/images/new-logo.svg" alt="logo" />
                    </div>
                </Nav.Link>
                {showCredits &&
                    <>
                        <div className="account-credits d-none d-md-block">
                            <img src="/images/usd_sign.svg" alt="sign"/>
                            <span className="credits-text">Credit Balance: {currentUser.credits}</span>
                        </div>
                        <Button className="btn-buy-credits d-none d-lg-block" onClick={handleBuyCredits} variant="outline-success">Buy Credits</Button>
                    </>
                }
            </Container>

            <Container id="navbar-right">
                {showNavItems &&
                    <Nav className="d-none d-xl-flex">
                        <Nav.Link onClick={handleClean}>
                            <div className={activeTab === "bulk" ? "navmenu active" : "navmenu"}>Bulk</div>
                        </Nav.Link>
                        <Nav.Link onClick={handleVerify}>
                            <div className={activeTab === "single" ? "navmenu active" : "navmenu"}>Single</div>
                        </Nav.Link>
                        <Nav.Link onClick={handleApps}>
                            <div className={activeTab === "apps" ? "navmenu active" : "navmenu"}>API</div>
                        </Nav.Link>
                    </Nav>
                }

                <NavDropdown
                    title={<Gravatar default='mp' email={currentUser.email} className="rounded-circle" size={40}/>}
                    id="navbarScrollingDropdown"
                    align="end"
                >
                    <Card className="account-popup-card">
                        <Card.Body>
                            <Card.Title>{currentUser.firstName}</Card.Title>
                            <Card.Title>{currentUser.lastName}</Card.Title>
                            <div className="account-email">{currentUser.email}</div>
                                <div className="edit-account">
                                    <NavDropdown.Item className="edit-account-item">
                                        <Button variant="success" onClick={handleEditProfile}>
                                            Edit Profile
                                        </Button>
                                    </NavDropdown.Item>
                                </div>
                        </Card.Body>

                        <Gravatar default='mp' email={currentUser.email} className="rounded-circle account-popup-gravatar" size={64}/>
                    </Card>
                    {!showNavItems &&
                        <>
                            {!showCredits &&
                                <>
                                    <div className="account-credits">
                                        <img src="images/usd_sign.svg" alt="sign"/>
                                        <span className="credits-text">Credit Balance: {currentUser.credits}</span>
                                    </div>
                                    <NavDropdown.Item className="edit-account-item">
                                        <Button className="btn-buy-credits" onClick={handleBuyCredits} variant="outline-success">
                                            Buy Credits
                                        </Button>
                                    </NavDropdown.Item>
                                </>
                            }
                            <NavDropdown.Divider className=""/>
                            <div className="account-controls">
                                <NavDropdown.Item
                                    className={activeTab === "clean" ? "account-popup-li active" : "account-popup-li"}
                                    onClick={handleClean}>
                                    <div className="image-placeholder">
                                        <UsageIcon height={20} width={20} highlighted={activeTab === "bulk"} />
                                    </div>
                                    Bulk
                                </NavDropdown.Item>
                                <NavDropdown.Item
                                    className={activeTab === "verify" ? "account-popup-li active" : "account-popup-li"}
                                    onClick={handleVerify}>
                                    <div className="image-placeholder">
                                        <UsageIcon height={20} width={20} highlighted={activeTab === "single"} />
                                    </div>
                                    Single
                                </NavDropdown.Item>
                                <NavDropdown.Item
                                    className={activeTab === "apps" ? "account-popup-li active" : "account-popup-li"}
                                    onClick={handleApps}>
                                    <div className="image-placeholder">
                                        <UsageIcon height={20} width={20} highlighted={activeTab === "apps"} />
                                    </div>
                                    API
                                </NavDropdown.Item>
                            </div>
                        </>
                    }
                    <NavDropdown.Divider/>
                    <div className="account-controls">
                        <NavDropdown.Item
                            className={activeTab === "usage" ? "account-popup-li active" : "account-popup-li"}
                            onClick={handleUsage}>
                            <div className="image-placeholder">
                                <UsageIcon height={20} width={20} highlighted={activeTab === "usage"} />
                            </div>Usage
                        </NavDropdown.Item>
                        <NavDropdown.Item className={activeTab === "paymentHistory" ? "account-popup-li active" : "account-popup-li"} onClick={handlePaymentHistory}>
                            <div className="image-placeholder">
                                <PaymentIcon highlighted={activeTab === "paymentHistory"} />
                            </div>Payment History
                        </NavDropdown.Item>
                        <NavDropdown.Item className={activeTab === "account" ? "account-popup-li active" : "account-popup-li"} onClick={handleAccount}>
                            <div className="image-placeholder">
                                <SettingsIcon highlighted={activeTab === "account"} />
                            </div>Settings
                        </NavDropdown.Item>
                        <NavDropdown.Item className="account-popup-li" onClick={handleHelpClick}>
                            <div className="image-placeholder">
                                <HelpIcon />
                            </div>Help
                        </NavDropdown.Item>
                    </div>
                    <NavDropdown.Divider />
                    <div className="account-controls">
                        {currentUser.role === "sa" &&
                            (<NavDropdown.Item className={activeTab === "adminpanel" ? "account-popup-li active" : "account-popup-li"} onClick={handleAdmin}>
                                <div className="image-placeholder">
                                    <SettingsIcon highlighted={activeTab === "adminpanel"} />
                                </div>Admin
                            </NavDropdown.Item>)
                        }

                        <NavDropdown.Item className="account-popup-li" onClick={handleSignOut}>
                            <div className="image-placeholder">
                                <SignOutIcon />
                            </div>Sign Out
                        </NavDropdown.Item>
                    </div>
                </NavDropdown>
            </Container>

        </Navbar>
    );
};

export default NavBar;
