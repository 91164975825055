import React from "react";
import { Navbar, Nav } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

import {
    chooseAdminClients,
    chooseAdminClean,
    chooseAdminWidget,
    chooseAdminStripe,
} from "../../services/action-creators";

const NavBar = () => {
    const dispatch = useDispatch();
    const { activeTab } = useSelector((state) => state.admin);

    const handleClients = () => {
        dispatch(chooseAdminClients());
    };
    const handleClean = () => {
        dispatch(chooseAdminClean());
    };
    const handleWidget = () => {
        dispatch(chooseAdminWidget());
    }
    const handleStripe = () => {
        dispatch(chooseAdminStripe());
    }

    return (
        <Navbar>
            <Nav.Link onClick={handleClients}>
                <div className={activeTab === "clients" ? "navmenu active" : "navmenu"}>Clients</div>
            </Nav.Link>
            {/*<Nav.Link onClick={handleClean}>*/}
            {/*    <div className={activeTab === "clean" ? "navmenu active" : "navmenu"}>Clean Help</div>*/}
            {/*</Nav.Link>*/}
            <Nav.Link onClick={handleWidget}>
                <div className={activeTab === "widget" ? "navmenu active" : "navmenu"}>Widget</div>
            </Nav.Link>
            <Nav.Link onClick={handleStripe}>
                <div className={activeTab === "stripe" ? "navmenu active" : "navmenu"}>Stripe</div>
            </Nav.Link>

            {/*<Nav.Link onClick={handleEmailPreferences}>*/}
                {/*<div className={activeTab === "emailPreferences" ? "navmenu active" : "navmenu"}>Email Preferences</div>*/}
            {/*</Nav.Link>*/}

        </Navbar>
    );
};

export default NavBar;
