import React from 'react';

// import PaymentHistoryChartSection from "./PaymentHistoryChartSection";
import PaymentHistoryDetailsSection from "./PaymentHistoryDetailsSection";
// import MySpending from "./MySpending";

const PaymentHistoryContent = () => {
    return (
        <div>
            {/*<MySpending/>*/}
            {/*<PaymentHistoryChartSection/>*/}
            <PaymentHistoryDetailsSection/>
        </div>
    );
};

export default PaymentHistoryContent;
