import API from "../../utils/httpAPI";
import { updateUserCredits } from "../action-creators";

import {
    EMAILS_UPLOAD_REQUEST,
    EMAILS_UPLOAD_SUCCESS,
    EMAILS_UPLOAD_FAILURE,

    GET_EMAILS_REQUEST,
    GET_EMAILS_FAILURE,
    GET_EMAILS_SUCCESS,
    // UPDATE_PENDING_EMAILS_SUCCESS,
} from "./action-types";

const emailUploadRequest = (emails) => {
    return {
        type: EMAILS_UPLOAD_REQUEST,
        payload: { emails }
    };
};

const emailUploadSuccess = ({ emails, userCredits }) => {
    return {
        type: EMAILS_UPLOAD_SUCCESS,
        payload: { emails, userCredits }
    };
};
const emailUploadFailure = (error) => {
    return {
        type: EMAILS_UPLOAD_FAILURE,
        payload: error,
    };
};

export const emailsUpload = (emails) => {
    return function (dispatch) {
        dispatch(emailUploadRequest(emails));
        API({
            method: "post",
            url: "/email",
            data: { emails },
        })
            .then((response) => {
                const {emails, userCredits} = response.data;
                dispatch(emailUploadSuccess({emails, userCredits}));
            })
            .catch((error) => {
                if (error.response) {
                    dispatch(emailUploadFailure(error.response.data.error));    
                } else {
                    dispatch(emailUploadFailure(error));
                }
            });
    };
};

const getEmailsRequest = (noLoading) => {
    return {
        type: GET_EMAILS_REQUEST,
        payload: { noLoading }
    };
};

const getEmailsSuccess = (emails, total) => {
    return {
        type: GET_EMAILS_SUCCESS,
        payload: { emails, total },
    };
};
const getEmailsFailure = (error) => {
    return {
        type: GET_EMAILS_FAILURE,
        payload: error,
    };
};

export const loadEmails = (search, page, pageSize, noLoading) => {
    return function (dispatch) {
        dispatch(getEmailsRequest(noLoading));
        API({
            method: "get",
            url: "/email",
            params: {
                search,
                page,
                pageSize
            }
        })
            .then((response) => {
                const { emails, total, userCredits } = response.data;
                dispatch(getEmailsSuccess(emails, total));
                dispatch(updateUserCredits(userCredits));
            })
            .catch(async (error) => {
                dispatch(getEmailsFailure(error));
            });
    };
};