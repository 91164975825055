import React from "react";
import {Modal, Button} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {hidePaymentDeleteModal, startPaymentDelete} from "../../services/action-creators";


const PaymentDeleteModal = () => {
    const dispatch = useDispatch();
    const { selectedId, selectedName, showPaymentDeleteModal } = useSelector((state) => state.account);
    const handleClose = () => dispatch(hidePaymentDeleteModal());

    const handleDelete = () => {
        if (selectedId) {
            return dispatch(startPaymentDelete(selectedId));
        }
    };

    return (
        <Modal centered
               show={showPaymentDeleteModal}
               onHide={handleClose}
               className='modal-clean-delete'>
            <button onClick={handleClose} type="button" className="btn-close btn-close-absolute" aria-label="Close"/>
            <div className="delete-file-modal">
                <div className="dfm-header">
                    Delete Payment
                </div>
                <div className="dfm-warning">
                    This action cannot be undone
                </div>
                <div className="dfm-text">
                    Please confirm the deletion of <strong>{selectedName}</strong> payment method
                </div>
                <div className="dfm-footer">
                    <Button variant="outline-secondary" onClick={handleClose}>Cancel</Button>
                    <Button variant="danger" onClick={handleDelete}>Delete</Button>
                </div>
            </div>
        </Modal>
    );

};

export default PaymentDeleteModal;
