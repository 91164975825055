import React, {useState} from 'react'
import API from "../../utils/httpAPI";
import NeedMoreCreditsHover from "./NeedMoreCreditsHover";
import {priceBullets} from "../../config/payment";
import {useHistory} from "react-router-dom";
import {Spinner} from "react-bootstrap";

const formatNumber = (number) => {
    return Number(number).toLocaleString("en-US");
}

const formatCurrentPrice = (number) => {
    const price = Number(number).toLocaleString("en-US", { style: "currency", currency: "USD" });
    const splitPrice = price.split('.');
    return splitPrice[1] === "00" ? splitPrice[0] : price;
}

const fromCentsToDollar = (cents) => {
    return parseFloat((cents / 100).toFixed(6));
}

const Subscriptions = ({basePrice, prices, children, paygPrices, subscriptionActive, lastSelectedCredits, setLastSelectedCredits}) => {
    const history = useHistory();

    const [subscribeLoading, setSubscribeLoading] = useState(false);
    const [upgradeLoading, setUpgradeLoading] = useState(false);
    const [isNeedMoreCreditsHover, setIsNeedMoreCreditsHover] = useState(false);

    const paymentTaxClickHandler = () => {
        history.push("/account/billingSettings");
        window.scrollTo(0, document.body.scrollHeight);
    }

    const creditsList = prices.map(item => {
            return Number(item.from);
        }
    ).filter(num => num > 1);


    const findClosestSubscription = (credits) => {
         const item = creditsList.find((item) => {
            return credits <= item;
         });
         return item;
    }

    const findSubscriptionRange = (credits) => {
        return prices.find(item => {
            const {from, to} = item;

            if (!to) {
                return credits >= from;
            }

            return credits >= from && credits <= to;
        });
    }

    const findCurrentCreditPrice = (credits) => {
        const item = findSubscriptionRange(credits);

        if (item) {
            return item.price;
        }

        return basePrice;
    }

    const [calculatedCredits, setCalculatedCredits] = useState(lastSelectedCredits);
    const [enterCreditsValue, setEnterCreditsValue] = useState(lastSelectedCredits);

    const selectCreditsFromList = (credits) => {
       setCalculatedCredits(credits);
       setEnterCreditsValue(credits);
       setLastSelectedCredits(credits);
    }

    const onUserEnterCredits = (e) => {
        const numberRegex = `(^[0-9]+$|^$)`;
        let value = e.target.value;

        if (value.match(numberRegex)) {
            if (value.length > 1 && value.substring(0,1) === "0") {
                value = value.substring(1);
            }

            setEnterCreditsValue(value);
        }

        const newValue = Number(value);

        isNeedMoreCreditsHover && setIsNeedMoreCreditsHover(false);

        if (newValue > creditsList[creditsList.length - 1]) {
            setIsNeedMoreCreditsHover(true);
            return;
        }

        const newCalculatedCredits = Math.ceil(newValue / 1000) * 1000;

        if (newValue >= creditsList[0]) {
            setCalculatedCredits(newCalculatedCredits);
            setLastSelectedCredits(newCalculatedCredits);
        } else {
            setCalculatedCredits(creditsList[0]);
            setLastSelectedCredits(creditsList[0]);
        }
    }

    const onSubscribeClick = async () => {
        setSubscribeLoading(true);
        try {
            const {data} = await API({method: "post", url: "/prices/checkout", data: {
                type: 'subscription-tiers',
                quantity: calculatedCredits
            }});

            const {status, url} = data;

            if (status === 'ok' && url) {
                window.location.href = url;
            }

        } catch (err) {

        }

        setSubscribeLoading(false);
    }

    const onUpgradeClick = async () => {
        setUpgradeLoading(true);
        const subscription = findClosestSubscription(calculatedCredits);
        try {
            const {data} = await API({method: "post", url: "/subscriptions/update-checkout", data: {
                    price: subscription.id,
                    quantity: calculatedCredits
                }});

            const {status, url} = data;

            if (status === 'ok' && url) {
                window.location.href = url;
            }

        } catch (err) {

        }

        setUpgradeLoading(false);
    }

    const roundUserInput = (e) => {
        const { value } = e.target;
        if (value < creditsList[0]) {
            setEnterCreditsValue(creditsList[0]);
        } else {
            setEnterCreditsValue(Math.ceil(value / 1000) * 1000);
        }
    }

    const validateInputKey = (e) => {
        if (e.key === 'Enter' || e.key === ',' || e.key === '.' || e.key === '+' || e.key === '-') {
            e.preventDefault();
        }
    }

    const searchPaygPrice = (credits) => {
        return paygPrices.find(item => {

            const {from, to} = item;

            if (!to) {
                return credits >= from;
            }

            return credits >= from && credits <= to;
        })?.price || 0;
    }

    const currentCreditPrice = findCurrentCreditPrice(calculatedCredits);
    const currentPrice = fromCentsToDollar(calculatedCredits * currentCreditPrice);
    const fullPrice = fromCentsToDollar(calculatedCredits * searchPaygPrice(calculatedCredits));

    return(
        <>
            <div className="payment-calculator">
                <div className="payment-credits">
                    <div className="payment-credits-head">
                        <h2>How many emails do you have?</h2>
                        <p className="payment-credits-head-comment">Set the number of emails you need or select a preset amount</p>
                    </div>
                    <div className="payment-credits-input">
                        <form onKeyDown={validateInputKey}>
                            <input placeholder="Enter the number of emails, for example: 5,000"
                                   type="number"
                                   value={enterCreditsValue}
                                   onChange={onUserEnterCredits}
                                   onBlur={roundUserInput}
                            />
                        </form>
                    </div>
                    <div className="payment-credits-select">
                        {creditsList.map((creditsNum, index) => (
                            <div className={`payment-credits-item ${calculatedCredits === creditsNum ? 'selected' : ''}`} key={`${creditsNum}-${index}`}
                            onClick={() => selectCreditsFromList(creditsNum)}>
                                <div className="credits-number">{formatNumber(creditsNum)}</div>
                                <div className="credits-text">Credits</div>
                            </div>
                        ))}
                    </div>
                </div>
                <div className="payment-summary-result">
                    <div className="payment-summary">
                        <NeedMoreCreditsHover visible={isNeedMoreCreditsHover}
                                              maxCredits={formatNumber(creditsList[creditsList.length - 1])}
                        />
                        {children}
                        <div className="content-padding">
                            <div className="payment-selected-credits">
                                <div className="selected-credits">{formatNumber(calculatedCredits)}</div>
                                <div className="selected-comment">Credits</div>
                            </div>
                            <div className="payment-per-credit-cost">
                                <div className="per-credit-price">${fromCentsToDollar(currentCreditPrice)}</div>
                                <div className="per-credit-price-comment">Cost Per Credit</div>
                            </div>
                            <div className="payment-summary-price">
                                <div className="discount">
                                    <div
                                        className="old-price">{formatNumber(fullPrice) !== "0" ? `$${formatNumber(fullPrice)}` : ''}</div>
                                    <div className="new-price">Save: 15%</div>
                                </div>
                                <div className="summary-price">
                                    {formatCurrentPrice(currentPrice)}
                                </div>
                            </div>
                            <div className="payment-button">
                                {!subscriptionActive.subscription &&
                                    <button disabled={subscribeLoading}
                                            onClick={onSubscribeClick}
                                            className={`btn btn-success`}>
                                        Subscribe
                                        {subscribeLoading && (
                                            <Spinner
                                                className="ms-3"
                                                as="span"
                                                animation="border"
                                                size="sm"
                                                role="status"
                                                aria-hidden="true"
                                            />
                                        )}
                                    </button>
                                }
                                {subscriptionActive.subscription &&
                                    <button disabled={upgradeLoading}
                                            onClick={onUpgradeClick}
                                            className={`btn btn-success`}>
                                        Upgrade
                                        {upgradeLoading && (
                                            <Spinner
                                                className="ms-3"
                                                as="span"
                                                animation="border"
                                                size="sm"
                                                role="status"
                                                aria-hidden="true"
                                            />
                                        )}
                                    </button>
                                }
                            </div>
                            <div className="payment-bullet-list">
                                {Object.keys(priceBullets.subscribe).map((key, index) => (
                                    <div key={key} className="payment-bullet">
                                        <img src="images/payment/calc-bullet.svg" alt=""/>
                                        <div className="bullet-label"> {priceBullets.subscribe[key]} </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                    <div className="payment-tax-warning">
                        If you have a VAT, Tax ID/EIN, or a business name, please <a className="payment-tax-link" onClick={paymentTaxClickHandler}>click here</a> to provide the details, and
                        we'll include them on your invoices.
                    </div>
                </div>
            </div>
        </>
    )
};

export default Subscriptions;
