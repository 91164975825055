import React from "react";
import {Modal, Button} from "react-bootstrap";

const SingleCleanHistoryModal = ({ show, setShow, clearHistory }) => {

    const handleClose = () => {
        setShow(false);
    };
    const handleClear = () => {
        clearHistory();
    };
    return (
        <Modal centered show={show} onHide={handleClose} className="modal-custom-confirm">
            <button onClick={handleClose} type="button" className="btn-close btn-close-absolute" aria-label="Close"/>
            <div className="small-modal-content">
                <div className="flex-row align-center">
                    <h3>Confirm Clearing</h3>
                </div>
                <div className="dfm-text mt-3">
                    Are you sure you want clear all history single verifications?
                </div>
                <div className="dfm-footer">
                    <Button className="adm-btn" variant="outline-secondary" onClick={handleClose}>Cancel</Button>
                    <Button className="adm-btn" variant="danger" onClick={handleClear}>Clear</Button>
                </div>
            </div>
        </Modal>
    );

};

export default SingleCleanHistoryModal;
