export const PRIVATE_ROUTES = [
    'bulk',
    'single',
    'sync',
    'apps',
    'account',
    'payment',
    'adminpanel',
    'paymentHistory',
    'usage',
    'checkout-success',
    'checkout-cancel',
    'appUsage',
    'payment-method-add-success',
];

export const DEBOUNCE_INPUT_WAIT_TIME = 500;
