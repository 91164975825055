import React from 'react';
import { TablePagination } from "@material-ui/core";
import { getDateTimeString } from "../../../utils/helpers";
import EventDetails from '../../usage/EventDetails';

const AppUsageDetails = ({ events, count, page, pageSize, onPageChange, onPageSizeChange }) => {
    return (
        <div className="au-details">
            <div className="payment_history_details_table">
                <div className="content-section-body">
                    <div className="table_clients">
                        <div className="t_row t_header">
                            <div className="tc_left tc_date">Time</div>
                            <div className="tc_center tc_total">Credits</div>
                            <div className="tc_left tc_description">Event Details</div>
                            <div className="tc_center tc_description">Request Details</div>
                            <div className="tc_center tc_description">Request IP</div>
                        </div>

                        {events.length ? (
                            events.map((e, i) => (
                                <div className="t_row" key={i}>
                                    <div className="tc_left tc_date">{getDateTimeString(e.time)}</div>
                                    <Credits event={e}/>
                                    <div className="tc_left tc_description">
                                      <EventDetails event={e}/>
                                    </div>
                                    <div className="tc_center tc_description">
                                      <RequestDetails event={e}/>
                                    </div>
                                    <div className="tc_center tc_description">{e.ip || 'N/A'}</div>
                                </div>
                            ))
                        ) : (<div className="t_row">
                            <div className="tc_center tc_no_content">
                                No events found for this period                       </div>
                        </div>)}
                    </div>
                </div>
                {events.length ? (
                    <TablePagination
                        component="div"
                        count={count}
                        page={page}
                        onPageChange={onPageChange}
                        rowsPerPage={pageSize}
                        onRowsPerPageChange={onPageSizeChange}
                    />
                ) : (<></>)}
            </div>
        </div>
    );
};

const Credits = ({ event }) => {
    const classNames = ["tc_center", "tc_total"];

    if (event.creditsUsed > 0) {
        classNames.push("text-danger");
    } else if (event.creditsUsed < 0) {
        classNames.push("text-success");
    }

    return (
        <div className={classNames.join(' ')}>
            {event.creditsUsed !== null ? -event.creditsUsed : 'N/A'}
        </div>
    );
}

const RequestDetails = ({ event }) => {
  return <div>{event.userAgent || 'N/A'}</div>;
};

export default AppUsageDetails;
