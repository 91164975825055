import React, { useState } from 'react'
import { emailsUpload } from "../../services/action-creators";
import { useDispatch, useSelector } from "react-redux";
import { Button, Form, FloatingLabel } from 'react-bootstrap'
import { isValidEmail } from '../../utils/helpers';

const VerifyEnterMails = () => {
    const { uploadingError } = useSelector(state => state.email);
    const { currentUser } = useSelector(state => state.authentication);

    const [enteredEmails, setNewEnteredEmails] = useState('');
    const [validationError, setValidationError] = useState('');
    
    const dispatch = useDispatch();

    const handleEmailsChange = (event) => {
        setNewEnteredEmails(event.target.value);
    };
    
    const handleValidateEmails = (event) => {
        event.preventDefault();

        setValidationError('');

        if (currentUser.credits === 0) {
            setValidationError('Not enough credits');
            return;
        }

        if (enteredEmails.length === 0) {
            setValidationError('Enter one or more emails, separated by commas or new lines');
            return;
        }

        if (enteredEmails.split(/\r?\n/).length > 10 || enteredEmails.split(',').length > 10) {
            setValidationError('Only 10 emails can be submitted in a single request.');
            return;
        }

        const emails = enteredEmails.split(/[\s,]+/)
            .filter(email => isValidEmail(email));

        if (emails.length === 0) {
            setValidationError('Emails not found');
            return;
        }

        setNewEnteredEmails('');

        dispatch(emailsUpload(emails));
    };

    const error = () => {
        let msg = uploadingError;
        if (!msg) {
            msg = validationError;
        }
        return (
            msg && <p className="text-danger">{msg}</p>
        );
    };
    const errorClass = () => {
        let msg = validationError;
        let errorClass = 'enter-mails-control';
        if (msg) {
            errorClass += ' errorClass';
        }
        return errorClass;
    };

    return (
        <div className='input-card'>
            <h3>Enter Email(s) to Verify</h3>
            <p className='text-secondary'>Verification costs 1 credit per email. Enter up to 10 emails, separated by commas or on separate lines.</p>
            <Form>
                <FloatingLabel controlId='floatingTextarea2' label='Enter email(s)'>
                    <Form.Control className={errorClass()}
                                  onChange={handleEmailsChange}
                                  as='textarea'
                                  placeholder='Enter email(s)'
                                  value={enteredEmails}
                    />
                </FloatingLabel>
                {error()}
                <div className='single-proof-btn'>
                    <Button onClick={handleValidateEmails} variant="success" type="submit">Proof</Button>
                </div>
            </Form>
        </div>
    )
};

export default VerifyEnterMails
