import React, {useEffect, useState} from 'react';
import { apps } from '../../config/apps';
import { useDispatch, useSelector } from "react-redux";
import { selectAppType } from "../../services/action-creators";
import useQuery from "../../utils/hooks/useQuery";
import {useHistory, useLocation} from "react-router-dom";

const SelectAppType = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const history = useHistory();

    const { appType } = useSelector((state) => state.apps);

    const query = useQuery();
    const selectedAppType = appType || query.get('appType') || 'custom';

    const handleSelectType = (type) => {
        history.push(`/createApp?appType=${type}`);
        dispatch(selectAppType(type));
    };

    useEffect(() => {
        const query = new URLSearchParams(location.search);
        dispatch(selectAppType(query.get('appType')));
    }, [location]);

    return (
        <div className="app-cards-select apps-padding">
            {Object.keys(apps).map(type => {
                const app = apps[type];

                if (type === 'zapier') {
                    return (
                        <div key={type}
                             className="app-card app-zapier"
                        >
                            <div className="app-card-header">
                                <img src={app.img} alt="" className="zap-icon" />
                                <div className="app-card-header-text">{app.header}</div>
                            </div>
                            <div className="app-type-description">{app.text}</div>
                            <div className="app-type-description mt-4 mx-lg-auto">Coming soon</div>
                        </div>
                    )
                }
                return (
                    <div key={type}
                         className={type === selectedAppType ? "app-card app-card-selected" : "app-card"}
                         onClick={() => handleSelectType(type)}
                    >
                        <div className="app-card-header">
                            <img src={app.img} alt=""/>
                            <div className="app-card-header-text">{app.header}</div>
                        </div>
                        <div className="app-type-description">{app.text}</div>
                    </div>
                )
            }
        )}
        </div>
    )
};

export default SelectAppType
