import React from 'react'
import SuccessContent from "./stripeCheckout/SuccessContent";

const StripeCheckoutSuccess = () => {
    return (
        <div className="page">
            <SuccessContent/>
        </div>
    )
};

export default StripeCheckoutSuccess