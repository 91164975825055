export * from '../common-action-types';

export const FILE_UPLOAD_START_REQUEST = "FILE_UPLOAD_START_REQUEST";
export const FILE_UPLOAD_START_SUCCESS = "FILE_UPLOAD_START_SUCCESS";
export const FILE_UPLOAD_START_FAILURE = "FILE_UPLOAD_START_FAILURE";

export const FILE_UPLOAD_REQUEST = "FILE_UPLOAD_REQUEST";
export const FILE_UPLOAD_SUCCESS = "FILE_UPLOAD_SUCCESS";
export const FILE_UPLOAD_FAILURE = "FILE_UPLOAD_FAILURE";

export const FILE_DELETE_REQUEST = "FILE_DELETE_REQUEST";
export const FILE_DELETE_SUCCESS = "FILE_DELETE_SUCCESS";
export const FILE_DELETE_FAILURE = "FILE_DELETE_FAILURE";

export const FILE_HELP_REQUEST = "FILE_HELP_REQUEST";
export const FILE_HELP_FAILURE = "FILE_HELP_FAILURE";

export const GET_FILE_REQUESTS_REQUEST = "GET_FILE_REQUESTS_REQUEST";
export const GET_FILE_REQUESTS_SUCCESS = "GET_FILE_REQUESTS_SUCCESS";
export const GET_FILE_REQUESTS_FAILURE = "GET_FILE_REQUESTS_FAILURE";

export const FILE_CLEAN_FAILURE = "FILE_CLEAN_FAILURE";

export const FILE_CANCEL_CLEAN_FAILURE = "FILE_CANCEL_CLEAN_FAILURE";

export const FILE_ANALYZE_FAILURE = "FILE_ANALYZE_FAILURE";

export const GET_FILES_REQUEST = "GET_FILES_REQUEST";
export const GET_FILES_FAILURE = "GET_FILES_FAILURE";
export const GET_FILES_SUCCESS = "GET_FILES_SUCCESS";

export const UPDATE_FILES = "UPDATE_FILES";

export const UPDATE_FILE_PROGRESS = "UPDATE_FILE_PROGRESS";

export const SHOW_FILE_DELETE_MODAL = "SHOW_FILE_DELETE_MODAL";
export const HIDE_FILE_DELETE_MODAL = "HIDE_FILE_DELETE_MODAL";

export const SHOW_PURCHASE_MODAL = "SHOW_PURCHASE_MODAL";
export const HIDE_PURCHASE_MODAL = "HIDE_PURCHASE_MODAL";

export const SHOW_DOWNLOAD_MODAL = "SHOW_DOWNLOAD_MODAL";
export const HIDE_DOWNLOAD_MODAL = "HIDE_DOWNLOAD_MODAL";
export const TOGGLE_DOWNLOAD_PRESET = "TOGGLE_DOWNLOAD_PRESET";

export const EXPORT_CREATED = "EXPORT_CREATED";
