import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {
    getSubscriptionActive,
    // getSubscriptionsList,
    subscriptionCancel,
    subscriptionContinue
} from "../../services/account/action-creators";
import {Button, Spinner} from "react-bootstrap";
import {useHistory} from "react-router-dom";
import {useMediaQuery} from "../../utils/hooks/useMediaQuery";
import {MOBILE_VIEW_WIDTH} from "../../config/mobile";

const AccountSubscriptions = () => {
    const dispatch = useDispatch();
    const history = useHistory();

    const {
        // subscriptionsList,
        subscriptionActive,
        subscriptionActiveLoading,
        // subscriptionsLoading,
        subscriptionCancelLoading,
        subscriptionContinueLoading
    } = useSelector((state) => state.account);


    useEffect(() => {
        // dispatch(getSubscriptionsList());
        dispatch(getSubscriptionActive());
    }, []);

    const onSubscribeClickHandle = () => {
        history.push("/payment");
    }

    const onSubscriptionCancelClick = (id) => {
        dispatch(subscriptionCancel(id));
    }

    const onSubscriptionContinueClick = (id) => {
        dispatch(subscriptionContinue(id));
    }

    const getDate = (date) => {
        const d = new Date(date);
        const day = d.getDay() + 1;
        return `${d.getMonth()}/${day}/${d.getFullYear()}`;
    }

    const isMobile = useMediaQuery(`(max-width: ${MOBILE_VIEW_WIDTH}px)`);

    return (

            <div className="account_content_section">
                <div className="content-section-header payment-history-calendar">
                    <div className={`block-heading ${isMobile ? '' : 'mb-3'}`}>Subscription</div>
                </div>
                <div className="content-section-body">
                    {subscriptionActiveLoading && <div className="loadingFiles"/>}
                    {!subscriptionActiveLoading && !subscriptionActive.subscription &&
                        <div style={{ display: "flex", flexDirection: "column" }}>
                            <div style={{textAlign: "center"}} className="mb-3">
                                You don't have any active subscriptions right now. You can create one.
                            </div>
                            <div className="m-auto">
                                <Button variant="success" onClick={onSubscribeClickHandle}> Subscribe </Button>
                            </div>
                        </div>
                    }
                    {!subscriptionActiveLoading && subscriptionActive.subscription &&
                        <table className="table-styled">
                            <thead>
                            <tr>
                                <th>Package</th>
                                <th>Period</th>
                                <th>Start Date</th>
                                <th>{subscriptionActive.subscription.cancelAtPeriodEnd ? 'Valid through' : 'Next billing day'}</th>
                                {subscriptionActive.subscription.cancelAtPeriodEnd && <th></th>}
                                <th></th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td className="subscribe-package-title">
                                    {subscriptionActive.subscription.description}
                                </td>
                                <td className="subscribe-title">
                                    {'Monthly'}
                                </td>
                                <td className="subscribe-title">
                                    {getDate(subscriptionActive.subscription.period_start)}
                                </td>
                                <td className="subscribe-title">
                                    {getDate(subscriptionActive.subscription.period_end)}
                                </td>
                                {subscriptionActive.subscription.cancelAtPeriodEnd &&
                                    <td className="subscription-canceled">
                                        {'Canceled'}
                                    </td>
                                }
                                <td>
                                    <div style={{display: "flex", justifyContent: "end"}}>
                                        {!subscriptionActive.subscription.cancelAtPeriodEnd &&
                                            <Button className="subscription-btn me-3"
                                                    disabled={subscriptionCancelLoading}
                                                    variant="outline-secondary"
                                                    onClick={() => onSubscriptionCancelClick(subscriptionActive.subscription._id)}>
                                                {!subscriptionCancelLoading && 'Cancel'}
                                                {subscriptionCancelLoading && (
                                                    <Spinner
                                                        as="span"
                                                        animation="border"
                                                        size="sm"
                                                        role="status"
                                                        aria-hidden="true"
                                                    />
                                                )}
                                            </Button>
                                        }
                                        {subscriptionActive.subscription.cancelAtPeriodEnd &&
                                            <Button className="subscription-btn me-3"
                                                    disabled={subscriptionContinueLoading}
                                                    variant="outline-secondary"
                                                    onClick={() => onSubscriptionContinueClick(subscriptionActive.subscription._id)}>
                                                {!subscriptionContinueLoading && 'Continue'}
                                                {subscriptionContinueLoading && (
                                                    <Spinner
                                                        as="span"
                                                        animation="border"
                                                        size="sm"
                                                        role="status"
                                                        aria-hidden="true"
                                                    />
                                                )}
                                            </Button>
                                        }
                                        <Button variant="success"
                                                onClick={onSubscribeClickHandle}>
                                            Upgrade
                                        </Button>
                                    </div>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    }
                </div>
            </div>
    )
}

export default AccountSubscriptions;
