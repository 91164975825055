import {
    CHOOSE_CLIENT,
    CHOOSE_ADMIN_CLIENTS,
    CHOOSE_ADMIN_CLEAN,
    CHOOSE_EMAIL_PREFERENCES,
    SHOW_CLIENT_BLOCK_MODAL,
    HIDE_CLIENT_BLOCK_MODAL,
    SIGN_IN_SUCCESS,
    SIGN_UP_SUCCESS,
    SIGN_OUT_SUCCESS,
    CHOOSE_ADMIN_WIDGET,
    CHOOSE_ADMIN_STRIPE,
    GET_STRIPE_PRODUCTS_REQUEST,
    GET_STRIPE_PRODUCTS_SUCCESS,
    GET_STRIPE_PRODUCTS_FAILURE,
    GET_STRIPE_TAGS_REQUEST,
    GET_STRIPE_TAGS_SUCCESS,
    GET_STRIPE_TAGS_FAILURE,
    ADD_STRIPE_PRODUCT_REQUEST,
    ADD_STRIPE_PRODUCT_FAILURE,
    ADD_STRIPE_PRODUCT_SUCCESS,
    REMOVE_STRIPE_PRODUCT_REQUEST,
    REMOVE_STRIPE_PRODUCT_SUCCESS,
    REMOVE_STRIPE_PRODUCT_FAILURE,
    SHOW_STRIPE_PRODUCT_DELETE_MODAL,
    HIDE_STRIPE_PRODUCT_DELETE_MODAL,
    SHOW_STRIPE_PRODUCT_ADD_MODAL,
    HIDE_STRIPE_PRODUCT_ADD_MODAL,
} from "./action-types";

const adminReducer = function (state, action) {
  switch (action.type) {
      case CHOOSE_ADMIN_CLIENTS:
      case CHOOSE_ADMIN_CLEAN:
      case CHOOSE_EMAIL_PREFERENCES:
      case CHOOSE_ADMIN_WIDGET:
      case CHOOSE_ADMIN_STRIPE:
        return {
            ...state,
            activeTab: action.payload,
            chosenId: null
        };
      case SHOW_CLIENT_BLOCK_MODAL:
          return {
              ...state,
              showClientBlockModal: true
          };
      case HIDE_CLIENT_BLOCK_MODAL:
          return {
              ...state,
              showClientBlockModal: false
          };
      case CHOOSE_CLIENT:
          return {
              ...state,
              chosenId: action.payload.id
          };
      case SIGN_IN_SUCCESS:
      case SIGN_UP_SUCCESS:
      case SIGN_OUT_SUCCESS:
          return {
              ...state,
              activeTab: 'clients',
              showClientBlockModal: false,
              chosenId: null
          };
      case GET_STRIPE_PRODUCTS_REQUEST:
          return {
              ...state,
              productsLoading: true,
          };
      case GET_STRIPE_PRODUCTS_SUCCESS:
          return {
              ...state,
              products: action.payload,
              productsLoading: false,
          };
      case GET_STRIPE_PRODUCTS_FAILURE:
          return {
              ...state,
              failure: action.payload,
              productsLoading: false,
          }

      case GET_STRIPE_TAGS_REQUEST:
          return {
              ...state,
              tagsLoading: true,
          };
      case GET_STRIPE_TAGS_SUCCESS:
          return {
              ...state,
              tags: action.payload,
              tagsLoading: false,
          };
      case GET_STRIPE_TAGS_FAILURE:
          return {
              ...state,
              failure: action.payload,
              tagsLoading: false,
          }

      case ADD_STRIPE_PRODUCT_REQUEST:
          return {
              ...state,
              addProductLoading: true,
          };
      case ADD_STRIPE_PRODUCT_FAILURE:
      case ADD_STRIPE_PRODUCT_SUCCESS:
          return {
              ...state,
              addProductLoading: false,
          };

      case REMOVE_STRIPE_PRODUCT_REQUEST:
          return {
              ...state,
              removeProductLoading: true,
          };
      case REMOVE_STRIPE_PRODUCT_SUCCESS:
      case REMOVE_STRIPE_PRODUCT_FAILURE:
          return {
              ...state,
              removeProductLoading: false,
          };
      case SHOW_STRIPE_PRODUCT_ADD_MODAL:
          return {
              ...state,
              showStripeProductAddModal: true
          };
      case HIDE_STRIPE_PRODUCT_ADD_MODAL:
          return {
              ...state,
              showStripeProductAddModal: false
          };
      case SHOW_STRIPE_PRODUCT_DELETE_MODAL:
          return {
              ...state,
              showStripeProductDeleteModal: true,
              selectedProductId: action.payload,
          };
      case HIDE_STRIPE_PRODUCT_DELETE_MODAL:
          return {
              ...state,
              showStripeProductDeleteModal: false,
              selectedProductId: null,
          };
      default:
            return { ...state };
  }
};

export default adminReducer;
