import React, {useEffect} from 'react';
import {BrowserRouter as Router, Route, useHistory} from 'react-router-dom';
import './styles/app.scss';
import SignIn from './pages/signIn/Signin';
import SignUp from './pages/signIn/Signup';
import ConfirmEmail from './pages/signIn/ConfirmEmail';
import ForgotPassword from './pages/signIn/ForgotPassword';
import ChangePassword from './pages/signIn/ChangePassword';
import Clean from './pages/Clean';
import { UserRoute } from './component/privateRoute';
import Sync from './pages/Sync';
import NavBar from './component/Navbar';
import Apps from './pages/Apps';
import Verify from './pages/Verify';
import Account from './pages/Account';
import Payment from './pages/Payment';
import PaymentHistory from './pages/PaymentHistory';
import Usage from './pages/Usage';
import AppUsage from './pages/AppUsage';
import AdminPanel from './pages/AdminPanel';
import CreateApp from "./pages/CreateApp";
import StripeCheckoutSuccess from "./pages/StripeCheckoutSuccess";
import StripeCheckoutCancel from "./pages/StripeCheckoutCancel";
import StripePaymentMethodSuccess from "./pages/StripePaymentMethodSuccess";
import {useDispatch, useSelector} from "react-redux";
import {signOut} from "./services/auth/action-creators";
import {NotificationContainer} from "react-notifications";
import 'react-notifications/lib/notifications.css';
import {Intercom, shutdown} from "@intercom/messenger-js-sdk";


function App() {

    const dispatch = useDispatch();
    const history = useHistory();
    const { syncError, currentUser } = useSelector((state) => state.authentication);

    useEffect(() => {
        if (syncError) {
            dispatch(signOut(history));
        }
    }, [syncError]);

    useEffect(() => {
        if (currentUser) {
            console.log('currentUser', currentUser);
            Intercom({
                app_id: process.env.REACT_APP_INTERCOM_APP_ID,
                user_id: currentUser._id,
                name: currentUser.firstName,
                email: currentUser.email,
                created_at: new Date(currentUser.createdAt).getTime(),
                user_hash: currentUser.hash,
            });
            document.head.insertAdjacentHTML(
                "beforeend",
                `<style>.grecaptcha-badge{visibility: hidden}</style>`
            );
        }
        return () => {
            shutdown();
        }
    }, [currentUser]);

    return (
        <Router>
            <NotificationContainer />
            <div className="background" />
            <div className="main">
                <NavBar />
                <Route path="/signin" exact>
                    <SignIn />
                </Route>
                <Route path="/signup" exact>
                    <SignUp />
                </Route>
                <Route path="/" exact>
                    <SignIn />
                </Route>
                <Route path="/confirmEmail/:confirmationToken" exact>
                    <ConfirmEmail />
                </Route>
                <Route path="/forgotPassword" exact>
                    <ForgotPassword />
                </Route>
                <Route path="/changePassword/:resetPasswordToken" exact>
                    <ChangePassword />
                </Route>

                <UserRoute path="/bulk">
                    <Clean />
                </UserRoute>
                <UserRoute path="/single">
                    <Verify />
                </UserRoute>
                <UserRoute path="/apps">
                    <Apps />
                </UserRoute>
                <UserRoute path="/createApp">
                    <CreateApp />
                </UserRoute>
                <UserRoute path="/sync">
                    <Sync />
                </UserRoute>
                <UserRoute path={['/account', '/account/:tab']}>
                    <Account />
                </UserRoute>
                <UserRoute path="/payment">
                    <Payment />
                </UserRoute>
                <UserRoute path="/payment-method-add-success">
                    <StripePaymentMethodSuccess />
                </UserRoute>
                <UserRoute path="/checkout-success">
                    <StripeCheckoutSuccess />
                </UserRoute>
                <UserRoute path="/checkout-cancel">
                    <StripeCheckoutCancel />
                </UserRoute>
                <UserRoute path="/usage">
                    <Usage />
                </UserRoute>
                <UserRoute path="/paymentHistory">
                    <PaymentHistory />
                </UserRoute>
                <UserRoute path="/appUsage/:id">
                     <AppUsage />
                </UserRoute>
                <UserRoute path="/adminpanel">
                    <AdminPanel />
                </UserRoute>
            </div>
        </Router>
    );
}

export default App;
