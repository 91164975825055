export * from '../common-action-types';

export const CHOOSE_YOUR_PROFILE = "CHOOSE_YOUR_PROFILE";
export const CHOOSE_EMAIL_PREFERENCES = "CHOOSE_EMAIL_PREFERENCES";
export const CHOOSE_SECURITY_SETTINGS = "CHOOSE_SECURITY_SETTINGS";
export const CHOOSE_CHANGE_PASSWORD = "CHOOSE_CHANGE_PASSWORD";
export const CHOOSE_TEAM_SETTINGS = "CHOOSE_TEAM_SETTINGS";
export const CHOOSE_BILLING_SETTINGS = "CHOOSE_BILLING_SETTINGS";
export const CHOOSE_INVOICE_SETTINGS = "CHOOSE_INVOICE_SETTINGS";
export const CHOOSE_PAYMENT_METHODS = "CHOOSE_PAYMENT_METHODS";

export const GET_PAYMENT_METHODS_REQUEST = "GET_PAYMENT_METHODS_REQUEST";
export const GET_PAYMENT_METHODS_FAILURE = "GET_PAYMENT_METHODS_FAILURE";
export const GET_PAYMENT_METHODS_SUCCESS = "GET_PAYMENT_METHODS_SUCCESS";

export const PAYMENT_METHOD_DELETE_REQUEST = "PAYMENT_METHOD_DELETE_REQUEST";
export const PAYMENT_METHOD_DELETE_SUCCESS = "PAYMENT_METHOD_DELETE_SUCCESS";
export const PAYMENT_METHOD_DELETE_FAILURE = "PAYMENT_METHOD_DELETE_FAILURE";

export const SET_DEFAULT_PAYMENT_METHOD_REQUEST = "SET_DEFAULT_PAYMENT_METHOD_REQUEST";
export const SET_DEFAULT_PAYMENT_METHOD_SUCCESS = "SET_DEFAULT_PAYMENT_METHOD_SUCCESS";
export const SET_DEFAULT_PAYMENT_METHOD_FAILURE = "SET_DEFAULT_PAYMENT_METHOD_FAILURE";

export const SHOW_PAYMENT_DELETE_MODAL = "SHOW_PAYMENT_DELETE_MODAL";
export const HIDE_PAYMENT_DELETE_MODAL = "HIDE_PAYMENT_DELETE_MODAL";

export const GET_SUBSCRIPTIONS_LIST_REQUEST = "GET_SUBSCRIPTIONS_LIST_REQUEST";
export const GET_SUBSCRIPTIONS_LIST_FAILURE = "GET_SUBSCRIPTIONS_LIST_FAILURE";
export const GET_SUBSCRIPTIONS_LIST_SUCCESS = "GET_SUBSCRIPTIONS_LIST_SUCCESS";

export const GET_SUBSCRIPTION_ACTIVE_REQUEST = "GET_SUBSCRIPTION_ACTIVE_REQUEST";
export const GET_SUBSCRIPTION_ACTIVE_FAILURE = "GET_SUBSCRIPTION_ACTIVE_FAILURE";
export const GET_SUBSCRIPTION_ACTIVE_SUCCESS = "GET_SUBSCRIPTION_ACTIVE_SUCCESS";

export const SUBSCRIPTION_UPDATE_REQUEST = "SUBSCRIPTION_UPDATE_REQUEST";
export const SUBSCRIPTION_UPDATE_FAILURE = "SUBSCRIPTION_UPDATE_FAILURE";
export const SUBSCRIPTION_UPDATE_SUCCESS = "SUBSCRIPTION_UPDATE_SUCCESS";

export const SUBSCRIPTION_CANCEL_REQUEST = "SUBSCRIPTION_CANCEL_REQUEST";
export const SUBSCRIPTION_CANCEL_FAILURE = "SUBSCRIPTION_CANCEL_FAILURE";
export const SUBSCRIPTION_CANCEL_SUCCESS = "SUBSCRIPTION_CANCEL_SUCCESS";

export const SUBSCRIPTION_CONTINUE_REQUEST = "SUBSCRIPTION_CONTINUE_REQUEST";
export const SUBSCRIPTION_CONTINUE_FAILURE = "SUBSCRIPTION_CONTINUE_FAILURE";
export const SUBSCRIPTION_CONTINUE_SUCCESS = "SUBSCRIPTION_CONTINUE_SUCCESS";
