import React from 'react'
import PageMenu from '../component/PageMenu'
import AdminContent from './admin/AdminContent'
import AdminNavbar from './admin/AdminNavbar'
import AdminClientBlockModal from './admin/AdminClientBlockModal'


const AdminPanel = () => {
    return (
        <div className="page">
            <PageMenu activeTabName={"adminpanel"} />
            {/*<PageAlert/>*/}
            <AdminNavbar/>
            <AdminContent/>

            <AdminClientBlockModal/>
            {/*<PageModal/>*/}
        </div>
    )
};

export default AdminPanel;