import React from 'react'
import PageMenu from '../component/PageMenu'
import PageModal from '../component/PageModal'
import CleanContent from './clean/CleanContent'
import CleanDownloadModalExtended from "./clean/CleanDownloadModalExtended";
import CleanDeleteModal from "./clean/CleanDeleteModal";
import CleanPurchaseModal from "./clean/CleanPurchaseModal";

const Clean = () => {
    return (
        <div className="page">
            <PageMenu activeTabName="bulk" />
            {/*<PageAlert/>*/}
            <CleanContent/>
            <PageModal/>
            <CleanDownloadModalExtended/>
            <CleanDeleteModal/>
            <CleanPurchaseModal/>
        </div>
    )
};

export default Clean
