import {useState, useEffect, useMemo} from "react";
import { useLocation, useHistory } from "react-router-dom";


export function usePagingListSync(initialParams = {}, validationSchema = {}) {
    const location = useLocation();
    const history = useHistory();

    const [paramsFirstUpdated, setParamsFirstUpdated] = useState(false);

    const validateParams = (params) => {
        const validated = {};

        for (const key in initialParams) {
            const rawValue = params[key] ?? initialParams[key];
            validated[key] =
                validationSchema[key] && rawValue !== null && rawValue !== undefined
                    ? validationSchema[key](rawValue) ? rawValue : initialParams[key]
                    : rawValue;
        }

        return validated;
    };

    const [params, setParams] = useState(() => {
        const searchParams = Object.fromEntries(new URLSearchParams(location.search));
        return validateParams(searchParams);
    });

    useEffect(() => {
        const validatedParams = validateParams(params);
        const updatedSearchParams = new URLSearchParams();

        Object.entries(validatedParams).forEach(([key, value]) => {
            if (value !== undefined && value !== null && value !== initialParams[key]) {
                updatedSearchParams.set(key, value);
            }
        });

        if (JSON.stringify(validatedParams) !== JSON.stringify(params)) {
            setParams(validatedParams);
        }

        history.replace({
            pathname: location.pathname,
            search: updatedSearchParams.toString(),
        });
    }, [params, history, location.pathname]);

    const updateParams = (newParams) => {
        setParams((prev) => ({
            ...prev,
            ...newParams,
        }));
        if (!paramsFirstUpdated) setParamsFirstUpdated(true);
    };

    const resetParams = () => {
        setParams(initialParams);
    };

    return {
        params,
        updateParams,
        resetParams,
        paramsFirstUpdated
    };
}