import React, {useState} from "react";
import {Modal} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {hideDownloadModal} from "../../services/action-creators";
import Presets from "./download/Presets";
import Custom from "./download/Custom";

const CleanDownloadModalExtended = () => {
    const dispatch = useDispatch();
    const { selectedId, showDownloadModal, files } = useSelector((state) => state.file);
    const handleClose = () => dispatch(hideDownloadModal());

    const [tab, setTab] = useState("presets")

    const onTabSelect = (tab) => {
        setTab(tab);
    }

    if (!selectedId) {
        return (<></>);
    }

    const getSelectedFile = () => {
        return files.find(file => file.id === selectedId);
    }

    return (
        <Modal show={showDownloadModal} onHide={ handleClose } className={"extended-download-modal"}>
            <button onClick={handleClose} type="button" className="btn-close btn-close-absolute" aria-label="Close"/>
            <div className="download-modal-extended">
                <div className="download-modal-extended-head">
                    <div className="download-modal-header">
                        <h1>Create your Download</h1>
                    </div>
                    <div className="download-tabs">
                        <div className={`download-tab ${tab === "presets" ? "selected" : ""}`} onClick={() => {onTabSelect("presets")}}>
                            <h2>Presets</h2>
                        </div>
                        <div className={`download-tab custom-options ${tab === "custom" ? "selected" : ""}`} onClick={() => {onTabSelect("custom")}}>
                            <h2>Custom Option</h2>
                            <div className="sub-name">With over 25 advanced features</div>
                        </div>
                    </div>
                </div>
                {tab === "presets" && <Presets file={getSelectedFile()}/>}
                {tab === "custom" && <Custom file={getSelectedFile()}/>}
            </div>
        </Modal>
    );
};

export default CleanDownloadModalExtended;
