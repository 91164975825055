import React, {useEffect, useState} from 'react'
import { useDispatch, useSelector } from "react-redux";
import {Button, Form, Spinner} from 'react-bootstrap'
import {loadEmails} from "../../services/action-creators"
import {TablePagination} from "@material-ui/core";
import { InputGroup } from "react-bootstrap";
import { getDateTimeString, saveResponseAsFile } from "../../utils/helpers";
import API from "../../utils/httpAPI";
import {MOBILE_VIEW_WIDTH} from "../../config/mobile";
import {useMediaQuery} from "../../utils/hooks/useMediaQuery";
import SingleCleanHistoryModal from "./VerifyClearHistoryModal";
import {usePagingListSync} from "../../utils/hooks/usePagingListSync";

const resultClassName = (result) => {
    switch (result) {
        case 'valid':
            return 'valid';
        case 'invalid':
            return 'invalid';
        case 'risky':
            return 'risky';
        case 'unknown':
            return 'unknown';
        case 'pending':
            return 't_pending';
        case 'unpaid':
            return 'invalid';
        case 'failed':
            return  'invalid';
    }
}

const VerifyResults = () => {
    const { emails, total, emailsLoaded, emailsLoading, failure, uploadingEmails } = useSelector((state) => state.email);

    const { params, updateParams } = usePagingListSync(
        {
            search: '',
            page: 1,
            pageSize: 10
        },
        {
            page: (value) => Number(value) >= 1,
            pageSize: (value) => Number(value) === 10 || Number(value) === 25 || Number(value) === 50 && Number(value) === 100
        }
    );

    const [lastSearched, setLastSearched] = useState('');
    const [debouncedSearch, setDebouncedSearch] = useState(params.search);
    const [showClearModal, setShowClearModal] = useState(false);
    const [isExporting, setIsExporting] = useState(false);

    const dispatch = useDispatch();
    const showFullClearBtn = useMediaQuery(`(min-width: ${MOBILE_VIEW_WIDTH}px)`);

    useEffect(() => {
        if (params.search === '') {
            setDebouncedSearch(params.search);
            return;
        }
        const handler = setTimeout(() => {
            if (lastSearched !== params.search) {
                setDebouncedSearch(params.search);
            }
        }, 500);

        return () => clearTimeout(handler);
    }, [params.search]);


    const loadMails = () => {
        if (!emailsLoading) {
            dispatch(loadEmails(params.search, params.page, params.pageSize));
            setLastSearched(params.search);
        }
    }

    useEffect(() => {
        loadMails();
    }, [params.page, params.pageSize, debouncedSearch, dispatch]);

    useEffect(() => {
        const hasPendingEmails = emails.some(email => email.result === 'pending');
        if (!hasPendingEmails) {
            return
        }

        const timeout = setTimeout(() => {
            dispatch(loadEmails(params.search, params.page, params.pageSize, true));
        }, 1000);

        return () => clearTimeout(timeout);
    }, [emails]);

    useEffect(() => {
        if (failure && !emailsLoaded) {
            // TODO: Show Error Modal
        }
    }, [failure]);

    const handlePageChange = (event, newPage) => {
        updateParams({page: newPage < 1 ? 1 : newPage + 1});
    };

    const handleChangeRowsPerPage = (event) => {
        updateParams({
            page: 1,
            pageSize: parseInt(event.target.value, 10),
        });
    };

    const handleSearchChange = (event) => {
        updateParams({ search: event.target.value, page: 1 });
    };

    let exportController = new AbortController();

    const exportClickHandler = event => {
        exportController.abort();
        exportController = new AbortController();

        setIsExporting(true);
        API({
            method: "get",
            url: "/email/export",
            params: {
                search: params.search,
            },
            responseType: 'blob',
            signal: exportController.signal
        }).then(response => {
            saveResponseAsFile(response);
            setIsExporting(false);
        });
    };

    const clearHistory = () => {
        API({
            method: "delete",
            url: "/email/remove-all"
        }).then(response => {
            if (response.status === 200) {
                loadMails();
            }
        });
    }

    const countMails = () => {
        return emails?.length + uploadingEmails.length;
    };

    return failure && !emailsLoaded ?
        (<div> failure : {JSON.stringify(failure)}</div>) :
        (<div className="results-container">
            <SingleCleanHistoryModal show={showClearModal} setShow={setShowClearModal} clearHistory={clearHistory} />
            <div className="result-card">
                <div className="menu">
                    <div className="result-history-header">
                        <h3>Result History</h3>
                    </div>
                    <div className="result-history-search">
                        <InputGroup>
                            <img className="search-icon" src={"images/search.svg"} alt="" />
                            <Form.Control
                                className="table_verify_search"
                                type="search"
                                onChange={handleSearchChange}
                                placeholder="Search By Email"
                                value={params.search}
                            />
                        </InputGroup>
                        <Button variant="success" className="export-to-csv" onClick={exportClickHandler} disabled={isExporting}>
                            Export to CSV
                            {isExporting && (
                                <Spinner
                                    className="ms-3 me-3"
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                />
                            )}
                        </Button>
                    </div>
                </div>
                <>
                    {countMails() === 0 ? (
                        <div className="table_verify">
                            <div className="t_row t_header">
                                <div className="t_email">Email</div>
                                <div className="t_result tc_center">Result</div>
                                <div className="t_time tc_center">Time</div>
                            </div>
                            <div className="t_row">
                                <div className="no-emails-text">No emails available</div>
                            </div>
                        </div>
                    ) : (
                        <div className="table_verify">
                            {showFullClearBtn && <a className="clear-history-btn" onClick={() => setShowClearModal(true)}>Clear history</a>}
                            {!showFullClearBtn &&
                                <Button className="clear-history-btn" variant="link" onClick={() => setShowClearModal(true)}>
                                    <img className="clean-btn-icon" src="/images/clean/delete-new.svg" alt=""/>
                                </Button>
                            }
                            <TablePagination
                                component="div"
                                count={total}
                                page={Number(params.page) - 1}
                                onPageChange={handlePageChange}
                                rowsPerPage={Number(params.pageSize)}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                            <div className="t_row t_header">
                                <div className="t_email">Email</div>
                                <div className="t_result tc_center">Result</div>
                                <div className="t_time tc_center">Time</div>
                            </div>

                            {uploadingEmails
                                .map((email, index) => (
                                        <div className="t_row" key={index}>
                                            <div className="t_email">
                                                <div className={"t_circle t_pending"}/>
                                                <div>{email}</div>
                                            </div>
                                            <div className="t_result">
                                                <div className={"t_status tc_center t_pending"}>creating</div>
                                            </div>
                                            <div className="t_time tc_center">creating now</div>
                                        </div>
                                    )
                                )
                            }

                            {emailsLoading ? (
                                <div className="t_row">
                                    <div className="no-emails-text">Loading...</div>
                                </div>
                            ) : (
                                countMails() === 0 ? (
                                    <div className="t_row">
                                        <div className="no-emails-text">No emails available</div>
                                    </div>
                                ) : (
                                    emails.slice(0, params.pageSize - uploadingEmails.length).map((email, index) => (
                                        <div className="t_row" key={index}>
                                            <div className="t_email">
                                                <div className={`verify-result-dot ${resultClassName(email.result)}`}/>
                                                <div className="validated-email">{email.email}</div>
                                            </div>
                                            <div className="t_result">
                                                <div
                                                    className={`verify-result ${resultClassName(email.result)}`}>{email.result}</div>
                                            </div>
                                            <div className="t_time tc_center">{getDateTimeString(email.createdAt)}</div>
                                        </div>
                                    ))
                                )
                            )}
                        </div>
                    )}
                </>
            </div>

                <div  className="light-small-text">Verification history is automatically deleted after 60 days.</div>
        </div>
    )
};

export default VerifyResults;
