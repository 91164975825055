import React from "react";
import {Cell, Pie, PieChart, ResponsiveContainer, Tooltip} from "recharts";
import {MOBILE_VIEW_WIDTH} from "../../config/mobile";
import {useMediaQuery} from "../../utils/hooks/useMediaQuery";

const statusMap = [
    {color: '#5DD38C', key: "valid", name: "Valid", order: 0},
    {color: '#FFBE21', key: "risky", name: "Risky", order: 1},
    {color: '#ED5359', key: "invalid", name: "Invalid", order: 2},
    {color: '#C2C2C2', key: "unknown", name: "Unknown", order: 3},
];

const PieChartTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
        const data = payload[0].payload;

        return (
            <div className="pie-chart-tooltip">
                <p style={{ color: data.color }}>
                    {data.name}
                </p>
                <div style={{ borderBottom: "1px solid #e3e3e9" }} />
                <div style={{ fontSize: 12, marginTop: 10 }}>
                    {data.value}
                    <div style={{ color: "#767678" }}>
                        ({data.percent}%)
                    </div>
                </div>
            </div>
        );
    }

    return null;
};


const PieChartComponent = ({ data, disabled }) => {
    const newData = disabled ? [{value: 1, name: ""}] : data;

    const showPieChart = useMediaQuery(`(min-width: ${MOBILE_VIEW_WIDTH}px)`);

    return (
        <>
            {showPieChart &&
                <div className='pie-chart-wrapper'>
                    <ResponsiveContainer className='pie-chart'>
                        <PieChart>
                            <Pie
                                data={newData}
                                startAngle={180}
                                endAngle={0}
                                innerRadius='40%'
                                paddingAngle={1}
                                dataKey='value'
                                isAnimationActive={false}
                            >
                                {newData.map((entry, index) => (
                                    <Cell className='pie-chart-cell'
                                          key={`cell-${index}`}
                                          fill={disabled ? "#BABABD" : statusMap[index].color}
                                          style={disabled ? {opacity: 0.3} : {}}
                                    />
                                ))}
                            </Pie>
                            {!disabled && <Tooltip wrapperStyle={{outline: "none"}} content={<PieChartTooltip/>}/>}
                        </PieChart>
                    </ResponsiveContainer>
                </div>
            }
        </>
    )
};


const CleanFileChart = ({ data, disabled }) => {

    const total = statusMap.reduce((acc, curr) => {
        return acc + (data?.[curr.key] || 0);
    }, 0)

    const calcData = statusMap
            .sort((a, b) => a.order - b.order)
            .map(el => {
                let value = data?.[el.key] || 0
                return {
                    ...el,
                    value: value,
                    percent: Number((value / total * 100).toFixed(2))
                }
            });

    return (
        <div className="cf_tablechart">
            <div className="cf_chart_info-block">
                <div className={`${disabled ? 'disabled' : ''}`}><h3>Verification Results</h3></div>
                <div className="cf_table">
                    {calcData.map(({name, color, value, percent}) => {
                        return (
                            <div className={`cf_table_row ${disabled ? 'disabled' : ''}`} key={name}>
                                <div className="d-flex align-items-center">
                                    <div className="cf_table_cell"
                                         style={{backgroundColor: color, width: "12px", height: "12px", borderRadius: "2px"}}></div>
                                    <div className="cf_table_cell cf_table_name">{name}</div>
                                </div>
                                <div className="cf_table_cell cf_table_value">
                                    <div>{value > 0 ? value : '0'}</div>
                                </div>
                                <div className={`cf_table_cell cf_table_percent ${disabled ? 'disabled' : ''}`}>
                                    {percent > 0 ? percent + '%' : '0%'}
                                </div>
                            </div>
                        )
                    })}
                </div>
                <div className={`cf_learn_more ${disabled ? 'disabled' : ''}`}>
                    <span className="icon icon-alert-circle"/>
                    <a className='proofy-link' href='https://proofy.io/proofy-terminology' target='_blank'>
                        Result Codes
                    </a>
                </div>
            </div>

            <PieChartComponent data={calcData} disabled={disabled} />
        </div>

    );
};

export default CleanFileChart;
