import React, {useEffect, useState} from "react";
import { useDispatch, useSelector } from "react-redux";
import {toggleDownloadPreset} from "../../../services/action-creators";
import PresetsAccordion from "./PresetsAccordion";
import {Button, Spinner} from "react-bootstrap";
import API from "../../../utils/httpAPI";

const Presets = ({file}) => {
    const dispatch = useDispatch();
    const { presetSelected } = useSelector((state) => state.file);
    const [downloadIsInProgress, setDownloadIsInProgress] = useState(false);
    let timerId = null;

    useEffect(() => {
        return () => {
            clearTimeout(timerId);
        };
    }, []);


    const handleClick = (presetName) => {
        dispatch(toggleDownloadPreset(presetName));
    }

    const handlePresetDownload = async () => {

        setDownloadIsInProgress(true);
        try {
            const { data } = await API({
                method: "post",
                url: "/export/preset",
                data: {
                    bulkId: file.id,
                    preset: presetSelected,
                }
            })

            const {download, url} = data;

            if (download.status === "created" && url) {
                setDownloadIsInProgress(false);
                return window.open(url, "_blank");
            }

            if (download.status !== "created") {

                const check = async(id) => {
                    const {data} = await API({method: "post", url: "export/check-status", data: {id: id}});

                    const {download, url} = data

                    if (download.status !== "created") {
                        timerId = setTimeout(check, 2000);
                    }

                    if (download.status === "created" && url) {
                        setDownloadIsInProgress(false);
                        return window.open(url, "_blank");
                    }
                }

                timerId = setTimeout(() => { check(download.id) }, 2000);
            }
        } catch (error) {
            // TODO: Add Error Handler
            setDownloadIsInProgress(false);
        }
    }

    const getListEntry = (text, type) => {
        return (
            <div className="description-preset-list">
                <img src={`images/clean/dm-${type}.svg`} alt=""/>
                <div className={`preset-text-${type}`}>{text}</div>
            </div>
        )
    };

    return (
        <>
            <div className="download-modal-content">
                <PresetsAccordion
                    alias={"deliverable"}
                    name={"Deliverable"}
                    isRecommended={true}
                    isActive={presetSelected === "deliverable"}
                    onSelect={handleClick}
                >
                    <>
                        <div className="description-h1">Deliverable</div>
                        <div className="description-text">This preset ensures the highest deliverability by including only valid email addresses, minimizing bounce rates, and maximizing engagement. Ideal for most senders, it filters out invalid, unverifiable, disposable, unknown, duplicate, and poorly formatted emails, providing a clean and reliable list for your campaigns.</div>
                        <div className="description-h2">Included Results:</div>
                        {getListEntry('Valids', 'ok')}
                        {getListEntry('Invalids', 'cancel')}
                        {getListEntry('Accept All (Unverifiable)', 'cancel')}
                        {getListEntry('Disposables', 'cancel')}
                        {getListEntry('Unknowns', 'cancel')}
                        {getListEntry('Duplicates', 'cancel')}
                        {getListEntry('Bad Syntax', 'cancel')}
                        <div className="description-text">Learn more about <span className="link-text-green">results.</span></div>
                    </>
                </PresetsAccordion>
                <PresetsAccordion
                    alias={"undeliverable"}
                    name={"Undeliverable"}
                    isRecommended={false}
                    isActive={presetSelected === "undeliverable"}
                    onSelect={handleClick}
                >
                    <>
                        <div className="description-h1">Undeliverable</div>
                        <div className="description-text">This preset contains email addresses that are highly likely to be invalid, non-functional, or result in bounces. It is best used for cleaning and suppressing low-quality contacts rather than for active email campaigns. Sending to these addresses may harm your sender reputation and deliverability.</div>
                        <div className="description-h2">Included Results:</div>
                        {getListEntry('Valids', 'cancel')}
                        {getListEntry('Invalids', 'ok')}
                        {getListEntry('Accept All (Unverifiable)', 'cancel')}
                        {getListEntry('Disposables', 'ok')}
                        {getListEntry('Unknowns', 'cancel')}
                        {getListEntry('Duplicates', 'cancel')}
                        {getListEntry('Bad Syntax', 'ok')}
                        <div className="description-text">Learn more about <span className="link-text-green">results.</span></div>
                    </>
                </PresetsAccordion>
                <PresetsAccordion
                    alias={"all"}
                    name={"All results"}
                    isRecommended={false}
                    isActive={presetSelected === "all"}
                    onSelect={handleClick}
                >
                    <>
                        <div className="description-h1">All results</div>
                        <div className="description-text">This preset includes all email classifications without filtering, providing a full dataset for custom segmentation. It contains valid, invalid, accept-all, disposable, unknown, duplicate, and bad syntax emails.</div>
                        <div className="description-h2">Included Results:</div>
                        {getListEntry('Valids', 'ok')}
                        {getListEntry('Invalids', 'ok')}
                        {getListEntry('Accept All (Unverifiable)', 'ok')}
                        {getListEntry('Disposables', 'ok')}
                        {getListEntry('Unknowns', 'ok')}
                        {getListEntry('Duplicates', 'ok')}
                        {getListEntry('Bad Syntax', 'ok')}
                        <div className="description-text">Learn more about <span className="link-text-green">results.</span></div>
                    </>
                </PresetsAccordion>
            </div>
            <div className="download-modal-footer">
                <Button variant="success" disabled={downloadIsInProgress} onClick={handlePresetDownload}>
                    {downloadIsInProgress
                        ? <>
                            Please wait
                            <Spinner
                                className="ms-3 me-3"
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                            />
                        </>
                        : "Download"}
                </Button>
            </div>
        </>
    );

};

export default Presets;
