import { useSyncExternalStore } from "react";

const getSnapshot = (query) => () => window.matchMedia(query).matches;

const subscribe = (query) => (callback) => {
    const mediaQuery = window.matchMedia(query);
    mediaQuery.addEventListener("change", callback);
    return () => mediaQuery.removeEventListener("change", callback);
};

export const useMediaQuery = (query) =>
    useSyncExternalStore(subscribe(query), getSnapshot(query));