import React, {ReactElement} from "react";
import * as serviceWorker from "./serviceWorker";
import { Provider } from "react-redux";
import App from "./App";
import makeStore from "./services/store";
import {GoogleReCaptchaProvider} from "react-google-recaptcha-v3";
import { createRoot } from 'react-dom/client';

if (process.env.REACT_APP_ENV === 'production') {
    const script = document.createElement('script');
    script.innerHTML = `
    (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
    'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
    })(window,document,'script','dataLayer','GTM-P2R8QGC');
  `;
    document.head.appendChild(script);

    const noscript = document.createElement('noscript');
    noscript.innerHTML = `
    <iframe src="https://www.googletagmanager.com/ns.html?id=GTM-P2R8QGC"
            height="0" width="0" style="display:none;visibility:hidden"></iframe>
  `;
    document.body.insertBefore(noscript, document.body.firstChild);
}

const store = makeStore();

const WithProvider = (): ReactElement => {

    return (
        <GoogleReCaptchaProvider
            useRecaptchaNet
            reCaptchaKey={process.env.REACT_APP_RECAPTCHA_KEY as string}
            scriptProps={{async: true, defer: true, appendTo: 'body'}}
        >
            <Provider store={store}>
                    <App/>
            </Provider>
        </GoogleReCaptchaProvider>
    );
}

const container = document.getElementById('root');
const root = createRoot(container as Element);
root.render(<WithProvider />);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
