import React from "react";
import { usageJSWidget as configs } from '../../../config/apps';

const AppUsageJSData = ({ data, type, hiddenBuckets = [], onBucketClick}) => {
    const config = configs[type];

    const configKeys = Object.keys(config);

    const handleCardClick = key => () => {
        onBucketClick(key);
    };

    const iconSet = type === 'results' ? 'results' : 'requests';
    return (
        <div className="au-data">
            {Object.keys(data)
                .filter(key => {
                    return configKeys.includes(key)
                })
                .map(key => (
                        <div key={key}
                             className={ `au-card au-card-${type} ` +
                             (hiddenBuckets.includes(key) ? "au-card-disabled" : "au-card-enabled")}
                             onClick={handleCardClick(key)}
                        >
                            <div className={`${iconSet}-status ${key} ${hiddenBuckets.includes(key) ? '' : 'filter-active'}`}/>
                            <div className="au-text-data">
                                <div className="au-card-header">{config[key].header}</div>
                                <div className="au-card-data">{data[key]}</div>
                            </div>
                        </div>
                    )
                )}
        </div>
    );
};

export default AppUsageJSData;
