import React from 'react';
import {Button, Form} from 'react-bootstrap';

const AccountSecuritySettings = () => {
    const handleSaveClick = (event) => {
        event.preventDefault();
        // dispatch(signUp({ name, email, password, checkTermsOfUse }, history));
    };
    return (
        <div className="account_content_security_settings">
            <div className="account_content_small_section">
                <h3>Security Settings</h3>
                <div className="form">
                    <Form>
                        <Form.Group className="mb-3" controlId="checkboxes">
                            <Form.Check type="checkbox" label="Receive low balance emails" />

                        </Form.Group>
                        <div>When your account is accessedfrom a new device or location we'll send a one time code to your email. This code will be used to confirm youridentity before getting access to the account.</div>
                        <Button onClick={handleSaveClick} variant="success" type="submit">
                            Save
                        </Button>
                    </Form>
                </div>
            </div>
            <div className="account_content_small_section">
                <h3>Two-Factor Authentication</h3>
                <div>Two-Factor Authentication adds an extra layes of security to your account</div>
                <Button onClick={handleSaveClick} variant="success" type="submit">
                    Turn on Two-Factor Authentication
                </Button>
            </div>
            <div className="account_content_small_section">
                <h3>Trusted IPs</h3>
            </div>
        </div>
    )
};

export default AccountSecuritySettings;