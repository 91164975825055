import React from 'react'
import PageMenu from '../component/PageMenu'
import PageModal from '../component/PageModal'

import PaymentContent from './payment/PaymentContent'

const Usage = () => {
    return (
        <div>
            <div className="page page-payment-adjust">
                <PageMenu activeTabName="payment" />
                {/*<PageAlert/>*/}
            </div>
            <PaymentContent/>
            <PageModal/>
        </div>

    )
};

export default Usage
