import React from "react";
import { usage as config } from '../../config/usage';


const UsageData = ({ data, hiddenBuckets = [], onBucketClick }) => {
    const handleCardClick = key => () => {
        onBucketClick(key);
    };

    return (
        <div className="au-data">
            {Object.keys(data)
                .map(key => (
                    <div key={key}
                        className={ `au-card au-card-total ` +
                        (hiddenBuckets.includes(key) ? "au-card-disabled" : "au-card-enabled")}
                        onClick={handleCardClick(key)}
                    >
                        <img src={config[key].img} alt=""/>
                        <div className="au-text-data">
                            <div className="au-card-header">{config[key].header}</div>
                            <div className="au-card-data">{data[key]}</div>
                        </div>
                    </div>
                    )
                )}
        </div>
    );
};

export default UsageData;
