import React from 'react';
import { apps as appsConfig } from '../../config/apps';
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { selectCreatedApp } from "../../services/action-creators";

const CreatedAppCard = (props) => {
    const {
        type,
        data,
        _id
    } = props.data;

    const config = appsConfig[type];
    const dispatch = useDispatch();
    const history = useHistory();

    const handleSelectCreatedApp = (id) => {
        return () => {
            dispatch(selectCreatedApp({ id, data }, history));
        }
    };

    return(
            <div
                // className={app.type === appType ? "app-card app-card-enabled" : "app-card app-card-disabled"}
                // className="create-app-card"
                onClick={handleSelectCreatedApp(_id)}
                className="app-card-created"
            >
                <div className="app-card-header">
                    <div>
                        <img src={config.img} alt=""/>
                    </div>
                    <div className="acc-header">
                        <div className="acc-name">{data.appName}</div>
                        <div className="acc-text">{config.header}</div>
                    </div>
                </div>
                <div className="app-card-progress"/>
            </div>
    )
};

export default CreatedAppCard
