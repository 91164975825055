import {
    EMAILS_UPLOAD_REQUEST,
    EMAILS_UPLOAD_SUCCESS,
    EMAILS_UPLOAD_FAILURE,

    GET_EMAILS_REQUEST,
    GET_EMAILS_FAILURE,
    GET_EMAILS_SUCCESS,

    SIGN_IN_SUCCESS,
    SIGN_UP_SUCCESS,
    SIGN_OUT_SUCCESS,
} from "./action-types";

const emailReducer = function (state, action) {
    switch (action.type) {
        case EMAILS_UPLOAD_REQUEST:
            return {
                ...state,
                uploadingEmails: [...action.payload.emails].reverse(),
                uploadingError: ''
            };
        case EMAILS_UPLOAD_SUCCESS:
            const newEmails = action.payload.emails.filter(newEmail => {
                return !state.emails.some(existingEmail => {
                    return existingEmail._id === newEmail._id;
                });
            });
            return {
                ...state,
                uploadingEmails: [],
                emails: [...newEmails.reverse(), ...state.emails],
                total: state.total + action.payload.emails.length
            };
        case EMAILS_UPLOAD_FAILURE:
            return {
                ...state,
                uploadingEmails: [],
                uploadingError: action.payload
            };
        case GET_EMAILS_REQUEST:
            return {
                ...state,
                emailsLoading: !action.payload.noLoading,
                emailsLoaded: false,
            };
        case GET_EMAILS_FAILURE:
            return {
                ...state,
                emailsLoading: false,
                failure: action.payload
            };
        case GET_EMAILS_SUCCESS:
            return {
                ...state,
                emailsLoading: false,
                emailsLoaded: true,
                emails: action.payload.emails,
                total: action.payload.total,
                uploadingEmails: []
            };
        case SIGN_IN_SUCCESS:
        case SIGN_UP_SUCCESS:
        case SIGN_OUT_SUCCESS:
            return {
                ...state,
                failure: null,
                emailsLoading: false,
                emailsLoaded: false,
                emails: [],
            };
        default:
            return { ...state };
    }
};

export default emailReducer;
