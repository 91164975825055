import React from "react";
import {Container} from "react-bootstrap";
import { Link } from "react-router-dom";
import {MOBILE_VIEW_WIDTH} from "../../config/mobile";
import {useMediaQuery} from "../../utils/hooks/useMediaQuery";


const PageMenu = () => {
    const isMobile = useMediaQuery(`(max-width: ${MOBILE_VIEW_WIDTH}px)`);

    return (
        <div className="page-menu">
            <Container>
                <h1>Create New App</h1>
            </Container>
            <Container className="page-menu-buttons">
                <Link to={"/apps"} className="btn-page btn-outline btn btn-outline-success">
                    <img className="btn-icon img-visibile" src={"images/apps/back.svg"} alt="" />
                    <img className="btn-icon img-celare" src={"images/apps/back-w.svg"} alt="" />
                    {isMobile ? 'Back' : 'Back To Apps'}
                </Link>
            </Container>
        </div>
    );

};

export default PageMenu;
