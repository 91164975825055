import React, {useState} from 'react';
import {Badge, Button, Form} from 'react-bootstrap';
import {useDispatch, useSelector} from "react-redux";
import {updateAppFormData} from "../../../services/action-creators";
import * as _ from "lodash-es";

const kvThrottle = {
    sec: "second",
    min: "minute",
    hour: "hour",
    week: "week",
    ip: "per IP",
    globally: "globally",
}

const JsWidgetAppForm = () => {

    const [addDomain, setAddDomain] = useState('');
    const [addRequests, setAddRequests] = useState('');
    const [addTime, setAddTime] = useState('min');
    const [addCondition, setAddCondition] = useState('ip');
    const [addThrottleError, setAddThrottleError] = useState('');

    const dispatch = useDispatch();

    const {appFormData} = useSelector((state) => state.apps);

    const handleAddDomainChange = (event) => {
        setAddDomain(event.target.value);
    };

    const handleAddDomain = () => {
        const domains = appFormData?.domains ? appFormData.domains : [];
        if (!domains.find(d => d === addDomain)) {
            domains.push(addDomain);
        }
        setAddDomain('');
        dispatch(updateAppFormData({domains}));
    };

    const handleDeleteDomain = (item) => {
        const domains = appFormData.domains.filter(domain => domain !== item);
        dispatch(updateAppFormData({domains}));
    }

    const handleAddThrottle = () => {
        if (addRequests.length > 0) {
            const throttles = appFormData?.throttles ? appFormData.throttles : [];
            const newThrottleRule = {requests: addRequests, time: addTime, condition: addCondition};

            if (Number(addRequests) > 500) {
                setAddThrottleError('Maximum number of requests 500')
                setAddRequests('');
                return;
            }

            if (throttles.find(e => (e.time === newThrottleRule.time && e.condition === newThrottleRule.condition))) {
                setAddThrottleError(`You already have a rule for verifications per ${kvThrottle[newThrottleRule.time]}`);
                return;
            }

            throttles.push(newThrottleRule);
            setAddRequests('');
            dispatch(updateAppFormData({throttles}));
            setAddThrottleError('');
        }
    };

    const handleDeleteThrottle = (item) => {
        setAddThrottleError('');
        const throttles = appFormData.throttles.filter(throttle =>  (!_.isEqual(throttle, item)))
        dispatch(updateAppFormData({throttles}));
    }

    const handleMaxAllowedRequestsChange = (event) => {
        let value = event.target.value;

        const numberRegex = `(^[0-9]+$|^$)`;
        if (value.match(numberRegex)) {

            if (value.substr(0,1) == "0")
            {
                value = value.substr(1);
            }
            setAddRequests(value);
        }
    };

    const handleMaxAllowedRequestsTimeChange = (event) => {
        setAddTime(event.target.value);
    };

    return (
        <div className="ca-form">
            <div className="ca-form-row content-padding">
                <h2 className="app-js-widget-title">JavaScript Widget</h2>
                <div className="ca-description-block">
                    <h4>Add Authorization Domain</h4>
                    <p className="ca-description-text">
                        Set domains for app access restrictions, or leave blank for open use.
                    </p>
                </div>
                <Form.Group className="input-group-with-button">
                    <div className="input-group-with-button-input">
                        <Form.Control value={addDomain} onChange={handleAddDomainChange} className="ca-auth-domain-input" type="text" placeholder="example.com" />
                    </div>
                    <div className="input-group-with-button-button">
                        <Button variant="success" className="btn-add" onClick={handleAddDomain}>Add</Button>
                    </div>
                </Form.Group>
                <div className="mt-2">
                    <Form.Text>
                        {appFormData?.domains && appFormData?.domains.length > 0 ? 'Widget is restricted to:' : 'Widget is not restricted to specific domains'}
                    </Form.Text>
                    {appFormData?.domains && appFormData?.domains.length > 0 &&
                        <div className="ca-throttle-usage-tags-container mt-1">
                            {appFormData.domains.map((item, index) => {
                                return (
                                    <Badge key={index} className="ca-throttle-usage-tag">
                                        {item}
                                        <button onClick={() => handleDeleteDomain(item)}
                                                type="button"
                                                className="btn-close ca-throttle-usage-close"
                                                aria-label="Close"/>
                                    </Badge>
                                );
                            })}
                        </div>
                    }
                </div>
            </div>

            <div className="line"/>
            <div className="ca-form-row content-padding">
                <div className="ca-description-block">
                    <h4>Rate Limit</h4>
                    <p className="ca-description-text">
                        Set request limits to control widget usage. Defaults are unrestricted.
                    </p>
                </div>
                <Form.Group>
                    <div className="ca-throttle-usage-settings">
                        <div className="ca-allow-up-to">
                            <Form.Label>Allow up to</Form.Label>
                            <Form.Control value={addRequests} onChange={handleMaxAllowedRequestsChange} type="text" placeholder="10" />
                        </div>
                        <div className="ca-verifications-per">
                            <Form.Label>Verifications per</Form.Label>
                            <Form.Select value={addTime} className="ca-verifications-per-time" onChange={handleMaxAllowedRequestsTimeChange}>
                                <option value="min">minute</option>
                                <option value="hour">hour</option>
                            </Form.Select>
                            <Button variant="success" className="btn-add" onClick={handleAddThrottle}>Add</Button>
                        </div>
                    </div>
                    {addThrottleError.length > 0 && <div className="error-text">
                        {addThrottleError}
                    </div>}
                    {appFormData?.throttles && appFormData?.throttles.length > 0
                        ? <div className="ca-throttle-usage-tags-container">
                            {appFormData.throttles.map((item, index) => {
                                return (
                                    <Badge key={index} className="ca-throttle-usage-tag">
                                        {`${item.requests} - ${item.time} - ${item.condition}`}
                                        <button onClick={() => handleDeleteThrottle(item)}
                                                type="button"
                                                className="btn-close ca-throttle-usage-close"
                                                aria-label="Close"/>
                                    </Badge>
                                );
                            })}
                        </div>
                        : <Badge className="ca-throttle-usage-tag">Unlimited usage is allowed</Badge>
                    }
                </Form.Group>
            </div>
        </div>
    );
};

export default JsWidgetAppForm


// Threshold

// < div
// className = "line" / >
//     < Form
// className = "content-padding" >
//     < Form.Group
// className = "ca-low-balance-block"
// controlId = "formBasicCheckbox" >
//     < div
// className = "ca-form-low-balance" >
//     < h4 > Low
// Balance
// Alerts < /h4>
// <p>Get an alert when your credits are running low. The low balance notifications are account wide settings.</p>
// </div>
// <Form.Check
//     className="ca-low-balance-check"
//     checked={appFormData.receiveLowBalanceAlerts === 'on'}
//     onChange={handleReceiveLowBalanceAlertsChange}
//     label="Receive low balance emails"
//     value={appFormData.receiveLowBalanceAlerts}
// />
// </Form.Group>
// <Form.Group className="ca-form-row ca-min-threshold" controlId="creditThreshold">
//     <Form.Label>Minimum Credit Threshold</Form.Label>
//     <Form.Control value={appFormData.minimumCreditThreshold} onChange={handleMinimumCreditThresholdChange} type="number"
//                   placeholder=""/>
// </Form.Group>
// <Form.Group className="ca-form-row ca-send-notifications" controlId="notifications">
//     <Form.Label>Send Notifications To</Form.Label>
//     <Form.Control as="textarea" rows={2} value={appFormData.sendNotificationsTo}
//                   onChange={handleSendNotificationsToChange}/>
//     <p className="ca-row-comment">Separate multiple emails with a comma</p>
// </Form.Group>
// </Form>
