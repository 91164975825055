type AppType = "custom" | "js" | "webhook" | "zapier";

interface AppContent {
    img: string;
    header: string;
    text: string;
}

type UsageRequestType = "overall" | "single" | "bulk" | "other";
type UsageResultType = "valid" | "risky" | "unknown" | "invalid";

interface UsageContent {
    header: string;
    color: string;
}

interface Usage {
    requests: Record<UsageRequestType, UsageContent>;
    results: Record<UsageResultType, UsageContent>;
}

interface UsageJS {
    requests: {
        overall: UsageContent;
    },
    results: {
        valid: UsageContent;
        invalid: UsageContent;
    }
}

export const apps: Record<AppType, AppContent>  = {
    custom: {
        img: "/images/apps/custom.svg",
        header: "Custom Integration",
        text: "Custom Integration. Integrate our single and bulk verification directly in your platform!"
    },
    js: {
        img: "/images/apps/js.png",
        header: "JavaScript Widget",
        text: "Use our verification directly on your website with minimal coding experience!"
    },
    webhook: {
        img: "/images/apps/webhook.png",
        header: "Webhook",
        text: "Easily add our one off validation service to your existing workflow."
    },
    zapier: {
        img: "/images/apps/zapier.png",
        header: "Zapier",
        text: "Connect Proofy to hundreds of services using Zapier."
    }
};

export const usage: Usage = {
    requests: {
        overall: {
            header: 'Total',
            color: '#82ca9d'
        },
        single: {
            header: 'Single',
            color: '#d87ebe'
        },
        bulk: {
            header: 'Bulk',
            color: '#8884d8'
        },
        other: {
            header: 'Other',
            color: '#91ffc1'
        }
    },

    results: {
        valid: {
            header: 'Valid',
            color: '#5DD38C'
        },
        risky: {
            header: 'Risky',
            color: '#FFBE21'
        },
        unknown: {
            header: 'Unknown',
            color: '#AE5ACC'
        },
        invalid: {
            header: 'Invalid',
            color: '#ED5359'
        },
    }
};

export const usageJSWidget: UsageJS = {
    requests: {
        overall: {
            header: 'Total',
            color: '#82ca9d'
        },
    },
    results: {
        valid: {
            header: 'Allowed',
            color: '#5DD38C'
        },
        invalid: {
            header: 'Blocked',
            color: '#ED5359'
        }
    }
};
