export * from '../common-action-types';

export const SET_PAYMENT_HISTORY_DETAILS_START_DATE = "SET_PAYMENT_HISTORY_DETAILS_START_DATE";
export const SET_PAYMENT_HISTORY_DETAILS_END_DATE = "SET_PAYMENT_HISTORY_DETAILS_END_DATE";

export const GET_PAYMENT_HISTORY_DETAILS_REQUEST = "GET_PAYMENT_HISTORY_DETAILS_REQUEST";
export const GET_PAYMENT_HISTORY_DETAILS_SUCCESS = "GET_PAYMENT_HISTORY_DETAILS_SUCCESS";
export const GET_PAYMENT_HISTORY_DETAILS_FAILURE = "GET_PAYMENT_HISTORY_DETAILS_FAILURE";

export const SET_PAYMENT_USAGE_DETAILS_START_DATE = "SET_PAYMENT_USAGE_DETAILS_START_DATE";
export const SET_PAYMENT_USAGE_DETAILS_END_DATE = "SET_PAYMENT_USAGE_DETAILS_END_DATE";

export const GET_PAYMENT_USAGE_DETAILS_REQUEST = "GET_PAYMENT_USAGE_DETAILS_REQUEST";
export const GET_PAYMENT_USAGE_DETAILS_SUCCESS = "GET_PAYMENT_USAGE_DETAILS_SUCCESS";
export const GET_PAYMENT_USAGE_DETAILS_FAILURE = "GET_PAYMENT_USAGE_DETAILS_FAILURE";
