import React, { useState } from 'react';
import {Button, Form, Spinner} from 'react-bootstrap';
import { useDispatch, useSelector } from "react-redux";
import { updateUserInfo } from "../../services/action-creators";

const AccountEmailPreferences = () => {
    const dispatch = useDispatch();
    const { currentUser, isUpdateUserLoading} = useSelector((state) => state.authentication);
    const [emails, setEmails] = useState(currentUser?.emailPreferences?.emails ?? "");
    const [listCompletes, setListCompletes] = useState(currentUser?.emailPreferences?.listCompletes ?? "");
    const [lowBalance, setLowBalance] = useState(currentUser?.emailPreferences?.lowBalance ?? "");
    const [minimumCreditThreshold, setMinimumCreditThreshold] = useState(currentUser?.emailPreferences?.minimumCreditThreshold ?? "");

    const handleEmailsChange = (event) => {
        setEmails(event.target.value);
    };

    const handleListCompletes = (event) => {
        setListCompletes(event.target.value === 'on' ? '' : 'on');
    };

    const handleLowBalance = (event) => {
        setLowBalance(event.target.value === 'on' ? '' : 'on');
    };

    const handleMinimumCreditThreshold = (event) => {
        const numberRegex = `(^[0-9]+$|^$)`;
        let value = event.target.value;

        if (value.match(numberRegex)) {
            if (value.length > 1 && value.substring(0,1) === "0") {
                value = value.substring(1);
            }
            setMinimumCreditThreshold(value);
        }
    }

    const handleSaveClick = (event) => {
        event.preventDefault();
        dispatch(updateUserInfo({
            currentEmail: currentUser.email,
            id: currentUser._id,
            emailPreferences:{
                emails,
                listCompletes,
                lowBalance,
                minimumCreditThreshold: Number(minimumCreditThreshold)
            }
        }));
    };

    return (
        <div className="account_content_section">
            <h1>Email Preferences</h1>
            <div className="line"/>
            <div className="account-form">
                <Form className="form-email">
                    <div className="account-email-checkboxes-section">
                        <Form.Group className="low-balance mt-1">
                            <p className='low-balance-title'>Notifications</p>
                            <Form.Check value={listCompletes}
                                        checked={listCompletes === 'on'}
                                        onChange={handleListCompletes}
                                        type="checkbox"
                                        label="Receive an email when a file is done"
                                        id="listCompletedCheckbox"/>
                        </Form.Group>
                        <Form.Group className="low-balance">
                            <p className="low-balance-title">Low Balance Notifications</p>
                            <Form.Check value={lowBalance}
                                        checked={lowBalance}
                                        onChange={handleLowBalance}
                                        type="checkbox"
                                        id="lowBalanceCheck"
                                        label="Receive an email for low balance" />
                        </Form.Group>
                    </div>
                    <div className="account-email-input-section">
                        <Form.Group className="account-send-notification-group">
                            <Form.Label>Send Notifications To</Form.Label>
                            <Form.Control value={emails} onChange={handleEmailsChange} as="textarea" rows={3}/>
                            <p className="form-input-comment">Separate emails with commas</p>
                        </Form.Group>
                        {lowBalance &&
                            <Form.Group className="account-threshold-group">
                                <Form.Label>Minimum Credit Threshold</Form.Label>
                                <Form.Control value={minimumCreditThreshold} onChange={handleMinimumCreditThreshold} type="text" placeholder="10" />
                            </Form.Group>
                        }
                        <div className="account-email-btn">
                            <Button className="mobile-btn-width" onClick={handleSaveClick} variant="success" type="submit" disabled={isUpdateUserLoading}>
                                {!isUpdateUserLoading && <img className="btn-icon" src="/images/account/save-btn-icon.svg" alt=""/>}
                                {isUpdateUserLoading && (
                                    <Spinner
                                        className="mr14"
                                        as="span"
                                        animation="border"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                    />
                                )}
                                Save
                            </Button>
                        </div>
                    </div>
                </Form>
            </div>
        </div>

    )
};

export default AccountEmailPreferences;
