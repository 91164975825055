import React, {useEffect, useState} from "react";
import {Modal, Button} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { hidePurchaseModal } from "../../services/action-creators";
import {useHistory} from "react-router-dom";


const CleanPurchaseModal = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { selectedId, files, showPurchaseModal } = useSelector((state) => state.file);
    const { currentUser } = useSelector((state) => state.authentication);
    const handleClose = () => dispatch(hidePurchaseModal());
    
    const [contains, setContains] = useState();

    const [show, setShow] = useState(true);

    useEffect(() => {
        if (files.length > 0) {
            setContains(files.find(file => file.id === selectedId)?.parseResult?.records);
        }
    }, []);

    const purchaseClickHandler = () => {
        history.push("/payment");
    }

    return (
        <Modal show={showPurchaseModal} onHide={handleClose} centered>
            <button onClick={handleClose} type="button" class="btn-close btn-close-absolute" aria-label="Close"></button>
            <div className="purchase-file-modal">
                <img src="/images/failure.svg" alt="" />
                <div className="pfm-header">
                    Sorry you don't have enough credits to verify this file
                </div>

                <div className="pfm-content">
                    {`This file contains ${contains} and you have ${currentUser.credits} available`}
                </div>

                <div className="pfm-footer">
                    <Button className ="pfm-btn" variant="danger" onClick={purchaseClickHandler}>
                        Purchase Credits
                    </Button>
                </div>
            </div>
        </Modal>
    );

};

export default CleanPurchaseModal;
