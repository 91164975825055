import React, {useEffect, useState} from 'react';
import { useDispatch, useSelector } from "react-redux";
import {getStripeProducts, getStripeTags} from "../../../services/admin/action-creators";
import ProductsList from "./ProductsList";
import ProductDeleteModal from "./ProductDeleteModal";
import ProductAddModal from "./ProductAddModal";


const AdminStripeProducts = () => {
    const {failure, tags} = useSelector((state) => state.admin);

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getStripeProducts());
        dispatch(getStripeTags());
    }, []);


    if (failure) {
        return (
            <div> failure : {JSON.stringify(failure)}</div>
        );
    }

    return (
        <>
            <ProductDeleteModal />
            {tags && <ProductAddModal/>}
            <ProductsList />
        </>
    )

};

export default AdminStripeProducts;
