import {
    GET_USAGE_DETAILS_REQUEST,
    GET_USAGE_DETAILS_SUCCESS,
    GET_USAGE_DETAILS_FAILURE,
    SIGN_IN_SUCCESS,
    SIGN_UP_SUCCESS,
    SIGN_OUT_SUCCESS,
} from "./action-types";

const usageDetailsReducer = function (state = {}, action) {
    switch (action.type) {
        case GET_USAGE_DETAILS_REQUEST:
            return state;
        case GET_USAGE_DETAILS_FAILURE:
            return state;
        case GET_USAGE_DETAILS_SUCCESS:
            return {
                ...state,
                chart: action.payload.chart,
                details: action.payload.details
            };
        case SIGN_IN_SUCCESS:
        case SIGN_UP_SUCCESS:
        case SIGN_OUT_SUCCESS:
            return {
                ...state,
                chart: [],
                details: {
                    events: [],
                    count: 0
                }
            };
        default:
            return state;
    }
};

export default usageDetailsReducer;
