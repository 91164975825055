export * from '../common-action-types';

export const CREATE_NEW_APP = "CREATE_NEW_APP";
export const BACK_TO_APPS = "BACK_TO_APPS";
export const SELECT_APP_TYPE = "SELECT_APP_TYPE";

export const CREATE_APP_REQUEST = "CREATE_APP_REQUEST";
export const CREATE_APP_SUCCESS = "CREATE_APP_SUCCESS";
export const CREATE_APP_FAILURE = "CREATE_APP_FAILURE";

export const UPDATE_SELECTED_APP_DATA = "UPDATE_SELECTED_APP_DATA";
export const UPDATE_APP_FORM_DATA = "UPDATE_APP_FORM_DATA";
export const CLEAR_APP_FORM_DATA = "CLEAR_APP_FORM_DATA";

export const GET_APPS_REQUEST = "GET_APPS_REQUEST";
export const GET_APPS_SUCCESS = "GET_APPS_SUCCESS";
export const GET_APPS_FAILURE = "GET_APPS_FAILURE";

export const SAVE_APP_DATA_REQUEST = "SAVE_APP_DATA_REQUEST";
export const SAVE_APP_DATA_FAILURE = "SAVE_APP_DATA_FAILURE";

export const CREATE_CUSTOM_EXPORT = "CREATE_CUSTOM_EXPORT";