import React, {useEffect} from "react";
import { Navbar, Nav } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

import {
    chooseYourProfile,
    chooseEmailPreferences,
    chooseSecuritySettings,
    chooseChangePassword,
    chooseTeamSettings,
    chooseBillingSettings
} from "../../services/account/action-creators";
import {useHistory, useLocation} from "react-router-dom";

const NavBar = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();
    const { activeTab } = useSelector((state) => state.account);

    const handleYourProfile = () => {
        dispatch(chooseYourProfile(history));
    };
    const handleEmailPreferences = () => {
        dispatch(chooseEmailPreferences(history));
    };
    const handleSecuritySettings = () => {
        dispatch(chooseSecuritySettings(history));
    };
    const handleChangePassword = () => {
        dispatch(chooseChangePassword(history));
    };
    const handleTeamSettings = () => {
        dispatch(chooseTeamSettings(history));
    };
    const handleBillingSettings = () => {
        dispatch(chooseBillingSettings(history));
    };

    useEffect(() => {
        switch (history.location.pathname) {
            case '/account':
            case '/account/yourProfile':
            case '/account/securitySettings':
            case '/account/teamSettings':
                handleYourProfile();
                break;
            case '/account/emailPreferences':
                handleEmailPreferences();
                break;
            case '/account/changePassword':
                handleChangePassword();
                break;
            case '/account/billingSettings':
                handleBillingSettings();
                break;
        }
    }, [location]);

    return (
        <Navbar className="account-settings-navbar">
            <Nav.Link onClick={handleYourProfile}>
                <div className={activeTab === "yourProfile" ? "navmenu active" : "navmenu"}>Your Profile</div>
            </Nav.Link>
            <Nav.Link onClick={handleEmailPreferences}>
                <div className={activeTab === "emailPreferences" ? "navmenu active" : "navmenu"}>Email Preferences</div>
            </Nav.Link>
            <Nav.Link onClick={handleChangePassword}>
                <div className={activeTab === "changePassword" ? "navmenu active" : "navmenu"}>Change Password</div>
            </Nav.Link>
            <Nav.Link onClick={handleBillingSettings}>
                <div className={activeTab === "billingSettings" ? "navmenu active" : "navmenu"}>Billing Settings</div>
            </Nav.Link>

            {/*<Nav.Link disabled onClick={handleTeamSettings}>*/}
            {/*    <div className={activeTab === "teamSettings" ? "navmenu active" : "navmenu disabled"}>Team Settings</div>*/}
            {/*</Nav.Link>*/}
            {/*<Nav.Link disabled onClick={handleSecuritySettings}>*/}
            {/*    <div className={activeTab === "securitySettings" ? "navmenu active" : "navmenu disabled"}>Security Settings</div>*/}
            {/*</Nav.Link>*/}
        </Navbar>
    );
};

export default NavBar;
