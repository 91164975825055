import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";

import AccountYourProfile from "./AccountYourProfile";
import AccountEmailPreferences from "./AccountEmailPreferences";
import AccountSecuritySettings from "./AccountSecuritySettings";
import AccountChangePassword from "./AccountChangePassword";
import AccountTeamSettings from "./AccountTeamSettings";
import AccountBillingSettings from "./AccountBillingSettings";

const AccountContent = () => {
    const { activeTab } = useSelector((state) => state.account);

    switch(activeTab) {
        case 'yourProfile':
            return (
                <AccountYourProfile/>
            );
        case 'emailPreferences':
            return (
                <AccountEmailPreferences/>
            );
        case 'securitySettings':
            return (
                <AccountSecuritySettings/>
            );
        case 'changePassword':
            return (
                <AccountChangePassword/>
            );
        case 'teamSettings':
            return (
                <AccountTeamSettings/>
            );
        case 'billingSettings':
            return (
                <AccountBillingSettings/>
            );
        default:
            return (<AccountYourProfile/>);
    }
};

export default AccountContent;
