export const priceBullets = {
    payg: {
        pb0: "Buy only what you need",
        pb1: "No monthly payments",
        pb2: "For use with Bulk, API, and Widget"
    },
    subscribe: {
        pb0: "Subscribe and save 15%",
        pb1: "Cancel anytime",
        pb2: "For use with Bulk, API, and Widget"
    }
}

export const keyFeatures = {
    kc0: {
        kr0: "Duplicate email remover",
        kr1: "Disposable and Temporary Detection",
        kr2: "Free IPS Detection",
    },
    kc1: {
        kr0: "Accept-All Detection",
        kr1: "Syntax Validation",
        kr2: "Single Verification Real-Time",
    },
    kc2: {
        kr0: "Mailbox Full Detection",
        kr1: "SMTP Verification",
        kr2: "Bulk Email Verification",
    },
    kc3: {
        kr0: "Domain validation",
        kr1: "SMTP Provider Details",
        kr2: "Email Verifier API",
    },
};

export const commonQuestions = {
    qc0: {
        qr0: {
            question: "Why is email verification important?",
            answer: "Email list verification improves accuracy, ensures email deliverability, boosts sender reputation, and maximizes ROI in email marketing. Optimize your email campaigns with verified lists for better customer reach and higher engagement."
        },
        qr1: {
            question: "What is email verification service?",
            answer: "An email verification service checks for syntax errors, invalid domains, disposable and temporary emails, catch-all addresses, and spam traps, ensuring higher deliverability and improved engagement rates. Enhance your email marketing campaigns with verified lists for better performance and results."
        },
        qr2: {
            question: "How do credits work?",
            answer: "One credit equals one email verification. We do not charge for unknown or duplicate email addresses. Credits never expire and include tiered discounts. We offer both pay-as-you-go and subscription packages. Optimize your email verification with our flexible plans."
        },
        qr3: {
            question: "Can I add my Company name and Tax ID (VAT)?",
            answer: "To add your Company, Tax ID (VAT), and Address to be included in your invoices, go to the \"Account\" page in your User Admin Panel. You can find it by clicking on your avatar in the upper right-hand corner and selecting \"Settings\" from the drop-down menu. Then, navigate to the \"Billing\" tab, enter your Company name, select the Tax ID type, input the number, and click \"Save.\""
        },
        qr4: {
            question: "Are you making a refund?",
            answer: "We refund money if you have not used your credits. Contact us via Intercom or email for assistance."
        },
        qr5: {
            question: "Do you retain or share my data?",
            answer: "We extract email addresses from your uploaded data and add the results to your sheet after verification. You can permanently delete the data from our servers via the user Admin Panel or API, and it will be automatically removed after 60 days. Please read about our Privacy Policy here."
        },
        qr6: {
            question: "Do you format the original sheets of the files I upload?",
            answer: "After verification, we’ll add validation result columns directly to your CSV file, preserving and maintaining your original data. No one has access to your data."
        },
        qr7: {
            question: "Do email credits for Pay-as-You-Go packages expire?",
            answer: "Pay-as-You-Go packages – Purchase only the credits you need. You can choose a preset package or enter the exact number of credits you require."
        }
    },
    qc1: {
        qr0: {
            question: "Do email credits for Subscribe packages expire?",
            answer: "Subscription packages – Credits never expire, and we offer a 15% discount. You can select a preset package or enter the required number of credits, rounded to the nearest 1,000. For example, 15,634 emails will be rounded up to 16,000."
        },
        qr1: {
            question: "Can I change my subscription plan later?",
            answer: "Yes, you can update your subscription at any time by increasing or decreasing your credit package, or cancel the current plan. Credits never expire."
        },
        qr2: {
            question: "How fast does your Email Verifier service?",
            answer: "It depends on factors such as list size, quality, email provider type, and the speed of remote email servers. We use the best tools and practices to guarantee quick list processing and are fast enough to handle any volume of emails."
        },
        qr3: {
            question: "Do you provide an API?",
            answer: "We provide a Single API, Bulk API, and Batch API, along with a webhook and JS Widget for our email verification service, with detailed reports for each API key. Read more about our APIs here."
        },
        qr4: {
            question: "Do you send messages to all uploaded email addresses for verification?",
            answer: "No, we do not send messages to uploaded emails for verification. Proofy complies with anti-spam laws and GDPR."
        },
        qr5: {
            question: "How accurate is Proofy?",
            answer: "Proofy achieves close to 99%+ accuracy in email verification without sending actual emails, ensuring reliable and secure results."
        },
        qr6: {
            question: "What payment methods are accepted?",
            answer: "We accept payments via Credit Card and invoices. For Enterprise clients, we offer flexible billing terms and additional payment methods, such as ACH or Wire Transfer. Please contact us through our online chat or at support@proofy.io for further assistance."
        },
        qr7: {
            question: "Upload Files",
            answer: "Proofy's bulk email validator accepts .txt and .csv file formats up to 65 MB. When you upload a file, Proofy automatically scans it. The file will be rejected if an incorrect format, missing email columns, or a size exceeding the limit is detected. Our system will attempt to determine the email column in your file. If you're having trouble uploading, please contact us via Intercom or email for assistance."
        },
    },
};
