import React, {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {addStripeProduct, hideStripeProductAddModal} from "../../../services/action-creators";
import {Button, Form, Modal} from "react-bootstrap";

const ProductAddModal = () => {
    const dispatch = useDispatch();
    const { showStripeProductAddModal, tags, tagsLoading } = useSelector((state) => state.admin);
    const handleClose = () => dispatch(hideStripeProductAddModal());

    const [tag, setTag] = useState(tags[0].alias || "");
    const [name, setName] = useState("");
    const [productId, setProductId] = useState("");

    const handleAdd = () => {
        if (tag && name && productId) {
            dispatch(addStripeProduct({tag, name, productId}));
            setName("");
            setProductId("");
        }
    };

    return (
        <Modal centered
               show={showStripeProductAddModal}
               onHide={handleClose}
               className='modal-clean-delete'>
            <button onClick={handleClose} type="button" className="btn-close btn-close-absolute" aria-label="Close"/>
            <div className="delete-file-modal">
                <div className="dfm-header">
                    Add New Stripe Product
                </div>

                {!tagsLoading && <Form.Group className="mt-4 mb-2">
                    <Form.Label className="app-application-name">Tag</Form.Label>
                    <Form.Select value={tag}
                                 onChange={(e) => setTag(e.target.value)}>
                        {tags.map((t) => <option key={t.alias} value={t.alias}>{t.name}</option>)}
                    </Form.Select>
                </Form.Group>}
                <Form.Group className="mb-2">
                    <Form.Label className="app-application-name">Name</Form.Label>
                    <Form.Control className="ca-input-name"
                                  value={name}
                                  onChange={(e) => setName(e.target.value)}
                                  type="name"
                    />
                </Form.Group>
                <Form.Group className="ca-form-row">
                    <Form.Label className="app-application-name">Id</Form.Label>
                    <Form.Control className="ca-input-name"
                                  value={productId}
                                  onChange={(e) => {setProductId(e.target.value)}}
                                  type="productId"
                    />
                </Form.Group>

                <div className="dfm-footer">
                    <Button variant="outline-secondary" onClick={handleClose}>Cancel</Button>
                    <Button variant="success"
                            onClick={handleAdd}
                            disabled={!tag || !name || !productId}>Add</Button>
                </div>
            </div>
        </Modal>
    );

};

export default ProductAddModal;
