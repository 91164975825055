export const usage = {
    overall: {
        img: 'images/appUsage/total_requests.svg',
        header: 'Overall Usage',
        color: '#82ca9d'
    },
    bulk: {
        img: 'images/appUsage/bulk_requests.svg',
        header: 'Bulk Usage',
        color: '#8884d8'
    },
    single: {
        img: 'images/appUsage/single_requests.svg',
        header: 'Single Usage',
        color: '#d87ebe'
    }
};