import { applyMiddleware, createStore, compose } from 'redux';
import thunk from 'redux-thunk';
import reducers from './reducers';
import jwt from 'jsonwebtoken';
import moment from "moment";

export const isValidToken = (token) => {
    let decoded = jwt.decode(token);
    if (decoded === null) return null;
    return new Date(decoded.exp * 1000) > new Date() ? decoded : null;
};

const createRootReducer = () => reducers;

const getUrlPath = () => {
    const isUserAuthorized = isValidToken(localStorage.getItem('USER-TOKEN'));

    if (!isUserAuthorized) {
        return;
    }

    let resultPath = 'bulk';
    const possiblePaths = [
        'bulk',
        'single',
        // 'sync',
        'apps',
        'account',
        'payment',
        'checkout-success',
        'payment-method-add-success',
        'checkout-cancel',
        'adminpanel',
        'paymentHistory',
        'appUsage',
        'createApp',
        'usage'
    ];
    const loc = new URL(window.location.href);
    const pathname = loc.pathname;
    if (!pathname) {
        window.location.href = `/${resultPath}`;
    }

    const pathparts = pathname.split('/');
    if (possiblePaths.includes(pathparts[1])) {
        resultPath = pathparts[1];
    } else {
        window.location.href = `/${resultPath}`;
    }

    return resultPath;
};

const defaultStartDate = () => {
    return moment().subtract(7, 'days');
}

const defaultEndDate = () => {
    return moment();
}

const initState = {
    authentication: {
        currentUser: isValidToken(localStorage.getItem('USER-TOKEN'))
            ? JSON.parse(localStorage.getItem('USER'))['user']
            : null,
        token: isValidToken(localStorage.getItem('USER-TOKEN'))
            ? localStorage.getItem('USER-TOKEN')
            : null,
        error: '',
        loading: false,
        syncStarted: false,
        syncError: '',
        signUpErrors: null,
        isAuthenticated: localStorage.getItem('USER-TOKEN') ? true : false,
        isUpdateUserLoading: false
    },
    navigation: {
        activeTab: getUrlPath(),
        showModal: false,
        pageAlerts: [
            // {
            //     key: 'test1',
            //     onPages: ['apps',],//'all'],
            //     header: 'test1',
            //     message: 'test message',
            //     style: 'warning', //info, danger
            //     buttons: [
            //         {
            //             type: 'close',
            //             text: 'Close'
            //         }
            //     ]
            // },
            // {
            //     key: 'test2',
            //     onPages: ['apps'],
            //     header: 'test2',
            //     message: 'test2 message',
            //     style: 'danger', //info, danger
            //     buttons: [
            //         {
            //             type: 'close',
            //             text: 'Close'
            //         },
            //         {
            //             type: 'custom',
            //             text: 'Custom'
            //         },
            //     ]
            // }
        ],
    },
    admin: {
        activeTab: 'clients',
        chosenId: null,
        showClientBlockModal: false,
    },
    account: {
        activeTab: 'yourProfile',
        showPaymentDeleteModal: false,
        paymentMethods: null,
        paymentMethodsLoading: true,
        selectedId: null,
        selectedName: null,
        subscriptionsList: null,
        subscriptionsLoading: false,
        subscriptionActive: null,
        subscriptionActiveLoading: true,
        subscriptionCancelLoading: false,
        subscriptionContinueLoading: false,
    },
    file: {
        fileName: '',
        displayFileName: '',
        signedUrl: '',
        failure: null,
        filesLoading: false,
        filesLoaded: false,
        files: [],
        total: 0,
        exports: [],
        fileRequestsLoading: false,
        fileRequestsLoaded: false,
        fileRequests: {},
        selectedName: null,
        selectedId: null,
        showDownloadModal: false,
        showDeleteModal: false,
        showPurchaseModal: false,
        presetSelected: 'deliverable',
        isFileUploading: false,
        progress: 0,
    },
    email: {
        failure: null,
        emailsLoading: false,
        emailsLoaded: false,
        emails: [],
        total: 0,
        uploadingEmails: [],
        uploadingError: ''
    },
    users: {
        failure: null,
        usersLoading: false,
        usersLoaded: false,
        users: [],
        total: 0,
        userTransactionsLoading: false,
        userTransactionsLoaded: false,
        userTransactions: {},
    },
    apps: {
        isCreatingNewApp: false,
        appType: '',
        appFormData: {},
        failure: null,
        appsLoading: false,
        appsLoaded: false,
        apps: {},
        selectedAppId: null,
        selectedAppData: {},
    },
    appUsageDetails: {
        chart: [],
        details: {
            events: [],
            count: 0
        },
        auStartDate: defaultStartDate(),
        auEndDate: defaultEndDate(),
        auIsChangedDate: false,
        auDetails: [],
        showRefreshKeyModal: false,
        showDeleteModal: false,
        showRefreshModal: false,
        selectedName: null,
        selectedId: null,
        enabled: {
            total: false,
            single: false,
            bulk: false,
            other: false,
            unknown: false,
            invalid: false,
            risky: false,
            valid: false,
        },
        apiKeyRefreshLoading: false,
        getAppUsageDetailsLoading: false,
    },
    usageDetails: {
        chart: [],
        details: {
            events: [],
            count: 0
        }
    },
    paymentDetails: {
        phdStartDate: defaultStartDate(),
        phdEndDate: defaultEndDate(),
        phdIsChangedDate: false,
        phdDetails: {},
        pudStartDate: defaultStartDate(),
        pudEndDate: defaultEndDate(),
        pudIsChangedDate: false,
        pudDetails: [],
    },
};

export default function makeStore(initialState = initState) {
    let composeEnhancers = compose;
    const middlewares = [thunk];

    if (process.env.NODE_ENV === 'development') {
        composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
            window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({ trace: true, traceLimit: 5 });
    }

    const store = createStore(
        createRootReducer(),
        initialState,
        composeEnhancers(applyMiddleware(...middlewares))
    );

    if (module.hot) {
        module.hot.accept('./reducers', () => {
            const nextReducer = require('./reducers').default;
            store.replaceReducer(nextReducer);
        });
    }
    return store;
}
