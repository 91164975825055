export * from '../common-action-types';

export const CLEAR_USERS = "CLEAR_USERS";

export const GET_USERS_REQUEST = "GET_USERS_REQUEST";
export const GET_USERS_FAILURE = "GET_USERS_FAILURE";
export const GET_USERS_SUCCESS = "GET_USERS_SUCCESS";

export const GET_USER_TRANSACTIONS_REQUEST = "GET_USER_TRANSACTIONS_REQUEST";
export const GET_USER_TRANSACTIONS_SUCCESS = "GET_USER_TRANSACTIONS_SUCCESS";
export const GET_USER_TRANSACTIONS_FAILURE = "GET_USER_TRANSACTIONS_FAILURE";
