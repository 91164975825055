import React from 'react'
import { useSelector } from "react-redux";
import AppUsageDefaultContent from "./contents/AppUsageDefaultContent";
import AppUsageJsContent from "./contents/AppUsageJsContent";
import AppUsageWebhookContent from "./contents/AppUsageWebhookContent";
import AppUsageZapierContent from './contents/AppUsageZapierContent';

const AppUsageContent = () => {
    const { apps, selectedAppId } = useSelector((state) => state.apps);
    switch(apps[selectedAppId].type) {
        case '':
            return (<></>);
        case 'js':
            return (<AppUsageJsContent/>);
        case 'webhook':
            return (<AppUsageWebhookContent/>);
        case 'zappier':
            return (<AppUsageZapierContent/>);
        default:
            return (<AppUsageDefaultContent/>);
    }
};

export default AppUsageContent