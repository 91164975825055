import React, {useEffect, useState} from 'react';
import useQuery from "../../utils/hooks/useQuery";
import API from "../../utils/httpAPI";

const PaymentMethodSuccessCallback = () => {

    let query = useQuery();
    const sid = query.get('sid');

    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        successPaymentMethodAdd(sid);
    }, [sid]);

    const successPaymentMethodAdd = async (sid) => {

        try {
            const {data} = await API({method: "post", url: "/payment/confirm-payment-method", data: {
                sid: sid,
            }});

            if (data?.status === 'ok') {
                window.location.href = "/account/paymentMethods";
            }

        } catch (err) {
            // TODO: Show error
            console.log(err);
        }

        setIsLoading(false);
    }

    return isLoading ? (
        <div>
            <h2>Please wait..</h2>
        </div>
    ) : (<div/>)
};

export default PaymentMethodSuccessCallback
