export const getAlert = (type, message, data) => {
    const defaultAlert = {
        key: `${type}-${Date.now()}`,
        style: 'danger', //info, danger
        buttons: [
            {
                type: 'close',
                text: 'Close'
            }
        ]
    };
    const customAlert = getCustomAlert(type, message, data);
    const result = {
        ...defaultAlert,
        ...customAlert
    };
    // console.error('ALERT REQ:', type, message, data);
    // console.error('ALERT RES:', result);
    return result;
};

const getCustomAlert = (type, message, data) => {
    if (type === 'chooseFile') {
        return {
            onPages: ['clean'],
            header: `Only CSV, TXT files of size less then ${data.maxSize} bytes accepted`,
            message: `Your file size is ${data.size} bytes, has ${data.type} type and .${data.ext} extension : ${data.name}. Please upload correct file type.`,
        };
    }
    if (type === 'fileUpload') {
        return {
            onPages: ['clean'],
            header: `Can't upload file... ${message}`,
            message: 'Try again later or contact support',
        };
    }

    if (type === 'fileClean' && message=== 'Balance is low') {
        return {
            onPages: ['clean'],
            header: message,
            message: message,
        };
    }

    if (type === 'changePassword' && message=== 'Password changed successfully!') {
        return {
            onPages: ['account'],
            header: message,
            message: message,
            style: 'success'
        };
    }

    if (type === 'changePassword') {
        return {
            onPages: ['account'],
            header: message,
            message: message,
        };
    }

    if (type === 'deleteAccount' && message=== 'Your account will be deleted soon!') {
        return {
            onPages: ['account'],
            header: message,
            message: message,
            style: 'success'
        };
    }

    if (type === 'deleteAccount') {
        return {
            onPages: ['account'],
            header: message,
            message: message,
        };
    }

};