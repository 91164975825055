import React, {useEffect, useState} from 'react';
import API from "../../utils/httpAPI";
import {Button, Spinner} from "react-bootstrap";
import PaymentDeleteModal from "./PaymentDeleteModal";
import {
    getPaymentMethods,
    setDefaultPaymentMethod,
    showPaymentDeleteModal
} from "../../services/account/action-creators";
import {useDispatch, useSelector} from "react-redux";
import PaymentMethodRow from "./components/PaymentMethodRow";
import {useMediaQuery} from "../../utils/hooks/useMediaQuery";
import {MOBILE_VIEW_WIDTH} from "../../config/mobile";

const PaymentMethods = () => {
    const dispatch = useDispatch();

    const { paymentMethods, paymentMethodsLoading } = useSelector((state) => state.account);

    const [isAddInProgress, setIsAddInProgress] = useState(false);

    useEffect(() => {
        dispatch(getPaymentMethods());
    }, []);

    const onAddPaymentClick = async () => {
        setIsAddInProgress(true);

        const {data} = await API({method: "post", url: "/payment/create-payment-method"});

        if (data.url) {
            window.open(data.url, '_blank');
        }

        setIsAddInProgress(false);
    }

    const addPaymentBtnText = useMediaQuery(`(max-width: ${MOBILE_VIEW_WIDTH}px)`)
        ? 'Add Payment'
        : '+ Add Payment Method';

    return (
        <>
            <PaymentDeleteModal />
            <div className="account_content_section pt18">
                <div className="payment-methods-header">
                    <div className="block-heading">Payment Methods</div>
                    <Button variant="success" disabled={isAddInProgress} onClick={onAddPaymentClick}>
                        {addPaymentBtnText}
                        {isAddInProgress && (
                            <Spinner
                                className="ms-3"
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                            />
                        )}
                    </Button>
                </div>
                <div className="payment-methods-line"/>
                <div className="account-payment-methods-box">
                    {paymentMethodsLoading && <div className="loadingFiles"/>}
                    {!paymentMethodsLoading &&
                        <PaymentMethodsList list={paymentMethods} />}
                </div>
            </div>
        </>
    )
};

export const PaymentMethodsList = ({list: list, onRemovePaymentMethod: onRemovePaymentMethod}) => {
    const dispatch = useDispatch();

    const handleDeleteClick = (id, name) => {
        dispatch(showPaymentDeleteModal(id, name));
    }

    const setDefaultClickHandler = (id) => {
        dispatch(setDefaultPaymentMethod(id));
    }

    return (
        <table className="payment-methods-table">
            <tbody>
            {
                list.length > 0 ?
                    list.map((item) => {
                        return (
                            <PaymentMethodRow
                                key={item.id}
                                item={item}
                                remove={handleDeleteClick}
                                setDefault={setDefaultClickHandler}
                            />
                        )
                    }) :
                    <tr><td className={"text-center"} colSpan={5}>No payment methods</td></tr>
            }
            </tbody>
        </table>
    );
}

export default PaymentMethods;
