import React, { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { Form, Button, Alert, InputGroup, Spinner } from 'react-bootstrap';
import jwt from 'jsonwebtoken';

import {
    changePasswordUsingResetToken,
    chooseSignIn,
} from '../../services/action-creators';
import useStateWithValidation from '../../utils/hooks/useStateWithValidation';
import { isValidPassword } from '../../utils/helpers';
import {MAIN_PROOFY_URL} from "../../config/proofy-links";

const isRepeatPasswordInvalid = (passwordRepeat, password) => {
    return passwordRepeat !== password;
};

const ChangePassword = () => {
    const dispatch = useDispatch();
    const { resetPasswordToken } = useParams();
    const history = useHistory();

    const { loading, error, changePasswordSuccess } = useSelector(
        (state) => state.authentication
    );

    const emailRef = useRef('');
    const [password, setPassword, { isInvalid: isPasswordInvalid }] =
        useStateWithValidation({
            initialState: '',
            isInvalidFn: (password) =>
                !isValidPassword(password, emailRef.current).valid,
        });
    const [passwordEyeShown, setPasswordEyeShown] = useState(true);
    const [passwordInputType, setPasswordInputType] = useState('password');

    const [
        passwordRepeat,
        setPasswordRepeat,
        { isInvalid: isPasswordRepeatInvalid },
    ] = useStateWithValidation({
        initialState: '',
        isInvalidFn: (passwordRepeat) =>
            isRepeatPasswordInvalid(passwordRepeat, password),
    });
    const [passwordRepeatEyeShown, setPasswordRepeatEyeShown] = useState(true);
    const [repeatPasswordInputType, setRepeatPasswordInputType] =
        useState('password');

    const redirectToSignIn = () => chooseSignIn(history);

    const handleInputChange = (setFn) => (event) => setFn(event.target.value);

    const handleChangePasswordClick = (event) => {
        event.preventDefault();

        if (password && !isValidPassword(password).valid) return;
        if (isRepeatPasswordInvalid(passwordRepeat, password)) return;

        dispatch(
            changePasswordUsingResetToken(resetPasswordToken, password)
        );
    };

    const handleEyeClick = (inputType, setInputType, setEyeShown) => () => {
        if (inputType === 'password') {
            setInputType('text');
            setEyeShown(false);

            return;
        }

        setInputType('password');
        setEyeShown(true);
    };

    const handleProofyClick = () => {
        window.open(MAIN_PROOFY_URL, '_blank').focus();
    }

    useEffect(() => {
        const payload = jwt.decode(resetPasswordToken);
        emailRef.current = payload.email;
    }, [resetPasswordToken]);


    if (changePasswordSuccess) {
        return (
            <div className="signin-wrapper">
                <div className="signin-logo" onClick={handleProofyClick}>
                    <img src="/images/new-logo.svg" alt="logo"/>
                </div>
                <div className="signup-success-content">
                    <img
                        className="signup-icon"
                        src="../images/success.svg"
                        alt="Success icon"
                    />

                    <h2 className="signup-success-title">Success!</h2>
                    <p className="signin-text">
                        Your password has been changed
                    </p>

                    <Button
                        className="signup-success-button"
                        variant="success"
                        onClick={redirectToSignIn}
                    >
                        Go to sign in
                    </Button>
                </div>
                <div className="sign-in-copy">Copyright ©2025 Proofy.io. All Rights Reserved</div>
            </div>
        );
    }


    return (
        <div className="signin-wrapper">

            <div className="signin-logo">
                <img src="/images/new-logo.svg" alt="logo"/>
            </div>


            <div className="signin-card">

                <div className="signin-card-body flex-column">
                    <div className="signin-header">Change Password</div>

                    <Form className="signin-form">
                        <Form.Group className="mb-3" controlId="formBasicPassword">
                            <Form.Label>Password</Form.Label>
                            <div className="password-input-group">
                                <InputGroup hasValidation>
                                    <Form.Control
                                        className="signin-password-control"
                                        type={passwordInputType}
                                        onChange={handleInputChange(setPassword)}
                                        value={password}
                                        isInvalid={isPasswordInvalid}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Please provide a valid password.
                                    </Form.Control.Feedback>
                                </InputGroup>

                                <img
                                    src={`../images/${
                                        passwordEyeShown ? 'eye-slash' : 'eye'
                                    }.svg`}
                                    alt=""
                                    onClick={handleEyeClick(
                                        passwordInputType,
                                        setPasswordInputType,
                                        setPasswordEyeShown
                                    )}
                                    className="eye"
                                />
                            </div>
                        </Form.Group>

                        <Form.Group
                            className="mb-3"
                            controlId="formBasicRepeatPassword"
                        >
                            <Form.Label>Repeat Password</Form.Label>
                            <div className="password-input-group">
                                <InputGroup hasValidation>
                                    <Form.Control
                                        type={repeatPasswordInputType}
                                        onChange={handleInputChange(
                                            setPasswordRepeat
                                        )}
                                        value={passwordRepeat}
                                        isInvalid={isPasswordRepeatInvalid}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Passwords should match.
                                    </Form.Control.Feedback>
                                </InputGroup>
                                <img
                                    src={`../images/${
                                        passwordRepeatEyeShown ? 'eye-slash' : 'eye'
                                    }.svg`}
                                    onClick={handleEyeClick(
                                        repeatPasswordInputType,
                                        setRepeatPasswordInputType,
                                        setPasswordRepeatEyeShown
                                    )}
                                    className="eye"
                                />
                            </div>
                        </Form.Group>

                        <div className="signin-text-muted password-description">
                            *Password must be at least 8 characters long
                        </div>

                        {error && (
                            <Alert className="mb-2 py-2 px-3" variant="danger">
                                {error}
                            </Alert>
                        )}

                        <Button
                            className="signin-button"
                            onClick={handleChangePasswordClick}
                            variant="success"
                            type="submit"
                            disabled={loading || changePasswordSuccess}
                        >
                            Change Password
                            {loading && (
                                <Spinner
                                    className="ms-3"
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                />
                            )}
                        </Button>
                    </Form>

                    <a className="signin-comment-link align-self-end" onClick={redirectToSignIn}>
                        Sign in
                    </a>
                </div>
            </div>

            <div className="sign-in-copy">Copyright ©2025 Proofy.io. All Rights Reserved</div>
        </div>
    );
};

export default ChangePassword;
