import React, {useEffect, useState} from 'react'
import { showModal, loadFiles } from "../../services/action-creators";
import { useDispatch, useSelector } from "react-redux";
import { Button } from 'react-bootstrap'
import CleanFile from './CleanFile.js';
import {TablePagination} from "@material-ui/core";
import {usePagingListSync} from "../../utils/hooks/usePagingListSync";
import {useHistory} from "react-router-dom";

const filters = [
    {name: "All", alias: "all"},
    {name: "Processing", alias: "processing"},
    {name: "Completed", alias: "completed"},
    {name: "Unverified", alias: "unverified"},
];

const PageContent = () => {
    const { files, total, filesLoaded, filesLoading } = useSelector((state) => state.file);

    const { params, updateParams, paramsFirstUpdated } = usePagingListSync(
        {
            search: '',
            type: 'all',
            page: 1,
            pageSize: 10
        },
        {
            type: (value) => filters.filter(f => f.alias === value),
            page: (value) => Number(value) >= 1,
            pageSize: (value) => Number(value) === 10 || Number(value) === 25 || Number(value) === 50 && Number(value) === 100
        }
    );

    const [lastSearched, setLastSearched] = useState('');
    const [debouncedSearch, setDebouncedSearch] = useState(params.search);

    const history = useHistory();
    const dispatch = useDispatch();
    const handleShow = () => dispatch(showModal());

    useEffect(() => {
        if (params.search === '') {
            setDebouncedSearch(params.search);
            return;
        }
        const handler = setTimeout(() => {
            if (lastSearched !== params.search) {
                setDebouncedSearch(params.search);
            }
        }, 500);

        return () => clearTimeout(handler);
    }, [params.search]);

    useEffect(() => {
        if (!filesLoading) {
            dispatch(
                loadFiles(debouncedSearch, params.type, params.page, params.pageSize)
            );
            setLastSearched(params.search);
        }
    }, [params.page, params.pageSize, params.type, debouncedSearch, dispatch]);

    useEffect(() => {
        const ms = files.map(file => {
            if (file.failed) {
                return 0;
            }
            
            if (!file.parseResult) {
                return 1000;
            }

            if (file.isCleanStarted && !file.isCleaned) {
                return 5000;
            }

            return 0;
        }).reduce((max, curr) => {
            return Math.max(max, curr);
        }, 0);

        if (ms === 0) {
            return;
        }

        const timeout = setTimeout(() => {
            dispatch(loadFiles(params.search, params.type, params.page, params.pageSize, true));
        }, ms);

        return () => clearTimeout(timeout);
    }, [files]);

    const handleSearchChange = (e) => {
        updateParams({ search: e.target.value, page: 1 });
    };

    const handleTypeChange = (type) => {
        updateParams({ type, page: 1 });
    };


    const handlePageChange = (event, newPage) => {
        const p = newPage < 1 ? 1 : newPage + 1;
        updateParams({page: p});
    };

    const handleChangeRowsPerPage = (event) => {
        updateParams({
            page: 1,
            pageSize: parseInt(event.target.value, 10),
        });
    };

    const handleSingleClick = () => {
        history.push('/single');
    }

    if (files.length === 0 && !filesLoading && filesLoaded && !paramsFirstUpdated) {
        return(
            <div className="page-green">
                <div className="clean-header">
                    <h1>Welcome to Proofy</h1>
                </div>
                <div className="clean-subheader">
                    <h3>Upload your first file</h3>
                </div>
                <div className="clean-upload-button">
                    <Button variant="success" className="add-list" onClick={handleShow}>+ Add File</Button>
                </div>
                <div className="clean-subheader mt-3">
                    or visit <div className="proofy-link underline ms-1" onClick={handleSingleClick}>single verification</div>
                </div>
            </div>
        );
    }

    return (
        <>
            {filesLoaded && <div className="cleanFiles">
            <div className="clean_files_head">
                    <div className="search">
                        <img className="search-icon" src={"images/search.svg"} alt=""/>
                        <input type="text" name="search" value={params.search} onChange={handleSearchChange}
                               placeholder="Search by ID or file name"/>
                    </div>

                    <div className="clean_files_filters">
                        {
                            filters.map(filter => {
                                return <div className={`clean_files_filter ${params.type === filter.alias ? 'current' : ''}`}
                                            key={filter.alias}
                                            onClick={() => handleTypeChange(filter.alias)}>{filter.name}</div>
                            })
                        }
                    </div>
                </div>
                {filesLoading && <div className="cleanFiles loadingFiles" />}
                {!filesLoading &&
                    <div className="clean-files-list">
                        {files.length ?
                            (<>
                                {
                                    files.map((file) => {
                                        return (
                                            <CleanFile key={file.id} data={file}/>)
                                    })
                                }

                                <TablePagination
                                    component="div"
                                    count={total}
                                    page={Number(params.page) - 1}
                                    rowsPerPage={Number(params.pageSize)}
                                    onPageChange={handlePageChange}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                />
                            </>) :
                            (<div className="no-results-section">No files found</div>)
                        }
                        <div className="light-small-text">
                            Verification data is automatically deleted after 60 days.
                        </div>
                    </div>}
            </div>}
        </>
    )

};

export default PageContent
