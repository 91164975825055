import React, {useEffect} from 'react'
import PageMenu from '../component/PageMenu'
import PageAlert from '../component/PageAlert'
import AppUsageContent from './appUsage/AppUsageContent'
import AppSettingsModal from "./appUsage/AppSettingsModal";
import AppDeleteModal from "./appUsage/AppDeleteModal";
import AppRefreshApiKeyModal from "./appUsage/AppRefreshApiKeyModal";
import {useHistory, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {loadApps, selectCreatedApp} from "../services/apps/action-creators";

const AppUsage = () => {
    const { id } = useParams();
    console.log('id', id);

    const dispatch = useDispatch();
    const history = useHistory();

    const { apps, appsLoaded, appsLoading, failure, selectedAppId } = useSelector((state) => state.apps);

    useEffect(() => {
        if (!appsLoaded) {
            if (failure) {
                // TODO: Show Error Modal
            }
            if (!appsLoading) {
                dispatch(loadApps());
            }
        }
        if (appsLoaded) {
            const selectedApp = apps[id];
            if (selectedApp) {
                dispatch(selectCreatedApp({ id, data: selectedApp }));
            } else {
                history.push('/apps');
            }
        }
    }, [appsLoaded]);


    return (
        <>
            {failure &&(<div> failure : {JSON.stringify(failure)}</div>)}
            {appsLoading && (<div className="cleanFiles"> loading ... </div>)}
            {selectedAppId && apps && (
                <div className="page">
                    <PageMenu activeTabName="appUsage"/>
                    {/*<PageAlert/>*/}
                    <AppUsageContent/>
                    <AppSettingsModal/>
                    <AppDeleteModal/>
                    <AppRefreshApiKeyModal/>
                 </div>
            )}
        </>
    )
};

export default AppUsage
