import { EMAILS_UPLOAD_SUCCESS } from '../email/action-types';
import {
    SYNC_REQUEST,
    SYNC_SUCCESS,
    SYNC_FAILURE,
    SIGN_IN_FAILURE,
    SIGN_IN_REQUEST,
    SIGN_IN_SUCCESS,
    SIGN_UP_FAILURE,
    SIGN_UP_REQUEST,
    SIGN_UP_SUCCESS,
    RESET_SUCCESS_SIGN_UP_STATUS,
    CONFIRM_EMAIL_REQUEST,
    CONFIRM_EMAIL_SUCCESS,
    CONFIRM_EMAIL_FAILURE,
    RESET_EMAIL_CONFIRMATION_ERROR,
    SIGN_OUT_FAILURE,
    SIGN_OUT_REQUEST,
    SIGN_OUT_SUCCESS,
    UPDATE_USER_CREDITS,
    RESET_PASSWORD_REQUEST,
    RESET_PASSWORD_SUCCESS,
    RESET_RESET_PASSWORD_SUCCESS_STATUS,
    RESET_PASSWORD_FAILURE,
    CHOOSE_SIGN_IN,
    CHOOSE_REGISTRATION,
    AUTH_VALIDATION_ERROR,
    CHOOSE_RESET_PASSWORD,
    CHANGE_PASSWORD_REQUEST,
    CHANGE_PASSWORD_SUCCESS,
    CHANGE_PASSWORD_FAILURE,
    UPDATE_USER_INFO_REQUEST,
    UPDATE_USER_INFO_SUCCESS,
    UPDATE_USER_INFO_FAILURE,
} from './action-types';

const updateStateCredits = (state, userCredits) => {
    state.currentUser.credits = userCredits;
    const user = JSON.parse(localStorage.getItem('USER'))['user'];
    // console.error('user', user);
    if (user.credits !== userCredits) {
        user.credits = userCredits;
        localStorage.setItem('USER', JSON.stringify({ user }));
    }
};

const authReducer = function (state, action) {
    switch (action.type) {
        case AUTH_VALIDATION_ERROR:
            return {
                ...state,
                error: action.payload,
            };

        case RESET_PASSWORD_REQUEST:
        case SIGN_IN_REQUEST:
        case SIGN_UP_REQUEST:
        case SIGN_OUT_REQUEST:
            return {
                ...state,
                loading: true,
                isAuthenticated: false,
                error: '',
                syncError: '',
                signUpErrors: null
            };

        case RESET_PASSWORD_SUCCESS:
            return {
                ...state,
                loading: false,
                isAuthenticated: false,
                error: '',
                resetPasswordSuccessStatus: true,
            };

        case RESET_RESET_PASSWORD_SUCCESS_STATUS:
            return {
                ...state,
                resetPasswordSuccessStatus: false,
            };

        case SIGN_IN_FAILURE:
        case SIGN_OUT_FAILURE:
        case RESET_PASSWORD_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload,
                currentUser: null,
                isAuthenticated: false,
                syncError: '',
            };

        case SIGN_UP_FAILURE:
            return {
               ...state,
                loading: false,
                signUpErrors: action.payload,
                isAuthenticated: false,
            };

        case SIGN_UP_SUCCESS:
            return {
                ...state,
                loading: false,
                successSignUp: true,
            };

        case RESET_SUCCESS_SIGN_UP_STATUS:
            return {
                ...state,
                successSignUp: false,
            };

        case CONFIRM_EMAIL_REQUEST:
            return {
                ...state,
                loading: true,
                emailConfirmed: false,
            };

        case CONFIRM_EMAIL_SUCCESS:
            return {
                ...state,
                loading: false,
                emailConfirmed: true,
            };

        case CONFIRM_EMAIL_FAILURE:
            return {
                ...state,
                loading: false,
                emailConfirmationError: action.payload.error,
            };

        case RESET_EMAIL_CONFIRMATION_ERROR:
            return {
                ...state,
                emailConfirmationError: '',
            };

        case SIGN_IN_SUCCESS:
            localStorage.setItem('USER-TOKEN', action.payload.token);
            localStorage.setItem('USER-ID', action.payload.user._id);
            localStorage.setItem(
                'USER',
                JSON.stringify({user: { ...action.payload.user, hash: action.payload.hash }})
            );
            return {
                ...state,
                loading: false,
                token: action.payload.token,
                currentUser: { ...action.payload.user, hash: action.payload.hash },
                isAuthenticated: true,
            };

        case SIGN_OUT_SUCCESS:
            localStorage.removeItem('USER-TOKEN');
            localStorage.removeItem('USER-ID');
            return {
                ...state,
                isAuthenticated: false,
                loading: false,
                currentUser: null,
                token: '',
            };

        case UPDATE_USER_INFO_REQUEST:
            return {
                ...state,
                isUpdateUserLoading: true
            };

        case UPDATE_USER_INFO_SUCCESS:
            updateStateCredits(state, action.payload.credits);
            localStorage.setItem(
                'USER',
                JSON.stringify({ user: action.payload.user })
            );
            return {
                ...state,
                currentUser: action.payload.user,
                isUpdateUserLoading: false
            };

        case UPDATE_USER_INFO_FAILURE:
            return {
                ...state,
                isUpdateUserLoading: false
            };

        case SYNC_REQUEST:
            return {
                ...state,
                syncStarted: true,
            };

        case SYNC_SUCCESS:
            updateStateCredits(state, action.payload.userCredits);
            return {
                ...state,
            };

        case SYNC_FAILURE:
            return {
                ...state,
                syncError: action.payload,
            };

        case UPDATE_USER_CREDITS:
            updateStateCredits(state, action.payload.userCredits);
            return {
                ...state,
            };

        case EMAILS_UPLOAD_SUCCESS:
            updateStateCredits(state, action.payload.userCredits);
            return {
                ...state,
            };

        case CHOOSE_RESET_PASSWORD:
        case CHOOSE_SIGN_IN:
        case CHOOSE_REGISTRATION:
            return {
                ...state,
                error: '',
                signUpErrors: null
            };

        case CHANGE_PASSWORD_REQUEST:
            return {
                loading: true,
                error: null,
                changePasswordSuccess: false,
            };

        case CHANGE_PASSWORD_SUCCESS:
            return {
                loading: false,
                changePasswordSuccess: true,
            };

        case CHANGE_PASSWORD_FAILURE:
            return {
                loading: false,
                error: action.payload.error,
                changePasswordSuccess: false,
            };

        default:
            return { ...state };
    }
};

export default authReducer;
