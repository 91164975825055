import React, {useState} from "react";
import { useDispatch, useSelector } from "react-redux";
import {Button, Alert, ProgressBar, OverlayTrigger} from "react-bootstrap";
import {
    cleanFile,
    cancelCleanFile,
    showDownloadModal,
    showDeleteModal,
    showPurchaseModal,
} from "../../services/action-creators"
import CleanFileChart from './CleanFileChart';
import { getDateString, getSmallId, getTimeString } from "../../utils/helpers";
import {truncate} from "lodash-es";
import Tooltip from 'react-bootstrap/Tooltip';
import {show as showIntercom} from "@intercom/messenger-js-sdk";
import {Default as Spinner} from 'react-spinners-css';


const CleanFile = (props) => {
    const dispatch = useDispatch();
    const { currentUser } = useSelector((state) => state.authentication);
    const {
        createdAt,
        fileName,
        displayFileName,
        parseResult,
        cleanResult,
        failed,
        id,
        isCleaned,
        isCleanStarted
    } = props.data;

    const name = displayFileName ?? fileName;

    const billableRecords = parseResult?.billable;
    const totalRecords = parseResult?.records;
    const duplicateRecords = parseResult?.duplicates;
    const badSyntaxRecords = parseResult?.badSyntax;
    const processedRecords = cleanResult?.processed;

    const progress = processedRecords ? Math.floor(100 * processedRecords/billableRecords) : 0;

    const handleCleanClick = () => {
        if (billableRecords > currentUser.credits) {
            dispatch(showPurchaseModal(id, name));
            return;
        }
        dispatch(cleanFile(id));
    };

    const handleCancelClick = () => {
        dispatch(cancelCleanFile(id));
    };

    const handleDownloadClick = () => {
        dispatch(showDownloadModal(id, name));
    };

    const handleDeleteClick = () => {
        dispatch(showDeleteModal(id, name));
    };

    const handleRequestHelpClick = () => {
        showIntercom();
    };

    const dateString = getDateString(new Date(createdAt));
    const timeString = getTimeString(new Date(createdAt));

    const getAnalyzeSection = () => {
        if (failed !== undefined) return (
            <div className="cf_fail_message_section">
                <div className="cf_fail_header">File issue</div>
                <div className="cf_fail_text">Could not parse the file. Please review and try again</div>
                <div className="cf_fail_buttons">
                    <Button variant="outline-danger" className="cf_btn cf_short_height_btn" onClick={handleDeleteClick}>
                        <img className="account-btn-icon" src = "images/clean/delete-list.svg" alt=""/>
                        <span>Delete</span>
                    </Button>
                    <Button variant="outline-success" className="cf_btn  cf_short_height_btn" onClick={handleRequestHelpClick}>
                        Request Help
                    </Button>
                </div>
            </div>
        );

        if (billableRecords === undefined) return (
            <div className="cf_fail_message_section">
                <div className="cf_fail_header">Please wait a bit</div>
                <ProgressBar className="parsing-progress" variant="success" animated now={100} />
                <div className="cf_fail_text">Your file is being parsed.</div>
                <div className="cf_fail_buttons">
                    <Button variant="outline-danger" className="cf_btn cf_short_height_btn" onClick={handleDeleteClick}>
                        <img className="account-btn-icon" src = "images/clean/delete-list.svg" alt=""/>
                        <span>Cancel</span>
                    </Button>
                    <Button variant="outline-success" className="cf_btn  cf_short_height_btn" onClick={handleRequestHelpClick}>
                        Request Help
                    </Button>
                </div>
            </div>
        );

        return (
            <div className="cf_validation_results">
                <CleanFileChart data={cleanResult} disabled={!isCleaned && !isCleanStarted}/>
            </div>
        )
    };


    const getCleanSection = () => {
        if (billableRecords === undefined) return (<></>);
        if (failed !== undefined) return (<></>);

        if (isCleaned) return (
            <div className="cf_cleaning">
                <div className="w-100">
                    <DownloadButton handleDownloadClick={handleDownloadClick}/>
                    <DownloadDescription/>
                </div>
                <Button variant="link" className="cf_btn cf_delete" onClick={handleDeleteClick}>
                    <img className="clean-btn-icon" src="/images/clean/delete-new.svg" alt=""/>
                    <span>Delete File</span>
                </Button>
            </div>
        );

        if (isCleanStarted) return (
            <div className="cf_cleaning">
                <Button variant="warning" className="cf_btn_first" disabled>
                    <div className="cf_processing_text">Processing</div>
                    <Spinner color="#ffffff" size={24} />
                </Button>
                <Alert variant="warning" className="cf_processing_warning mb-0">
                    Verification has started! Please wait while we process your list.
                </Alert>
                <ProgressBar className="cleaning-progress" variant="success" label={progress < 15 ? "" : `${progress}%`}
                             now={progress}/>
                <div className="cf_verified_title">Verified: {cleanResult?.processed} out
                    of {parseResult?.billable}</div>
                <Button variant="outline-secondary" className="cf_btn" onClick={handleCancelClick}>
                    Cancel
                </Button>
            </div>
        );

        return (
            <div className="cf_cleaning">
                <div className="w-100">
                    <Button variant="success" className="cf_btn cf_cleaning_btn" onClick={handleCleanClick}>
                        Proof
                    </Button>
                    <Alert variant="light" className="cf_alert">
                        Verification duration depends on file size, remote email servers, and IPS providers.
                    </Alert>
                </div>
                <Button variant="link" className="cf_btn cf_delete" onClick={handleDeleteClick}>
                    <img className="clean-btn-icon" src="/images/clean/delete-new.svg" alt=""/>
                    <span>Delete File</span>
                </Button>
            </div>
        );
    };

    const [tooltipText, setTooltipText] = useState('Copy');
    const [showTooltip, setShowTooltip] = useState(false);
    const [showTooltipName, setShowTooltipName] = useState(false);
    const [showIdTooltip, setShowIdTooltip] = useState(false);

    const getFileName = () => {
        return (
            <div className="cleanFile-summary-name">
                <OverlayTrigger
                    show={showTooltipName}
                    overlay={<Tooltip id="tooltip-disabled">{name}</Tooltip>}
                >
                    <div
                        className="cleanFile-file-title"
                        onMouseEnter={() => {
                            if (name.length > 35) setShowTooltipName(true);
                        }}
                        onMouseLeave={() => setShowTooltipName(false)}
                    >
                        {truncate(name, {length: 35})}
                    </div>
                </OverlayTrigger>
                <OverlayTrigger
                    show={showTooltip}
                    overlay={<Tooltip  id="tooltip-disabled">{tooltipText}</Tooltip>}
                >
                      <span className="d-inline-block"
                            onClick={() => {
                                navigator.clipboard.writeText(name).then(() => {
                                    setShowTooltip(false);
                                    setShowTooltip(true);
                                    setTooltipText('Copied');
                                });
                            }}
                            onMouseEnter={() => setShowTooltip(true)}
                            onMouseLeave={() => {
                                setShowTooltip(false);
                                setTimeout(() => {
                                    setTooltipText('Copy');
                                }, 200)
                            }}
                      >
                        <img className="copy-fileName-icon" src="/images/appUsage/btn-link.svg" alt=""/>
                      </span>
                </OverlayTrigger>
            </div>
        )
    }

    const getJobId = () => {
        return (
            <OverlayTrigger
                show={showIdTooltip}
                overlay={<Tooltip  id="tooltip-disabled">{tooltipText}</Tooltip>}
            >
                      <span className="d-inline-block"
                            onClick={() => {
                                navigator.clipboard.writeText(id).then(() => {
                                    setShowIdTooltip(false);
                                    setShowIdTooltip(true);
                                    setTooltipText('Copied');
                                });
                            }}
                            onMouseEnter={() => setShowIdTooltip(true)}
                            onMouseLeave={() => {
                                setShowIdTooltip(false);
                                setTimeout(() => {
                                    setTooltipText('Copy');
                                }, 200)
                            }}
                      >
                        <a className="cleanFile-job-id">{getSmallId(id)}</a>
                      </span>
            </OverlayTrigger>
        )
    }

    return (
        <div className="cleanFile">
            <div className="cleanFile-summary">
                { getFileName() }
                <div className="cleanFile-summary-row">
                    <div className="row-title">Created:</div>
                    <div className="row-value">{dateString} {timeString}</div>
                </div>
                <div className="cleanFile-summary-row">
                    <div className="row-title">File ID:</div>
                    <div className="row-value">
                        {getJobId()}
                    </div>
                </div>
                {totalRecords === undefined ?
                    (<></>) :
                    (
                        <div className="cleanFile-summary-row">
                            <div className="row-title">Emails total:</div>
                            <div className="row-value">{totalRecords}</div>
                        </div>
                    )}
                {billableRecords === undefined ?
                    (<></>) :
                    (
                        <div className="cleanFile-summary-row">
                            <div className="row-title">Emails for verify:</div>
                            <div className="row-value">{billableRecords}</div>
                        </div>
                    )}
                {duplicateRecords === undefined ?
                    (<></>) :
                    (
                        <div className="cleanFile-summary-row">
                            <div className="row-title">Duplicate Emails Found:</div>
                            <div className="row-value">{duplicateRecords}</div>
                        </div>
                    )}
                {badSyntaxRecords === undefined ?
                    (<></>) :
                    (
                        <div className="cleanFile-summary-row">
                            <div className="row-title">Bad Syntax Records Found:</div>
                            <div className="row-value">{badSyntaxRecords}</div>
                        </div>
                    )}
            </div>
            { getAnalyzeSection() }
            { getCleanSection() }
        </div>
    );
};

export default CleanFile;


const DownloadButton = ({handleDownloadClick}) => {
    return (
        <Button variant="success" className="cf_btn_first" onClick={handleDownloadClick}>
            Download
        </Button>
    );
}

const DownloadDescription = () => {
    return (
        <Alert variant="light" className="cf_alert">
            Verification complete! Click 'Download' to get your file.
        </Alert>
    )
}
