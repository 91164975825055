import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Spinner, Button } from 'react-bootstrap';
import { useHistory, useParams } from 'react-router-dom';

import {
    chooseSignIn,
    confirmEmail,
    resetEmailConfirmationError,
} from '../../services/action-creators';
import {MAIN_PROOFY_URL} from "../../config/proofy-links";

const ConfirmEmail = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { confirmationToken } = useParams();

    const { loading, emailConfirmed, emailConfirmationError } = useSelector(
        (state) => state.authentication
    );

    const handleRedirectToSignIn = () => dispatch(chooseSignIn(history));

    useEffect(() => {
        dispatch(confirmEmail(confirmationToken));

        return () => {
            dispatch(resetEmailConfirmationError());
        };
    }, []);

    const handleProofyClick = () => {
        window.open(MAIN_PROOFY_URL, '_blank').focus();
    }

    if (loading && !emailConfirmed) {
        return (
            <div className="signin-spinner-wrapper">
                <Spinner animation="border" role="status">
                    <span className="visually-hidden">Loading...</span>
                </Spinner>
            </div>
        );
    }

    if (emailConfirmed) {
        return (
            <div className="signin-wrapper">
                <div className="signin-logo" onClick={handleProofyClick}>
                    <img src="/images/new-logo.svg" alt="logo"/>
                </div>
                <div className="signup-success-content">
                    <img
                        className="signup-icon"
                        src="../images/success.svg"
                        alt="Success icon"
                    />

                    <h2 className="signup-success-title">Activated</h2>
                    <p className="signin-text">
                        Your account has been activated!
                    </p>

                    <Button
                        className="signup-success-button"
                        variant="success"
                        onClick={handleRedirectToSignIn}
                    >
                        Go to admin panel
                    </Button>
                </div>
                <div className="sign-in-copy">Copyright ©2025 Proofy.io. All Rights Reserved</div>
            </div>
        );
    }

    if (emailConfirmationError) {
        return (
            <div className="signin-wrapper">
                <div className="signin-logo">
                    <img src="/images/new-logo.svg" alt="logo"/>
                </div>
                <div className="signup-success-content">
                    <img
                        className="signup-icon"
                        src="../images/failure.svg"
                        alt="Success icon"
                    />

                    <h2 className="signup-success-title">Error</h2>
                    <p className="signin-text">
                        Failed to confirm registration
                    </p>

                    <Button
                        className="signup-success-button"
                        variant="success"
                        onClick={handleRedirectToSignIn}
                    >
                        Go to admin panel
                    </Button>
                </div>
                <div className="sign-in-copy">Copyright ©2025 Proofy.io. All Rights Reserved</div>
            </div>
        );
    }

    return null;
};

export default ConfirmEmail;
