import React from 'react';
import PaymentMethods from "./PaymentMethods";
import AccountInvoiceSettings from "./AccountInvoiceSettings";
import AccountSubscriptions from "./AccountSubscriptions";

const AccountBillingSettings= () => {
    return (
        <>
            <AccountSubscriptions />
            <PaymentMethods/>
            <AccountInvoiceSettings/>
        </>
    )
};

export default AccountBillingSettings;
