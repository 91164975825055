import React from 'react'
import CancelContent from "./stripeCheckout/CancelContent";

const StripeCheckoutCancel = () => {
    return (
        <div className="page">
            <CancelContent/>
        </div>
    )
};

export default StripeCheckoutCancel