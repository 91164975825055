import React, {useState} from 'react'
import API from "../../utils/httpAPI";
import SaveCardModal from "./SaveCardModal";
import {Spinner} from "react-bootstrap";
import NeedMoreCreditsHover from "./NeedMoreCreditsHover";
import {priceBullets} from "../../config/payment";
import {useHistory} from "react-router-dom";

const formatNumber = (number) => {
    return Number(number).toLocaleString("en-US");
}

const formatCurrentPrice = (number) => {
    const price = Number(number).toLocaleString("en-US", { style: "currency", currency: "USD" });
    const splitPrice = price.split('.');
    return splitPrice[1] === "00" ? splitPrice[0] : price;
}

const fromCentsToDollar = (cents) => {
    return parseFloat((cents / 100).toFixed(6));
}

const PayAsYouGo = ({id, prices, basePrice, children, lastSelectedCredits, setLastSelectedCredits}) => {
    const history = useHistory();

    const [checkoutLoading, setCheckoutLoading] = useState(false);
    const [showSavePaymentModal, setShowSavePaymentModal] = useState(false);
    const [isNeedMoreCreditsHover, setIsNeedMoreCreditsHover] = useState(false);

    const paymentTaxClickHandler = () => {
        history.push("/account/billingSettings");
        window.scrollTo(0, document.body.scrollHeight);
    }

    const creditsList = prices.map(item => {
            return Number(item.from);
        }
    ).filter(num => num > 1);

    const findPayAsYouGoRange = (credits) => {
        return prices.find(item => {
            const {from, to} = item;

            if (!to) {
                return credits >= from;
            }

            return credits >= from && credits <= to;
        });
    }

    const findCurrentCreditPrice = (credits) => {
        const item = findPayAsYouGoRange(credits);

        if (item) {
            return item.price;
        }

        return basePrice;
    }

    const [calculatedCredits, setCalculatedCredits] = useState(lastSelectedCredits);
    const [enterCreditsValue, setEnterCreditsValue] = useState(lastSelectedCredits);

    const selectCreditsFromList = (credits) => {
       setCalculatedCredits(credits);
       setEnterCreditsValue(credits);
       setLastSelectedCredits(credits);
    }

    const onUserEnterCredits = (e) => {
        const numberRegex = `(^[0-9]+$|^$)`;
        let value = e.target.value;

        if (value.match(numberRegex)) {
            if (value.length > 1 && value.substring(0,1) === "0") {
                value = value.substring(1);
            }

            setEnterCreditsValue(value);
        }

        const newValue = Number(value);
        isNeedMoreCreditsHover && setIsNeedMoreCreditsHover(false);

        if (newValue > creditsList[creditsList.length - 1]) {
            setIsNeedMoreCreditsHover(true);
            return;
        }

        const newCalculatedCredits = newValue <= creditsList[0] ? creditsList[0] : newValue;
        setCalculatedCredits(newCalculatedCredits);

        setLastSelectedCredits(Math.ceil(newCalculatedCredits / 1000) * 1000);
    }

    const checkout = async (savePaymentInformation, callback) => {
        const postData = {
            type: 'pay-as-you-go',
            price: id,
            quantity: calculatedCredits
        }
        if (savePaymentInformation) {
            postData.savePaymentInformation = 1;
        }

        try {
            const {data} = await API({method: "post", url: "/prices/checkout", data: postData});

            const {status, url} = data;

            if (status === 'ok' && url) {
                callback();
                window.location.href = url;
            }
        } catch (err) {}
    };

    const onCheckoutClick = async () => {
        setCheckoutLoading(true);

        try {
            const {data} = await API({
                method: "get", url: "/payment-methods/list"
            });
            const { list } = data;

            if (list.length > 0) {
                await checkout(false, () => setCheckoutLoading(false))
            } else
            setShowSavePaymentModal(true);
        } catch (err) {}

        setCheckoutLoading(false);
    }

    const validateInputKey = (e) => {
        if (e.key === 'Enter' || e.key === ',' || e.key === '.' || e.key === '+' || e.key === '-') {
            e.preventDefault();
        }
    }

    const roundUserInput = (e) => {
        const { value } = e.target;
        if (value < creditsList[0]) {
            setEnterCreditsValue(creditsList[0]);
        }
    }

    const currentCreditPrice = findCurrentCreditPrice(calculatedCredits);
    const currentPrice = fromCentsToDollar(calculatedCredits * currentCreditPrice);


    return(
        <>
            {showSavePaymentModal &&
                <SaveCardModal
                    showModal={showSavePaymentModal}
                    setShowModal={setShowSavePaymentModal}
                    checkout={checkout} />
            }
            <div className="payment-calculator">
                <div className="payment-credits">
                    <div className="payment-credits-head">
                        <h2>How many emails do you have?</h2>
                        <p className="payment-credits-head-comment">Set the number of emails you need or select a preset amount</p>
                    </div>
                    <div className="payment-credits-input">
                        <form onKeyDown={validateInputKey}>
                            <input placeholder="Enter the number of emails, for example: 5,000"
                                   type="number"
                                   min="1"
                                   value={enterCreditsValue}
                                   onChange={onUserEnterCredits}
                                   onBlur={roundUserInput}
                            />
                        </form>
                    </div>
                    <div className="payment-credits-select">
                        {creditsList.map((creditsNum, index) => {
                            return (
                                <div
                                    className={`payment-credits-item ${calculatedCredits === creditsNum ? 'selected' : ''}`}
                                    key={`${creditsNum}-${index}`}
                                    onClick={() => selectCreditsFromList(creditsNum)}>
                                    <div className="credits-number">{formatNumber(creditsNum)}</div>
                                    <div className="credits-text">Credits</div>
                                </div>
                            )
                        })}
                    </div>
                </div>
                <div className="payment-summary-result">
                    <div className="payment-summary">
                        <NeedMoreCreditsHover visible={isNeedMoreCreditsHover}
                                              maxCredits={formatNumber(creditsList[creditsList.length - 1])}
                        />
                        {children}
                        <div className="content-padding">
                            <div className="payment-selected-credits">
                                <div className="selected-credits">{formatNumber(calculatedCredits)}</div>
                                <div className="selected-comment">Credits</div>
                            </div>
                            <div className="payment-per-credit-cost">
                                <div className="per-credit-price">${fromCentsToDollar(currentCreditPrice)}</div>
                                <div className="per-credit-price-comment">Cost Per Credit</div>
                            </div>
                            <div className="payment-summary-price">
                                <div className="summary-price fs-72">
                                    {formatCurrentPrice(currentPrice)}
                                </div>
                            </div>
                            <div className="payment-button">
                                <button disabled={checkoutLoading} onClick={onCheckoutClick}
                                        className={`btn btn-success`}>
                                    Checkout
                                    {checkoutLoading && (
                                        <Spinner
                                            className="ms-3"
                                            as="span"
                                            animation="border"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true"
                                        />
                                    )}
                                </button>
                            </div>
                            <div className="payment-bullet-list">
                                {Object.keys(priceBullets.payg).map((key, index) => (
                                    <div key={key} className="payment-bullet">
                                        <img src="images/payment/calc-bullet.svg" alt=""/>
                                        <div className="bullet-label"> {priceBullets.payg[key]} </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                    <div className="payment-tax-warning">
                        If you have a VAT, Tax ID/EIN, or a business name, please <a className="payment-tax-link" onClick={paymentTaxClickHandler}>click here</a> to provide the details, and
                        we'll include them on your invoices.
                    </div>
                </div>
            </div>
        </>
    )
};

export default PayAsYouGo;
