import React from 'react'
import VerifyEnterMails from './VerifyEnterMails';
import VerifyResults from './VerifyResults';

const VerifyContent = () => {
    return(
        <div className="page-verify">
            <VerifyEnterMails/>
            <VerifyResults/>
        </div>
    )
};

export default VerifyContent