import React, {useState} from 'react';
import { useDispatch, useSelector } from "react-redux";
import {Button, Container, Form} from 'react-bootstrap';
import {createApp, updateAppFormData, clearAppFormData, saveAppData, hideModal} from "../../services/action-creators";
import useQuery from "../../utils/hooks/useQuery";

import AppForm from "./AppForm";
import SelectAppType from "./SelectAppType";
import {Link, useHistory} from "react-router-dom";

const CreateAppContent = () => {
    const dispatch = useDispatch();
    const history = useHistory();

    const { appType, appFormData } = useSelector((state) => state.apps);

    const query = useQuery();
    const selectedAppType = appType || query.get('appType');

    const [isValidName, setIsValidName] = useState(true);

    const handleCancel = () => {
        dispatch(clearAppFormData());
    };

    const handleCreateApp = (event) => {
        event.preventDefault();
        event.stopPropagation();
        const form = event.currentTarget;

        if (form.checkValidity() === false) {
            setIsValidName(false);
            return;
        }

        dispatch(createApp({
            type: selectedAppType,
            name: appFormData.appName,
            data: appFormData
        }, history));
    };

    const handleAppNameChange = (event) => {
        if (event.target.value.length > 0) {
            setIsValidName(true);
        }
        dispatch(updateAppFormData({appName: event.target.value}));
    };

    return(
        <Form onSubmit={handleCreateApp} noValidate>
            <div className="page-create-app">
                <Form.Group className="ca-form-row content-padding">
                    <Form.Label>Application Name</Form.Label>
                    <Form.Control
                        value={appFormData.appName}
                        onChange={handleAppNameChange}
                        type="text"
                        placeholder=""
                        required
                        isInvalid={!isValidName}
                        formNoValidate
                    />
                    <Form.Control.Feedback type="invalid">
                        The name field is required.
                    </Form.Control.Feedback>
                </Form.Group>
                <div className="select-app-type-header content-padding">
                    <h3>Select App Type</h3>
                </div>
                <SelectAppType/>
                <AppForm/>
                <div className="create-app-buttons content-padding">
                    <Link to={"/apps"} className="ca-btn btn btn-outline-secondary">
                        Cancel
                    </Link>
                    <Button className="ca-btn" variant="success" type="submit">Create App</Button>
                </div>
            </div>
        </Form>
    )
};

export default CreateAppContent
