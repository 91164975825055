import React, { useEffect, useState } from 'react'
import { useSelector } from "react-redux";
import UsageData from "./UsageData";
import UsageChart from "./UsageChart";
import UsageDetails from "./UsageDetails";
import { DateRangePicker } from "react-dates";
import moment from "moment";

const UsageContent = ({ fetchUsageDetails, fetchApps }) => {
    const [appType, setAppType] = useState("all");
    const [appTypeName, setAppTypeName] = useState("All Apps");
    const [focusedInput, setFocusedInput] = useState(null);

    const { chart, details } = useSelector((state) => state.usageDetails);
    const { apps, appsLoaded, appsLoading, failure  } = useSelector((state) => state.apps);

    const [hiddenBuckets, setHiddenBuckets] = useState([]);
    const [dates, setDates] = useState({
        start: moment().subtract(7, 'days'),
        end: moment().endOf('day')
    });
    const [pickerDates, setPickerDates] = useState(dates);

    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);

    useEffect(() => {
        const appId = appType === 'all' ? void(0) : appType;

        fetchUsageDetails({
            from: dates.start.valueOf(),
            to: dates.end.valueOf(),
            page,
            pageSize,
            appId,
            hiddenBuckets
        });

    }, [dates, page, pageSize, appType, hiddenBuckets, fetchUsageDetails]);

    useEffect(() => {
        setPage(1);
    }, [dates, appType, hiddenBuckets]);

    if (!appsLoaded) {
        if (failure) {
            // TODO: Show Error Modal
            return (<div> failure : {JSON.stringify(failure)}</div>)
        }

        if (!appsLoading) {
            fetchApps();
        }

        return (<div className="loadingFiles"/>);
    }

    const summary = {
        overall: 0,
        single: 0,
        bulk: 0
    };

    chart.forEach(item => {
        summary.overall += item.overall;
        summary.single += item.single;
        summary.bulk += item.bulk;
    });

    const handleBucketClick = bucket => {
        const newHiddenBuckets = hiddenBuckets.filter(item => item !== bucket);
        if (newHiddenBuckets.length === hiddenBuckets.length) {
            newHiddenBuckets.push(bucket);
        }
        setHiddenBuckets(newHiddenBuckets);
    };

    const handleAppChange = (event) => {
        setAppType(event.target.value);
        const index = event.nativeEvent.target.selectedIndex;
        setAppTypeName(event.nativeEvent.target[index].text);
    };

    const isOutsideRange = date => date.isAfter(moment().endOf('day'));

    const onClose = ({ startDate, endDate }) => {
        const start = startDate.startOf('day');
        const end = endDate.endOf('day');
        if (!start.isSame(dates.start) || !end.isSame(dates.end)) {
            setDates({ start, end });
        }
    };

    const handleDateChange = ({startDate, endDate}) => {
        setPickerDates({
            start: startDate,
            end: endDate
        });
    };

    const handlePageChange = (event, newPage) => {
        const p = newPage < 1 ? 1 : newPage + 1;
        setPage(p);
    };

    const handlePageSizeChange = (event) => {
        setPageSize(parseInt(event.target.value, 10));
        setPage(1);
    };

    return (
        <div className="app-usage-stats account-usage">
            <div className="app-usage-stats-header">
                <div className="stats-title">
                    <h3>Overall Usage</h3>
                </div>
                <div className="stats-type">
                    <form>
                        <select onChange={handleAppChange}>
                            <option key="all" value="all">All Apps</option>
                            <option key="website" value="">Website</option>
                            {Object.keys(apps).map(key => {
                                return (<option key={key} value={apps[key]._id}>{apps[key].name}</option>)
                            })}
                        </select>
                    </form>
                </div>
                <div className="date-range-picker-wrapper">
                    <div className="calendar-icon"/>
                    <DateRangePicker
                        isOutsideRange={isOutsideRange}
                        startDate={pickerDates.start}
                        startDateId="start_date_id"
                        endDate={pickerDates.end}
                        endDateId="end_date_id"
                        onDatesChange={handleDateChange}
                        focusedInput={focusedInput}
                        onFocusChange={setFocusedInput}
                        onClose = {onClose}
                        displayFormat={'MMMM D, YYYY'}
                    />
                </div>
            </div>
            <div className="app-usage-data">
                <UsageData data={summary} hiddenBuckets={hiddenBuckets} onBucketClick={handleBucketClick}/>
                <UsageChart data={chart} hiddenBuckets={hiddenBuckets} />
            </div>
            <div className="app-result-history">
                <div className="app-usage-details-header">
                    <h3>Result History</h3>
                    <p className="app-usage-details-header-app-type">Showing {appTypeName}</p>
                </div>
                <div className="app-usage-details">
                    <UsageDetails
                        events={details.events}
                        count={details.count}
                        page={page - 1}
                        pageSize={pageSize}
                        onPageChange={handlePageChange}
                        onPageSizeChange={handlePageSizeChange}
                    />
                </div>
            </div>
        </div>
    )

};

export default UsageContent
