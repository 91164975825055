import API from "../../utils/httpAPI";

import {
    SET_PAYMENT_HISTORY_DETAILS_START_DATE,
    SET_PAYMENT_HISTORY_DETAILS_END_DATE,
    GET_PAYMENT_HISTORY_DETAILS_REQUEST,
    GET_PAYMENT_HISTORY_DETAILS_SUCCESS,
    GET_PAYMENT_HISTORY_DETAILS_FAILURE,
    SET_PAYMENT_USAGE_DETAILS_START_DATE,
    SET_PAYMENT_USAGE_DETAILS_END_DATE,
    GET_PAYMENT_USAGE_DETAILS_REQUEST,
    GET_PAYMENT_USAGE_DETAILS_SUCCESS,
    GET_PAYMENT_USAGE_DETAILS_FAILURE,
} from "./action-types";

export const setPaymentHistoryDetailsStartDate = (date) => {
    return {
        type: SET_PAYMENT_HISTORY_DETAILS_START_DATE,
        payload: { date }
    };
};

export const setPaymentHistoryDetailsEndDate = (date) => {
    return {
        type: SET_PAYMENT_HISTORY_DETAILS_END_DATE,
        payload: { date }
    };
};

export const getPaymentHistoryDetailsRequest = () => {
    return {
        type: GET_PAYMENT_HISTORY_DETAILS_REQUEST,
        // payload: { date }
    };
};

export const getPaymentHistoryDetailsSuccess = ({ details }) => {
    return {
        type: GET_PAYMENT_HISTORY_DETAILS_SUCCESS,
        payload: { details }
    };
};

export const getPaymentHistoryDetailsFailure = () => {
    return {
        type: GET_PAYMENT_HISTORY_DETAILS_FAILURE,
        // payload: { date }
    };
};

export const setPaymentUsageDetailsStartDate = (date) => {
    return {
        type: SET_PAYMENT_USAGE_DETAILS_START_DATE,
        payload: { date }
    };
};

export const setPaymentUsageDetailsEndDate = (date) => {
    return {
        type: SET_PAYMENT_USAGE_DETAILS_END_DATE,
        payload: { date }
    };
};

export const getPaymentUsageDetailsRequest = () => {
    return {
        type: GET_PAYMENT_USAGE_DETAILS_REQUEST,
        // payload: { date }
    };
};

export const getPaymentUsageDetailsSuccess = ({ details }) => {
    return {
        type: GET_PAYMENT_USAGE_DETAILS_SUCCESS,
        payload: { details }
    };
};

export const getPaymentUsageDetailsFailure = () => {
    return {
        type: GET_PAYMENT_USAGE_DETAILS_FAILURE,
        // payload: { date }
    };
};

export const getPaymentHistoryDetails = (dates) => {
        return function (dispatch) {
            dispatch(getPaymentHistoryDetailsRequest());
            API({
                method: "post",
                url: "/payment/history/getDetails",
                data: { dates },
            })
                .then((response) => {
                    const { details } = response.data;
                    // const newRequestPendingEmailIds = [...oldEmails, ...paidEmails].map(({ _id }) => _id);
                    // setTimeout(() => checkPendingEmails(dispatch, newRequestPendingEmailIds), 10000);
                    dispatch(getPaymentHistoryDetailsSuccess({ details }));
                })
                .catch((error) => {
                    // dispatch(getPaymentHistoryDetailsFailure(error));
                });
        };
    };

export const getPaymentUsageDetails = (dates) => {
    return function (dispatch) {
        dispatch(getPaymentUsageDetailsRequest());
        API({
            method: "post",
            url: "/payment/usage/getDetails",
            data: { dates },
        })
            .then((response) => {
                const { details } = response.data;
                // const newRequestPendingEmailIds = [...oldEmails, ...paidEmails].map(({ _id }) => _id);
                // setTimeout(() => checkPendingEmails(dispatch, newRequestPendingEmailIds), 10000);
                dispatch(getPaymentUsageDetailsSuccess({ details }));
            })
            .catch((error) => {
                // dispatch(getPaymentHistoryDetailsFailure(error));
            });
    };
};
