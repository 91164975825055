import React, {useRef, useState} from 'react';
import {Button, Form, InputGroup, Spinner} from 'react-bootstrap';
import { changePassword } from "../../services/action-creators";
import {useDispatch, useSelector} from "react-redux";
import { isValidPassword } from '../../utils/helpers';
import useStateWithValidation from '../../utils/hooks/useStateWithValidation';
import { useHistory } from "react-router-dom";

const AccountChangePassword = () => {
    const dispatch = useDispatch();
    const history = useHistory();

    const { loading } = useSelector((state) => state.authentication);

    const [oldPassword, setOldPassword] = useState("");
    const [isOldPasswordInvalid, setIsOldPasswordInvalid] = useState(false);

    const newPasswordRef = useRef(null);
    const repeatPasswordRef = useRef(null);

    const [newPassword, setNewPassword, { isInvalid: isNewPasswordInvalid }] =
        useStateWithValidation({
            initialState: '',
            isInvalidFn: (newPassword) => !isValidPassword(newPassword).valid,
        });
    const [newPasswordRepeat, setNewPasswordRepeat, { isInvalid: isNewPasswordRepeatInvalid }] =
        useStateWithValidation({
            initialState: '',
            isInvalidFn: (newPasswordRepeat) => newPasswordRepeat !== newPassword || !isValidPassword(newPasswordRepeat).valid,
        });

    const [eyeShownOld, setEyeShownOld] = useState(true);
    const [eyeShownNew, setEyeShownNew] = useState(true);
    const [eyeShownRepeat, setEyeShownRepeat] = useState(true);

    const [oldPasswordInputType, setOldPasswordInputType] = useState("password");
    const [newPasswordInputType, setNewPasswordInputType] = useState("password");
    const [repeatPasswordInputType, setRepeatPasswordInputType] = useState("password");


    const handleInputChange = (setFn) => (value) => setFn(value);
    const handleNewPasswordChange = handleInputChange(setNewPassword);
    const handleNewPasswordRepeatChange = handleInputChange(setNewPasswordRepeat);

    const handleOldPasswordChange = (e) => {
        const { value } = e.target;
        setIsOldPasswordInvalid(false);
        setOldPassword(value);
    }

    const handleSaveClick = (event) => {
        event.preventDefault();
        event.stopPropagation();
        const form = event.currentTarget;

        if (form.checkValidity() === false) {
            if (!oldPassword) setIsOldPasswordInvalid(true);
            if (!newPassword) newPasswordRef.current.className = "form-control is-invalid";
            if (!newPasswordRepeat) repeatPasswordRef.current.className = "form-control is-invalid";
            return;
        }

        if (newPassword !== newPasswordRepeat) {
            setNewPassword('');
            setNewPasswordRepeat('');
            return;
        }

        dispatch(changePassword(oldPassword, newPassword, history));
    };

    const handleEyeClick = (input)=> {
        switch (input) {
            case 'old':
                setOldPasswordInputType(eyeShownOld ? 'text' : 'password');
                setEyeShownOld(!eyeShownOld);
                break;
            case 'new':
                setNewPasswordInputType(eyeShownNew ? 'text' : 'password');
                setEyeShownNew(!eyeShownNew);
                break;
            case 'repeat':
                setRepeatPasswordInputType(eyeShownRepeat ? 'text' : 'password');
                setEyeShownRepeat(!eyeShownRepeat);
                break;
        }
    };

    return (
        <div className="account_content_section">
            <h1>Change Password</h1>
            <div className="line"/>
            <div className="form-change-password account-form-common">
                <Form className="change-password-form" onSubmit={handleSaveClick} noValidate>
                    <div className="account-form-row">
                        <Form.Group className="form-group">
                            <Form.Label>Enter Your Current Password</Form.Label>
                            <div className="password-input">
                                <Form.Control className=""
                                              value={oldPassword}
                                              onChange={handleOldPasswordChange}
                                              type={oldPasswordInputType}
                                              placeholder=""
                                              isInvalid={isOldPasswordInvalid}
                                />
                                <img src={`/images/${eyeShownOld ? 'eye-slash' : 'eye'}.svg`} alt="" onClick={() => handleEyeClick('old')} className="eye"/>
                                <Form.Control.Feedback type="invalid">
                                    Please provide a valid password.
                                </Form.Control.Feedback>
                            </div>
                        </Form.Group>
                        <Form.Group className="form-group">
                            <Form.Label>Enter New Password</Form.Label>
                            <div className="password-input">
                                <InputGroup className="">
                                    <Form.Control
                                        className=""
                                        ref={newPasswordRef}
                                        type={newPasswordInputType}
                                        onChange={(e) => handleNewPasswordChange(e.target.value)}
                                        isInvalid={isNewPasswordInvalid}
                                        value={newPassword}
                                        required
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Please provide a valid password.
                                    </Form.Control.Feedback>
                                </InputGroup>
                                <img src={`/images/${eyeShownNew ? 'eye-slash' : 'eye'}.svg`} alt="" onClick={() => handleEyeClick('new')} className="eye"/>
                            </div>
                        </Form.Group>

                        <Form.Group className="form-group">
                            <Form.Label>Repeat New Password</Form.Label>
                            <div className="password-input">
                                <InputGroup className="">
                                    <Form.Control
                                        className=""
                                        ref={repeatPasswordRef}
                                        type={repeatPasswordInputType}
                                        onChange={(e) => handleNewPasswordRepeatChange(e.target.value)}
                                        isInvalid={isNewPasswordRepeatInvalid}
                                        value={newPasswordRepeat}
                                        required
                                    />

                                    <Form.Control.Feedback type="invalid">
                                        Please make sure your passwords match.
                                    </Form.Control.Feedback>
                                </InputGroup>
                                <img src={`/images/${eyeShownRepeat ? 'eye-slash' : 'eye'}.svg`} alt="" onClick={() => handleEyeClick('repeat')} className="eye"/>
                            </div>
                        </Form.Group>
                    </div>

                    <div className="account-from-controls">
                        <Button className="mobile-mt-16 mobile-btn-width" variant="success" type="submit">
                            {!loading && <img className="btn-icon" src="/images/account/save-btn-icon.svg" alt=""/>}
                            {loading && (
                                <Spinner
                                    className="mr14"
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                />
                            )}
                            Save
                        </Button>
                    </div>
                </Form>
            </div>
        </div>
    )
};

export default AccountChangePassword;
