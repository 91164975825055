export const SIGN_IN_SUCCESS = "SIGN_IN_SUCCESS";
export const SIGN_UP_SUCCESS = "SIGN_UP_SUCCESS";
export const SIGN_OUT_SUCCESS = "SIGN_OUT_SUCCESS";

export const CHOOSE_APP_USAGE = "CHOOSE_APP_USAGE";

export const CHOOSE_SIGN_IN = "CHOOSE_SIGN_IN";
export const CHOOSE_REGISTRATION = "CHOOSE_REGISTRATION";
export const CHOOSE_RESET_PASSWORD = "CHOOSE_RESET_PASSWORD";
export const CHOOSE_ADMIN_PANEL = "CHOOSE_ADMIN_PANEL";
export const CHOOSE_ADMIN_CLIENTS = "CHOOSE_ADMIN_CLIENTS";

export const SYNC_SUCCESS = "SYNC_SUCCESS";
export const SHOW_ALERT = "SHOW_ALERT";

export const HIDE_MODAL = "HIDE_MODAL";

export const UPDATE_USER_CREDITS = "UPDATE_USER_CREDITS";
export const CHANGE_BLOCKED_STATUS_SUCCESS = "CHANGE_BLOCKED_STATUS_SUCCESS";

export const APP_DELETE_SUCCESS = "APP_DELETE_SUCCESS";
export const APP_REFRESH_API_KEY_SUCCESS = "APP_REFRESH_API_KEY_SUCCESS";
export const SAVE_APP_DATA_SUCCESS = "SAVE_APP_DATA_SUCCESS";
