export * from '../common-action-types';

export const SET_APP_USAGE_DETAILS_START_DATE = "SET_APP_USAGE_DETAILS_START_DATE";
export const SET_APP_USAGE_DETAILS_END_DATE = "SET_APP_USAGE_DETAILS_END_DATE";

export const GET_APP_USAGE_DETAILS_REQUEST = "GET_APP_USAGE_DETAILS_REQUEST";
export const GET_APP_USAGE_DETAILS_SUCCESS = "GET_APP_USAGE_DETAILS_SUCCESS";
export const GET_APP_USAGE_DETAILS_FAILURE = "GET_APP_USAGE_DETAILS_FAILURE";

export const SHOW_APP_DELETE_MODAL = "SHOW_APP_DELETE_MODAL";
export const HIDE_APP_DELETE_MODAL = "HIDE_APP_DELETE_MODAL";
export const SHOW_APP_REFRESH_API_KEY_MODAL = "SHOW_APP_REFRESH_API_KEY_MODAL";
export const HIDE_APP_REFRESH_API_KEY_MODAL = "HIDE_APP_REFRESH_API_KEY_MODAL";

export const APP_DELETE_REQUEST = "APP_DELETE_REQUEST";
export const APP_DELETE_FAILURE = "APP_DELETE_FAILURE";

export const APP_REFRESH_API_KEY_REQUEST = "APP_REFRESH_API_KEY_REQUEST";
export const APP_REFRESH_API_KEY_FAILURE = "APP_REFRESH_API_KEY_FAILURE";
