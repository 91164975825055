import React, {useEffect, useState} from "react";

const SettingsAccordion = ({
    alias,
    name,
    options = [],
    isActive = false,
    selectedResults = [],
    onResultOptionSelectClick,
    onResultGroupSelectClick
}) => {

    const [showOptions, setShowOptions] = useState(false);

    useEffect(() => {
        setShowOptions(isActive);
    }, [isActive]);

    const onShowMoreClick = () => {
        setShowOptions(!showOptions);
    }

    return (
        <div className={`settings-accordion ${isActive ? "selected-accordion" : ""}`}>
            <div className="settings-accordion-header">
                <div className="theme-checkbox">
                    <input id={`select-accordion-${alias}`} type="checkbox" value={alias} checked={isActive} onChange={onResultGroupSelectClick} />
                    <label htmlFor={`select-accordion-${alias}`}>{name}</label>
                </div>
                {options.length > 0
                    && <ShowOptions isActive={showOptions} onClick={onShowMoreClick}/>
                }
            </div>
            {
                options.length > 0 &&
                <div className={`options-list ${showOptions ? "show-options" : ""}`}>
                    {options.map((option, index) => {
                        return <Option
                            key={option.alias + '-' + index}
                            {...option}
                            isActive={selectedResults.includes(option.alias)}
                            groupAlias={alias}
                            onResultOptionSelectClick={onResultOptionSelectClick}
                        />;
                    })}
                </div>
            }
        </div>
    );
};

const ShowOptions = ({isActive, onClick}) => {
    if (!isActive) {
        return (
            <div className={"settings-accordion-show-options"} onClick={onClick}>
                <span>Show more</span>
                <div className={"icon icon-arrow-down"}/>
            </div>
        );
    }

    return (
        <div className={"settings-accordion-show-options"} onClick={onClick}>
            <span>Show less</span>
            <div className={"icon icon-arrow-up"}/>
        </div>
    );
}

const Option = ({alias, name, onResultOptionSelectClick, description = '', isActive = false, groupAlias}) => {
    return (
        <div className="settings-accordion-option">
            <div className="option-checkbox">
                <div className="theme-checkbox">
                    <input id={`select-accordion-${alias}`} type="checkbox" value={alias} checked={isActive} onChange={(e) => onResultOptionSelectClick(e, groupAlias)} />
                    <label htmlFor={`select-accordion-${alias}`}>{name}</label>
                </div>
            </div>
            {description.length > 0 &&
                <div className="option-description">
                    {description}
                </div>
            }
        </div>
    );
}

export default SettingsAccordion;
