import { combineReducers } from 'redux'

import authentication from "./auth/reducer";
import account from "./account/reducer";
import admin from "./admin/reducer";
import navigation from "./navigation/reducer";
import file from "./file/reducer";
import email from "./email/reducer";
import users from "./users/reducer";
import apps from "./apps/reducer";
import paymentDetails from "./paymentDetails/reducer";
import appUsageDetails from "./appUsageDetails/reducer";
import usageDetails from "./usageDetails/reducer";

export default combineReducers({
    authentication,
    account,
    admin,
    navigation,
    file,
    email,
    users,
    apps,
    paymentDetails,
    appUsageDetails,
    usageDetails
})