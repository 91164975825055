import React from 'react';
import { useSelector } from "react-redux";

import AdminClients from "./AdminClients";
import AdminCleanHelpRequests from "./AdminCleanHelpRequests";
import AdminWidget from "./AdminWidget";
import AdminStripeProducts from "./Stripe/page";

const AdminContent = () => {
    const { activeTab } = useSelector((state) => state.admin);

    switch(activeTab) {
        case 'clients':
            return (
                <AdminClients/>
            );
        // case 'clean':
        //     return (
        //         <AdminCleanHelpRequests/>
        //     );
        case 'widget':
            return (
                <AdminWidget/>
            )
        case 'stripe':
            return (
                <AdminStripeProducts />
            )
        default:
            return (<></>);
    }
};

export default AdminContent;
