import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import moment from 'moment'
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import {DateRangePicker} from "react-dates";

import {
    getPaymentHistoryDetails,
    setPaymentHistoryDetailsEndDate,
    setPaymentHistoryDetailsStartDate,
} from "../../services/action-creators";
import {getDateTimeString} from "../../utils/helpers";
import {TablePagination} from "@material-ui/core";

const PaymentHistoryDetailsSection = () => {
    const dispatch = useDispatch();
    const { phdStartDate, phdEndDate, phdIsChangedDate,
        phdDetails } = useSelector((state) => state.paymentDetails);

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [focusedInput, setFocusedInput] = useState(null);

    const [dates, setDates] = useState({
        start: moment().subtract(7, 'days'),
        end: moment().endOf('day')
    });

    const nowMoment = moment();
    const isOutsideRange = date => date.isAfter(nowMoment);

    useEffect(() => {
        dispatch(getPaymentHistoryDetails({
            startStamp: dates.start.startOf('day').unix(),
            endStamp: dates.end.endOf('day').unix()
        }));
    }, [dates]);

    const onClose = ({ startDate, endDate }) => {
        if (!startDate || !endDate) return;

        if (!phdIsChangedDate) return;

        setDates({ start: startDate, end: endDate });
    };

    const handleDateChange = ({startDate, endDate}) => {
        if (startDate !== null && !startDate.isSame(phdStartDate)) {
            dispatch(setPaymentHistoryDetailsStartDate(startDate));
        }
        if (endDate !== null && !endDate.isSame(phdEndDate)) {
            dispatch(setPaymentHistoryDetailsEndDate(endDate));
        }
    };

    const handlePageChange = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        const newPageSize = parseInt(event.target.value, 10);
        setRowsPerPage(newPageSize);
        setPage(0);
    };


    const countDetails = () => {
        return filterDetails().length;
    };

    const filterDetails = () => {
        return Object.keys(phdDetails).filter(key => phdDetails[key].status === 'fail' || phdDetails[key].status === 'complete');
    };

    return (
        <>
            <div className="content-section pagination-bottom">
                <div className="content-section-header payment-history-calendar">
                    <h2>Payment Details</h2>
                    <div className="date-range-picker-wrapper">
                        <div className="calendar-icon"/>
                        <DateRangePicker
                            isOutsideRange={isOutsideRange}
                            startDate={phdStartDate ? phdStartDate : dates.start}
                            startDateId="start_date_id"
                            endDate={phdEndDate ? phdEndDate : dates.end}
                            endDateId="end_date_id"
                            onDatesChange={handleDateChange}
                            focusedInput={focusedInput}
                            onFocusChange={setFocusedInput}
                            onClose={onClose}
                            displayFormat={'MMMM D, YYYY'}
                        />
                    </div>

                </div>
                <div className="content-section-body">
                    <table className="table-styled">
                        <thead>
                            <tr>
                                <th>Date</th>
                                <th>Type</th>
                                <th>Description</th>
                                <th>Total Paid</th>
                                <th>Status</th>
                                <th>Invoice</th>
                            </tr>
                        </thead>
                        <tbody>
                            {filterDetails().length ? (
                                filterDetails()
                                    .filter(
                                        (el, i) =>
                                            i < rowsPerPage * ( Number(page) + 1 ) &&
                                            i > ( Number(page) * rowsPerPage - 1 )
                                    )
                                    .map((res, i) => {
                                        return res;
                                    })
                                    .map((key, index) => (
                                        <tr key={key}>
                                            <td>{getDateTimeString(phdDetails[key].createdAt)}</td>
                                            <td>{phdDetails[key].type}</td>
                                            <td>{phdDetails[key].description}</td>
                                            <td>{phdDetails[key].quantity}</td>
                                            <td>
                                                <div className={`content-section-status ${phdDetails[key].status}`}>
                                                    {phdDetails[key].status}
                                                </div>
                                            </td>
                                            <td>
                                                {
                                                    (phdDetails[key].invoiceUrl && phdDetails[key].invoiceUrl.length > 0)
                                                    ? <a href={phdDetails[key].invoiceUrl} target={"_blank"}>Invoice</a>
                                                    : <></>
                                                }
                                            </td>
                                        </tr>
                                    ))
                            ) : (<tr>
                                <td colSpan={6} className="table-no-content">
                                    {`No payments available between ${phdStartDate ? phdStartDate.format('MMM D, YYYY') : dates.start.format('MMM D, YYYY')} and ${phdEndDate ? phdEndDate.format('MMM D, YYYY') : dates.end.format('MMM D, YYYY')}`}
                                </td>
                            </tr>)}

                        </tbody>
                    </table>
                </div>
            </div>
            {filterDetails().length ? (
                <TablePagination
                    component="div"
                    count={countDetails()}
                    page={page}
                    rowsPerPage={rowsPerPage}
                    onPageChange={handlePageChange}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            ) : (<></>)}
        </>
    );
};

export default PaymentHistoryDetailsSection;
