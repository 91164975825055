import React from "react";
import {Modal, Button} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { hideClientBlockModal, changeBlockedStatus } from "../../services/action-creators";


const AdminClientBlockModal = () => {
    const dispatch = useDispatch();
    const { chosenId, showClientBlockModal } = useSelector((state) => state.admin);
    const { users } = useSelector((state) => state.users);

    const newStatus = chosenId && users[chosenId] && !users[chosenId].blockAccount;
    const handleClose = () => dispatch(hideClientBlockModal());
    const handleBlock = () => {
        if (chosenId) {
            return dispatch(changeBlockedStatus(chosenId, newStatus));
        }
    };

    let name = '';
    let email = '';
    if (users[chosenId]) {
        name = users[chosenId].name;
        email = users[chosenId].email;
    }

    return (
        <Modal show={showClientBlockModal} onHide={ handleClose }>
            <button onClick={handleClose} type="button" class="btn-close btn-close-absolute" aria-label="Close"></button>
            <div className="delete-file-modal">
                    <div className="dfm-header">
                        { newStatus ? "Block" : "Unblock" } this user
                    </div>
                    <div className="bum-warning">
                        <div>{`id: ${chosenId}`}</div>
                        <div>{`name: ${name}`}</div>
                        <div>{`email: ${email}`}</div>
                    </div>
                <div className="dfm-footer">
                    <Button variant="outline-secondary" onClick = {handleClose}>Cancel</Button>
                    <Button variant="danger" onClick = {handleBlock}>{ newStatus ? "Block" : "Unblock" }</Button>
                </div>
            </div>
        </Modal>
    );

};

export default AdminClientBlockModal;
