import {
    CHOOSE_ADMIN_PANEL,
    CHOOSE_APPS,
    CHOOSE_VERIFY,
    CHOOSE_CLEAN,
    CHOOSE_SYNC,
    CHOOSE_SIGN_IN,
    CHOOSE_REGISTRATION,
    CHOOSE_ACCOUNT,
    CHOOSE_PAYMENT,
    SHOW_MODAL,
    HIDE_MODAL,
    HIDE_ALERT,
    SHOW_ALERT,
    CHOOSE_RESET_PASSWORD,
    CHOOSE_CHECKOUT_CANCEL,
    CHOOSE_CHECKOUT_SUCCESS
} from "./action-types";

import { getAlert } from "./alertConfig";

export const showAlert = (alert) => {
    return {
        type: SHOW_ALERT,
        payload: { alert },
    };
};

export const showAlertByError = ({type, message, data}) => {
    const alert = getAlert(type, message, data);
    // console.error('ALERT FOR', type, alert);
    return showAlert(alert);
};

export const choosePayment = (history) => {
    history.push("/payment");
    return {
        type: CHOOSE_PAYMENT,
        payload: 'payment'
    };
};

export const chooseCheckoutSuccess = (history) => {
    history.push("/checkout-success");
    return {
        type: CHOOSE_CHECKOUT_SUCCESS,
        payload: 'checkout-success'
    };
};

export const chooseCheckoutCancel = (history) => {
    history.push("/checkout-cancel");
    return {
        type: CHOOSE_CHECKOUT_CANCEL,
        payload: 'checkout-cancel'
    };
};

export const chooseApps = (history) => {
    history.push("/apps");
    return {
        type: CHOOSE_APPS,
        payload: 'apps'
    };
};

export const chooseVerify = (history) => {
    history.push("/single");
    return {
        type: CHOOSE_VERIFY,
        payload: 'single'
    };
};

export const chooseClean = (history) => {
    history.push("/bulk");
    return {
        type: CHOOSE_CLEAN,
        payload: 'bulk'
    };
};

export const chooseSync = (history) => {
    history.push("/sync");
    return {
        type: CHOOSE_SYNC,
        payload: 'sync'
    };
};

export const chooseResetPassword = (history) => {
    history.push("/forgotPassword");
    return {
        type: CHOOSE_RESET_PASSWORD,
        payload: 'forgotPassword'
    };
};

export const chooseSignIn = (history) => {
    history.push("/signin");
    return {
        type: CHOOSE_SIGN_IN,
        payload: 'signin'
    };
};

export const chooseRegistration = (history) => {
    history.push("/signup");
    return {
        type: CHOOSE_REGISTRATION,
        payload: 'signup'
    };
};

export const chooseUsage = (history) => {
    history.push("/usage");
    return {
        type: CHOOSE_ACCOUNT,
        payload: 'usage'
    };
};

export const chooseAdmin = (history) => {
    history.push("/adminpanel");
    return {
        type: CHOOSE_ADMIN_PANEL,
        payload: 'adminpanel'
    };
};

export const choosePaymentHistory = (history) => {
    history.push("/paymentHistory");
    return {
        type: CHOOSE_ACCOUNT,
        payload: 'paymentHistory'
    };
};

export const chooseAccount = (history) => {
    history.push("/account/yourProfile");
    return {
        type: CHOOSE_ACCOUNT,
        payload: 'account'
    };
};

export const showModal = () => {
    return {
        type: SHOW_MODAL
    };
};

export const hideModal = () => {
    return {
        type: HIDE_MODAL
    };
};

export const hideAlert = (key) => {
    return {
        type: HIDE_ALERT,
        payload: key
    };
};
