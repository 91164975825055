import React from 'react'
import PageMenu from "./createApp/PageMenu";
import CreateAppContent from "./createApp/CreateAppContent";

const CreateApp = () => {
    return (
        <div className="page">
            <PageMenu/>
            {/*<PageAlert/>*/}
            <CreateAppContent/>
        </div>
    )
};

export default CreateApp
