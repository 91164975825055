import React, { useEffect } from 'react';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Button, Alert, InputGroup, Spinner } from 'react-bootstrap';

import {
    resetPassword,
    resetResetPasswordSuccessStatus,
} from '../../services/action-creators';
import { isValidEmail } from '../../utils/helpers';
import useStateWithValidation from '../../utils/hooks/useStateWithValidation';
import {MAIN_PROOFY_URL} from "../../config/proofy-links";

const ForgotPassword = () => {
    const dispatch = useDispatch();
    const [email, setEmail, { isInvalid: isEmailInvalid }] =
        useStateWithValidation({
            initialState: '',
            isInvalidFn: (email) => !isValidEmail(email),
        });
    const { loading, resetPasswordSuccessStatus, error } = useSelector(
        (state) => state.authentication
    );

    const handleEmailChange = (event) => {
        setEmail(event.target.value);
    };

    const handleResetPassword = (event) => {
        event.preventDefault();

        if (!isValidEmail(email)) return;

        dispatch(resetPassword({ email }));
    };

    const handleProofyClick = () => {
        window.open(MAIN_PROOFY_URL, '_blank').focus();
    }

    useEffect(() => () => resetResetPasswordSuccessStatus(), []);

    return (
        <div className="signin-wrapper">
            <div className="signin-logo" onClick={handleProofyClick}>
                <img src="/images/new-logo.svg" alt="logo"/>
            </div>
            <div className="signin-card">
                <div className="signin-card-body">
                    <h2 className="forgot-password-title">Reset Your Password</h2>
                    <p className={classNames('signin-text', resetPasswordSuccessStatus ? 'mb-3' : 'mb-4')}>
                        Enter the email associated with your Proofy account and well send you a reset link
                    </p>

                    {resetPasswordSuccessStatus && (
                        <p className="signin-text text-success mb-4">
                            Email successfully sent!
                        </p>
                    )}

                    <Form className="signin-form" noValidate>
                        <Form.Group controlId="formBasicEmail">
                            <Form.Label>Email address*</Form.Label>
                            <InputGroup hasValidation>
                                <Form.Control
                                    className="signin-email-control"
                                    type="email"
                                    onChange={handleEmailChange}
                                    isInvalid={isEmailInvalid}
                                />

                                <Form.Control.Feedback type="invalid">
                                    Please provide a valid email.
                                </Form.Control.Feedback>
                            </InputGroup>
                        </Form.Group>

                        {error && (
                            <Alert className="mb-2 py-2 px-3" variant="danger">
                                {error}
                            </Alert>
                        )}

                        <div className="send-reset-password-button">
                            <Button
                                className="signin-button"
                                onClick={handleResetPassword}
                                variant="success"
                                type="submit"
                                disabled={loading}
                            >
                                Send Reset Link
                                {loading && (
                                    <Spinner className="ms-3" as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                                )}
                            </Button>
                        </div>
                    </Form>

                    <div class="forgot-password-link">
                        <a href="/" class="forgot-password-link__to-go">
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                <path d="M6.66699 3.3335L3.33366 6.66683L6.66699 10.0002" stroke="#32B767" stroke-width="1.2" stroke-linecap="round"/>
                                <path d="M3.33301 6.6665H12.083C14.6143 6.6665 16.6663 8.71853 16.6663 11.2498V11.2498C16.6663 13.7811 14.6143 15.8332 12.083 15.8332H4.16634" stroke="#5DD38C" stroke-width="1.2" stroke-linecap="round"/>
                            </svg>
                            To go back</a>
                        <a href="#">Recall password</a>
                    </div>
                </div>
            </div>
            <div className="sign-in-copy">Copyright ©2025 Proofy.io. All Rights Reserved</div>
        </div>
    );
};

export default ForgotPassword;
