import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {hideStripeProductDeleteModal, removeStripeProduct} from "../../../services/action-creators";
import {Button, Modal} from "react-bootstrap";

const ProductDeleteModal = () => {
    const dispatch = useDispatch();
    const { selectedProductId, showStripeProductDeleteModal } = useSelector((state) => state.admin);
    const handleClose = () => dispatch(hideStripeProductDeleteModal());

    const handleDelete = () => {
        if (selectedProductId) {
            return dispatch(removeStripeProduct(selectedProductId));
        }
    };

    return (
        <Modal centered
               show={showStripeProductDeleteModal}
               onHide={handleClose}
               className='modal-clean-delete'>
            <button onClick={handleClose} type="button" className="btn-close btn-close-absolute" aria-label="Close"/>
            <div className="delete-file-modal">
                <div className="dfm-header">
                    Delete Product
                </div>
                <div className="dfm-text mt-3">
                    Are you sure you want to delete {selectedProductId} product?
                </div>
                <div className="dfm-footer">
                    <Button variant="outline-secondary" onClick = {handleClose}>Cancel</Button>
                    <Button variant="danger" onClick = {handleDelete}>Delete</Button>
                </div>
            </div>
        </Modal>
    );

};

export default ProductDeleteModal;
