import React from 'react'
import PageMenu from '../component/PageMenu'
import AccountContent from './account/AccountContent'
import AccountNavbar from './account/AccountNavbar'


const Account = () => {
    return (
        <div className="page">
            <PageMenu activeTabName="account" />
            <AccountNavbar/>
            <AccountContent/>
        </div>
    )
};

export default Account
