import {
    CREATE_NEW_APP,
    BACK_TO_APPS,
    SELECT_APP_TYPE,
    UPDATE_APP_FORM_DATA,
    CLEAR_APP_FORM_DATA,
    GET_APPS_REQUEST,
    GET_APPS_SUCCESS,
    GET_APPS_FAILURE,
    SAVE_APP_DATA_SUCCESS,
    CHOOSE_APP_USAGE,
    SIGN_IN_SUCCESS,
    SIGN_UP_SUCCESS,
    SIGN_OUT_SUCCESS,
    APP_DELETE_SUCCESS,
    APP_REFRESH_API_KEY_SUCCESS,
    CREATE_APP_SUCCESS,
    UPDATE_SELECTED_APP_DATA,
} from "./action-types";

const appsReducer = function (state, action) {
    switch (action.type) {
        case CHOOSE_APP_USAGE:
            return {
                ...state,
                selectedAppId: action.payload.appId,
                selectedAppData: action.payload.appData
            };
        case CREATE_NEW_APP:
            return {
                ...state,
                isCreatingNewApp: true
            };
        case BACK_TO_APPS:
            return {
                ...state,
                isCreatingNewApp: false,
                appType: "",
                selectedAppData: {}
            };
        case CLEAR_APP_FORM_DATA:
            Object.keys(state.appFormData).forEach(key => {
                state.appFormData[key] = '';
            });
            return {
                ...state
            };
        case CREATE_APP_SUCCESS:
            Object.keys(state.appFormData).forEach(key => {
                state.appFormData[key] = '';
            });
            if (action.payload.app){
                state.apps[action.payload.app._id] = action.payload.app;
            }
            return {
                ...state,
                isCreatingNewApp: false,
            };
        case UPDATE_APP_FORM_DATA:
            state.appFormData = {...state.appFormData, ...action.payload};
            return {
                ...state
            };
        case UPDATE_SELECTED_APP_DATA:
            state.selectedAppData.data = {...state.selectedAppData.data, ...action.payload};
            return {
                ...state
            };
        case SELECT_APP_TYPE:
            return {
                ...state,
                appType: action.payload.type
            };

        case GET_APPS_REQUEST:
            return {
                ...state,
                appsLoading: true,
            };
        case GET_APPS_FAILURE:
            return {
                ...state,
                appsLoading: false,
                failure: action.payload,
            };
        case SAVE_APP_DATA_SUCCESS:
            state.apps[action.payload.app._id] = action.payload.app;
            return {
                ...state
            };
        case GET_APPS_SUCCESS:
            const newApps = {};
            action.payload.apps.forEach(app => {
                newApps[app._id] = app;
            });
            return {
                ...state,
                appsLoading: false,
                appsLoaded: true,
                apps: newApps
            };
        case SIGN_IN_SUCCESS:
        case SIGN_UP_SUCCESS:
        case SIGN_OUT_SUCCESS:
            return {
                ...state,
                appsLoading: false,
                appsLoaded: false,
                apps: {},
                selectedAppId: null
            };
        case APP_DELETE_SUCCESS:
            state.apps[action.payload.id].deleted = true;
            return {
                ...state,
                isCreatingNewApp: false,
                appType: "",
            };
        case APP_REFRESH_API_KEY_SUCCESS:
            state.apps[action.payload.app._id] = action.payload.app;
            return {
                ...state
            };
        default:
            return { ...state };
    }
};

export default appsReducer;
