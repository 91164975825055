import React from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { loadApps } from "../../services/action-creators"
import CreatedAppCard from "./CreatedAppCard";
import { apps as appTypes } from '../../config/apps';

import AppCards from "./AppCards";

const AppsContent = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { apps, appsLoaded, appsLoading, failure } = useSelector((state) => state.apps);

    if (!appsLoaded) {
        if (failure) {
            // TODO: Show Error Modal
            return (<div> failure : {JSON.stringify(failure)}</div>)
        }

        if (!appsLoading) {
            dispatch(loadApps());
        }

        return (<div className="page-apps loadingFiles mt-lg-5" />);
    }

     if (Object.keys(apps).length === 0) {
        return (
            <div className="first-apps">
                <div className="page-apps">
                    <h3 className="apps-create-first-title">Create Your First App</h3>
                    <AppCards/>
                </div>
            </div>
        );
     }

    const filterApps = () => {
        return Object.keys(apps)
            .filter(key => !apps[key].deleted);
    };

    const filteredApps = filterApps();

    return(
        <div className="page-apps-list">
            <div className="app-cards-created">
                {filteredApps
                    .map((key) => {
                        return (<CreatedAppCard key={key} data={apps[key]}/>)
                    })
                }
            </div>

        </div>
    )
};

export default AppsContent
