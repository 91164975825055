import React from 'react';
import { apps } from '../../config/apps';
import {Link} from "react-router-dom";

const AppCards = () => {
    return(
        <div className="app-cards">
            {Object.keys(apps).map(type => {
                    const app = apps[type];

                if (type === 'zapier') {
                    return (
                        <Link key={type} to={`/createApp?appType=${type}`} className="app-card app-zapier">
                            <div className="app-card-header">
                                <img src={app.img} alt="" className="zap-icon"/>
                                <div className="app-card-header-text">{app.header}</div>
                            </div>
                            <div className="app-type-description">{app.text}</div>
                            <div className="app-type-description mt-4 mx-lg-auto">Coming soon</div>
                        </Link>
                    )
                }

                return (
                        <Link key={type} to={`/createApp?appType=${type}`} className="app-card">
                            <div className="app-card-header">
                                <img src={app.img} alt=""/>
                                <div className="app-card-header-text">{app.header}</div>
                            </div>
                            <div className="app-type-description">{app.text}</div>
                        </Link>
                    )
                }
            )}
        </div>
    )
};

export default AppCards
