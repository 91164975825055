import React, { useCallback, useState } from 'react'
import { ProgressBar } from 'react-bootstrap';
import { useDropzone } from 'react-dropzone'
import { useDispatch, useSelector } from "react-redux";
import { fileUploadStart } from "../services/action-creators";
import moment from "moment";

const FileUpload = () => {
    const dispatch = useDispatch();
    const { isFileUploading, progress } = useSelector((state) => state.file);
    const [hasError, setHasError] = useState(false);
    const [error, setError] = useState('');
    const [fileName, setFileName] = useState('');

    const onDrop = useCallback(async acceptedFiles => {
        setHasError(false);

        const { type, name, size } = acceptedFiles[0];
        const maxSize = 65 * 1024 * 1024;

        const nameParts = name.split('.');
        const ext = nameParts[nameParts.length - 1].toLowerCase();
        const masSizeError = size > maxSize;
        const wrongFile = !['text/csv', 'text/plain', 'application/vnd.ms-excel'].includes(type) || !['csv',  'txt'].includes(ext);
        if (masSizeError || wrongFile) {
            setHasError(true);
            let errors = [];
            if (wrongFile) {
                errors = errors.concat([`You can't upload file of this type`]);
            }
            if (masSizeError) {
                errors = errors.concat([`File size reached 65MB limit`]);
            }
            setFileName(acceptedFiles[0].name);
            setError(errors.join('. '))
            return;
        }

        const reader = new FileReader();
        reader.readAsText(acceptedFiles[0]);
        const fileText = await new Promise((resolve, reject) => {
            reader.onload = () => {
                resolve(reader.result);
            };
            reader.onerror = (err) => {
                reject(err);
            };
        });

        const newName = `${nameParts[0]}-${moment().format('Y-M-D-h-m-s')}-${Math.ceil(Math.random()*10000)}.${ext}`;
        const displayFileName = `${nameParts[0]}.${ext}`;

        dispatch(fileUploadStart(displayFileName, newName, fileText));
    }, []);

    const { getRootProps, getInputProps } = useDropzone({onDrop});

    return (
        <>
            {
                isFileUploading ?
                    (<ProgressBar className="upload-progress" variant="success" animated now={progress} />) :
                    (<></>)
            }
            {
                (hasError || progress < 0) && <FileUploadProgress fileName={fileName} error={error} progress={100} hasError={hasError} />
            }
            {
                !isFileUploading &&
                <div className="dropzone" {...getRootProps()}>
                    <input {...getInputProps()} />
                    <div className="dropzone-label">
                        <img src="images/clean/icon-plus.svg" alt=""/>
                        <p className="dropzone-text">Click or drop file here to upload</p>
                    </div>
                </div>
            }
        </>
    )
};

export default FileUpload;

const FileUploadProgress = (props) => {
    const {fileName, hasError, error, progress} = props;

    const variant = (progress, hasError) => {
        if (hasError) {
            return "danger";
        }

        if (progress === 100) {
            return "success";
        }

        return "warning";
    }

    const icon = (progress, hasError) => {
        if (hasError) {
            return <img src={`images/clean/upload-failed.svg`} alt="" />;
        }

        if (progress === 100) {
            return <img src={`images/clean/upload-success.svg`} alt="" />;
        }

        return <div className="percent-progress">{`${progress}%`}</div>
    }

    return (
        <div className="file-upload-progress">
            <div className="file-upload-group">
                <div className="file-upload-name">{fileName}</div>
                <div className="file-upload-progress-icon">{icon(progress, hasError)}</div>
            </div>
            <ProgressBar className="upload-progress" variant={variant(progress, hasError)} now={100}/>
            {error && <div className="file-upload-error">{error}</div>}
        </div>
    );
}
