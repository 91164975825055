import React from "react";
import { useSelector } from "react-redux";
import AppCustomSettingsModal from "./settingsModals/AppCustomSettingsModal";
import AppWebhookSettingsModal from "./settingsModals/AppWebhookSettingsModal";
import AppJsSettingsModal from "./settingsModals/AppJsSettingsModal";

const AppSettingsModal = () => {
    const { apps, selectedAppId } = useSelector((state) => state.apps);
    switch(apps[selectedAppId].type) {
        case 'custom':
            return (<AppCustomSettingsModal />);
        case 'js':
            return (<AppJsSettingsModal />);
        case 'webhook':
            return (<AppWebhookSettingsModal />);
        // case 'zappier':
        //     return (<></>);
        default:
            return (<AppCustomSettingsModal />);
    }
};

export default AppSettingsModal;
