import {
    GET_USERS_REQUEST,
    GET_USERS_FAILURE,
    GET_USERS_SUCCESS,
    GET_USER_TRANSACTIONS_REQUEST,
    GET_USER_TRANSACTIONS_SUCCESS,
    GET_USER_TRANSACTIONS_FAILURE,
    CLEAR_USERS,
    SIGN_IN_SUCCESS,
    SIGN_UP_SUCCESS,
    SIGN_OUT_SUCCESS,
    CHANGE_BLOCKED_STATUS_SUCCESS,
    CHOOSE_ADMIN_CLIENTS,
    CHOOSE_ADMIN_PANEL
} from "./action-types";

const userReducer = function (state, action) {
    switch (action.type) {
        case GET_USERS_REQUEST:
            return {
                ...state,
                usersLoading: true,
            };
        case GET_USERS_FAILURE:
            return {
                ...state,
                usersLoading: false,
                failure: action.payload,
            };
        case GET_USER_TRANSACTIONS_REQUEST:
            return {
                ...state,
                userTransactionsLoading: true,
            };
        case GET_USER_TRANSACTIONS_FAILURE:
            return {
                ...state,
                userTransactionsLoading: false,
                failure: action.payload,
            };
        case GET_USERS_SUCCESS:
            const newUsers = {};
            action.payload.users.forEach(user => {
                newUsers[user._id] = user;
            });
            return {
                ...state,
                usersLoading: false,
                usersLoaded: true,
                users: newUsers,
                total: action.payload.total,
            };
        case GET_USER_TRANSACTIONS_SUCCESS:
            const newUserTransactions = {};
            action.payload.userTransactions.forEach(ut => {
                newUserTransactions[ut._id] = ut;
            });
            return {
                ...state,
                userTransactionsLoading: false,
                userTransactionsLoaded: true,
                userTransactions: newUserTransactions
            };
        case CLEAR_USERS:
        case SIGN_IN_SUCCESS:
        case SIGN_UP_SUCCESS:
        case SIGN_OUT_SUCCESS:
            return {
                ...state,
                failure: null,
                usersLoading: false,
                usersLoaded: false,
                userTransactionsLoading: false,
                userTransactionsLoaded: false,
                users: {},
                userTransactions: {}
            };
        case CHANGE_BLOCKED_STATUS_SUCCESS:
            state.users[action.payload.userId].blockAccount = action.payload.blockAccount;
            return {
                ...state,
            };
        case CHOOSE_ADMIN_CLIENTS:
            return {
                ...state,
                userTransactionsLoading: false,
                userTransactionsLoaded: false,
                userTransactions: {}
            };
        case CHOOSE_ADMIN_PANEL:
            return {
                ...state,
                userTransactionsLoading: false,
                userTransactionsLoaded: false,
                userTransactions: {},
                users: {},
                usersLoading: false,
                usersLoaded: false,
            };
        default:
            return { ...state };
    }
};

export default userReducer;
