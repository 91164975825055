import React, { useCallback } from 'react'
import PageMenu from '../component/PageMenu'
import PageModal from '../component/PageModal'
import UsageContent from "./usage/UsageContent";

import { useDispatch } from "react-redux";
import { getUsageDetails, loadApps } from "../services/action-creators";

const Usage = () => {
    const dispatch = useDispatch();
    const fetchApps = useCallback(() => {
        dispatch(loadApps());
    }, [dispatch]);
    const fetchUsageDetails = useCallback(opts => {
        dispatch(getUsageDetails(opts));
    }, [dispatch]);

    return (
        <div className="page">
            <PageMenu activeTabName="usage" />
            {/*<PageAlert />*/}
            <UsageContent
                fetchApps={fetchApps}
                fetchUsageDetails={fetchUsageDetails} />
            <PageModal />
        </div>
    )
};

export default Usage
