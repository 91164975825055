import API from "../../utils/httpAPI";

import {
    CREATE_NEW_APP,
    BACK_TO_APPS,
    SELECT_APP_TYPE,
    CREATE_APP_REQUEST,
    CREATE_APP_SUCCESS,
    CREATE_APP_FAILURE,
    UPDATE_APP_FORM_DATA,
    CLEAR_APP_FORM_DATA,
    GET_APPS_REQUEST,
    GET_APPS_SUCCESS,
    GET_APPS_FAILURE,
    SAVE_APP_DATA_REQUEST,
    SAVE_APP_DATA_SUCCESS,
    SAVE_APP_DATA_FAILURE,
    CHOOSE_APP_USAGE,
    UPDATE_SELECTED_APP_DATA,
    CREATE_CUSTOM_EXPORT,
} from "./action-types";

export const createNewApp = () => {
    return {
        type: CREATE_NEW_APP
    };
};

export const backToApps = () => {
    return {
        type: BACK_TO_APPS
    };
};

export const selectAppType = (type) => {
    return {
        type: SELECT_APP_TYPE,
        payload: {type}
    };
};

const createAppRequest = () => {
    return {
        type: CREATE_APP_REQUEST
    };
};

const createAppSuccess = (app) => {
    return {
        type: CREATE_APP_SUCCESS,
        payload: {app}
    };
};
const createAppFailure = (error) => {
    return {
        type: CREATE_APP_FAILURE,
        payload: error,
    };
};


export const createApp = (data, history) => {
    return function (dispatch) {
        dispatch(createAppRequest());
        API({
            method: "post",
            url: "/app/create",
            data,
        })
            .then(response => {
                history.push('/apps');
                const { savedApp } = response.data;
                dispatch(createAppSuccess(savedApp));
            })
            .catch(error => {
                dispatch(createAppFailure(error));
            });
    };
};

export const updateAppFormData = (updateObj) => {
    return {
        type: UPDATE_APP_FORM_DATA,
        payload: updateObj
    };
};
export const updateSelectedAppData = (updateObj) => {
    return {
        type: UPDATE_SELECTED_APP_DATA,
        payload: updateObj
    };
};
export const clearAppFormData = () => {
    return {
        type: CLEAR_APP_FORM_DATA
    };
};

const getAppsRequest = () => {
    return {
        type: GET_APPS_REQUEST,
    };
};


const getAppsSuccess = (apps) => {
    return {
        type: GET_APPS_SUCCESS,
        payload: {
            apps,
        },
    };
};
const getAppsFailure = (error) => {
    return {
        type: GET_APPS_FAILURE,
        payload: error,
    };
};

const saveAppDataRequest = () => {
    return {
        type: SAVE_APP_DATA_REQUEST,
    };
};

const saveAppDataSuccess = (app) => {
    return {
        type: SAVE_APP_DATA_SUCCESS,
        payload: { app },
    };
};
const saveAppDataFailure = (error) => {
    return {
        type: SAVE_APP_DATA_FAILURE,
        payload: error,
    };
};

export const loadApps = () => {
    return function (dispatch) {
        dispatch(getAppsRequest());
        API({
            method: "post",
            url: "/app/getAll",
            data: {}
        })
            .then((response) => {
                const { apps } = response.data;
                dispatch(getAppsSuccess(apps));
            })
            .catch(async (error) => {
                dispatch(getAppsFailure(error));
            });
    };
};

export const saveAppData = ({ id, appData }) => {
    return function (dispatch) {
        dispatch(saveAppDataRequest());
        API({
            method: "post",
            url: "/app/update",
            data: { id, data: appData }
        })
            .then((response) => {
                const { app } = response.data;
                dispatch(saveAppDataSuccess(app));
            })
            .catch(async (error) => {
                dispatch(saveAppDataFailure(error));
            });
    };
};

export const selectCreatedApp = ({ id, data }, history) => {
    if (history) {
        history.push(`/appUsage/${id}`);
    }
    return {
        type: CHOOSE_APP_USAGE,
        payload: {
            activeTab: 'appUsage',
            appId: id,
            appData: data
        },
    };
};

export const loadAppsByUserId = (userId) => {
    return function (dispatch) {
        dispatch(getAppsRequest());
        API({
            method: 'get',
            url: `/admin/users/${userId}/apps`
        })
            .then((response) => {
                const { apps } = response.data;
                dispatch(getAppsSuccess(apps));
            })
            .catch(async (error) => {
                dispatch(getAppsFailure(error));
            });
    };
};
